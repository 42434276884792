import React, { useMemo } from 'react'
import { ajax } from 'rxjs/ajax'
import { rj, useRj } from 'react-rocketjump'
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import ListOrdini from './ListOrdini'
import OrderDetail from './OrderDetail'
import AddOrder from './AddOrder'
import Tempistiche from './Tempistiche'
import ImportOrders from './ImportOrders'
import PersonaleDetail from './PersonaleDetail'
import PersonaleList from './PersonaleList'
import Fornitori from './Fornitori'
import CalendarAzienda from './CalendarAzienda'
import FasiSchedulate from './FasiSchedulate'
import Schedulatore from './Schedulatore'
import OrdiniFornitore from './OrdiniFornitore'
import RigheOrdini from './RigheOrdini'
import { readDjangoJson, PERMISSIONS } from '../../django'
import { ConfigPlannerContext } from '../../context'
import groupBy from 'lodash/groupBy'
import PermissionRoute from '../../components/PermissionRoute/PermissionRoute'
import ImportRigheOrdiniFornitore from './ImportRigheOrdiniFornitore'
import Stats from './Stats'
import AttivitaOperatore from './AttivitaOperatore'
import AttivitaOperatoreAdmin from './AttivitaOperatoreAdmin'
import LavorazioniOrdineOperatoreAdmin from './LavorazioniOrdineOperatoreAdmin'
import ConfermaOrdine from './ConfermaOrdine'
import Clienti from './Clienti'
import ClienteDetail from './ClienteDetail'
import ImportClienti from './ImportClienti'
import MessaggiOrdiniEmails from './MessaggiOrdiniEmails'
import Performance from './Analisi/Performance'
import Completezza from './Analisi/Completezza'
import ManipolatoreMacchina from './ManipolatoreMacchina'
import StatsClienti from './StatsClienti'
import StatsAgenti from './StatsAgenti'
import PerformanceOperatori from './Analisi/PerformanceOperatori'
import AvanzamentoOrdini from './Avanzamento/AvanzamentoOrdini'
import WarningOrdiniFornitore from './Avanzamento/WarningOrdiniFornitore'

const CodiciSerramentoState = rj({
  name: 'Codici Serramento',
  effect: () => ajax.getJSON(`/api/planner/codici-serramento/`),
})

export default function AdminPreventiviApp() {
  const [{ data: updatedCodici }, { run: fetchCodici }] = useRj(
    CodiciSerramentoState
  )
  const MappedPlannerConfig = useMemo(() => {
    const PlannerConfig = readDjangoJson('planner-config')

    let codiciSerramento = PlannerConfig.codiciSerramento
    if (updatedCodici) {
      codiciSerramento = updatedCodici
    }
    const codiciByCategoria = groupBy(codiciSerramento, 'categoria')

    const MappedPlannerConfig = {
      ...PlannerConfig,
      valoriStato: groupBy(PlannerConfig.valoriStato, 'contesto'),
      categorieWithCodici: PlannerConfig.categorieSerramento.map(
        (categoria) => ({
          label: categoria[1],
          value: categoria[0],
          codici: groupBy(codiciByCategoria[categoria[0]], 'forma') || [],
        })
      ),
      refreshCodiciSerramento: fetchCodici,
    }
    return MappedPlannerConfig
  }, [fetchCodici, updatedCodici])

  return (
    <ConfigPlannerContext.Provider value={MappedPlannerConfig}>
      <Router>
        <Switch>
          <PermissionRoute
            hasPermission={PERMISSIONS.adminOrders}
            path="/tempistiche"
            exact
            component={Tempistiche}
          />
          <Route path="/ordini" exact component={ListOrdini} />
          <Route path="/clienti" exact component={Clienti} />
          <Route path="/messaggi-ordini-emails" exact component={MessaggiOrdiniEmails} />
          <Route path="/clienti/:id" exact component={ClienteDetail} />
          <Route path="/ordini/:id/:tab?" exact component={OrderDetail} />
          <PermissionRoute
            path="/righe-ordini"
            exact
            component={RigheOrdini}
            hasPermission={PERMISSIONS.viewStats}
          />
          <PermissionRoute
            hasPermission={PERMISSIONS.addOrders}
            path="/aggiungi-ordine"
            exact
            component={AddOrder}
          />
          <PermissionRoute
            hasPermission={PERMISSIONS.addOrders}
            path="/importa-ordini"
            exact
            component={ImportOrders}
          />
          <PermissionRoute
            hasPermission={PERMISSIONS.addOrders}
            path="/importa-clienti"
            exact
            component={ImportClienti}
          />
          <PermissionRoute
            hasPermission={PERMISSIONS.adminFornitori}
            path="/fornitori"
            exact
            component={Fornitori}
          />
          <PermissionRoute
            hasPermission={PERMISSIONS.adminPersonale}
            path="/personale"
            exact
            component={PersonaleList}
          />
          <PermissionRoute
            hasPermission={PERMISSIONS.adminPersonale}
            path="/personale/:id"
            exact
            component={PersonaleDetail}
          />
          <PermissionRoute
            hasPermission={PERMISSIONS.adminPersonale}
            path="/calendario-aziendale"
            exact
            component={CalendarAzienda}
          />
          <PermissionRoute
            hasPermission={PERMISSIONS.scheduleOrder}
            path="/schedulatore"
            exact
            component={Schedulatore}
          />
          <PermissionRoute
            hasPermission={PERMISSIONS.updateStatoFasiOrdineFornitore}
            path="/fasi-schedulate"
            exact
            component={FasiSchedulate}
          />
          <PermissionRoute
            hasPermission={PERMISSIONS.viewOrderFasi}
            path="/ordini-fornitore"
            exact
            component={OrdiniFornitore}
          />
          <PermissionRoute
            hasPermission={PERMISSIONS.adminOrders}
            path="/import-righe-ordini-fornitore"
            exact
            component={ImportRigheOrdiniFornitore}
          />
          <PermissionRoute
            path="/stats"
            component={Stats}
            hasPermission={PERMISSIONS.viewStats}
          />
          <PermissionRoute
            path="/operatore"
            exact
            component={AttivitaOperatore}
            hasPermission={PERMISSIONS.addAttivita}
          />
          <PermissionRoute
            path="/attivita-operatori"
            exact
            component={AttivitaOperatoreAdmin}
            hasPermission={PERMISSIONS.adminAttivitaOperatori}
          />
          <PermissionRoute
            path="/analisi/performance"
            exact
            component={Performance}
            hasPermission={PERMISSIONS.adminAttivitaOperatori}
          />
          <PermissionRoute
            path="/analisi/performance/operatori"
            exact
            component={PerformanceOperatori}
            hasPermission={PERMISSIONS.adminAttivitaOperatori}
          />
          <PermissionRoute
            path="/analisi/completezza"
            exact
            component={Completezza}
            hasPermission={PERMISSIONS.adminAttivitaOperatori}
          />
          <PermissionRoute
            path="/lavorazioni-ordine-operatore"
            exact
            component={LavorazioniOrdineOperatoreAdmin}
            hasPermission={PERMISSIONS.adminAttivitaOperatori}
          />
          <PermissionRoute
            path="/conferme-ordini"
            component={ConfermaOrdine}
            hasPermission={PERMISSIONS.confermaOrdini}
          />
          <PermissionRoute
            path="/manipolatore-macchina"
            component={ManipolatoreMacchina}
            hasPermission={PERMISSIONS.adminOrders}
          />
          <PermissionRoute
            path="/statistiche-clienti"
            component={StatsClienti}
            hasPermission={PERMISSIONS.adminOrders || PERMISSIONS.isCapoArea}
          />
          <PermissionRoute
            path="/statistiche-agenti"
            component={StatsAgenti}
            hasPermission={PERMISSIONS.adminOrders || PERMISSIONS.isCapoArea}
          />
          <PermissionRoute
            path="/avanzamento-ordini"
            component={AvanzamentoOrdini}
            hasPermission={PERMISSIONS.adminOrders}
          />
          <PermissionRoute
            path="/warnings-ordini-fornitore"
            component={WarningOrdiniFornitore}
            hasPermission={PERMISSIONS.adminOrders}
          />
          <Redirect from="/" to="/ordini" />
        </Switch>
      </Router>
    </ConfigPlannerContext.Provider>
  )
}
