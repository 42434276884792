import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ModalChooseTipoApertura = ({ isOpen, toggle, onSelect, tipiApertura }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}>Seleziona il tipo di apertura</ModalHeader>
      <ModalBody>
        <div className='container'>
          <div className='row'>
            {tipiApertura.map(tipoApertura => (
              <div className='col-6 col-md-4 p-2 text-center border pointer' key={tipoApertura.id} onClick={() => onSelect(tipoApertura.id)}>
                <img src={tipoApertura.immagine} alt={tipoApertura.nome} style={{maxWidth:100}} />
                <div>{tipoApertura.nome}</div>
              </div>
            ))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Chiudi</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalChooseTipoApertura
