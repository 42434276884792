
export function mergeMaterialiServerField(materiale) {
  const sortByCols = materiale.sort.reduce(
    (all, sortCol, i) => ({
      ...all,
      [sortCol[0]]: {
        direction: sortCol[1],
        order: String(i),
      },
    }),
    {}
  )

  return {
    ...materiale,
    colonne_list: materiale.colonne_list.map((col) => ({
      colonna: col,
      ...(sortByCols[col] ?? { direction: '', order: '' }),
      label: materiale.labels[col] ?? '',
    })),
  }
}