import React, { useState, useCallback } from 'react'
import ModalFormFile from '../../../components/QrApp/ModalFormFile'
import SharedFileRow from './SharedFileRow'
import FolderRow from '../../../components/QrApp/FolderRow'
import { PERMISSIONS } from '../../../django'
import { Button } from 'reactstrap'

function UploadFile({ upload, uploading }) {
  const [showModal, setShowModal] = useState(false)
  const toggleModal = useCallback(() => setShowModal((a) => !a), [])
  const saveFile = useCallback((data) => upload(data, toggleModal), [
    toggleModal,
    upload,
  ])

  return (
    <>
      <Button onClick={toggleModal} color="primary" disabled={uploading}>
        Carica file
        <i className="fa fa-file ml-2" aria-hidden="true"></i>
      </Button>
      <ModalFormFile
        toggle={toggleModal}
        save={saveFile}
        header={
          <span>
            Carica file <i className="fa fa-file ml-2" aria-hidden="true"></i>
          </span>
        }
        isOpen={showModal}
      />
    </>
  )
}

const folderColProps = {
  colSpan: 2,
}

const FoldersAndFilesList = React.memo(({
  sharedFiles,
  childFolders,
  update,
  onDeleteClick,
}) => {
  const [showEditModal, setShowEditModal] = useState(null)
  const closeEditModal = useCallback(() => setShowEditModal(null), [])

  const updateFile = useCallback(
    (fileData) => {
      return update(fileData, closeEditModal)
    },
    [closeEditModal, update]
  )
  const totalNodes = sharedFiles.length + childFolders.length
  return (
    <>
      <table className="table table-bordered table-hover">
        <tbody>
          {totalNodes === 0 && (
            <tr className="bg-light">
              <td>
                <i className="fa fa-folder mr-2" aria-hidden="true"></i>
                <i>Nessun elemento</i>
              </td>
            </tr>
          )}
          {childFolders.map((folder) => (
            <FolderRow
              showMeta={false}
              colProps={folderColProps}
              key={`folder-${folder.id}`}
              folder={folder}
            />
          ))}
          {sharedFiles.map((sharedFile) => (
            <SharedFileRow
              key={`file-${sharedFile.id}`}
              sharedFile={sharedFile}
              onEditClick={update ? setShowEditModal : null}
              onDeleteClick={onDeleteClick}
            />
          ))}
        </tbody>
      </table>
      <ModalFormFile
        hideFile
        toggle={closeEditModal}
        save={updateFile}
        fileData={showEditModal}
        header={
          <span>
            Modifca file
            {showEditModal && (
              <i>
                <small> {showEditModal.name}</small>
              </i>
            )}{' '}
            <i className="fa fa-file ml-2" aria-hidden="true"></i>
          </span>
        }
        isOpen={showEditModal !== null}
      />
    </>
  )
})

export default function SharedFilesAndFolders({
  uploading,
  upload,
  update,
  sharedFiles,
  onDeleteClick,
  childFolders,
  buttons,
}) {
  return (
    <div>
      {PERMISSIONS.adminApp && <div className="mb-2">
        <UploadFile uploading={uploading} upload={upload} />
        {buttons}
      </div>}
      <FoldersAndFilesList
        onDeleteClick={onDeleteClick}
        update={update}
        sharedFiles={sharedFiles}
        childFolders={childFolders}
      />
    </div>
  )
}
