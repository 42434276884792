import React, { useMemo, useState } from 'react'
import { AgentiStatsState } from './localstate'
import { useQsDataTable as useDataTable } from 'react-use-datatable/react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button, ButtonGroup } from 'reactstrap'
import moment from 'moment'
import DateRangeQsFilter from '../../components/Planner/DateRangeQsFilter'
import Column from '../../components/Column'
import { useHistory } from 'react-router-dom'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts'
import ReactSelect from 'react-select'
import { useRunRj } from 'react-rocketjump'
import { AgentiState } from '../AdminPreventiviApp/localstate'
import { PERMISSIONS, USER } from '../../django'
import classNames from 'classnames'

export default function StatsAgenti() {
  const [
    // Data State
    { agenti, pagination, loading, deleting, totals },
    // Table state
    { page, ...filters },
    {
      // DT Actions
      goToPage,
      onFilterChangeDebounced,
      onFilterChange,
      onFiltersChange,
    },
  ] = useDataTable(AgentiStatsState, () => ({
    search: '',
    agenti: [],
    tipo_agente: '',
    page_size: 40,
    ordering: 'user__first_name',
    data_inizio: moment()
      .subtract(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD'),
    data_fine: moment()
      .subtract(1, 'month')
      .endOf('month')
      .format('YYYY-MM-DD'),
  }))

  const columnProps = {
    value: filters.ordering,
    onChange: (value) => onFilterChange(value, 'ordering'),
  }

  const history = useHistory()

  const [typeStat, setTypeStat] = useState('table')

  const [{ data: agentiState }] = useRunRj(AgentiState)

  const agentiOptions = useMemo(() => {
    if (!agentiState) return []
    const idAgente = USER.agente?.id
    if (PERMISSIONS.isCapoArea) {
      const agi = agentiState
        .filter((agente) => agente?.capo_area === idAgente)
        .map((agente) => ({
          value: agente.id,
          label: agente.user.first_name + ' ' + agente.user.last_name,
        }))
      return agi.sort((a, b) => {
        if (a.label < b.label) return -1
        if (a.label > b.label) return 1
        return 0
      })
    }

    // check if user is capo   area

    // return all agenti

    const agi = agentiState.map((agente) => ({
      value: agente.id,
      label: agente.user.first_name + ' ' + agente.user.last_name,
    }))
    // return sorted agenti
    return agi.sort((a, b) => {
      if (a.label < b.label) return -1
      if (a.label > b.label) return 1
      return 0
    })
  }, [agentiState])

  const selectedAgenti = useMemo(() => {
    if (!filters.agenti || !agentiState) {
      return []
    }
    if (typeof filters.agenti === 'string') {
      return filters.agenti.split(',').map((id) => ({
        value: id,
        label:
          agentiState.find((agente) => agente.id === Number(id)).user
            .first_name +
          ' ' +
          agentiState.find((agente) => agente.id === Number(id)).user.last_name,
      }))
    } else {
      return filters.agenti.map((id) => ({
        value: id,
        label:
          agentiState.find((agente) => agente.id === Number(id)).user
            .first_name +
          ' ' +
          agentiState.find((agente) => agente.id === Number(id)).user.last_name,
      }))
    }
  }, [filters.agenti, agentiState])

  const agentiForQs = useMemo(() => {
    if (!filters.agenti || !agenti) {
      return ''
    }
    if (typeof filters.agenti === 'string') {
      return '&agenti=' + filters.agenti
    } else {
      return filters.agenti.map((id) => '&agenti=' + id).join('')
    }
  }, [filters.agenti, agenti])

  return (
    <div className="container-fluid">
      {agenti === null && loading && (
        <div className="text-center mt-4">
          <CircularProgress color="primary" />
        </div>
      )}
      {agenti && (
        <>
          <h3 className="mt-2">Statistiche agenti</h3>
          <div className="row mt-4 align-items-center">
            <div className="col-md-3">
              <DateRangeQsFilter
                filters={filters}
                onFiltersChange={onFiltersChange}
                startField="data_inizio"
                endField="data_fine"
                showClearDates={false}
                withPortal={true}
                customArrowIcon={<div />}
                small={false}
                noBorder
              />
            </div>
            <div className="col-md-2">
              <select
                className="form-control"
                value={filters.tipo_agente}
                onChange={(e) =>
                  onFiltersChange({ tipo_agente: e.target.value })
                }
              >
                <option value={''}>Filtra per tipo agente</option>
                <option value={'B2C'}>B2C</option>
                <option value={'B2B'}>B2B</option>
              </select>
            </div>

            <div className="col-md-5">
              <ReactSelect
                name="agenti"
                isMulti
                placeholder={'Filtra per agenti'}
                options={agentiOptions}
                classNamePrefix="multi-select"
                hideSelectedOptions={false}
                isOptionSelected={(option) =>
                  filters.agenti && filters.agenti.includes(option.value)
                }
                value={selectedAgenti}
                className="stats-multi-select select-multi-options text-nowrap w-100"
                onChange={(newValues) => {
                  onFiltersChange({
                    ...filters,
                    agenti: newValues ? newValues.map((v) => v.value) : [],
                  })
                }}
              />
            </div>
            <div className="col-md-2">
              {/* button for reset */}
              <Button
                onClick={() =>
                  onFiltersChange({
                    tipo_cliente: '',
                    agente: '',
                    data_inizio: moment()
                      .subtract(1, 'month')
                      .startOf('month')
                      .format('YYYY-MM-DD'),
                    data_fine: moment()
                      .subtract(1, 'month')
                      .endOf('month')
                      .format('YYYY-MM-DD'),
                  })
                }
                size="sm"
                outline
                color="secondary"
              >
                Reset
              </Button>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <div className="border-bottom d-flex align-items-center w-100">
                <div
                  className="pointer"
                  onClick={() =>
                    history.push(
                      '/statistiche-clienti?data_inizio=' +
                        filters.data_inizio +
                        '&data_fine=' +
                        filters.data_fine +
                        agentiForQs
                    )
                  }
                >
                  Clienti
                </div>
                <div
                  className={classNames(
                    'font-weight-bold border-bottom border-primary pointer ml-4'
                  )}
                  onClick={() =>
                    history.push(
                      '/statistiche-agenti?data_inizio=' +
                        filters.data_inizio +
                        '&data_fine=' +
                        filters.data_fine +
                        agentiForQs
                    )
                  }
                >
                  Agenti
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <ButtonGroup>
                  <Button
                    color={'primary'}
                    onClick={() => setTypeStat('table')}
                    active={typeStat === 'table'}
                    outline={typeStat === 'chart'}
                  >
                    Tabella
                  </Button>
                  <Button
                    color={'primary'}
                    onClick={() => setTypeStat('chart')}
                    active={typeStat === 'chart'}
                    outline={typeStat === 'table'}
                  >
                    Grafico
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          {typeStat === 'table' && (
            <div className="table-responsive">
              <table className="table table-bordered mt-2">
                <thead>
                  <tr>
                    <td>
                      <input
                        name="search"
                        type="text"
                        className="form-control form-control-sm"
                        placeholder={'Ricerca libera'}
                        value={filters && filters.search}
                        onChange={onFilterChangeDebounced}
                      />
                    </td>

                    <td className="text-center">
                      {totals.totale_clienti && (
                        <span className="badge badge-primary">
                          {' '}
                          {totals.totale_clienti}
                        </span>
                      )}
                    </td>
                    <td className="text-center">
                      {totals.totale_ordini && (
                        <span className="badge badge-primary">
                          {' '}
                          {totals.totale_ordini}
                        </span>
                      )}
                    </td>
                    <td className="text-center">
                      {totals.totale_serramenti && (
                        <span className="badge badge-primary">
                          {' '}
                          {totals.totale_serramenti}
                        </span>
                      )}
                    </td>
                    <td>
                      {totals.totale_listino && (
                        <span className="badge badge-primary">
                          {' '}
                          {totals.totale_listino}
                        </span>
                      )}
                    </td>
                    <td>
                      {totals.totale_sconto && (
                        <span className="badge badge-primary">
                          {' '}
                          {totals.totale_sconto.toFixed(2)} %
                        </span>
                      )}
                    </td>
                    <td>
                      {totals.totale_media_ordini && (
                        <span className="badge badge-primary">
                          {' '}
                          {totals.totale_media_ordini.toFixed(2)}
                        </span>
                      )}
                    </td>
                    <td>
                      {totals.totale_media_serramenti && (
                        <span className="badge badge-primary">
                          {' '}
                          {totals.totale_media_serramenti.toFixed(2)}
                        </span>
                      )}
                    </td>
                    <td>
                      {totals.totale_netto && (
                        <span className="badge badge-primary">
                          {' '}
                          {totals.totale_netto.toFixed(2)}
                        </span>
                      )}
                    </td>
                    <td>
                      {totals.totale_budget && (
                        <span className="badge badge-primary">
                          {' '}
                          {totals.totale_budget.toFixed(2)}
                        </span>
                      )}
                    </td>
                    <td>
                      {totals.totale_differenza_netto_budget ? (
                        <span className="badge badge-primary">
                          {' '}
                          {totals.totale_differenza_netto_budget.toFixed(2)} %
                        </span>
                      ) : (
                        <span className="badge badge-primary">0 %</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <Column {...columnProps} field="user__first_name">
                      Nome
                    </Column>
                    <Column {...columnProps} field="numero_clienti">
                      N° clienti
                    </Column>
                    <Column {...columnProps} field="numero_ordini">
                      N° commesse
                    </Column>
                    <Column {...columnProps} field="numero_serramenti">
                      N° serramenti
                    </Column>
                    <Column {...columnProps} field="listino_serramenti">
                      Listino
                    </Column>
                    <Column {...columnProps} field="sconto_serramenti">
                      Sconto
                    </Column>
                    <Column {...columnProps} field="media_ordini">
                      Media commesse
                    </Column>
                    <Column {...columnProps} field="media_serramenti">
                      Media € serramento
                    </Column>
                    <Column {...columnProps} field="netto_serramenti">
                      Netto
                    </Column>
                    <Column {...columnProps} field="budget_agente">
                      Budget
                    </Column>
                    <Column {...columnProps} field="differenza_netto_budget">
                      Differenza
                    </Column>
                  </tr>
                </thead>
                <tbody className="stats-table">
                  {agenti.map((agente) => (
                    <tr key={agente.id}>
                      <td
                        style={{
                          background:
                            agente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {agente?.user_data?.first_name}{' '}
                        {agente?.user_data?.last_name}
                      </td>
                      <td
                        style={{
                          background:
                            agente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {agente.numero_clienti}
                      </td>
                      <td
                        style={{
                          background:
                            agente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {agente.numero_ordini}
                      </td>
                      <td
                        style={{
                          background:
                            agente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {agente.numero_serramenti}
                      </td>
                      <td
                        style={{
                          background:
                            agente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {agente.listino_serramenti}
                      </td>
                      <td
                        style={{
                          background:
                            agente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {agente.sconto_serramenti} %
                      </td>
                      <td
                        style={{
                          background:
                            agente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {agente.media_ordini}
                      </td>
                      <td
                        style={{
                          background:
                            agente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {agente.media_serramenti}
                      </td>
                      <td
                        style={{
                          background:
                            agente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {agente.netto_serramenti}
                      </td>
                      <td
                        style={{
                          background:
                            agente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        {agente.budget_agente}
                      </td>
                      <td
                        style={{
                          background:
                            agente.differenza_netto_budget >= 100
                              ? '#CFF5C9'
                              : 'inherit',
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <div
                            style={{
                              width: 80,
                            }}
                          >
                            {
                              // Differenza in percentuale tra budget e netto
                              agente.differenza_netto_budget ?? 0
                            }
                            {' %'}
                          </div>
                          <div
                            style={{
                              height: 5,
                              width: 180,
                              background: '#D7E7F8',
                              position: 'relative',
                              borderRadius: 2,
                            }}
                          >
                            <div
                              className="position-absolute"
                              style={{
                                height: 5,
                                width: `${agente.differenza_netto_budget}%`,
                                maxWidth: '100%',
                                background:
                                  agente.differenza_netto_budget < 20
                                    ? '#CA0A0A'
                                    : agente.differenza_netto_budget < 40
                                    ? '#E77D1C'
                                    : agente.differenza_netto_budget < 60
                                    ? '#F9BC20'
                                    : agente.differenza_netto_budget < 80
                                    ? '#8DCA0A'
                                    : '#04A90A',
                                borderRadius: 2,
                              }}
                            ></div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {typeStat === 'chart' && (
            <ResponsiveContainer width="100%" height={650} className={'mt-4'}>
              <BarChart
                layout="vertical"
                data={agenti}
                margin={{ top: 5, right: 30, left: 80, bottom: 10 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" dataKey="differenza_netto_budget" />
                <YAxis
                  type="category"
                  dataKey="user_data.first_name"
                  tickFormatter={(value) =>
                    value +
                    ' ' +
                    agenti.find((a) => a.user_data.first_name === value)
                      ?.user_data.last_name
                  }
                />
                <Tooltip
                  formatter={(value) => value + ' %'}
                  labelFormatter={(value) =>
                    value +
                    ' ' +
                    agenti.find((a) => a.user_data.first_name === value)
                      ?.user_data.last_name
                  }
                />
                {/* <Legend /> */}
                <Bar dataKey="differenza_netto_budget">
                  {agenti.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        entry.differenza_netto_budget < 20
                          ? '#CA0A0A'
                          : entry.differenza_netto_budget < 40
                          ? '#E77D1C'
                          : entry.differenza_netto_budget < 60
                          ? '#F9BC20'
                          : entry.differenza_netto_budget < 80
                          ? '#8DCA0A'
                          : '#04A90A'
                      }
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}
        </>
      )}
    </div>
  )
}
