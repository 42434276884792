import React, { useState } from 'react'
import { Collapse, Button } from 'reactstrap'
import { Field } from 'formik'
import DetailFamily from '../../DetailFamily'
import OptionDetail from '../../OptionDetail'
import { FieldRadio } from '../../Fields'
import { useScrollOnTop } from '../../../../helpers'

const Step1Mobile = ({ tipologieFamiglia, setStep, selectedFamily, resetFamilyOptions, values }) => {

  useScrollOnTop()

  const [isOpenFilters,setIsOpenFilters] = useState(false)

  return (
    <div className='Step1Mobile p-mobile-bottom text-center'>
      <div className='container'>
        <div className='row border pt-2 pb-2 pointer' onClick={() => setIsOpenFilters(!isOpenFilters)}>
          <div className='col-12'>
            <div><small>Seleziona una categoria</small></div>
            {selectedFamily && (
              <>
                <strong>{selectedFamily.tipologiaFamiglia.nome}</strong> - { selectedFamily.nome} <i className="fas fa-sort-down ml-2"></i>
              </>
            )}
          </div>
        </div>
        <Collapse isOpen={isOpenFilters}>
          {tipologieFamiglia.map(tipologia => (
            <div key={tipologia.id}>
              <strong>{tipologia.nome}</strong>
              {tipologia && tipologia.famiglie.map(family => (
                <div className="form-check d-flex" key={family.id}>
                  <Field
                    onClick={() => resetFamilyOptions(family.id)}
                    component={FieldRadio}
                    className='form-check-input'
                    name="famiglia"
                    id={family.nome}
                    value={`${family.id}`}
                  />
                  <label htmlFor={family.nome} className='form-check-label'>{family.nome}</label>
                </div>
              ))}
            </div>
          ))}
        </Collapse>
        {selectedFamily && <DetailFamily heightImg={200} famiglia={selectedFamily} />}
        {selectedFamily && (
          <>
            {selectedFamily.opzioni.map(option => (
              <div key={option.id}>
                <OptionDetail opzione={option} valuesForm={values}  />
                <hr />
              </div>
            ))}
          </>
        )}
        <div className='mt-4 text-center'>
          <Button type='button' size='md' color='info' onClick={() => setStep(2)}>Inserimento misure <i className="fas fa-chevron-circle-right"></i></Button>
        </div>
      </div>
    </div>
  )
}

export default Step1Mobile
