import React from 'react'
import moment from 'moment'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

const COLOR_ACTIVITIES_LOCKED = 'rgba(0, 0, 255, 0.3)'

function isThereAttivitaLockedOfSameOrdineAndLavorazione(
  singleAttivita,
  attivita
) {
  const isThere = attivita.some(
    (attivita) =>
      attivita.ordine === singleAttivita.ordine &&
      attivita.lavorazione === singleAttivita.lavorazione &&
      attivita.ora_fine === null
  )
  return isThere
    ? attivita.filter(
        (attivita) =>
          attivita.ordine === singleAttivita.ordine &&
          attivita.lavorazione === singleAttivita.lavorazione &&
          attivita.ora_fine === null
      )
    : []
}


function isLastAttivitaOfOrdineAndLavorazione(singleAttivita, attivita) {
  // if attività is the last of the same ordine and lavorazione by ora_inizio and not by id
  const isLast = attivita.some(
    (attivita) =>
      attivita.ordine &&
      attivita.ordine === singleAttivita.ordine &&
      attivita.lavorazione === singleAttivita.lavorazione &&
      attivita.ora_inizio > singleAttivita.ora_inizio
  )
  return !isLast
}

const TableAttivita = ({
  attivita,
  setAttivita,
  setShowModalDeleteAttivita,
  setShowModalFormAttivitaUpdate,
  isAdmin,
  addAttivita,
  refresh,
  refreshAttivita,
  attivitaLocked,
}) => {
  const setRow = (singleAttivita, activities) => {
    if (!isAdmin) {
      const attivita = isThereAttivitaLockedOfSameOrdineAndLavorazione(
        singleAttivita,
        activities
      )

      // if there is one attivita take it

      const attivitaToSet = attivita.length > 0 ? attivita[0] : singleAttivita

      setAttivita(attivitaToSet)
      setShowModalFormAttivitaUpdate(true)
    }
  }

  return (
    <table className="table table-bordered table-hover table-striped mt-2">
      <thead>
        <tr>
          {isAdmin && <td>Operatore</td>}
          <th>Lavorazione</th>
          <th>Ordine</th>
          <th>Data</th>
          <th>Ora inizio</th>
          <th>Ora fine</th>
          <th>Tempo</th>
          <th>Fine lavoro</th>
          <th>Note</th>
          {!isAdmin && <th>Azioni</th>}
        </tr>
      </thead>
      <tbody>
        {attivita.map((singleAttivita, key) => (
          <tr
            className={classnames({ pointer: !isAdmin })}
            key={key}
            style={{
              background: singleAttivita.ora_fine
                ? undefined
                : COLOR_ACTIVITIES_LOCKED,
            }}
          >
            {isAdmin && (
              <td onClick={() => setRow(singleAttivita, attivita)}>
                {singleAttivita.operatore.user.first_name}{' '}
                {singleAttivita.operatore.user.last_name}
              </td>
            )}
            <td onClick={() => setRow(singleAttivita, attivita)}>
              {singleAttivita.lavorazione_data.nome}<br />
              {singleAttivita.seconda_lavorazione_data && (
                <span>
                  {singleAttivita.seconda_lavorazione_data.nome}
                </span>
              )}
            </td>
            <td
              onClick={() => setRow(singleAttivita, attivita)}
              className="text-nowrap"
            >
              {singleAttivita.ordine_data && (
                <Link to={`/ordini/${singleAttivita.ordine_data.id}/edit`}>
                  <span>
                    {singleAttivita.ordine_data.numero_commessa} -{' '}
                    {singleAttivita.ordine_data.cliente.nome}
                  </span>
                </Link>
              )}
            </td>
            <td
              onClick={() => setRow(singleAttivita, attivita)}
              className="text-nowrap"
            >
              {moment(singleAttivita.data).format('DD/MM/YYYY')}
            </td>
            <td
              onClick={() => setRow(singleAttivita, attivita)}
              className="text-center"
            >
              {singleAttivita.ora_inizio &&
                singleAttivita.ora_inizio.substring(
                  0,
                  singleAttivita.ora_inizio.length - 3
                )}
            </td>
            <td
              onClick={() => setRow(singleAttivita, attivita)}
              className="text-center"
            >
              {singleAttivita.ora_fine ? (
                singleAttivita.ora_fine.substring(
                  0,
                  singleAttivita.ora_fine.length - 3
                )
              ) : (
                <i className="fa fa-alarm text-danger" aria-hidden="true"></i>
              )}
            </td>
            <td
              onClick={() => setRow(singleAttivita, attivita)}
              className="text-nowrap"
            >
              {singleAttivita.tempo
                ? parseInt(
                    moment.duration(singleAttivita.tempo).as('minutes')
                  ) + ' min'
                : ''}
            </td>
            
            <td
              onClick={() => setRow(singleAttivita, attivita)}
              className="text-center"
            >
              {singleAttivita.fine_lavorazione ? (
                <i className="fa fa-check text-success" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-times text-danger" aria-hidden="true"></i>
              )}
            </td>
            <td>
              {singleAttivita.note && singleAttivita.note}
            </td>
            {!isAdmin && isLastAttivitaOfOrdineAndLavorazione(singleAttivita, attivita) && (
              <td className="text-center">
                <div className="d-flex justify-content-center">
                  <div
                    className="pointer ml-2"
                    onClick={(e) => {
                      setAttivita(singleAttivita)
                      setShowModalDeleteAttivita(true)
                    }}
                  >
                    <i
                      title={`Elimina ${singleAttivita.lavorazione_data.nome}`}
                      className="fas fa-trash text-danger"
                    ></i>
                  </div>
                </div>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default TableAttivita
