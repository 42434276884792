import React from 'react'
import { Field } from 'formik'


function getHtmlValue(valori,idValore){
  const valoreOpzione = valori.filter(valore => valore.id === parseInt(idValore))
  return valoreOpzione[0]
}

const OptionDetail = ({ opzione, valuesForm }) => {

  const htmlValue = getHtmlValue(opzione.valori,valuesForm.options[opzione.id])

  return (
    <div className='OptionDetail mt-2'>
      <h6>{opzione.nome}</h6>
      <Field
        component='select'
        className='form-control'
        name={`options.${opzione.id}`}
      >
        {opzione && opzione.valori.map(valore => (
          <option key={valore.id} value={valore.id}>{valore.nome}</option>
        ))}
      </Field>
      <div className='mt-2 mb-2' dangerouslySetInnerHTML={{__html: htmlValue && htmlValue.html}}></div>
    </div>
  )
}

export default OptionDetail
