import React, { useRef, useMemo, useCallback, createContext, useContext, useEffect } from 'react'
import { scaleLinear } from 'd3-scale'
import classNames from 'classnames'
import { FixedSizeList as List, areEqual } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import range from 'lodash/range'
import get from 'lodash/get'
import moment from 'moment'
import { padTimeStr } from '../../apps/PlannerApp/dateUtils'

moment.locale('it')

const TimelineContext = createContext({})

const TODAY = moment().format('YYYY-MM-DD')

// 0-24
const HOURS_IN_A_DAY = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]

const SCHEDULING_ORDER_COLOR = 'deeppink'
const FREE_SCHEDULING_COLOR = 'var(--success)'
const BROKEN_SCHEDULING_COLOR = 'var(--dark)'
const SCHEDULING_ERROR_COLOR = '#e6e600'
const ORDERS_COLORS = ['dodgerblue', 'royalblue', 'skyblue', 'deepskyblue']
const DELIVERY_DATE_COLOR = 'red'
function getOrderColor(id) {
  return ORDERS_COLORS[id % ORDERS_COLORS.length]
}

const HOUR_CELL_WIDTH = 15
export const FASE_ROW_HEIGHT = 110
const LEGEND_WIDTH = 80
export const DATE_TITLE_HEIGHT = 95

const EMPTY_OBJ = {}

const Cell = React.memo(({
  dateTimeStr,
  status,
  orderFilled,
  height,
  showBorderRight = true,
  showBorderBottom = false,
  isCurrentScheduling = false,
  onFreeSlotClick,
  isBroken,
  hasError,
}) => {
  const { interactive, onOrderSlotClick } = useContext(TimelineContext)
  let clickHandler
  if (interactive) {
    if ((status === 'free' || isCurrentScheduling || status === 'filled'/* FIXME*/) && typeof onFreeSlotClick === 'function') {
      clickHandler = () => onFreeSlotClick(dateTimeStr)
    } else if (status === 'filled' && typeof onOrderSlotClick === 'function') {
      clickHandler = () => onOrderSlotClick(orderFilled)
    }
  }

  let bgColor
  if (status === 'filled') {
    if (isBroken) {
      bgColor = BROKEN_SCHEDULING_COLOR
    } else if (isCurrentScheduling) {
      if (!hasError) {
        bgColor = SCHEDULING_ORDER_COLOR
      } else {
        bgColor = SCHEDULING_ERROR_COLOR
      }
    } else {
      bgColor = getOrderColor(orderFilled.id)
    }
  } else if (status === 'free') {
    bgColor = FREE_SCHEDULING_COLOR
  }
  return (
    <div
      onClick={clickHandler}
      style={{
        height: status === 'closed' ? FASE_ROW_HEIGHT : height,
        backgroundColor: bgColor,
      }}
      title={orderFilled ? `Order #${orderFilled.numero_commessa} ${orderFilled.nome_cliente}` : undefined}
      className={classNames('w-100', {
        'event-click-timeline': !!clickHandler,
        'pointer': !!clickHandler,
        'border-right': showBorderRight,
        'border-bottom': showBorderBottom,
        'bg-light': status === 'closed',
      })}
    />
  )
})
Cell.displayName = 'Cell'

const HourSlot = React.memo(({
  slot,
  dateKey,
  hour,
  scheduleOrder,
  scheduleOccupation,
  onFreeSlotClick,
  nomeFase,
  hasError,
  needed,
}) => {
  // Remove current scheduling order from slot
  const showSlot = useMemo(() => {
    if (!scheduleOrder) {
      return slot
    }
    let [showFreeSlots, occupations] = slot

    let occupationReplaced = false
    let showOccupations = occupations.map(o => {
      const [hours, order] = o
      if (order.id === scheduleOrder.id) {
        if (showFreeSlots !== null) {
          showFreeSlots += hours
        }
        if (scheduleOccupation) {
          occupationReplaced = true
          return [scheduleOccupation.duration, scheduleOrder]
        }
        return false
      }
      return o
    })

    // Filter false from show occupations
    showOccupations = showOccupations.filter(Boolean)

    if (scheduleOccupation && !occupationReplaced) {
      // Add new occupation
      showOccupations.push([scheduleOccupation.duration, scheduleOrder])
    }

    // Subtract occupation duration from free slots
    if (scheduleOccupation && showFreeSlots !== null) {
      showFreeSlots -= scheduleOccupation.duration
    }

    // Remove other occupations form inifinite capacity while scheduling ...
    if (showFreeSlots === null) {
      showOccupations = showOccupations.filter(([_, o]) => o.id === scheduleOrder.id)
    }

    return [showFreeSlots, showOccupations]
  }, [slot, scheduleOrder, scheduleOccupation])
  const [freeSlotsCount, occupations] = showSlot

  const workingMinutes = useMemo(() => {
    return (freeSlotsCount || 0) + occupations.reduce((tot, o) => tot + o[0], 0)
  }, [freeSlotsCount, occupations])
  const isLastHour = hour === HOURS_IN_A_DAY[HOURS_IN_A_DAY.length - 1]
  const loopWorkingHours = Math.ceil(workingMinutes / 60)

  const dateTimeStr = dateKey + ' ' + padTimeStr(hour)
  const cellProps = {
    onFreeSlotClick: needed ? onFreeSlotClick : undefined,
    dateTimeStr,
    hasError
  }

  const isClosed = freeSlotsCount === 0 && occupations.length === 0

  const cellCountInfiniteCapacity = occupations.length + 1
  const cellHeightInfinite = FASE_ROW_HEIGHT / cellCountInfiniteCapacity

  const scheduleOrderId = scheduleOrder ? scheduleOrder.id : null

  const scale = useMemo(
    () => scaleLinear()
      .range([0, FASE_ROW_HEIGHT])
      .domain([0, workingMinutes]),
    [workingMinutes]
  )

  return (
    <div style={{ width: HOUR_CELL_WIDTH }} className='d-flex flex-column'>
      {isClosed && (
        <Cell
          {...cellProps}
          showBorderRight={!isLastHour}
          status='closed'
        />
      )}
      {/* Infinite capacity */}
      {!isClosed && freeSlotsCount === null && (
        <>
          {occupations.map(occupation => (
            <Cell
              {...cellProps}
              key={occupation[1].id}
              isCurrentScheduling={occupation[1].id === scheduleOrderId}
              orderFilled={occupation[1]}
              showBorderRight={!isLastHour}
              showBorderBottom
              status='filled'
              height={cellHeightInfinite}
            />
          ))}
          <Cell
            {...cellProps}
            status='free'
            showBorderRight={!isLastHour}
            height={cellHeightInfinite}
            showBorderBottom={false}
          />
        </>
      )}
      {!isClosed && freeSlotsCount !== null && (
        <div className='h-100 position-relative overflow-hidden'>
          <div
            style={{ zIndex: 2 }}
            className='position-absolute w-100'>
            {occupations.map(occupation => (
              <Cell
                {...cellProps}
                key={occupation[1].id}
                isCurrentScheduling={occupation[1].id === scheduleOrderId}
                orderFilled={occupation[1]}
                showBorderRight={!isLastHour}
                showBorderBottom
                status='filled'
                isBroken={freeSlotsCount < 0}
                height={Math.min(scale(occupation[0]), FASE_ROW_HEIGHT)}
              />
            ))}
          </div>
          <div
            style={{ zIndex: 1 }}
            className='position-absolute h-100 w-100 overflow-hidden'>
            {range(loopWorkingHours).map(i => (
              <Cell
                key={i}
                {...cellProps}
                status='free'
                showBorderRight={!isLastHour}
                height={scale(60)}
                showBorderBottom={i !== loopWorkingHours - 1}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
})
HourSlot.displayName = 'HourSlot'

const FaseRow = React.memo(({
  slots,
  fase,
  dateKey,
  showBorderBottom,
  scheduleOrder,
  occupations,
  onFreeSlotClick,
  hasError,
  needed,
}) => {
  const nomeFase = fase.nome_fase
  const onFreeSlotClickFase = useMemo(
    () => {
      if (typeof onFreeSlotClick === 'function') {
        return dateTimeStr =>  onFreeSlotClick(nomeFase, dateTimeStr)
      }
      return undefined
    },
    [onFreeSlotClick, nomeFase]
  )

  return (
    <div
      style={{
        height: FASE_ROW_HEIGHT,
        borderRight: '1px solid #dee2e6',
        borderLeft: '1px solid #dee2e6',
        borderTop: '1px solid white',
        borderBottom: showBorderBottom ? '1px solid #dee2e6' : undefined,
      }}
      className='d-flex'
    >
      {HOURS_IN_A_DAY.map(h => (
        <HourSlot
          needed={needed}
          hasError={hasError}
          onFreeSlotClick={onFreeSlotClickFase}
          dateKey={dateKey}
          hour={h}
          scheduleOccupation={get(occupations, h, null)}
          scheduleOrder={scheduleOrder}
          slot={slots[h]}
          key={h}
          nomeFase={nomeFase}
        />
      ))}
    </div>
  )
})
FaseRow.displayName = 'FaseRow'

const ColumnLegend = React.memo(() => (
  <div className='d-flex flex-1 border-top text-dark'>
    {HOURS_IN_A_DAY.map(h => (
      <div
        key={h}
        style={{ width: HOUR_CELL_WIDTH, fontSize: '60%' }}
        className={classNames('text-center', {
          'border-right': h !== HOURS_IN_A_DAY[HOURS_IN_A_DAY.length - 1],
        })}
      >
        {h}
      </div>
    ))}
  </div>
))
ColumnLegend.displayName = 'ColumnLegend'

const ColumnDay = React.memo(({ index, style, data }) => {
  const {
    fromDate,
    slots,
    fasiScheduler,
    loadedDates,
    scheduleOrder,
    fasiSchedulazioneOrdine,
    onFreeSlotClick,
    onMoveDateDelivery,
    errors,
    fasiNeeded,
    dateDelivery,
  } = data

  const date = moment(fromDate).add(index, 'day')
  const dateKey = date.format('YYYY-MM-DD')
  const loaded = !!loadedDates[dateKey]

  const isDelivery = useMemo(() => {
    if (!dateDelivery) {
      return false
    }
    return date.isSame(moment(dateDelivery), 'date')
  }, [date, dateDelivery])

  return (
    <div style={style}>
      {isDelivery && <div style={{
        position: 'absolute',
        top: 0,
        height: (FASE_ROW_HEIGHT * fasiScheduler.length) + DATE_TITLE_HEIGHT,
        left: -1,
        backgroundColor: DELIVERY_DATE_COLOR,
        width: 2,
      }} />}
      <div className={classNames('text-center d-flex flex-column border-left border-right', {
        'bg-lightgrey': dateKey === TODAY,
      })} style={{ height: DATE_TITLE_HEIGHT }}>
        <div className='h-100 d-flex flex-column'>
          <div>
            <b>{date.format('DD-MM-YYYY')}</b>
          </div>
          {dateDelivery && <div className='d-flex align-itmes-center justify-content-center flex-1 flex-column'>
            <div>
              <button
                onClick={() => onMoveDateDelivery(dateKey)}
                style={{ fontSize: '65%' }}>
                  <i className="fa fa-calendar-check-o" aria-hidden="true" />
              </button>
            </div>
          </div>}
        </div>
        <div style={{
          fontSize: '70%',
          position: 'absolute',
          textTransform: 'uppercase',
          left: 2,
        }}>{date.format('ddd')}</div>
        <ColumnLegend />
      </div>
      {loaded && fasiScheduler.map((fase, i) => (
        <FaseRow
          needed={fasiNeeded ? fasiNeeded[fase.nome_fase] : true}
          hasError={errors ? !!errors[fase.nome_fase] : false}
          dateKey={dateKey}
          onFreeSlotClick={onFreeSlotClick}
          fase={fase}
          key={fase.nome_fase}
          occupations={get(fasiSchedulazioneOrdine, `${fase.nome_fase}.occupations.${dateKey}`, EMPTY_OBJ)}
          slots={slots[fase.nome_fase][dateKey]}
          showBorderBottom={i === fasiScheduler.length - 1}
          scheduleOrder={scheduleOrder}
        />
      ))}
    </div>
  )
}, areEqual)
ColumnDay.displayName = 'ColumnDay'

const SideLegend = React.memo(({ fasiScheduler, render }) => (
  <>
    {fasiScheduler.map((fase, i) => (
      <div
        key={i}
        className={classNames('border', {
          'border-bottom-0': i !== fasiScheduler.length - 1,
        })}
        style={{
          height: FASE_ROW_HEIGHT,
          width: LEGEND_WIDTH,
        }}
        >
          {render
            ? render(fase)
            : (
              <div className='text-capitalize text-dark d-flex justify-content-center align-items-center h-100 w-100'>
                <small>{fase.nome_fase}</small>
              </div>
            )
          }
        </div>
      ))}
  </>
))

function FasiTimeline({
  // The global from domain of our timeline
  fromDate,
  // The global to domain of our timeline can be very very long
  // because our FasiTimeline load and render data on de mand
  toDate,
  // The initial date (virtual scrolled to)
  initialDate = TODAY,
  // DJANGO CONF FASI
  fasiScheduler,
  // The current scheduled order fasi
  fasiSchedulazioneOrdine,
  // Which fasi are neede?
  fasiNeeded,
  // Basic order info (used in the popup)
  scheduleOrder,
  // Time slots descibe the hours in dates closed, open, filled etc
  slots,
  // Load slots on - de - mand
  loadSlots,
  // Render the only free space lol the right top angle of components
  renderAngle,
  // Render the fase leged
  renderFaseLegend,
  // The dates alredy loaed
  loadedDates,
  // callback called when an free slot is clicked
  onFreeSlotClick,
  onOrderSlotClick,
  // Move date delivery
  onMoveDateDelivery,
  // Is my timeline interactive?
  interactive = true,
  // Error in schedule (dependencies)
  errors,
  // Date delivery of order drawed on timeline
  dateDelivery,
  // The timeline width
  width,
}, outerRef) {
  // Data 4 virtualized columns
  const itemData = useMemo(() => ({
    fromDate,
    fasiScheduler,
    fasiSchedulazioneOrdine,
    fasiNeeded,
    slots,
    loadedDates,
    scheduleOrder,
    onFreeSlotClick,
    errors,
    dateDelivery,
    onMoveDateDelivery,
  }), [
    fasiScheduler, slots, scheduleOrder, fasiSchedulazioneOrdine,
    onFreeSlotClick, loadedDates, fromDate, errors, fasiNeeded,
    dateDelivery, onMoveDateDelivery,
  ])

  // Is a column item loaed?
  const isItemLoaded = useCallback(index => {
    const date = moment(fromDate).add(index, 'days').format('YYYY-MM-DD')
    const isLoaeded = !!loadedDates[date]
    return isLoaeded
  }, [loadedDates, fromDate])

  const timelineConfig = useMemo(() => ({
    interactive,
    onOrderSlotClick,
  }), [interactive, onOrderSlotClick])

  // Load (more lol) items on de mand
  const loadMoreItems = useCallback((startIndex, stopIndex) => {
    const loadFromDate = moment(fromDate).add(startIndex, 'days').format('YYYY-MM-DD')
    const loadToDate = moment(fromDate).add(stopIndex, 'days').format('YYYY-MM-DD')
    loadSlots(loadFromDate, loadToDate)
  }, [loadSlots, fromDate])

  // The count is the difference in number of days
  // between the start and the end
  const itemCount = useMemo(
    () => moment(toDate).diff(moment(fromDate), 'days') + 1,
    [toDate, fromDate]
  )

  const initialIndex = useMemo(
    () => moment(initialDate).diff(moment(fromDate), 'days'),
    [initialDate, fromDate]
  )

  const infiniteLoaderRef = useRef()
  const scrollToDate = useCallback((date) => {
    const index = moment(date).diff(moment(fromDate), 'days')
    infiniteLoaderRef.current._listRef.scrollToItem(index, 'start')
  }, [fromDate])

  const scrollToDay = useCallback(() => {
    scrollToDate(TODAY)
  }, [scrollToDate])

  // Living in a workaround life
  useEffect(() => {
    outerRef.current = { scrollToDate }
  }, [scrollToDate, outerRef])

  return (
    <TimelineContext.Provider value={timelineConfig}>
      <div className='d-flex' style={{ width }}>
        <div className='d-flex flex-column'>
          <div style={{ height: DATE_TITLE_HEIGHT }}>
            {typeof renderAngle === 'function' ? renderAngle() : null}
          </div>
          <SideLegend
            render={renderFaseLegend}
            fasiScheduler={fasiScheduler}
          />
        </div>
        {/* TODAY Button */}
        {/* <div style={{
          zIndex: 9999,
          position: 'fixed',
          opacity: 0.8,
          top: 2,
          // top: -20,
          right: 5,
        }}>
          <button onClick={scrollToDay}>
            <i className='fa fa-calendar-o' aria-hidden='true'></i>
          </button>
        </div> */}
        {width && <InfiniteLoader
          ref={infiniteLoaderRef}
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
        >
        {({ onItemsRendered, ref }) => (
          <List
            ref={ref}
            initialScrollOffset={initialIndex * HOUR_CELL_WIDTH * HOURS_IN_A_DAY.length}
            onItemsRendered={onItemsRendered}
            height={'100%'}
            itemCount={itemCount}
            itemData={itemData}
            itemSize={HOUR_CELL_WIDTH * HOURS_IN_A_DAY.length}
            layout='horizontal'
            width={width - LEGEND_WIDTH}
          >
            {ColumnDay}
          </List>
        )}
        </InfiniteLoader>}
      </div>
    </TimelineContext.Provider>
  )
}

export default React.forwardRef(FasiTimeline)
