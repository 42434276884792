import React, { useMemo } from 'react'
import moment from 'moment'
import flatMap from 'lodash/flatMap'
import { useDimensions } from '../../hooks'
import { extent } from 'd3-array'
import { scaleTime } from 'd3-scale'

const AXIS_HEIGHT = 40
const ROW_HEIGHT = 75
const LEGEND_WIDTH = 150
const TICK_SPACE = 100

const SchedulazioneChartRow = React.memo(({ schedulazione, scale }) => {
  const startPos = scale(moment(schedulazione.inizio).toDate())
  const endPos = scale(moment(schedulazione.fine).toDate())
  const display = !(isNaN(Number(startPos)) || isNaN(Number(endPos)))
  const width = endPos - startPos

  return (
    <div className='w-100 position-relative border-bottom' style={{
      height: ROW_HEIGHT,
    }}>
      {display && <div
        className='border bg-success'
        style={{
          height: ROW_HEIGHT,
          position: 'absolute',
          left: startPos,
          width,
        }}
      />}
    </div>
  )
})

const SchedulazioneChartLegend = React.memo(({ schedulazione, scale }) => {
  return (
    <div
      style={{ width: LEGEND_WIDTH, height: ROW_HEIGHT }}
      className='align-items-center d-flex flex-column justify-content-center border-bottom border-right'
    >
      <span className='text-capitalize'>{schedulazione.nome_fase}</span>
      {schedulazione.inizio && (
        <div>
          <small>
            {moment(schedulazione.inizio).format('DD/MM/YYYY HH:mm')}
          </small>
        </div>
      )}
      {schedulazione.fine && (
        <div>
          <small>
            {moment(schedulazione.fine).format('DD/MM/YYYY HH:mm')}
          </small>
        </div>
      )}
    </div>
  )
})

const TopAxis = ({ ticks, scale, width }) => (
  <div
    className='position-relative'
    style={{
      height: AXIS_HEIGHT,
      width: width - LEGEND_WIDTH,
      marginLeft: LEGEND_WIDTH
    }}
  >
    {ticks.map((tick, i) => {
      const mDate = moment(tick)
      return (
        <div
          key={i}
          className='text-center'
          style={{
            position: 'absolute',
            height: 50,
            left: scale(tick),
          }}
        >
          <small>{mDate.format('DD/MM/YY')}</small>
          <br />
          <small>{mDate.format('HH')}</small>
        </div>
      )
    })}
  </div>
)

export default function SchedulazioniGanttChart({ schedulazioni }) {
  const [ref, { width }] = useDimensions({ liveMeasure: false })

  const domain = useMemo(() => (
    extent(flatMap(schedulazioni, s => [
      moment(s.inizio).toDate(),
      moment(s.fine).toDate(),
    ]))
  ), [schedulazioni])
  const scale = useMemo(() => (
    scaleTime()
      .domain(domain)
      .range([0, width - LEGEND_WIDTH - TICK_SPACE / 2])
  ), [width, domain])

  const ticks = useMemo(() => {
    if (!width || !scale) {
      return []
    }
    return scale.ticks(Math.floor(width / TICK_SPACE))
  }, [scale, width])

  return (
    <div className='w-100' ref={ref}>
      {width && (
        <TopAxis ticks={ticks} scale={scale} width={width} />
      )}
      {width &&
        <div className='d-flex border-left border-right border-top'>
          <div style={{ width: LEGEND_WIDTH }}>
            {schedulazioni.map(schedulazione => (
              <SchedulazioneChartLegend
                key={schedulazione.id}
                schedulazione={schedulazione}
              />
            ))}
          </div>
          <div style={{ width: width - LEGEND_WIDTH }}>
            {schedulazioni.map(schedulazione => (
              <SchedulazioneChartRow
                key={schedulazione.id}
                schedulazione={schedulazione}
                scale={scale}
              />
            ))}
          </div>
        </div>}
    </div>
  )
}
