import React, { useCallback, useState } from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useRunRj, useRj } from 'react-rocketjump'
import get from 'lodash/get'
import { PERMISSIONS } from '../../../django'
import { FolderState, FoldersTreeState } from '../localstate'
import ModalFormFolder from '../../../components/QrApp/ModalFormFolder'
import SharedFilesAndFolders from './SharedFilesAndFolders'
import SharedAccounts from './SharedAccounts'

export default function FolderDetail({ match, location, history }) {
  const { id } = match.params
  const [
    { folder, error, uploading, sharing, deletingFile, deletingFolder },
    {
      addFile,
      shareFolder,
      deleteFile,
      patchFolder,
      patchFile,
      deleteFolder,
      addFolder,
    },
  ] = useRunRj(FolderState, [id])

  const [
    { foldersTree, loading: loadingFoldersTree },
    { run: fetchFoldersTree }
  ] = useRj(FoldersTreeState)

  const prevLinkToFolders = get(location, 'state.prevFolders', '/folders')

  const [showDeleteFile, setShowDeleteFile] = useState(null)
  const toggleShowDeleteFile = useCallback(() => setShowDeleteFile(null), [])

  const [showDeleteFolder, setShowDeleteFolder] = useState(false)
  const toggleShowDeleteFolder = useCallback(
    () => setShowDeleteFolder((a) => !a),
    []
  )

  const [showAddFolder, setShowAddFolder] = useState(false)
  const toggleShowAddFolder = useCallback(() => setShowAddFolder((a) => !a), [])

  const [showEdit, setShowEdit] = useState(false)
  const toggleShowEdit = useCallback(() => {
    const nextShoEdit = !showEdit
    setShowEdit(nextShoEdit)
    if (nextShoEdit) {
      fetchFoldersTree()
    }
  }, [showEdit, fetchFoldersTree])

  const folderId = folder ? folder.id : null
  const handleUpload = useCallback(
    (data, clearFile) => {
      const form = new FormData()
      form.append('folder', folderId)
      form.append('name', data.file.name)
      form.append('file', data.file)
      if (data.title) {
        form.append('title', data.title)
      }
      if (data.description) {
        form.append('description', data.description)
      }
      if (data.permalink_code) {
        form.append('permalink_code', data.permalink_code)
      }
      return addFile.onSuccess(clearFile).asPromise(form)
    },
    [addFile, folderId]
  )

  const handleUpdateFile = useCallback(
    (fileData, clearFile) => {
      return patchFile.onSuccess(clearFile).asPromise(fileData)
    },
    [patchFile]
  )

  const handleShare = useCallback(
    (ids, toggle) => {
      shareFolder.onSuccess(toggle).run(folderId, ids)
    },
    [shareFolder, folderId]
  )

  if (error) {
    let msg = `Errore ${error.status}`
    if (error.status === 404) {
      msg = 'Cartella non trovata'
    }
    return (
      <div className="p-4 text-center">
        <div style={{ fontSize: 50 }}>
          <i className="fa fa-exclamation-triangle " />
        </div>
        <h3>{msg}</h3>
      </div>
    )
  }

  if (folder === null) {
    return (
      <div className="mt-4 text-center">
        <CircularProgress color="primary" />
      </div>
    )
  }

  return (
    <div className="container-fluid">
      <div className="d-flex pb-2 mt-2 border-bottom justify-content-between">
        <div className="d-flex align-items-end">
          <div className="mr-5">
            {!folder.parent_folder_data && (
              <Link to={prevLinkToFolders}>
                <i className="fa fa-list" aria-hidden="true"></i> Cartelle
              </Link>
            )}
            {folder.parent_folder_data && (
              <Link to={`/folders/${folder.parent_folder_data.id}`}>
                <i className="fa fa-folder" aria-hidden="true"></i>
                {' ../'}
                {folder.parent_folder_data.name}
              </Link>
            )}
          </div>
          <h4 className="p-0 m-0">
            <div>
              <i className="fa fa-folder mr-3" aria-hidden="true"></i>
              {folder.name}
            </div>
          </h4>
        </div>
        <div className="d-flex align-items-end">
          {PERMISSIONS.adminApp && <Button color="primary" size="sm" onClick={toggleShowEdit}>
            <i className="fa fa-edit" aria-hidden="true"></i>
          </Button>}
          {PERMISSIONS.adminApp && <Button
            className="ml-2"
            color="danger"
            size="sm"
            onClick={toggleShowDeleteFolder}
          >
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Button>}
        </div>
      </div>
      {folder.description && (
        <p
          className="p-2 border border-light text-muted"
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {folder.description}
        </p>
      )}
      <div className="mt-4 row">
        <div className={`col-md-${PERMISSIONS.adminApp ? 8 : 12}`}>
          <SharedFilesAndFolders
            buttons={
              <>
                <Button
                  className="ml-2"
                  onClick={toggleShowAddFolder}
                  color="primary"
                >
                  Nuova cartella
                  <i className="fa fa-folder ml-2" aria-hidden="true"></i>
                </Button>
              </>
            }
            onDeleteClick={PERMISSIONS.adminApp ? setShowDeleteFile : null}
            sharedFiles={folder.shared_files}
            childFolders={folder.child_folders}
            uploading={uploading}
            upload={handleUpload}
            update={PERMISSIONS.adminApp ? handleUpdateFile : null}
          />
        </div>
        {PERMISSIONS.adminApp && <div className="col-md-4">
          {!folder.parent_folder_data && <SharedAccounts
            onShare={handleShare}
            sharing={sharing}
            sharedAccounts={folder.accounts}
          />}
        </div>}
      </div>
      <ModalFormFolder
        folder={folder}
        isOpen={showEdit}
        toggle={toggleShowEdit}
        foldersTree={foldersTree}
        loadingFoldersTree={loadingFoldersTree}
        withParentFolder
        header={`Modifica cartella ${folder.name}`}
        save={(folder) =>
          patchFolder
            .onSuccess(() => {
              toggleShowEdit()
            })
            .asPromise(folder)
        }
      />
      <ModalFormFolder
        isOpen={showAddFolder}
        toggle={toggleShowAddFolder}
        header={`${folder.name} / Nuova Cartella`}
        save={newFolder => addFolder
          .onSuccess(() => toggleShowAddFolder())
          .asPromise({
            ...newFolder,
            parent_folder: folder.id,
          })}
      />
      <Modal isOpen={showDeleteFile !== null} toggle={toggleShowDeleteFile}>
        <ModalHeader toggle={toggleShowDeleteFile}>
          {showDeleteFile && `Eliminare il file ${showDeleteFile.name}`}
        </ModalHeader>
        <ModalBody>
          {showDeleteFile && (
            <>Sei sicuro di voler eliminare il file {showDeleteFile.name}?</>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button disabled={deletingFile} onClick={toggleShowDeleteFile}>
            Annulla
          </Button>
          <Button
            disabled={deletingFile}
            color="danger"
            onClick={() => {
              deleteFile
                .onSuccess(() => {
                  toggleShowDeleteFile()
                })
                .run(showDeleteFile.id)
            }}
          >
            Elimina
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showDeleteFolder} toggle={toggleShowDeleteFolder}>
        <ModalHeader toggle={toggleShowDeleteFolder}>
          Eliminare la cartella {folder.name}?
        </ModalHeader>
        <ModalBody>
          Sei sicuro di voler eliminare la cartella {folder.name}?
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button disabled={deletingFolder} onClick={toggleShowDeleteFolder}>
            Annulla
          </Button>
          <Button
            disabled={deletingFolder}
            color="danger"
            onClick={() => {
              deleteFolder
                .onSuccess(() => {
                  history.push(`/folders`)
                })
                .run(folder.id)
            }}
          >
            Elimina
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
