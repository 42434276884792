import React, { useState } from 'react'
import { useRunRj } from 'react-rocketjump'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Spinner,
} from 'reactstrap'
import { BomRigaOrdineState } from '../../../apps/PlannerApp/localstate'

const ModalBom = ({ isOpen, toggle, order, indexRow, numero }) => {
  const [index, setIndex] = useState(0)
  const [{ bom, loading }] = useRunRj(BomRigaOrdineState, [
    order?.numero_commessa,
    indexRow + 1,
    index + 1,
  ])

  console.log(loading)

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        Visualizza BOM - {order?.numero_commessa} - Riga #{indexRow + 1}
      </ModalHeader>
      <ModalBody
        style={{ maxHeight: '70vh', overflowY: 'auto', minHeight: '70vh' }}
      >
        <div>
          <h5>Posizione #{index + 1}</h5>
        </div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Ord.</th>
              <th>Nome</th>
              <th>Quantità</th>
              <th>Meins</th>
              <th>Image</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={5} className="text-center">
                  <Spinner color="primary" />
                </td>
              </tr>
            )}
            {bom?.result?.data?.length === 0 && (
              <tr>
                <td colSpan={5} className="text-center">
                  Nessun risultato
                </td>
              </tr>
            )}
            {bom?.result?.data?.map((item, index) => (
              <tr key={index}>
                <td>{item.ORD}</td>
                <td>{item.MAKTX}</td>
                <td>{item.QUANTITY}</td>
                <td>{item.MEINS}</td>
                <td className="pointer">
                  <img
                    src={`${item.PIC_URL}`}
                    alt={item.MAKTX}
                    width={100}
                    onClick={() => window.open(`${item.PIC_URL}`, '_blank')}
                  />
                  <div className="bg-light text-dark text-center p-2">
                    <i
                      className="fas fa-external-link-alt ml-2"
                      onClick={() => window.open(`${item.PIC_URL}`, '_blank')}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button color="secondary" onClick={toggle}>
          Chiudi
        </Button>
        <div className="d-flex align-items-center">
          <div className="mr-2">
            <select
              className="form-control"
              value={index}
              onChange={(e) => setIndex(Number(e.target.value))}
            >
              {Array.from({ length: numero }, (_, i) => i).map((item) => (
                <option key={item} value={item}>
                  Posizione #{item + 1}
                </option>
              ))}
            </select>
          </div>
          <div className="mr-2">
            <Button
              color="primary"
              onClick={() => setIndex(index - 1)}
              disabled={index === 0}
            >
              Indietro
            </Button>
          </div>
          <Button
            color="primary"
            onClick={() => setIndex(index + 1)}
            disabled={index === numero - 1}
          >
            Avanti
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ModalBom
