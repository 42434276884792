import React from 'react'
import {
  Nav, NavItem, NavLink
} from 'reactstrap'
import classNames from 'classnames'

const TabsDesktop = ({ setStep, step }) => (
  <div className='container mt-2'>
    <Nav tabs >
      <NavItem>
        <NavLink
          className={classNames('pointer',{ active: step === 1 })}
          onClick={() => setStep(1)}
        >
          <div>
            <div><strong>Step 1</strong></div>
            <div>Scelta serramento</div>
          </div>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={classNames('pointer',{ active: step === 2 })}
          onClick={() => setStep(2)}
        >
          <div>
            <div><strong>Step 2</strong></div>
            <div>Inserimento misure</div>
          </div>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={classNames('pointer',{ active: step === 3 })}
          onClick={() => setStep(3)}
          >
            <div>
              <div><strong>Step 3</strong></div>
              <div>Riferimenti</div>
            </div>
          </NavLink>
        </NavItem>
    </Nav>
  </div>
)

export default TabsDesktop
