import get from 'lodash/get'
import isPlainObject from 'lodash/isPlainObject'
import mapValues from 'lodash/mapValues'
import { AjaxError } from 'rxjs/ajax'

// Fix intial values null
// \w empty string
export const fixInitialValuesNull = (values, fields, maxDepth = Infinity) => {
  if (maxDepth === 0) {
    return values
  }
  if (!values || !isPlainObject(values)) {
    return values
  }
  return (fields || Object.keys(values)).reduce((fixedValues, field) => {
    let fixedValue
    if (isPlainObject(values[field])) {
      fixedValue = fixInitialValuesNull(values[field], fields, maxDepth - 1)
    } else if (Array.isArray(values[field])) {
      fixedValue = values[field].map((value) =>
        fixInitialValuesNull(value, fields, maxDepth - 1)
      )
    } else {
      fixedValue = values[field] === null ? '' : values[field]
    }
    return {
      ...fixedValues,
      [field]: fixedValue,
    }
  }, values)
}

const arrayze = (a) => (Array.isArray(a) ? a : [a])

// Set null instead of empty string
export const setNullOnEmptyValues = (values, fields, maxDepth = Infinity) => {
  if (maxDepth === 0) {
    return values
  }
  if (!values || !isPlainObject(values)) {
    return values
  }
  return (fields || Object.keys(values)).reduce((fixedValues, field) => {
    let fixedValue
    if (isPlainObject(values[field])) {
      fixedValue = setNullOnEmptyValues(values[field], fields, maxDepth - 1)
    } else if (Array.isArray(values[field])) {
      fixedValue = values[field].map((value) =>
        setNullOnEmptyValues(value, fields, maxDepth - 1)
      )
    } else {
      fixedValue = values[field] === '' ? null : values[field]
    }
    return {
      ...fixedValues,
      [field]: fixedValue,
    }
  }, values)
}

export const transformErrorForForm = (error) => {
  if (error.status === 400) {
    let errorData
    if (error instanceof AjaxError && isPlainObject(error.response)) {
      errorData = error.response
    } else if (isPlainObject(get(error, 'response.body'))) {
      errorData = error.response.body
    }
    // TODO: Better joins of errors...
    if (errorData) {
      return mapValues(errorData, (listOfErrors) =>
        arrayze(listOfErrors).join(',')
      )
    }
  }
  // When no 400 (not related 2 form)
  // add a special __noFieldsServerError key \w original error
  return {
    __noFieldsServerError: error,
  }
}
