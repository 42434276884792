import React, { useMemo, useState } from 'react'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import 'moment/locale/it'

moment.locale('it')
const FORMAT = 'YYYY-MM-DD'

export default function DateRangeQsFilter({
  filters,
  onFiltersChange,
  startField,
  endField,
  showClearDates = true,
  ...props
}) {

  const rawStart = filters[startField]
  const startDate = useMemo(() => {
    if (rawStart === null || rawStart === '' || rawStart === undefined) {
      return null
    }
    const m = moment(rawStart, FORMAT)
    return m.isValid() ? m : null
  }, [rawStart])

  const rawEnd = filters[endField]
  const endDate = useMemo(() => {
    if (rawEnd === null || rawEnd === '' || rawEnd === undefined) {
      return null
    }
    const m = moment(rawEnd, FORMAT)
    return m.isValid() ? m : null
  }, [rawEnd])

  const [focusedInput, setFocusedInput] = useState(null)
  return (
    <DateRangePicker
      startDate={startDate}
      startDateId={startField}
      endDate={endDate}
      block={true}
      small={true}
      showClearDates={showClearDates}
      horizontalMargin={30}
      endDateId={endField}
      startDatePlaceholderText={'Data inizio'}
      endDatePlaceholderText={'Data fine'}
      hideKeyboardShortcutsPanel
      isOutsideRange={() => false}
      onDatesChange={({ startDate, endDate }) => {
        onFiltersChange({
          [startField]: startDate ? startDate.format(FORMAT) : '',
          [endField]: endDate ? endDate.format(FORMAT) : ''
        })
      }}
      focusedInput={focusedInput}
      onFocusChange={focusedInput => setFocusedInput(focusedInput)}
      {...props}
    />
  )
}
