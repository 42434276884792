import { Field, Formik } from 'formik'
import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { Button } from 'reactstrap'
import pick from 'lodash/pick'
import { FileField, InputField } from '../../../components/Form/Fields'
import {
  fixInitialValuesNull,
  transformErrorForForm,
} from '../../../components/Form/Fields/utils'
import { Link } from 'react-router-dom'

const ConfermaOrdineSchema = Yup.object().shape({
  nome_cliente: Yup.string().required(),
  email_cliente: Yup.string().required(),
})

export default function ConfermaOrdinePageForm({
  save,
  confermaOrdine,
  readOnly = false,
}) {
  return (
    <Formik
      onSubmit={(values, actions) => {
        return save(values).then(
          () => {
            actions.setSubmitting(false)
          },
          (error) => {
            actions.setSubmitting(false)
            actions.setErrors(transformErrorForForm(error))
          }
        )
      }}
      validationSchema={ConfermaOrdineSchema}
      initialValues={useMemo(
        () =>
          fixInitialValuesNull(
            pick(confermaOrdine, [
              'id',
              'nome_cliente',
              'tipo_cliente',
              'codice_cliente',
              'email_cliente',
              'numero_commessa',
              'note',
            ])
          ),
        [confermaOrdine]
      )}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="m-0 p-0">
          <div className="row">
            <div className="col-md-4">
              <Field
                disabled={readOnly}
                autoComplete="off"
                component={InputField}
                label="Nome Cliente"
                name="nome_cliente"
              />
            </div>
            <div className="col-md-4">
              <Field
                disabled={readOnly}
                autoComplete="off"
                component={InputField}
                label="Tipo Cliente"
                name="tipo_cliente"
              />
            </div>
            <div className="col-md-4">
              <Field
                disabled={readOnly}
                autoComplete="off"
                component={InputField}
                label="Codice Cliente"
                name="codice_cliente"
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <Field
                disabled={readOnly}
                autoComplete="off"
                component={InputField}
                label="Email Cliente"
                type="email"
                name="email_cliente"
              />
            </div>
            <div className="col-md-4">
              <Field
                disabled={readOnly || confermaOrdine.ordine}
                autoComplete="off"
                component={InputField}
                label={
                  <span>
                    Numero Commessa{' '}
                    {confermaOrdine.ordine && (
                      <Link to={`/ordini/${confermaOrdine.ordine.id}`}>
                        Ordine {'#'}{confermaOrdine.ordine.numero_commessa}
                      </Link>
                    )}
                  </span>
                }
                name="numero_commessa"
              />
            </div>
          </div>
          <Field
            disabled={readOnly}
            className="mt-2"
            autoComplete="off"
            component={InputField}
            label="Note"
            type="textarea"
            name="note"
          />
          {!readOnly && (
            <div className="d-flex mt-3">
              <Field
                component={FileField}
                type="file"
                label="Preventivo"
                name="preventivo_da_firmare"
              />
              <Field
                component={FileField}
                type="file"
                label="Preventivo Firmato"
                name="preventivo_firmato"
              />
            </div>
          )}
          {readOnly && (
            <div className="d-flex align-items-center mt-3">
              <Button
                outline
                tag="a"
                target='_blank'
                rel="noopener noreferrer"
                href={confermaOrdine.preventivo_da_firmare}
                size="sm"
                color="success"
              >
                <i className="fa fa-download" aria-hidden="true"></i> Preventivo
                Da Firmare
              </Button>
              <Button
                outline
                tag="a"
                target='_blank'
                rel="noopener noreferrer"
                disabled={!confermaOrdine.preventivo_firmato}
                href={confermaOrdine.preventivo_firmato}
                size="sm"
                color="success"
                className="ml-2"
              >
                <i className="fa fa-download" aria-hidden="true"></i> Preventivo
                Firmato
              </Button>
            </div>
          )}
          {!readOnly && (
            <div className="mt-2 text-right">
              <Button disabled={isSubmitting} type="submit" color="success">
                Salva
              </Button>
            </div>
          )}
        </form>
      )}
    </Formik>
  )
}
