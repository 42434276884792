import React, { useMemo } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { Formik, Field } from "formik"
import * as Yup from "yup"
import { InputField } from "../../Form/Fields"
import {
  transformErrorForForm,
  setNullOnEmptyValues,
  fixInitialValuesNull,
} from "../../Form/Fields/utils"
import { RichTextNewsletterField } from "../../Form/QuillField"

const MessaggioSchema = Yup.object().shape({
  titolo: Yup.string().required(),
})

const ModalFormMessaggioOrdineEmail = ({ isOpen, toggle, save, messaggio, agenti }) => {
  const initialValues = useMemo(() => {
    if (messaggio) {
      return fixInitialValuesNull(messaggio)
    } else {
      return {
        titolo: "",
        testo: "",
      }
    }
  }, [messaggio])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" scrollable>
      <ModalHeader toggle={toggle}>
        {messaggio
          ? `Modifica messaggio -  ${messaggio.titolo}`
          : "Aggiungi messaggio"}
      </ModalHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={MessaggioSchema}
        onSubmit={(values, actions) => {
          return save(setNullOnEmptyValues(values)).then(
            () => {
              actions.setSubmitting(false)
            },
            (error) => {
              actions.setSubmitting(false)
              actions.setErrors(transformErrorForForm(error))
            }
          )
        }}
        render={({ handleSubmit, values, isSubmitting, errors }) => (
          <>
            <ModalBody>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <Field
                      label="Titolo *"
                      type="text"
                      name="titolo"
                      className="mb-2"
                      component={InputField}
                    />
                  </div>
                  <div className="col-md-12">
                    <Field
                      label="Testo"
                      type="textarea"
                      name="testo"
                      rows={10}
                      className="mb-2"
                      component={RichTextNewsletterField}
                    />
                  </div>
                </div>

                <input type="submit" className="d-none" />
              </form>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
              <Button
                disabled={isSubmitting}
                color="secondary"
                onClick={toggle}
              >
                Chiudi
              </Button>
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
                color="success"
              >
                Salva
              </Button>
            </ModalFooter>
          </>
        )}
      />
    </Modal>
  )
}

export default ModalFormMessaggioOrdineEmail
