import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export const RichTextNewsletterField = ({ field, ...props }) => {
  return (
    <ReactQuill
      {...props}
      // modules={quillModules}
      value={field.value}
      onChange={field.onChange(field.name)}
    />
  )
}
