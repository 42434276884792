import React, { useState, useCallback } from 'react'
import { useRunRj } from 'react-rocketjump'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useQsDataTable } from 'react-use-datatable/react'
import get from 'lodash/get'
import Paginator from '../../components/Paginator'
import { UsersState } from './localstate'
import {
  AgentiState,
} from '../AdminPreventiviApp/localstate'
import ModalDeleteUser from '../../components/Users/ModalDeleteUser'
import moment from 'moment'

export default function UsersList() {
  const [
    { users, pagination, deleting },
    { page },
    { goToPage, onFilterChangeDebounced, onFilterChange, deleteUser, refresh },
  ] = useQsDataTable(UsersState, () => ({
    search: '',
    is_staff: '',
    is_superuser: '',
    is_app_account: '',
    is_operatore: '',
    agente: '',
  }))

  const [{ data: agenti }] = useRunRj(AgentiState)

  const [selectedUser, setUser] = useState(null)
  const toggleUser = useCallback(() => setUser(null), [])
  

  console.log(users)

  return (
    <div className="container-fluid mt-2">
      <div className="d-flex mb-2 justify-content-between">
        <div className='d-flex '>
          <input
            style={{ width: 'auto' }}
            placeholder="Cerca"
            name="search"
            onChange={onFilterChangeDebounced}
            className="form-control"
            type="text"
          />

        </div>
        <div>
          <Button to={'/create'} color="primary" tag={Link} outline>
            <i className="fa fa-user-plus mr-2" aria-hidden="true"></i>
            Crea utente
          </Button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <select name='is_superuser' className='w-auto custom-select custom-select-sm' onChange={onFilterChange}>
                <option value={''}>Tutti</option>
                <option value='0'>No</option>
                <option value='1'>Si</option>
                </select>
              </td>
              <td>
                <select name='is_staff' className='w-auto custom-select custom-select-sm' onChange={onFilterChange}>
                  <option value={''}>Tutti</option>
                  <option value='0'>No</option>
                  <option value='1'>Si</option>
                </select>
              </td>
              <td>
                <select name='is_operatore' className='w-auto custom-select custom-select-sm' onChange={onFilterChange}>
                  <option value={''}>Tutti</option>
                  <option value='0'>No</option>
                  <option value='1'>Si</option>
                </select>
              </td>
              <td>
                <select name='is_app_account' className='w-auto custom-select custom-select-sm' onChange={onFilterChange}>
                  <option value={''}>Tutti</option>
                  <option value='0'>No</option>
                  <option value='1'>Si</option>
                </select>
              </td>
              <td></td>
              <td></td>
              <td>
                <div className='col-md-3'>
                  <select name='agente' className='w-auto custom-select custom-select-sm' onChange={onFilterChange}>
                    <option value=''>Agente</option>
                    {agenti && agenti.map(agente => (
                      <option key={agente.id} value={agente.id}>{agente.user.first_name} {agente.user.last_name}</option>
                    ))}
                  </select>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Username</td>
              <td>Email</td>
              <td>Nome</td>
              <td>Cognome</td>
              <td>Note</td>
              <td>Admin</td>
              <td>Staff</td>
              <td>Operatore</td>
              <td>APP</td>
              <td>Ultimo Accesso APP</td>
              <td>APP clienti</td>
              <td>Agente</td>
              <td>Agente Capoarea</td>
              <td>Azioni</td>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user) => (
                <tr key={user.id}>
                  <td>
                    <Link to={`/${user.id}`}>{user.username}</Link>
                  </td>
                  <td>{user.email}</td>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{get(user, 'info.note', '')}</td>
                  <td className="text-center">
                    {user.is_superuser ? (
                      <i
                        className="fa fa-check text-success"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-times text-danger"
                        aria-hidden="true"
                      ></i>
                    )}
                  </td>
                  <td className="text-center">
                    {user.is_staff ? (
                      <i
                        className="fa fa-check text-success"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-times text-danger"
                        aria-hidden="true"
                      ></i>
                    )}
                  </td>
                  <td className="text-center">
                    {user.operatore ? (
                      <i
                        className="fa fa-check text-success"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-times text-danger"
                        aria-hidden="true"
                      ></i>
                    )}
                  </td>
                  <td className="text-center">
                    {user.app_account ? (
                      <i
                        className="fa fa-check text-success"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-times text-danger"
                        aria-hidden="true"
                      ></i>
                    )}
                  </td>
                  <td className="text-center">
                    {user.app_account && user.app_account.ultimo_accesso ? (
                      moment(user.app_account.ultimo_accesso).format('DD/MM/YYYY HH:mm')
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {user.app_account &&
                      user.app_account.accesso_clienti.map((cliente) => (
                        <div key={cliente.id}>{cliente.nome}</div>
                      ))}
                  </td>
                  <td className="text-center">
                    {user.agente && <span>{user.agente.codice}</span>}
                  </td>
                  <td>
                    {user.agente && user.agente.capo_area && (
                      <span>
                        {user.agente.capo_area.user.first_name}{' '}
                        {user.agente.capo_area.user.last_name}
                        {user.agente.capo_area.codice
                          ? ` (${user.agente.capo_area.codice})`
                          : ''}
                      </span>
                    )}
                  </td>
                  <td>
                    <div className='pointer' onClick={() => setUser(user)}>
                      <i
                        className="fa fa-trash text-danger"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <Paginator
          numPages={pagination.numPages}
          currentPage={page}
          goToPage={goToPage}
        />
        <ModalDeleteUser
          toggle={toggleUser}
          isOpen={selectedUser !== null}
          user={selectedUser}
          deleting={deleting}
          onDelete={() => {
            deleteUser
              .onSuccess(() => {
                toggleUser()
                refresh()
              }).run(selectedUser)
          }}
        />
      </div>
    </div>
  )
}
