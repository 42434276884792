import React, { useContext } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { ConfigPrevetiviContext } from '../../../../../context'

const ModalChooseTipoSerramento = ({ isOpen, toggle, onSelect }) => {
  const { tipiSerramento } = useContext(ConfigPrevetiviContext)
  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg' scrollable={true}>
      <ModalHeader toggle={toggle}>Seleziona il tipo di serramento</ModalHeader>
      <ModalBody>
        <div className='container'>
          <div className='row'>
            {tipiSerramento.map(tipoSerramento => (
              <div className='col-6 col-md-4 p-2 text-center border pointer' key={tipoSerramento.id} onClick={() => onSelect(tipoSerramento.id)}>
                <img src={tipoSerramento.immagine} alt={tipoSerramento.nome} style={{maxWidth:100}} />
                <div>{tipoSerramento.nome}</div>
              </div>
            ))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Chiudi</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalChooseTipoSerramento
