import request from "superagent"
import React, { useState, useMemo } from "react"
import { Link } from "react-router-dom"
import { rj, useRj } from "react-rocketjump"
import classNames from "classnames"
import get from "lodash/get"
import { CSRF } from "../../django"
import { Button } from "reactstrap"

const ImportOrdersState = rj({
  name: "Import Orders",
  effect: (files) => {
    const r = request.post("/api/planner/import-orders/")
    r.set("X-CSRFToken", CSRF)
    files.forEach((file) => {
      r.attach("files[]", file)
    })
    return r.then((r) => r.body)
  },
})

export default function ImportOrders() {
  const [{ data: result, pending, error }, { run: runImport, clean }] =
    useRj(ImportOrdersState)
  const [files, setFiles] = useState([null])

  const goodFiles = useMemo(() => files.filter(Boolean), [files])

  return (
    <div className="mt-3 px-4 py-2">
      <div className="card">
        <div className="card-header">
          <div style={{ right: 10, top: 2 }} className="position-absolute">
            <Button
              tag={Link}
              size="sm"
              to={"/ordini"}
              color="secondary"
              outline
            >
              <i className="fas fa-arrow-left"></i> Lista ordini
            </Button>
          </div>
          Importa Ordini
        </div>
        <div className="card-body">
          <div className="mb-2">
            Carica il tuo file xls e procedi all'importazione degli ordini.
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              if (goodFiles.length) {
                clean()
                runImport
                  .onSuccess(() => {
                    setFiles([null])
                    // Sorry react
                    // This trick reset inputs....
                    const inputs = document.querySelectorAll(
                      "#order-uploader input"
                    )
                    inputs.forEach((i) => (i.value = ""))
                  })
                  .run(goodFiles)
              }
            }}
          >
            <div>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setFiles(files.concat(null))
                }}
                className="btn btn-sm btn-primary"
              >
                <i className="fas fa-plus"></i>
              </button>
            </div>
            <ul id="order-uploader">
              {files.map((file, i) => (
                <li key={i}>
                  <div className="d-flex align-items-center mb-1">
                    <label
                      htmlFor={`file_${i}`}
                      className="btn btn-sm btn-outline-primary pointer m-0"
                    >
                      {file === null && "Carica XLS"}
                      {file !== null && file.name}
                    </label>
                    <input
                      accept={".xls"}
                      className="d-none"
                      id={`file_${i}`}
                      name={`file_${i}`}
                      type="file"
                      onChange={(e) => {
                        const newFiles = [...files]
                        const uploadFile = e.target.files[0]
                        if (uploadFile) {
                          newFiles[i] = uploadFile
                          setFiles(newFiles)
                        }
                      }}
                    />
                    <button
                      className="btn btn-sm btn-danger ml-2"
                      onClick={(e) => {
                        e.preventDefault()
                        const newFiles = [...files]
                        newFiles.splice(i, 1)
                        setFiles(newFiles)
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </li>
              ))}
            </ul>
            <div>
              <button
                className={classNames("btn btn-success", {
                  disabled: goodFiles.length === 0 || pending,
                })}
                type="submit"
              >
                {pending ? "IMPORTO..." : "IMPORTA"}
              </button>
            </div>
            <div className="mt-4">
              {error && (
                <div className="alert alert-danger">
                  {get(
                    error,
                    "response.body[0]",
                    "Errore durante l'importanzione"
                  )}
                </div>
              )}
              {result && result.exists.length > 0 && (
                <div className="alert alert-warning">
                  <div>
                    <b>Ordini già presenti nel sistema:</b>
                  </div>
                  <ul>
                    {result.exists.map((order) => (
                      <li key={order.id}>
                        <Link to={`/ordini/${order.id}/modifica`}>
                          {order.numero_commessa}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {result && result.imported.length > 0 && (
                <div className="alert alert-success">
                  <div>
                    <b>Ordini importati:</b>
                  </div>
                  <ul>
                    {result.imported.map((order) => (
                      <li key={order.id}>
                        <Link to={`/ordini/${order.id}`}>
                          {order.numero_commessa}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
