import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { useRj } from 'react-rocketjump'
import { UserDetailState } from './localstate'
import UserForm from '../../components/Users/UserForm'

export default function UserCreate({ history }) {
  const { createUser } = useRj(UserDetailState)[1]

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="card mt-2">
            <div className="card-header d-flex justify-content-between">
              <div style={{ zIndex: 5 }}>
                <Button size="sm" outline to="/" tag={Link}>
                  <i className="fa fa-list mr-2" aria-hidden="true"></i>
                  Lista utenti
                </Button>
              </div>
              <div
                style={{
                  zIndex: 1,
                  position: 'absolute',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                <b>Crea utente</b>
              </div>
              <div />
            </div>
            <div className="card-body">
              <UserForm
                save={(user) =>
                  createUser
                    .onSuccess((createdUser) =>
                      history.push(`/${createdUser.id}`)
                    )
                    .asPromise(user)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
