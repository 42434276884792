import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ModalDeleteAttivita = ({ isOpen, toggle, onSetEliminato, deleting, attivita }) => {
  return(
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}>Elimina attività</ModalHeader>
      <ModalBody>
        {attivita &&
        <div className='container'>
          Sei sicuro di voler eliminare l'attivita {attivita.lavorazione.nome}?
        </div>
        }
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between'>
        <Button color="secondary" onClick={toggle}>Chiudi</Button>
        <Button color="danger" disabled={deleting} onClick={onSetEliminato}>Elimina</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalDeleteAttivita
