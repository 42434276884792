import React, { useState, useCallback, useContext, useMemo } from 'react'
import keyBy from 'lodash/keyBy'
import capitalize from 'lodash/capitalize'
import { useReactDataTable as useDataTable } from 'react-use-datatable/react'
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'
import { deps, useRunRj } from 'react-rocketjump'
import groupBy from 'lodash/groupBy'
import {
  OrdineOrdiniFornitoreState,
  OrdineRigheOrdineFornitoreState,
  FasiOrdineState,
} from './localstate'
import ModalOrdineRigaOrdineFornitore from '../../components/Planner/ModalOrdineRigaOrdineFornitore'
import ModalSplitRigheOrdiniFornitori from '../../components/Planner/ModalSplitRigheOrdiniFornitori'
import ModalConfermaMailOrdineFornitore from '../../components/Planner/ModalConfermaMailOrdineFornitore'
import RigheOrdiniFornitoriTable from '../../components/Planner/RigheOrdiniFornitoreTable'
import OrdiniFornitoreCardList from '../../components/Planner/OrdiniFornitoreCardList'
import OrderFornitoreForm from '../../components/Planner/OrderFornitoreForm'
import ModalFormFornitore from '../../components/Planner/ModalFormFornitore'
import { ConfigPlannerContext } from '../../context'
import {
  ContextOrdineOrdiniFornitoriActions,
  ContextOrdineOrdiniFornitoriRigheActions,
} from './contexts'
import {
  ContextFornitori,
  ContextFornitoriActions,
} from '../../apps/PlannerApp/contexts'
import { getFasiNeededByOrder } from './localstate/common'
import ModalFormMoveFaseSchedulata from '../../components/Planner/ModalFormMoveFaseSchedulata/ModalFormMoveFaseSchedulata'
import { useSubscribeToOrderDetailEvents } from './OrderDetailEventsProvider'

export default function OrdineOrdiniFornitore({ order }) {
  // Grab tipologie list from planner config
  const {
    fasiScheduler,
    materiali,
    valoriStato,
    colonneMateriali,
  } = useContext(ConfigPlannerContext)
  const stati = valoriStato.ordine_fornitore

  // Fornitori form parent context
  const fornitori = useContext(ContextFornitori)
  const { refreshFornitori } = useContext(ContextFornitoriActions)
  const tipologie = useMemo(() => {
    if (!order) {
      return []
    }
    const fasiNeeded = getFasiNeededByOrder(order, fasiScheduler)

    const listTipologieFromFasi = fasiScheduler.reduce((list, fase) => {
      if (fase.with_fornitore && fasiNeeded[fase.nome_fase]) {
        list.push(fase.nome_fase)
      }
      return list
    }, [])
    return listTipologieFromFasi.concat(materiali)
  }, [fasiScheduler, materiali, order])

  // STATO SCHEDULATORE
  const [{ data: fasiOrdine }, { moveFase }] = useRunRj(FasiOrdineState, [
    deps.maybeGet(order, 'id'),
  ])

  // STATO ORDINI FORNITORI
  const [
    {
      list: ordiniFornitore,
      sending,
      loading: loadingOrdiniFornitori,
      deleting: deletingOrdineFornitore,
    },
    filters,
    {
      addOrdineFornitoreWithoutInsert,
      onFiltersChange,
      updateOrdineFornitore,
      deleteOrdineFornitore,
      sendMail,
      updateFornitore,
      addRighe,
      onFilterChange,
      refresh: refreshOrdiniFornitori,
    },
  ] = useDataTable(
    OrdineOrdiniFornitoreState,
    () => ({
      fornitore: '',
      tipologia: '',
      stato: '',
      note: '',
      ordine_fornitore: '',
    }),
    [deps.maybeGet(order, 'id')]
  )

  // STATO RIGHE ORDINI FORNITORI
  const [
    { list: righeOrdiniFornitore, loading, generating, deletingMulti },
    filtersRighe,
    {
      refresh: refreshRighe,
      onFilterChange: onFilterChangeRighe,
      onFiltersChange: onFiltersChangeRighe,
      addRigaOrdineFornitore,
      updateRigaOrdineFornitore,
      deleteMultiRigheOrdineFornitore,
      resetOrdineFornitore,
      generateOrders,
      mergeRighe,
      splittingRiga,
    },
  ] = useDataTable(
    OrdineRigheOrdineFornitoreState,
    () => ({
      fornitore: '',
      ordine_fornitore: '',
      tipologia: '',
      stato: '',
      note: '',
    }),
    [deps.maybeGet(order, 'id')]
  )

  // REFRESH ORDINI FORNITORI + RIGHT ON IMPORT SUCCESS INSIDE ORDER
  useSubscribeToOrderDetailEvents((event) => {
    if (event.type === 'ordiniFornitoriImported') {
      refreshOrdiniFornitori()
      refreshRighe()
    }
  })

  // RAGGRUPPA LE RIGHE X TIPOLOGIA CREA N TABELLE
  const righeOrdiniFornitoreGrouped = useMemo(
    () => groupBy(righeOrdiniFornitore, 'tipologia'),
    [righeOrdiniFornitore]
  )

  // FORNITORI X TIPOLOGIA IN SELECT
  const fornitoriTipologia = useMemo(
    () => fornitori.filter((f) => f.tipologia === filtersRighe.tipologia),
    [fornitori, filtersRighe.tipologia]
  )

  // RIGHE ORDINI FORNITORE SELEZIONATE
  const [selectedRigheIds, setSelectedRigheIds] = useState({})
  const toggleSelectedRigaId = useCallback((id) => {
    setSelectedRigheIds((idMap) => ({
      ...idMap,
      [id]: !idMap[id],
    }))
  }, [])

  // ORDINI FORNITORI SELEZIONATI
  const [selectedOrdiniFornitoriIds, setSelectedOrdiniFornitoriIds] = useState(
    {}
  )
  const toggleSelectedOrdineFornitore = useCallback((id) => {
    setSelectedOrdiniFornitoriIds((idMap) => ({
      ...idMap,
      [id]: !idMap[id],
    }))
  }, [])

  const tipologiaSelected = useMemo(() => {
    const righeById = keyBy(righeOrdiniFornitore, 'id')
    let tipologia = null
    const ids = Object.keys(selectedRigheIds).filter(
      (id) => selectedRigheIds[id] && righeById[id]
    )
    for (const id of ids) {
      const riga = righeById[id]
      if (tipologia === null) {
        tipologia = riga.tipologia
      } else if (tipologia !== riga.tipologia) {
        tipologia = null
        break
      }
    }
    return tipologia
  }, [righeOrdiniFornitore, selectedRigheIds])

  const activeCreateOrderFornitore = useMemo(() => {
    const righeById = keyBy(righeOrdiniFornitore, 'id')
    const ids = Object.keys(selectedRigheIds).filter(
      (id) => selectedRigheIds[id] && righeById[id]
    )
    for (const id of ids) {
      const riga = righeById[id]
      if (riga.ordine_fornitore) {
        return false
      }
    }
    return true
  }, [righeOrdiniFornitore, selectedRigheIds])

  // GESTISTICE MODALzS x Ordine Fornitore
  const [modalOrderFornitore, setModalOrderFornitore] = useState({
    operation: null,
    record: null,
  })
  const closeModalOrderFornitore = useCallback(() => {
    setModalOrderFornitore({ operation: null, record: null })
  }, [])
  const openCreateModalOrdineFornitore = useCallback(() => {
    setModalOrderFornitore({ operation: 'CREATE', record: null })
  }, [])
  const openUpdateModalOrdineFornitore = useCallback((record) => {
    setModalOrderFornitore({ operation: 'UPDATE', record })
  }, [])
  const openMailModalOrdineFornitore = useCallback((record) => {
    setModalOrderFornitore({ operation: 'MAIL', record })
  }, [])
  const openDeleteModalOrdineFornitore = useCallback((ordineFornitore) => {
    setModalOrderFornitore({
      operation: 'DELETE',
      record: ordineFornitore,
      tipologia: ordineFornitore.tipologia,
    })
  }, [])

  // GESTISTICE MODALzS RIGHE ORDINE FORNITORE
  const [modalRigaOrdineFornitore, setModalRigaOrdineFornitore] = useState({
    operation: null,
    record: null,
  })
  const closeModalRigaOrdineFornitore = useCallback(() => {
    setModalRigaOrdineFornitore({ operation: null, record: null })
  }, [])
  const showModalRigaOrdineFornitore =
    ['DUPLICATE', 'CREATE', 'UPDATE'].indexOf(
      modalRigaOrdineFornitore.operation
    ) !== -1
  const showModalSplit = modalRigaOrdineFornitore.operation === 'SPLIT'
  const isDuplicate = modalRigaOrdineFornitore.operation === 'DUPLICATE'
  const openModalSplitRigaOrdineFornitore = useCallback((record) => {
    setModalRigaOrdineFornitore({ operation: 'SPLIT', record })
  }, [])
  const openModalCreateRigaOrdineFornitore = useCallback(() => {
    setModalRigaOrdineFornitore({ operation: 'CREATE', record: null })
  }, [])
  const openModalUpdateRigaOrdineFornitore = useCallback((record) => {
    setModalRigaOrdineFornitore({ operation: 'UPDATE', record })
  }, [])
  const openModalDuplicateRigaOrdineFornitore = useCallback((record) => {
    setModalRigaOrdineFornitore({ operation: 'DUPLICATE', record })
  }, [])
  const selectedRigaOrdineFornitore = modalRigaOrdineFornitore.record

  // MODAL GENERAZIONI AUTOMATICA ORDINI FORNITORI
  const [showModalGenerateOrders, setShowModalGenerateOrders] = useState(false)
  const toggleModalGenerateOrders = useCallback(() => {
    setShowModalGenerateOrders((show) => !show)
  }, [])

  // MODAL FORNITORE
  const [selectedFornitoreInModal, setSelectedFornitoreInModal] = useState(null)
  const showModalFornitore = selectedFornitoreInModal !== null
  const closeModalFornitore = useCallback(() => {
    setSelectedFornitoreInModal(null)
  }, [])
  const toggleShowModalFornitore = useCallback((fornitore = null) => {
    setSelectedFornitoreInModal(fornitore)
  }, [])

  // MODAL MULTI DELETE RIGHE
  const [showModalMultiDeleteRighe, setShowModalMultiDeleteRighe] = useState(
    false
  )
  const toggleModalMultiDeleteRighe = useCallback(() => {
    setShowModalMultiDeleteRighe((show) => !show)
  }, [])

  // MODAL FASE SCHEDULATA
  const [faseSchedulataInModal, openMoveSchedulazioneModal] = useState(null)
  const isMoveFaseSchedulataOpen = faseSchedulataInModal !== null
  const closeMoveSchedulazioneModal = useCallback(() => {
    openMoveSchedulazioneModal(null)
  }, [])

  const moveRighe = useCallback(
    (ordineFornitore) => {
      const righe = Object.keys(selectedRigheIds).filter(
        (id) => selectedRigheIds[id]
      )
      addRighe
        .onSuccess(() => {
          setSelectedRigheIds({})
          refreshOrdiniFornitori()
          refreshRighe()
        })
        .run(ordineFornitore.id, righe)
    },
    [addRighe, refreshOrdiniFornitori, refreshRighe, selectedRigheIds]
  )

  const sendMailOrderFornitore = useCallback(
    (ordineFornitore) => {
      sendMail
        .onSuccess(() => {
          refreshOrdiniFornitori()
          refreshRighe()
          closeModalOrderFornitore()
        })
        .run(ordineFornitore.id)
    },
    [closeModalOrderFornitore, refreshOrdiniFornitori, refreshRighe, sendMail]
  )

  function handleResetRigheOrdineFornitore() {
    const righe = Object.keys(selectedRigheIds).filter(
      (id) => selectedRigheIds[id]
    )
    resetOrdineFornitore
      .onSuccess(() => {
        setSelectedRigheIds({})
        refreshOrdiniFornitori()
        refreshRighe()
      })
      .run(righe)
  }

  function handleUnisciRigheOrdineFornitore() {
    const righe = Object.keys(selectedRigheIds).filter(
      (id) => selectedRigheIds[id]
    )
    mergeRighe
      .onSuccess(() => {
        setSelectedRigheIds({})
        refreshOrdiniFornitori()
        refreshRighe()
      })
      .run(righe)
  }

  const selectedRigheList = useMemo(() => {
    return Object.keys(selectedRigheIds)
      .filter((key) => selectedRigheIds[key])
      .map((k) => +k)
  }, [selectedRigheIds])

  const filterOrdineFornitore = useCallback(
    (ordineFornitore) => {
      onFilterChangeRighe(ordineFornitore.tipologia, 'tipologia')
      onFilterChange(ordineFornitore.tipologia, 'tipologia')
      onFilterChangeRighe(ordineFornitore.fornitore, 'fornitore')
      onFilterChange(ordineFornitore.fornitore, 'fornitore')
    },
    [onFilterChange, onFilterChangeRighe]
  )

  const resetFilters = () => {
    onFiltersChangeRighe({
      fornitore: '',
      ordine_fornitore: '',
      tipologia: '',
      stato: '',
    })
    onFiltersChange({
      fornitore: '',
      ordine_fornitore: '',
      tipologia: '',
      stato: '',
    })
  }

  const idsUrlPdfMulti = useMemo(() => {
    return Object.keys(selectedOrdiniFornitoriIds)
      .filter((id) => selectedOrdiniFornitoriIds[id])
      .join(',')
  }, [selectedOrdiniFornitoriIds])

  // PASS DOWN CALLBACKS
  const ordineOrdiniFornitoriActions = useMemo(
    () => ({
      moveRighe,
      toggleShowModalFornitore,
      toggleSelectedOrdineFornitore,
      filterOrdineFornitore,
      openMailModalOrdineFornitore,
      openUpdateModalOrdineFornitore,
      openDeleteModalOrdineFornitore,
      openMoveSchedulazioneModal,
    }),
    [
      moveRighe,
      toggleShowModalFornitore,
      toggleSelectedOrdineFornitore,
      filterOrdineFornitore,
      openMailModalOrdineFornitore,
      openUpdateModalOrdineFornitore,
      openDeleteModalOrdineFornitore,
      openMoveSchedulazioneModal,
    ]
  )

  const ordineOrdiniFornitoriRigheActions = useMemo(
    () => ({
      toggleSelectedRigaId,
      setSelectedRigheIds,
      openModalSplitRigaOrdineFornitore,
      openModalUpdateRigaOrdineFornitore,
      openModalCreateRigaOrdineFornitore,
      openModalDuplicateRigaOrdineFornitore,
    }),
    [
      openModalCreateRigaOrdineFornitore,
      openModalDuplicateRigaOrdineFornitore,
      openModalSplitRigaOrdineFornitore,
      openModalUpdateRigaOrdineFornitore,
      toggleSelectedRigaId,
    ]
  )

  return (
    <ContextOrdineOrdiniFornitoriActions.Provider
      value={ordineOrdiniFornitoriActions}
    >
      <ContextOrdineOrdiniFornitoriRigheActions.Provider
        value={ordineOrdiniFornitoriRigheActions}
      >
        <div className="RigheOrdineFornitore mb-2 position-relative">
          <div className="row">
            <div className="col-md-8 border-right">
              <div className="d-flex justify-content-between">
                <div className="mt-2 d-flex">
                  <select
                    style={{ width: 'auto' }}
                    name="ordine_fornitore"
                    value={filtersRighe.ordine_fornitore}
                    className="custom-select custom-select-sm font-11 mt-1"
                    onChange={onFilterChangeRighe}
                  >
                    <option value="">Tutte le righe</option>
                    <option value="0">Con ordine</option>
                    <option value="1">Senza ordine</option>
                  </select>
                  <select
                    name="tipologia"
                    className="custom-select custom-select-sm ml-2 font-11 mt-1"
                    onChange={(e) => {
                      onFilterChangeRighe(e)
                      onFilterChange(e)
                      onFilterChangeRighe('', 'fornitore')
                      onFilterChange('', 'fornitore')
                      setSelectedRigheIds({})
                    }}
                    value={filtersRighe.tipologia}
                  >
                    <option value="">{'Tutte le tipologie'}</option>
                    {tipologie.map((tipologia) => (
                      <option key={tipologia} value={tipologia}>
                        {capitalize(tipologia)}
                      </option>
                    ))}
                  </select>
                  <select
                    style={{ width: 'auto' }}
                    disabled={filtersRighe.tipologia === ''}
                    name="fornitore"
                    className="custom-select custom-select-sm ml-2 font-11 mt-1"
                    onChange={(e) => {
                      onFilterChangeRighe(e)
                      onFilterChange(e)
                      setSelectedRigheIds({})
                    }}
                    value={filtersRighe.fornitore}
                  >
                    <option value="">{'Tutti i fornitori'}</option>
                    {fornitoriTipologia.map((fornitore) => (
                      <option key={fornitore.id} value={fornitore.id}>
                        {fornitore.nome}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="buttons-righe-ordini mt-1">
                  <Button
                    onClick={toggleModalGenerateOrders}
                    size="sm"
                    disabled={
                      !activeCreateOrderFornitore ||
                      selectedRigheList.length === 0
                    }
                    className="mt-2 ml-2 mr-2"
                    outline
                    color="dark"
                  >
                    <i className="fas fa-plus-circle"></i> Crea ord.
                  </Button>
                  <Button
                    onClick={handleResetRigheOrdineFornitore}
                    size="sm"
                    disabled={selectedRigheList.length === 0}
                    className="mt-2 mr-2"
                    outline
                    color="secondary"
                  >
                    <i className="fas fa-undo"></i> Reset ordine
                  </Button>
                  <Button
                    onClick={handleUnisciRigheOrdineFornitore}
                    size="sm"
                    disabled={selectedRigheList.length === 0}
                    className="mt-2 mr-2"
                    outline
                    color="secondary"
                  >
                    <i className="fas fa-undo"></i> Unisci
                  </Button>
                  <Button
                    onClick={resetFilters}
                    size="sm"
                    className="mt-2 mr-2"
                    outline
                    color="primary"
                  >
                    <i className="fas fa-undo"></i> Reset filtri
                  </Button>
                  <Button
                    disabled={selectedRigheList.length === 0}
                    onClick={toggleModalMultiDeleteRighe}
                    size="sm"
                    className="mt-2 mr-1"
                    outline
                    color="danger"
                  >
                    <i className="fas fa-trash"></i> Cancella
                  </Button>
                  <Button
                    onClick={() => {
                      openModalCreateRigaOrdineFornitore()
                    }}
                    size="sm"
                    className="mt-2"
                    outline
                    color="secondary"
                  >
                    <i className="fas fa-plus-circle"></i> Aggiungi riga
                  </Button>
                </div>
              </div>
              {righeOrdiniFornitoreGrouped &&
                Object.keys(righeOrdiniFornitoreGrouped).map(
                  (tipologia, key) => {
                    return (
                      <RigheOrdiniFornitoriTable
                        key={key}
                        extraFields={colonneMateriali[tipologia]}
                        tipologia={tipologia}
                        selectedIds={selectedRigheIds}
                        righeOrdiniFornitore={
                          righeOrdiniFornitoreGrouped[tipologia]
                        }
                        loading={loading}
                      />
                    )
                  }
                )}
            </div>

            <div className="col-md-4">
              <div className="position-sticky" style={{ top: 70 }}>
                <div className="d-flex justify-content-between">
                  <select
                    style={{ width: 'auto' }}
                    name="stato"
                    className="custom-select custom-select-sm mt-2 font-11"
                    onChange={(e) => {
                      onFilterChange(e)
                      setSelectedRigheIds({})
                    }}
                    value={filters.stato}
                  >
                    <option value="">{'Tutti gli stati'}</option>
                    {stati.map((stato) => (
                      <option key={stato.nome} value={stato.nome}>
                        {stato.nome}
                      </option>
                    ))}
                  </select>
                  <div className="buttons-righe-ordini">
                    {idsUrlPdfMulti && (
                      <Button
                        tag="a"
                        outline
                        color="secondary"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                        href={`/api/planner/ordini-fornitore/pdf_multi/?ids=${idsUrlPdfMulti}`}
                        className="btn btn-sm btn-outline-dark mr-2 mt-2"
                      >
                        <i className="fas fa-file-pdf-o mr-2 text-danger" /> Pdf
                        ordini
                      </Button>
                    )}
                    <Button
                      onClick={openCreateModalOrdineFornitore}
                      size="sm"
                      className="mt-2"
                      outline
                      color="secondary"
                    >
                      <i className="fas fa-plus-circle"></i> Aggiungi ordine
                      fornitore
                    </Button>
                  </div>
                </div>
                <OrdiniFornitoreCardList
                  tipologiaSelected={tipologiaSelected}
                  fasiOrdine={fasiOrdine}
                  selectedOrdiniFornitoriIds={selectedOrdiniFornitoriIds}
                  sending={sending}
                  loading={loadingOrdiniFornitori}
                  ordiniFornitore={ordiniFornitore}
                />
              </div>
            </div>
          </div>

          <Modal
            size="lg"
            toggle={closeModalOrderFornitore}
            isOpen={
              modalOrderFornitore.operation !== null &&
              (modalOrderFornitore.operation === 'UPDATE' ||
                modalOrderFornitore.operation === 'CREATE')
            }
          >
            <ModalHeader toggle={closeModalOrderFornitore}>
              {modalOrderFornitore &&
                modalOrderFornitore.operation === 'UPDATE' &&
                'Modifica Ordine Fornitore'}
              {modalOrderFornitore &&
                modalOrderFornitore.operation === 'CREATE' &&
                'Aggiungi Ordine Fornitore'}
            </ModalHeader>
            <OrderFornitoreForm
              tipologie={tipologie}
              save={(data) => {
                if (modalOrderFornitore.record) {
                  return updateOrdineFornitore
                    .onSuccess(() => {
                      refreshOrdiniFornitori()
                      refreshRighe()
                      closeModalOrderFornitore()
                    })
                    .asPromise(data)
                } else {
                  return addOrdineFornitoreWithoutInsert
                    .onSuccess(() => {
                      refreshOrdiniFornitori()
                      closeModalOrderFornitore()
                    })
                    .asPromise({
                      ordine: order.id,
                      ...data,
                    })
                }
              }}
              toggle={closeModalOrderFornitore}
              orderFornitore={modalOrderFornitore.record}
              inModal
            />
          </Modal>
          <Modal
            toggle={closeModalOrderFornitore}
            isOpen={
              modalOrderFornitore !== null &&
              modalOrderFornitore.operation === 'DELETE'
            }
          >
            <ModalHeader toggle={closeModalOrderFornitore}>
              {modalOrderFornitore &&
                modalOrderFornitore.operation === 'DELETE' &&
                'Elimina Ordine Fornitore'}
            </ModalHeader>
            <ModalBody>
              {modalOrderFornitore &&
                modalOrderFornitore.operation === 'DELETE' && (
                  <div>
                    Eliminare ordine fornitore per{' '}
                    {modalOrderFornitore.record.fornitore_data.nome}?
                  </div>
                )}
            </ModalBody>
            <ModalFooter className="justify-content-between">
              <Button
                disabled={deletingOrdineFornitore}
                onClick={closeModalOrderFornitore}
              >
                Annulla
              </Button>
              <Button
                disabled={deletingOrdineFornitore}
                color="danger"
                onClick={() => {
                  deleteOrdineFornitore
                    .onSuccess(() => {
                      refreshOrdiniFornitori()
                      refreshRighe()
                      closeModalOrderFornitore()
                    })
                    .run(modalOrderFornitore.record)
                }}
              >
                Elimina
              </Button>
            </ModalFooter>
          </Modal>
          <ModalOrdineRigaOrdineFornitore
            isOpen={showModalRigaOrdineFornitore}
            tipologie={tipologie}
            colonneMateriali={colonneMateriali}
            isDuplicate={isDuplicate}
            riga={selectedRigaOrdineFornitore}
            toggle={closeModalRigaOrdineFornitore}
            order={order}
            save={(data) => {
              if (selectedRigaOrdineFornitore) {
                if (isDuplicate) {
                  return addRigaOrdineFornitore
                    .onSuccess(() => {
                      refreshRighe()
                      closeModalRigaOrdineFornitore()
                    })
                    .asPromise({
                      ordine: order.id,
                      ...data,
                    })
                } else {
                  return updateRigaOrdineFornitore
                    .onSuccess(() => {
                      refreshRighe()
                      closeModalRigaOrdineFornitore()
                    })
                    .asPromise(data)
                }
              } else {
                return addRigaOrdineFornitore
                  .onSuccess(() => {
                    refreshRighe()
                    closeModalRigaOrdineFornitore()
                  })
                  .asPromise({
                    ordine: order.id,
                    ...data,
                  })
              }
            }}
          />
          <ModalFormFornitore
            isOpen={showModalFornitore}
            toggle={closeModalFornitore}
            fornitore={selectedFornitoreInModal}
            save={(fornitore) => {
              return updateFornitore
                .onSuccess(() => {
                  refreshOrdiniFornitori()
                  refreshRighe()
                  refreshFornitori()
                  closeModalFornitore()
                })
                .asPromise(fornitore)
            }}
          />
          <Modal
            toggle={toggleModalMultiDeleteRighe}
            isOpen={showModalMultiDeleteRighe}
          >
            <ModalHeader toggle={toggleModalMultiDeleteRighe}>
              Elimina righe ordine fornitore
            </ModalHeader>
            <ModalBody>
              <div>Vuoi eliminare le righe ordine fornitore selezionate?</div>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
              <Button
                disabled={deletingMulti}
                onClick={toggleModalMultiDeleteRighe}
              >
                Annulla
              </Button>
              <Button
                disabled={deletingMulti}
                color="danger"
                onClick={() => {
                  deleteMultiRigheOrdineFornitore
                    .onSuccess(() => {
                      setSelectedRigheIds({})
                      refreshRighe()
                      refreshOrdiniFornitori()
                      toggleModalMultiDeleteRighe()
                    })
                    .run(selectedRigheList, true)
                }}
              >
                Elimina
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            toggle={toggleModalGenerateOrders}
            isOpen={showModalGenerateOrders}
          >
            <ModalHeader toggle={toggleModalGenerateOrders}>
              Generazione ordini fornitori
            </ModalHeader>
            <ModalBody>
              <div>
                Vuoi generare gli ordini fornitori per le righe selezionate?
              </div>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
              <Button disabled={generating} onClick={toggleModalGenerateOrders}>
                Annulla
              </Button>
              <Button
                disabled={generating}
                color="success"
                onClick={() => {
                  generateOrders
                    .onSuccess(() => {
                      refreshOrdiniFornitori()
                      refreshRighe()
                      setSelectedRigheIds({})
                      toggleModalGenerateOrders()
                    })
                    .run(selectedRigheList)
                }}
              >
                Genera ordine
              </Button>
            </ModalFooter>
          </Modal>
          <ModalSplitRigheOrdiniFornitori
            toggle={closeModalRigaOrdineFornitore}
            isOpen={showModalSplit}
            riga={selectedRigaOrdineFornitore}
            save={(quantities) => {
              return splittingRiga
                .onSuccess(() => {
                  refreshOrdiniFornitori()
                  refreshRighe()
                  closeModalRigaOrdineFornitore()
                })
                .asPromise(selectedRigaOrdineFornitore.id, quantities)
            }}
          />
          <ModalConfermaMailOrdineFornitore
            toggle={closeModalOrderFornitore}
            sending={sending}
            isOpen={
              modalOrderFornitore !== null &&
              modalOrderFornitore.operation === 'MAIL'
            }
            send={() => sendMailOrderFornitore(modalOrderFornitore.record)}
            ordineFornitore={modalOrderFornitore?.record ?? null}
          />
          <ModalFormMoveFaseSchedulata
            toggle={closeMoveSchedulazioneModal}
            isOpen={isMoveFaseSchedulataOpen}
            faseSchedulata={faseSchedulataInModal}
            save={(values) => {
              return moveFase
                .onSuccess(() => closeMoveSchedulazioneModal())
                .asPromise(order.id, values)
            }}
          />
        </div>
      </ContextOrdineOrdiniFornitoriRigheActions.Provider>
    </ContextOrdineOrdiniFornitoriActions.Provider>
  )
}
