import moment from 'moment'

export const DATE_FORMAT = 'YYYY-MM-DD'
export const formatDateAsDay = d => moment(d).format(DATE_FORMAT)

const DEFAULT_FROM_H = '09:00'
const DEFAULT_TO_H = '19:00'

export function makeRangeStartEnd(assenza, view) {
  if (view === 'week') {
    const oraDa = assenza.ora_da === null ? DEFAULT_FROM_H : assenza.ora_da
    const oraA = assenza.ora_a === null ? DEFAULT_TO_H : assenza.ora_a
    // Fake Time 4 week
    return {
      start: moment(assenza.giorno_da + ' ' + oraDa).toDate(),
      end: moment(assenza.giorno_a + ' '  + oraA).toDate(),
    }
  } else {
    const oraA = assenza.ora_a === null ? DEFAULT_TO_H : assenza.ora_a
    return {
      start: moment(assenza.giorno_da).toDate(),
      end: moment(assenza.giorno_a + ' '  + oraA).toDate(),
    }
  }
}

// 2 -> 02
export function padTimeStr(str) {
  if (String(str).length < 2) {
    return `0${str}`
  }
  return str
}
