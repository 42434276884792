import { rj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import magikApi from 'magik-api'
import { SETTINGS, CSRF } from '../../../django'
import { rjListWithTotals } from '../localstate/common'
import { get } from 'lodash'

const api = magikApi().trailingSlash(true).headers({
  'X-CSRFToken': CSRF,
})

export const StatsConfigListState = rj(
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  {
    name: 'StatsConfigList',
    mutations: {
      addConfig: {
        effect: (config) =>
          api.post('/api/planner/ordini-stats-config/', config),
        updater: (s) => s,
      },
      updateConfig: {
        effect: (config) =>
          api.put(`/api/planner/ordini-stats-config/${config.id}`, config),
        updater: 'updateItem',
      },
      removeConfig: rj.mutation.single({
        effect: (config) =>
          api
            .mapResponse(() => config)
            .delete(`/api/planner/ordini-stats-config/${config.id}`),
        updater: 'deleteItem',
      }),
    },
    computed: {
      list: 'getList',
      pagination: 'getPagination',
      loading: 'isLoading',
      error: 'getError',
      removing: (s) => s.mutations.removeConfig.pending,
    },
    effect: (params = {}) =>
      api.get('/api/planner/ordini-stats-config/', params),
  }
)

export const StatsConfigDetailState = rj({
  name: 'StatsConfigDetail',
  mutations: {
    updateConfig: {
      effect: (config) =>
        api.put(`/api/planner/ordini-stats-config/${config.id}`, config),
      updater: 'updateData',
    },
  },
  effect: (id) => api.get(`/api/planner/ordini-stats-config/${id}`),
})

export const StatsAvailableFieldsState = rj({
  name: 'StatsAvailableFields',
  effect: () => api.get(`/api/planner/ordini-stats-config/fields`),
})

export const StatsRigheOrdiniState = rj(
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  rjListWithTotals(),
  {
    name: 'StatsRigheOrdini',
    computed: {
      totals: 'getTotals',
      list: 'getList',
      pagination: 'getPagination',
      loading: 'isLoading',
      error: 'getError',
    },

    effect: (id, params = {}) => {
      const stato = get(params, 'stato', [])
      if (stato && stato.includes('NESSUNO STATO')) {
        let fixParams = {}
        if (stato && stato.includes('NESSUNO STATO')) {
          fixParams = {
            ...params,
            stato: params.stato.filter(
              (x) => x !== 'NESSUNO STATO'
            ),
            empty: true,
          }
        }
        return api.get(`/api/planner/righe-ordini-stats/${id}`, fixParams)
      }
      return api.get(`/api/planner/righe-ordini-stats/${id}`, params)
    },
  }
)
