import qs from 'query-string'
import { ajax } from 'rxjs/ajax'
import { map } from 'rxjs/operators'
import { rj } from 'react-rocketjump'
import rjDebounce from 'react-rocketjump/plugins/debounce'
import rjList, { nextPreviousPaginationAdapter } from 'react-rocketjump/plugins/list'
import { CSRF, SETTINGS } from '../../../django'

export const PersonaleState = rj(
  rjDebounce(),
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: 'Personale',
    mutations: {
      deletePersona: rj.mutation.single({
        effect: (persona) => ajax({
          method: 'delete',
          url: `/api/planner/personale/${persona.id}`,
          headers: {
           'Content-Type': 'application/json',
           'X-CSRFToken': CSRF,
          },
       }).pipe(map(() => persona)),
       updater: 'deleteItem',
     }),
     addPersona: {
       effect: (persona) => ajax({
         method: 'post',
         url: `/api/planner/personale/`,
         headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': CSRF,
         },
         body: JSON.stringify(persona),
       }).pipe(map(r => r.response)),
       updater: 'insertItem',
     },
     updatePersona: {
       effect: (persona) => ajax({
         method: 'put',
         url: `/api/planner/personale/${persona.id}/`,
         headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': CSRF,
         },
         body: JSON.stringify(persona),
       }).pipe(map(r => r.response)),
       updater: 'updateItem',
     },
    },
    effect: (filters = {}) =>
      ajax.getJSON(`/api/planner/personale/?${qs.stringify(filters)}`),
    computed: {
      persone: 'getList',
      pagination: 'getPagination',
      loading: 'isLoading',
      error: 'getError',
      deleting: s => s.mutations.deletePersona.pending
    }
  }
)

export const PersonaState = rj({
  name: 'Persona',
  effect: (id) =>
    ajax.getJSON(`/api/planner/personale/${id}/`),
})
