import qs from 'query-string'
import { rj } from 'react-rocketjump'
import rjDebounce from 'react-rocketjump/plugins/debounce'
import rjList, { nextPreviousPaginationAdapter } from 'react-rocketjump/plugins/list'
import { ajax } from 'rxjs/ajax'
import { FORNITORE_CONFIG, SETTINGS } from '../../django'

export const OrdiniFornitoreState = rj(
  rjDebounce(),
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    name: 'OrdiniFornitore',
    effect: (params = {}) =>
      ajax.getJSON(`/api/planner/fornitore-ordini/?${qs.stringify(params)}`, {
        'X-Fornitore-Auth': FORNITORE_CONFIG.token,
      }),
    computed: {
      list: 'getList',
      pagination: 'getPagination',
      loading: 'isLoading',
      error: 'getError',
    }
  }
)
