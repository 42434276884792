import React from "react"
import ReactHtmlParser from "react-html-parser"

export default function TableMessaggiOrdiniEmails({
  messaggi,
  setShowModalFormMessaggio,
  setShowModalDeleteMessaggio,
  setMessaggio,
}) {
  return (
    <div className="table table-bordered  mt-2">
      <table className="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Testo</th>
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody>
          {messaggi.map((messaggio) => (
            <tr key={messaggio.id}>
              <td>{messaggio.titolo}</td>
              <td>{ReactHtmlParser(messaggio.testo)}</td>
              <td className="text-center">
                <div className="d-flex justify-content-center">
                  <div
                    className="pointer ml-2"
                    onClick={() => {
                      setMessaggio(messaggio)
                      setShowModalFormMessaggio(true)
                    }}
                  >
                    <i
                      title={`Modifica ${messaggio.titolo}`}
                      className="fas fa-pencil-alt text-warning"
                    ></i>
                  </div>

                  <div
                    className="pointer ml-2"
                    onClick={() => {
                      setMessaggio(messaggio)
                      setShowModalDeleteMessaggio(true)
                    }}
                  >
                    <i
                      title={`Elimina ${messaggio.titolo}`}
                      className="fas fa-trash text-danger"
                    ></i>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
