import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

function ModalBodyMail({ send, sending, toggle, ordineFornitore }) {
  return (
    <>
      <ModalBody>
        <div>
          <strong>Testo email</strong>
        </div>
        <textarea className="form-control" rows={5} disabled>
          {ordineFornitore.testo_email}
        </textarea>
        <hr />
        <div>
          <strong>Pdf allegato</strong>
        </div>
        <iframe
          //   sandbox="allow-same-origin allow-scripts allow-forms"
          title="Pdf Ordine"
          width="100%"
          height="400"
          src={`/api/planner/ordini-fornitore/${ordineFornitore.id}/pdf/`}
        />
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button color="secondary" disabled={sending} onClick={toggle}>
          Chiudi
        </Button>
        <Button onClick={send} disabled={sending} type="submit" color="success">
          Invia mail
        </Button>
      </ModalFooter>
    </>
  )
}

const ModalConfermaMailOrdineFornitore = ({
  isOpen,
  send,
  toggle,
  ordineFornitore,
  sending,
  ...props
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" scrollable>
      {ordineFornitore && (
        <ModalHeader toggle={toggle}>
          Invia mail ordine fornitore - {ordineFornitore.fornitore_data.nome}
        </ModalHeader>
      )}
      {ordineFornitore && (
        <ModalBodyMail
          {...props}
          sending={sending}
          send={send}
          ordineFornitore={ordineFornitore}
          toggle={toggle}
        />
      )}
    </Modal>
  )
}

export default ModalConfermaMailOrdineFornitore
