import React from "react"
import { useHistory } from "react-router-dom"
import Column from "../../Column"

export function TableClienti({
  clienti,
  setShowModalFormCliente,
  filters,
  onFilterChange,
  setShowModalDeleteCliente,
  ordersDomain,
  setCliente,
}) {
  const columnProps = {
    value: filters.ordering,
    onChange: (value) => onFilterChange(value, "ordering"),
  }

  const history = useHistory()

  return (
    <div className="mt-2">
      <table className="table table-bordered table-hover">
        <thead>
          <tr>
            <td></td>
            <td></td>
            <td>
              <select
                name="tipo_cliente"
                value={filters.tipo_cliente}
                className="form-control form-control-sm"
                onChange={onFilterChange}
              >
                <option value=""></option>
                {ordersDomain &&
                  ordersDomain.tipi_cliente.map((tipo) => (
                    <option key={tipo} value={tipo}>
                      {tipo}
                    </option>
                  ))}
              </select>
              {/* <input
                name='tipo_cliente_search'
                type='text'
                className='form-control form-control-sm'
                value={filters.tipo_cliente_search}
                onChange={onFilterChangeDebounced}
              /> */}
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <Column {...columnProps} field={"nome"}>
              Nome
            </Column>
            <Column {...columnProps} field={"codice"}>
              Codice
            </Column>
            <Column {...columnProps} field={"tipo_cliente"}>
              Tipo cliente
            </Column>
            <Column {...columnProps} field={"email"}>
              E-mail
            </Column>
            <Column {...columnProps} field={"indirizzo"}>
              Indirizzo
            </Column>
            <Column {...columnProps} field={"citta"}>
              Città
            </Column>
            <Column {...columnProps} field={"cap"}>
              Cap
            </Column>
            <Column {...columnProps} field={"provincia"}>
              Provincia
            </Column>
            <Column {...columnProps} field={"telefono"}>
              Telefono
            </Column>
            <Column {...columnProps} field={"numero_ordini"}>
              Numero ordini
            </Column>
            <Column {...columnProps} field={"agente_full_name"}>
              Agente
            </Column>
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody>
          {clienti.map((cliente) => (
            <tr key={cliente.id} className="pointer">
              <td onClick={() => history.push(`/clienti/${cliente.id}`)}>
                {cliente.nome}
              </td>
              <td onClick={() => history.push(`/clienti/${cliente.id}`)}>
                {cliente.codice}
              </td>
              <td onClick={() => history.push(`/clienti/${cliente.id}`)}>
                {cliente.tipo_cliente}
              </td>
              <td onClick={() => history.push(`/clienti/${cliente.id}`)}>
                {cliente.email}
              </td>
              <td onClick={() => history.push(`/clienti/${cliente.id}`)}>
                {cliente.indirizzo}
              </td>
              <td onClick={() => history.push(`/clienti/${cliente.id}`)}>
                {cliente.citta}
              </td>
              <td onClick={() => history.push(`/clienti/${cliente.id}`)}>
                {cliente.cap}
              </td>
              <td onClick={() => history.push(`/clienti/${cliente.id}`)}>
                {cliente.provincia}
              </td>
              <td onClick={() => history.push(`/clienti/${cliente.id}`)}>
                {cliente.telefono}
              </td>
              <td
                className="text-center"
                onClick={() => history.push(`/clienti/${cliente.id}`)}
              >
                {cliente.numero_ordini}
              </td>
              <td onClick={() => history.push(`/clienti/${cliente.id}`)}>
                {cliente.agente_data
                  ? `${cliente.agente_data.user.first_name || " "} ${
                      cliente.agente_data.user.last_name || " "
                    }`.trim()
                  : ""}
              </td>
              <td className="text-center">
                <div className="d-flex justify-content-center">
                  <div
                    className="pointer ml-2"
                    onClick={() => {
                      setCliente(cliente)
                      setShowModalFormCliente(true)
                    }}
                  >
                    <i
                      title={`Modifica ${cliente.nome}`}
                      className="fas fa-pencil-alt text-warning"
                    ></i>
                  </div>
                  {cliente.numero_ordini === 0 && (
                    <div
                      className="pointer ml-2"
                      onClick={() => {
                        setCliente(cliente)
                        setShowModalDeleteCliente(true)
                      }}
                    >
                      <i
                        title={`Elimina ${cliente.nome}`}
                        className="fas fa-trash text-danger"
                      ></i>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
