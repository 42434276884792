import { interpolateTurbo } from 'd3-scale-chromatic'
import { shuffle } from 'seed-shuffle'
import range from 'lodash/range'

const TOTAL_COLORS = 20
const COLOR_SEED = 1
const colors = shuffle(range(TOTAL_COLORS).map(i => {
  let k = i / TOTAL_COLORS
  return interpolateTurbo(k)
}), COLOR_SEED)
export const getColorByOrderFornitoreId = id => {
  return colors[id % TOTAL_COLORS]
}