import React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import ListMateriali from './ListMateriali'

export default function MaterialiApp() {
  return (
    <Router>
      <Switch>
        <Route path='/' component={ListMateriali} />
      </Switch>
    </Router>
  )
}
