import React, { useMemo } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap'
import omit from 'lodash/omit'
import * as Yup from 'yup'
import { Formik, Field } from 'formik'
import { InputField, FileField } from '../Form/Fields'
import { transformErrorForForm, fixInitialValuesNull, setNullOnEmptyValues } from '../Form/Fields/utils'

const InitialFileData = { title: '', file: null, description: '' }
const FileDataSchema = Yup.object().shape({
  file: Yup.mixed().required(),
})

function FormFile({ fileData = null, toggle, save, hideFile = false }) {
  const initialValues = useMemo(() => {
    if (fileData) {
      return fixInitialValuesNull(fileData)
    }
    return InitialFileData
  }, [fileData])
  return (
    <Formik
      onSubmit={(values, actions) => {
        let fileValues
        if (hideFile) {
          fileValues = omit(values , 'file')
        } else {
          fileValues = values
        }
        fileValues = setNullOnEmptyValues(fileValues)
        return save(fileValues).then(
          () => {
            // actions.setSubmitting(false)
          },
          error => {
            actions.setSubmitting(false)
            actions.setErrors(transformErrorForForm(error))
          }
        )
      }}
      validationSchema={hideFile ? undefined : FileDataSchema}
      initialValues={initialValues}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className='m-0 p-0'>
          <ModalBody>
            {!hideFile && <Field
              component={FileField}
              type='file'
              label='File'
              name='file'
            />}
            <Field
              autoComplete='off'
              component={InputField}
              label='Titolo'
              name='title'
            />
            <Field
              autoComplete='off'
              component={InputField}
              type='textarea'
              label='Descrizione'
              name='description'
            />
            <Field
              autoComplete='off'
              component={InputField}
              label='Permalink'
              name='permalink_code'
            />
          </ModalBody>
          <ModalFooter className='d-flex justify-content-between'>
            <Button disabled={isSubmitting} type='button' onClick={toggle}>Annulla</Button>
            <Button disabled={isSubmitting} type='submit' color='success'>
              {!isSubmitting && (
                <>
                  {(fileData && fileData.id) ? 'Modifica' : 'Carica'}
                </>
              )}
              {isSubmitting && (
                <CircularProgress color='inherit' size={20} />
              )}
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  )
}

export default function ModalFormFile({ isOpen, fileData, toggle, header, save, hideFile }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      {header && <ModalHeader toggle={toggle}>
        {header}
      </ModalHeader>}
      <FormFile
        hideFile={hideFile}
        fileData={fileData}
        toggle={toggle}
        save={save}
      />
    </Modal>
  )
}
