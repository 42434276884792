import moment from 'moment'
import React from 'react'

export default function TableCompletezzaGiorno({
  attivitaGroupedByOperatoreAndDay,
  lavorazioni,
}) {
  return (
    
    <table className="table table-bordered table-hover table-striped mt-2">
      <thead>
        <tr>
          <th>Giorno</th>
          <th>Minuti lavoro</th>
          <th>Ore lavoro</th>
          <th>Operatore</th>
          <th>Lavorazioni</th>
          <th>Ordini</th>
        </tr>
      </thead>
      <tbody>
        {attivitaGroupedByOperatoreAndDay.map((operatore) => {
          return Object.values(operatore.days).map((day) => {
            const totalMinuti = day.attivita.reduce((acc, attivita) => {
              return (
                acc + parseInt(moment.duration(attivita.tempo).as('minutes'))
              )
            }, 0)

            const ordiniWithoutDuplicates = [
              ...new Set(
                day.attivita
                  .filter((d) => d.ordine)
                  .map((attivita) => attivita.ordine_data.id)
              ),
            ]

            const lavorazioniWithoutDuplicates = [
              ...new Set(
                day.attivita
                  .map((attivita) => attivita.lavorazione_data.id)
                  .concat(
                    day.attivita.map(
                      (attivita) => attivita?.seconda_lavorazione_data?.id
                    )
                  )
              ),
            ]

            return (
              <tr key={day.day}>
                <td className="text-nowrap">
                  {moment(day.day).format('DD-MM-YYYY')}
                </td>
                <td>{totalMinuti} min</td>
                <td>
                  {moment.duration(totalMinuti, 'minutes').hours()}h{' '}
                  {moment.duration(totalMinuti, 'minutes').minutes()}m
                </td>
                <td>
                  {operatore.operatore.user.first_name}{' '}
                  {operatore.operatore.user.last_name}
                </td>
                <td>
                  {lavorazioniWithoutDuplicates
                    .map((lavorazioneId) => {
                      const lavorazione = lavorazioni.find(
                        (lavorazione) =>
                          lavorazione.id === Number(lavorazioneId)
                      )
                      return lavorazione && lavorazione.nome
                    })
                    .join(', ')}
                </td>
                <td>
                  {ordiniWithoutDuplicates
                    .map((ordineId) => {
                      const ordine = day.attivita.find(
                        (attivita) =>
                          attivita?.ordine_data?.id === Number(ordineId)
                      )
                      return (
                        ordine &&
                        ordine.ordine_data.numero_commessa +
                          ' - ' +
                          ordine.ordine_data.cliente.nome
                      )
                    })
                    .join(', ')}
                </td>
              </tr>
            )
          })
        })}
      </tbody>
    </table>
  )
}
