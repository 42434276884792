import { rj } from 'react-rocketjump'
import rjDebounce from 'react-rocketjump/plugins/debounce'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import qs from 'query-string'
import { ajax } from 'rxjs/ajax'
import { map } from 'rxjs/operators'
import { SETTINGS, CSRF } from '../../../django'
import { adjustMulitpleMaterialiFilters, rjListWithTotals } from './common'
import get from 'lodash/get'

export const FasiSchedulateState = rj(
  rjDebounce(),
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  rjListWithTotals(),
  {
    name: 'FasiSchedulate',
    computed: {
      savingStato: (s) => s.mutations.setStato.pending,
      totals: 'getTotals',
      fasiSchedulate: 'getList',
      pagination: 'getPagination',
      loading: 'isLoading',
      error: 'getError',
    },
    mutations: {
      setStato: rj.mutation.single({
        effect: (fase, nuovoStato) => {
          return ajax({
            method: 'put',
            url: `/api/planner/stati-fasi-ordine/set_stato/`,
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CSRF,
            },
            body: JSON.stringify({
              ordine: fase.schedulazione_ordine.ordine.id,
              nome_fase: fase.nome_fase,
              stato: nuovoStato,
            }),
          }).pipe(map((r) => r.response))
        },
        updater: (state, dataStato) => ({
          ...state,
          data: {
            ...state.data,
            list: state.data.list.map((fase) => {
              if (
                fase.schedulazione_ordine.ordine.id === dataStato.ordine &&
                fase.nome_fase === dataStato.nome_fase
              ) {
                return {
                  ...fase,
                  stato: dataStato,
                }
              }
              return fase
            }),
          },
        }),
      }),
    },
    effect: (filters = {}) => {
      let deFilters = { ...filters }
      const stato = get(filters, 'stato', [])
      if (stato && stato.includes('NESSUNO STATO')) {
        deFilters.stato = filters.stato.filter((x) => x !== 'NESSUNO STATO')
        deFilters.empty = true
      }
      deFilters = adjustMulitpleMaterialiFilters(deFilters)
      return ajax.getJSON(
        `/api/planner/fasi-schedulate/?${qs.stringify(deFilters)}`
      )
    },
  }
)
