import React from "react"
import moment from "moment"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

const ModalDeleteControlloEffettuato = ({
  isOpen,
  toggle,
  onSetEliminato,
  deleting,
  controllo,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalHeader toggle={toggle}>Elimina controllo effettuato</ModalHeader>
      <ModalBody>
        {controllo && (
          <div className="container">
            Sei sicuro di voler eliminare il controllo effettuato in data{" "}
            {moment(controllo.data).format("DD/MM/YYYY")} risultato{" "}
            {controllo.conforme ? "conforme" : "non conforme"}?
          </div>
        )}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button color="secondary" onClick={toggle}>
          Chiudi
        </Button>
        <Button color="danger" disabled={deleting} onClick={onSetEliminato}>
          Elimina
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalDeleteControlloEffettuato
