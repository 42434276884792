import React, { useContext, useMemo, useEffect, useRef } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import capitalize from 'lodash/capitalize'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { InputField } from '../Form/Fields'
import { ContextFornitori } from '../../apps/PlannerApp/contexts'
import {
  transformErrorForForm,
  setNullOnEmptyValues,
} from '../Form/Fields/utils'
import { PERMISSIONS } from '../../django'

const RigaOrdineFornitoreSchema = Yup.object().shape({
  tipologia: Yup.mixed().required(),
  fornitore: Yup.mixed().required(),
  quantita: Yup.number().required(),
})

const emptyRigaOrdineFornitore = {
  tipologia: '',
  codice: '',
  quantita: '',
  um: '',
  spessore: '',
  stampato: '',
  misure: '',
  misure_vetro: '',
  riga_ordine: '',
  sezione: '',
  descrizione: '',
  fornitore: '',
  extra_fields: {}
}

function FormFields({ fornitori, tipologia, tipologie, setFieldValue, riga, colonneMateriali }) {
  const fornitoriForFase = useMemo(
    () => fornitori.filter((f) => f.tipologia === tipologia),
    [fornitori, tipologia]
  )

  const extraFields = useMemo(() => {
    if(!tipologia){
      return []
    }
    return colonneMateriali[tipologia]
  },[colonneMateriali, tipologia])

  const prevTipolgia = useRef(null)
  useEffect(() => {
    if (prevTipolgia.current === null) {
      prevTipolgia.current = tipologia
    } else if (prevTipolgia.current !== tipologia) {
      prevTipolgia.current = tipologia
      setFieldValue('fornitore', '', false)
    }
  }, [tipologia, setFieldValue])

  return (
    <div className="row">
      <div className="col-md-6">
        <Field
          disabled={!PERMISSIONS.adminOrdiniFornitore || riga}
          label="Tipologia *"
          type="select"
          name="tipologia"
          component={InputField}
          className="form-control"
        >
          <option value="">{'--- Scegli Tipologia ---'}</option>
          {tipologie.map((tipologia) => (
            <option key={tipologia} value={tipologia}>
              {capitalize(tipologia)}
            </option>
          ))}
        </Field>
        <Field
          disabled={!PERMISSIONS.adminOrdiniFornitore || (riga && riga.ordine_fornitore)}
          label="Fornitore *"
          type="select"
          name="fornitore"
          component={InputField}
          className="form-control"
        >
          <option value="">{'--- Scegli Fornitore ---'}</option>
          {fornitoriForFase.map((fornitore) => (
            <option key={fornitore.id} value={fornitore.id}>
              {fornitore.nome}
            </option>
          ))}
        </Field>
        <Field
          label="Codice"
          name="codice"
          type="text"
          component={InputField}
        />
        <Field
          label="Quantità *"
          name="quantita"
          type="number"
          component={InputField}
        />
        <Field
          label="Riga Ordine"
          name="riga_ordine"
          type="number"
          component={InputField}
        />
      </div>
      <div className="col-md-6">
        <Field
          label="Descrizione"
          name="descrizione"
          type="textarea"
          component={InputField}
        />
        {extraFields.length > 0 && extraFields.map(field => (
          <Field
            key={field}
            label={field.replace('_',' ')}
            labelClassName={'text-capitalize'}
            name={'extra_fields.'+field}
            type="text"
            component={InputField}
            relaxed
          />
        ))}
      </div>
    </div>
  )
}

const ModalOrdineRigaOrdineFornitore = ({
  isOpen,
  toggle,
  save,
  tipologie,
  colonneMateriali,
  riga,
  isDuplicate,
}) => {
  const fornitori = useContext(ContextFornitori)

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Riga Ordine Fornitore</ModalHeader>
      <Formik
        initialValues={riga ? riga : emptyRigaOrdineFornitore}
        validationSchema={RigaOrdineFornitoreSchema}
        onSubmit={(values, actions) => {
          return save(setNullOnEmptyValues(values)).then(
            () => {
              actions.setSubmitting(false)
            },
            (error) => {
              actions.setSubmitting(false)
              actions.setErrors(transformErrorForForm(error))
            }
          )
        }}
        render={({
          handleSubmit,
          values,
          isSubmitting,
          errors,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="p-0 m-0">
            <ModalBody>
              <FormFields
                setFieldValue={setFieldValue}
                fornitori={fornitori}
                riga={riga}
                colonneMateriali={colonneMateriali}
                tipologie={tipologie}
                tipologia={values.tipologia}
                fornitore={values.fornitore}
              />
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
              <Button
                color="secondary"
                disabled={isSubmitting}
                onClick={toggle}
              >
                Chiudi
              </Button>
              <Button type="submit" disabled={isSubmitting} color="success">
                {riga ? isDuplicate ? 'Duplica' : 'Modifica' : 'Aggiungi'}
              </Button>
            </ModalFooter>
          </form>
        )}
      />
    </Modal>
  )
}

export default ModalOrdineRigaOrdineFornitore
