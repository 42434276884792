import React, { useContext } from 'react'
import { Field } from 'formik'
import { FieldRadio } from '../../Fields'
import { ConfigPrevetiviContext } from '../../../../context'

function FormTipoIntervento(){

  const { tipiIntervento } = useContext(ConfigPrevetiviContext)

  return (
    <div className='FormTipoIntervento mb-4'>
      <h6>Tipo intervento</h6>
      <div>
        {tipiIntervento.map((intervento,i) => (
          <div className="form-check d-flex" key={i}>
            <Field
              component={FieldRadio}
              className='form-check-input'
              name="tipo_intervento"
              id={intervento[0]}
              value={intervento[0]}
            />
            <label htmlFor={intervento[0]} className='form-check-label'>{intervento[1]}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default React.memo(FormTipoIntervento)
