import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ModalDeletePreventivo = ({ isOpen, toggle, onSetEliminato, saving, textModal, titleModal, classButton }) => {
  return(
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
      <ModalBody>
        <div className='container'>
          {textModal}
        </div>
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between'>
        <Button color="secondary" onClick={toggle}>Chiudi</Button>
        <Button color={classButton} disabled={saving} onClick={onSetEliminato}>{titleModal}</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalDeletePreventivo
