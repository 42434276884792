import React, { useCallback, useMemo, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useRunRj } from 'react-rocketjump'
import { useQsDataTable as useDataTable } from 'react-use-datatable/react'
import useQueryParams from 'magik-react-hooks/useRouterQueryParams'
import Button from 'reactstrap/lib/Button'
import ModalDeleteAttivita from '../../components/Planner/ModalDeleteAttivita'
import ModalFormAttivita from '../../components/Planner/ModalFormAttivita'
import ModalFormAttivitaUpdate from '../../components/Planner/ModalFormAttivitaUpdate'
import TableAttivita from '../../components/Planner/TableAttivita'
import { AttivitaMonthState, AttivitaState } from './localstate/attivita'
import moment from 'moment'
import { USER } from '../../django'
import it from 'date-fns/locale/it'
import { LavorazioniState } from './localstate/lavorazioni'
// import countBy from 'lodash/countBy'
import TableLockedAttivita from '../../components/Planner/TableLockedAttivita'
import Paginator from '../../components/Paginator'
import Spinner from 'reactstrap/lib/Spinner'

registerLocale('it', it)
const FORMAT = 'YYYY-MM-DD'

const BIG_PAGE_PARAMS = { page_size: 1000 }

const COLOR_ACTIVITIES_LOCKED = 'rgba(0, 0, 255, 0.3)'
const COLOR_DAY_WITH_ACTIVITY = '#3dcc4a'
const COLOR_DAY_SELECTED = '#216ba5'

export default function AttivitaOperatore() {
  const [selectedAttivita, setAttivita] = useState(null)
  const [queryParams] = useQueryParams()
  const [showModalDeleteAttivita, setShowModalDeleteAttivita] = useState(false)
  const [showModalFormAttivita, setShowModalFormAttivita] = useState(false)
  const [showModalFormAttivitaUpdate, setShowModalFormAttivitaUpdate] =
    useState(false)
  const [{ lavorazioni }] = useRunRj(LavorazioniState, [BIG_PAGE_PARAMS])
  const currentData = useMemo(() => {
    if (queryParams.data) {
      return new Date(queryParams.data)
    }
    return new Date()
  }, [queryParams])
  const [date, setDate] = useState(currentData)
  const [currentMonth, setCurrentMonth] = useState(currentData)
  const toggleShowModalAttivita = useCallback(() => {
    setShowModalFormAttivita((show) => !show)
  }, [])
  const toggleShowModalAttivitaUpdate = useCallback(() => {
    setAttivita(null)
    setShowModalFormAttivitaUpdate((show) => !show)
  }, [])
  const toggleShowModalDelete = useCallback(() => {
    setShowModalDeleteAttivita((show) => !show)
  }, [])

  const [
    // Data State
    { attivita, pagination, loading, deleting },
    // Table state
    { page },
    {
      // DT Actions
      onFiltersChange,
      goToPage,
      updateAttivita,
      addAttivita,
      deleteAttivita,
      deleteControlloEffettuato,
      refresh: refreshAttivita,
    },
  ] = useDataTable(AttivitaState, () => ({
    data: moment(currentData).format(FORMAT),
  }))

  const [
    // Data State
    { attivita: attivitaLocked },
    // Table state
    { page: pageLocked },
    {
      // DT Actions
      refresh,
    },
  ] = useDataTable(AttivitaState, () => ({
    ora_fine__isnull: true,
  }))

  const [{ dates }, { run: refreshCalendar }] = useRunRj(
    AttivitaMonthState,
    [moment(currentMonth).format(FORMAT)],
    false
  )

  const lavorazioniLocked =
    attivita && attivita.filter((item) => !item.fine_lavorazione)

  // const attivitaLock = countBy(
  //   attivita,
  //   (attivita) => attivita.ora_fine === null
  // )

  const datesToShowMonth = useMemo(() => {
    if (dates === null) {
      return null
    }
    return dates.map((data) => moment(data[0]).toDate())
  }, [dates])

  return (
    <div className="container-fluid">
      {attivita === null && attivitaLocked === null && loading && (
        <div className="text-center mt-4">
          <Spinner color="primary" />
        </div>
      )}
      {attivita && (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <div className="text-left">
              <Button
                onClick={() => toggleShowModalAttivita()}
                size="sm"
                className="mt-2"
                outline
                color="secondary"
              >
                <i className="fas fa-plus-circle"></i> Aggiungi attività
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  setDate(new Date())
                  onFiltersChange({
                    data: moment().format(FORMAT),
                  })
                }}
                size="sm"
                className="mt-2"
                outline
                color="primary"
              >
                <i className="fas fa-calendar"></i> Attività di oggi
              </Button>
            </div>
          </div>
          <div className="text-center text-primary">
            <h4 style={{ color: '#216ba5' }}>
              {moment(date).format('DD-MM-YYYY')}
            </h4>
          </div>
          <div className="d-flex align-items-center">
            <div
              style={{
                height: 20,
                width: 30,
                background: COLOR_ACTIVITIES_LOCKED,
              }}
            ></div>
            <div className="ml-2">Attività senza orario di fine lavorazione</div>
          </div>
          <div className="d-flex align-items-center">
            <div
              style={{
                height: 20,
                width: 30,
                background: COLOR_DAY_WITH_ACTIVITY,
              }}
            ></div>
            <div className="ml-2">Giorni con attività</div>
          </div>
          <div className="d-flex align-items-center">
            <div
              style={{
                height: 20,
                width: 30,
                background: COLOR_DAY_SELECTED,
              }}
            ></div>
            <div className="ml-2">Giorno selezionato</div>
          </div>
          <div className="row">
            <div className="col-md-9">
              <TableAttivita
                attivita={attivita}
                loading={loading}
                attivitaLocked={attivitaLocked}
                addAttivita={addAttivita}
                refresh={refresh}
                refreshAttivita={refreshAttivita}
                setAttivita={setAttivita}
                setShowModalDeleteAttivita={setShowModalDeleteAttivita}
                setShowModalFormAttivitaUpdate={setShowModalFormAttivitaUpdate}
              />
              <Paginator
                numPages={pagination.numPages}
                currentPage={page}
                goToPage={goToPage}
              />
            </div>
            <div className="col-md-3">
              <div className="mt-2 text-center">
                <DatePicker
                  selected={date}
                  name="data"
                  locale={'it'}
                  onMonthChange={(month) => setCurrentMonth(month)}
                  onChange={(date) => {
                    setDate(date)
                    onFiltersChange({
                      data: moment(date).format(FORMAT),
                    })
                  }}
                  inline
                  highlightDates={datesToShowMonth ?? []}
                  className="mt-1"
                />
              </div>
              {attivitaLocked && attivitaLocked.length > 0 && (
                <div>
                  <TableLockedAttivita
                    attivita={attivitaLocked}
                    setDate={setDate}
                    setAttivita={setAttivita}
                    setShowModalFormAttivitaUpdate={
                      setShowModalFormAttivitaUpdate
                    }
                    onFiltersChange={onFiltersChange}
                  />
                </div>
              )}
            </div>
          </div>
          {showModalDeleteAttivita && (
            <ModalDeleteAttivita
              toggle={toggleShowModalDelete}
              isOpen={showModalDeleteAttivita}
              attivita={selectedAttivita}
              deleting={deleting}
              onSetEliminato={() => {
                deleteAttivita
                  .onSuccess(() => {
                    refresh()
                    refreshAttivita()
                    refreshCalendar(moment(currentMonth).format(FORMAT))
                    toggleShowModalDelete()
                  })
                  .run(selectedAttivita)
              }}
            />
          )}
          <ModalFormAttivita
            isOpen={showModalFormAttivita}
            lavorazioni={lavorazioni}
            lavorazioniLocked={lavorazioniLocked}
            toggle={toggleShowModalAttivita}
            date={date}
            save={(attivita) => {
              return addAttivita
                .onSuccess((attivita) => {
                  setAttivita(attivita)
                  refresh()
                  refreshAttivita()
                  refreshCalendar(moment(currentMonth).format(FORMAT))
                  toggleShowModalAttivita()
                  toggleShowModalAttivitaUpdate()
                  setAttivita(attivita)
                })
                .asPromise({
                  ...attivita,
                  operatore: USER.operatore.id,
                  data: moment(date).format('YYYY-MM-DD'),
                  ordine: attivita.ordine ? attivita.ordine.id : null,
                  seconda_lavorazione: attivita.seconda_lavorazione 
                    ? attivita.seconda_lavorazione.id: null, 
                })
            }}
          />
          {selectedAttivita && (
            <ModalFormAttivitaUpdate
              isOpen={showModalFormAttivitaUpdate}
              lavorazioni={lavorazioni}
              toggle={toggleShowModalAttivitaUpdate}
              date={date}
              newRecordOfAttivita={
                !!selectedAttivita.ora_inizio &&
                !!selectedAttivita.ora_fine &&
                selectedAttivita.ordine
              }
              attivita={selectedAttivita}
              onDeleteControllo={deleteControlloEffettuato}
              save={(attivita) => {
                if (attivita.new_record) {
                  return addAttivita
                    .onSuccess((attivita) => {
                      refresh()
                      refreshAttivita()
                      refreshCalendar(moment(currentMonth).format(FORMAT))
                      toggleShowModalAttivitaUpdate()
                    })
                    .asPromise({
                      ...attivita,
                      operatore: USER.operatore.id,
                      data: moment(date).format('YYYY-MM-DD'),
                      seconda_lavorazione: attivita.seconda_lavorazione
                    })
                } else {
                  return updateAttivita
                    .onSuccess(() => {
                      setAttivita(null)
                      refresh()
                      refreshAttivita()
                      refreshCalendar(moment(currentMonth).format(FORMAT))
                      toggleShowModalAttivitaUpdate()
                    })
                    .asPromise({
                      ...attivita,
                      operatore: USER.operatore.id,
                      data: moment(date).format('YYYY-MM-DD'),
                    })
                }
              }}
            />
          )}
        </>
      )}
    </div>
  )
}
