import React, { useMemo, useRef, useState } from 'react'
import Select from 'react-select'
import { map } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { useAsyncPaginate, useComponents } from 'react-select-async-paginate'
import find from 'lodash/find'
import { deps, rj, useRunRj } from 'react-rocketjump'

function ordersAutocomplteApi(q = '', page = 1, id = '') {
  return ajax.getJSON(
    `/api/planner/ordini-autocomplete/?search=${q}&page=${page}&id=${id}`
  )
}

function ordersAutocomplteMultiApi(q = '', page = 1, id = '') {
  return ajax.getJSON(
    `/api/planner/ordini-autocomplete/?search=${q}&page=${page}&ordini=${id}`
  )
}

const InitalOrderState = rj({
  name: 'InitalOrdersAtucomplete',
  effect: (id) =>
    ordersAutocomplteApi('', 1, id).pipe(
      map((data) => makeOptions(data.results))
    ),
})

const InitalOrderStateMulti = rj({
  name: 'InitalOrdersAtucompleteMulti',
  effect: (id) =>
    ordersAutocomplteMultiApi('', 1, id).pipe(
      map((data) => makeOptions(data.results))
    ),
})

function makeOptions(list) {
  return list
    ? list.map((item) => ({
        ...item,
        label: item.numero_commessa + ' - ' + item.cliente.nome,
        value: item.id,
      }))
    : []
}

function loadOrdersOptions(inputValue, loadedOptions, options) {
  const { page = 1 } = options
  return ordersAutocomplteApi(inputValue, page)
    .pipe(
      map((data) => ({
        ...data,
        page,
      }))
    )
    .toPromise()
    .then((data) => ({
      options: makeOptions(data.results),
      hasMore: data.next !== null,
      additional: {
        page: data.page + 1,
      },
    }))
}

const OrdersSelectAutoComplete = ({
  loadOptions = loadOrdersOptions,
  options,
  defaultOptions,
  additional,
  defaultAdditional,
  loadOptionsOnMenuOpen,
  debounceTimeout,
  reduceOptions,
  shouldLoadMore,
  filterOption,
  inputValue,
  menuIsOpen,
  defaultInputValue,
  defaultMenuIsOpen,
  onInputChange,
  onMenuClose,
  onMenuOpen,

  components: defaultComponents,

  value: valueId,
  onChange,
  ...props
}) => {
  const initialValueRef = useRef(valueId)
  const initialValue = initialValueRef.current
  const stateToUse = props.isMulti ? InitalOrderStateMulti : InitalOrderState
  const [{ data: initialOptions, pending: initialLoading }] = useRunRj(
    stateToUse,
    [deps.maybe(initialValue)]
  )

  const asyncPaginateProps = useAsyncPaginate({
    loadOptions,
    options,
    defaultOptions,
    additional,
    defaultAdditional,
    loadOptionsOnMenuOpen,
    debounceTimeout,
    reduceOptions,
    shouldLoadMore,
    filterOption,
    inputValue,
    menuIsOpen,
    defaultInputValue,
    defaultMenuIsOpen,
    onInputChange,
    onMenuClose,
    onMenuOpen,
  })

  const components = useComponents(defaultComponents)
  const [lastOptions, setLastOptions] = useState([])

  const dataOptions = asyncPaginateProps.options
  const value = useMemo(() => {
    if (!valueId) {
      return null
    }
    let myOrderValue = null
    if (dataOptions.length > 0) {
      myOrderValue = props.isMulti
        ? dataOptions.filter((item) => valueId.includes(String(item.id)))
        : find(dataOptions, { id: +valueId })
    }
    if (!myOrderValue && initialOptions) {
      myOrderValue = props.isMulti
        ? initialOptions.filter((item) => valueId.includes(String(item.id)))
        : find(initialOptions, { id: +valueId })
    }
    if (!myOrderValue && lastOptions) {
      myOrderValue = find(lastOptions, { id: +valueId })
    }
    return myOrderValue ?? null
  }, [valueId, dataOptions, initialOptions, lastOptions, props.isMulti])

  return (
    <Select
      {...props}
      {...asyncPaginateProps}
      isLoading={asyncPaginateProps.isLoading || initialLoading}
      components={components}
      value={value}
      onChange={(ordine) => {
        // FIXME: FIXME: FIXME: FIXME: Workaround to avoid
        // out of pagination bug .....
        setLastOptions(dataOptions)
        onChange(ordine)
      }}
    />
  )
}

export default OrdersSelectAutoComplete
