import React, { useState, useCallback } from "react"
import Paginator from "../../components/Paginator"
import { useQsDataTable as useDataTable } from "react-use-datatable/react"
import CircularProgress from "@material-ui/core/CircularProgress"
import { Button } from "reactstrap"
import { MessaggiOrdiniEmailsState } from "./localstate/messaggi-ordini-emails"
import TableMessaggiOrdiniEmails from "../../components/Planner/TableMessaggiOrdiniEmails"
import ModalFormMessaggioOrdineEmail from "../../components/Planner/ModalFormMessaggioOrdineEmail"
import ModalDeleteMessaggioOrdineEmail from "../../components/Planner/ModalDeleteMessaggioOrdineEmail"

export default function MessaggiOrdiniEmails() {
  const [
    // Data State
    { messaggi, pagination, loading, deleting },
    // Table state
    { page, ...filters },
    {
      // DT Actions
      goToPage,
      onFilterChangeDebounced,
      deleteMessaggio,
      addMessaggio,
      updateMessaggio,
    },
  ] = useDataTable(MessaggiOrdiniEmailsState, () => ({
    search: "",
    ordering: "",
  }))

  const [selectedMessaggio, setMessaggio] = useState(null)

  const [showModalDeleteMessaggio, setShowModalDeleteMessaggio] = useState(false)
  const toggleShowModalDelete = useCallback(() => {
    setShowModalDeleteMessaggio((show) => !show)
  }, [])

  const [showModalFormMessaggio, setShowModalFormMessaggio] = useState(false)
  const toggleShowModalMessaggio = useCallback(() => {
    setMessaggio(null)
    setShowModalFormMessaggio((show) => !show)
  }, [])

  return (
    <div className="container-fluid">
      {messaggi === null && loading && (
        <div className="text-center mt-4">
          <CircularProgress color="primary" />
        </div>
      )}
      {messaggi && (
        <>
          <div className="d-flex justify-content-between">
            <div>
              <div>
                <input
                  name="search"
                  type="text"
                  className="form-control form-control-sm mt-2 mr-2"
                  placeholder={"Ricerca libera"}
                  value={filters && filters.search}
                  onChange={onFilterChangeDebounced}
                />
              </div>
            </div>
            <div className="text-right">
              <Button
                onClick={() => toggleShowModalMessaggio()}
                size="sm"
                className="mt-2"
                outline
                color="secondary"
              >
                <i className="fas fa-plus-circle"></i> Aggiungi messaggio
              </Button>
            </div>
          </div>
          <TableMessaggiOrdiniEmails
            messaggi={messaggi}
            loading={loading}
            setMessaggio={setMessaggio}
            setShowModalDeleteMessaggio={setShowModalDeleteMessaggio}
            setShowModalFormMessaggio={setShowModalFormMessaggio}
          />
          <Paginator
            className=""
            numPages={pagination.numPages}
            currentPage={page}
            goToPage={goToPage}
          />
          <ModalFormMessaggioOrdineEmail
            isOpen={showModalFormMessaggio}
            toggle={toggleShowModalMessaggio}
            messaggio={selectedMessaggio}
            save={(messaggio) => {
              if (selectedMessaggio) {
                return updateMessaggio
                  .onSuccess(() => {
                    setMessaggio(null)
                    toggleShowModalMessaggio()
                  })
                  .asPromise(messaggio)
              } else {
                return addMessaggio
                  .onSuccess(toggleShowModalMessaggio)
                  .asPromise(messaggio)
              }
            }}
          />
          <ModalDeleteMessaggioOrdineEmail
            toggle={toggleShowModalDelete}
            isOpen={showModalDeleteMessaggio}
            messaggio={selectedMessaggio}
            deleting={deleting}
            onSetEliminato={() => {
              deleteMessaggio
                .onSuccess(() => {
                  toggleShowModalDelete()
                })
                .run(selectedMessaggio)
            }}
          />
        </>
      )}
    </div>
  )
}
