import React, { useState, useCallback } from 'react'
import get from 'lodash/get'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap'
import { useQsDataTable } from 'react-use-datatable/react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { PERMISSIONS } from '../../../django'
import Paginator from '../../../components/Paginator'
import ModalFormFolder from '../../../components/QrApp/ModalFormFolder'
import { FoldersState } from '../localstate'
import FolderRow from '../../../components/QrApp/FolderRow'
import './FoldersList.css'

export default function FoldersList({ history, location }) {
  const [
    { folders, loading, deleting, lastParams, pagination },
    { page, search },
    {
      deleteFolder,
      addFolder,
      patchFolder,
      // Table actions
      goToPage,
      refresh,
      onFilterChangeDebounced,
    },
  ] = useQsDataTable(FoldersState, () => ({
    search: '',
  }))

  const [showAdd, setShowAdd] = useState(false)
  const toggleShowAdd = useCallback(() => setShowAdd((a) => !a), [])

  const [showEdit, setShowEdit] = useState(null)
  const toggleShowEdit = useCallback(() => setShowEdit(null), [])

  const [showDelete, setShowDelete] = useState(null)
  const toggleShowDelete = useCallback(() => setShowDelete(null), [])

  const handleEdit = useCallback((folder, e) => {
    e.preventDefault()
    setShowEdit(folder)
  }, [])

  const handleDelete = useCallback((folder, e) => {
    e.preventDefault()
    setShowDelete(folder)
  }, [])

  if (folders === null) {
    return (
      <div className="text-center mt-4">
        <CircularProgress color="primary" />
      </div>
    )
  }

  const lastSearch = get(lastParams, '[0].search', '')

  return (
    <div className="container-fluid">
      <div className="mb-3 mt-3 d-flex justify-content-between">
        <div>
          <Input
            placeholder="Cerca cartella"
            autoComplete={'off'}
            value={search}
            name="search"
            onChange={onFilterChangeDebounced}
          />
        </div>
        <div>
          {PERMISSIONS.adminApp && (
            <Button onClick={toggleShowAdd} color="primary">
              Nuova cartella
              <i className="fa fa-folder ml-2" aria-hidden="true"></i>
            </Button>
          )}
        </div>
      </div>
      {folders && folders.length === 0 && !loading && (
        <table className="table table-bordered table-hover">
          <tbody>
            <tr>
              <td className="bg-light">
                <i className="fa fa-folder mr-2" aria-hidden="true"></i>
                {lastSearch === '' && <i>Ancora nessuna cartella</i>}
                {lastSearch !== '' && (
                  <i>Nessuna cartella per la ricerca: "{lastSearch}"</i>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      )}
      <table className="TableFolders table table-bordered table-hover">
        <tbody>
          {folders &&
            folders.map((folder) => (
              <FolderRow
                onDeleteClick={PERMISSIONS.adminApp ? handleDelete : null}
                onEditClick={PERMISSIONS.adminApp ? handleEdit : null}
                key={folder.id}
                folder={folder}
                location={location}
                showSharedCount={PERMISSIONS.adminApp}
              />
            ))}
        </tbody>
      </table>
      <Paginator
        numPages={pagination.numPages}
        currentPage={page}
        goToPage={goToPage}
      />
      <Modal isOpen={showDelete !== null} toggle={toggleShowDelete}>
        <ModalHeader toggle={toggleShowDelete}>
          {showDelete && `Eliminare la cartella ${showDelete.name}`}
        </ModalHeader>
        <ModalBody>
          {showDelete && (
            <>Sei sicuro di voler elminare la cartella {showDelete.name}?</>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button disabled={deleting} onClick={toggleShowDelete}>
            Annulla
          </Button>
          <Button
            disabled={deleting}
            color="danger"
            onClick={() => {
              deleteFolder
                .onSuccess(() => {
                  toggleShowDelete()
                  refresh()
                })
                .run(showDelete.id)
            }}
          >
            Elimina
          </Button>
        </ModalFooter>
      </Modal>
      <ModalFormFolder
        isOpen={showEdit !== null}
        toggle={toggleShowEdit}
        folder={showEdit}
        header={showEdit ? `Modifica cartella ${showEdit.name}` : null}
        save={(folder) =>
          patchFolder
            .onSuccess(() => {
              toggleShowEdit()
              refresh()
            })
            .asPromise(folder)
        }
      />
      <ModalFormFolder
        isOpen={showAdd}
        toggle={toggleShowAdd}
        header="Nuova cartella"
        save={(folder) =>
          addFolder
            .onSuccess((folderCreated) =>
              history.push(`/folders/${folderCreated.id}`)
            )
            .asPromise(folder)
        }
      />
    </div>
  )
}
