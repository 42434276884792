import React from "react"
import moment from "moment"

const COLOR_ACTIVITIES_LOCKED = "rgba(0, 0, 255, 0.3)"

export default function TableLockedAttivita({
  attivita,
  setDate,
  onFiltersChange,
  setAttivita,
  setShowModalFormAttivitaUpdate
}) {
  return (
    <div className="card mt-2" style={{ border: '1px solid blue'}}>
      <div className="card-header" style={{ background: COLOR_ACTIVITIES_LOCKED}}>
        Attività senza orario di fine lavorazione
      </div>
      <div className="card-body">
        <table className="table table-bordered table-striped">
          <tbody>
            {attivita.map((activity) => (
              <tr
                key={activity.id}
                className="pointer"
                onClick={() => {
                  setDate(new Date(activity.data))
                  onFiltersChange({
                    data: moment(activity.data).format("YYYY-MM-DD"),
                  })
                  setAttivita(activity)
                  setShowModalFormAttivitaUpdate(true)
                }}
              >
                <td className="text-nowrap">
                  <small>{moment(activity.data).format("DD/MM/YYYY")}</small>
                </td>
                <td>
                  <small>{activity.lavorazione_data.nome}</small>
                </td>
                <td>
                  <small>{activity?.ordine_data?.numero_commessa}</small>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
