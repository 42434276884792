import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import dayjs from 'dayjs'

const ModalDetailMail = ({ isOpen, toggle, preventivo }) => {

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}><i className="fas fa-envelope"></i> E-mail inviata il {dayjs(preventivo.email_inviata_il).format('DD-MM-YYYY')}</ModalHeader>
      <ModalBody>
        <input readOnly value={preventivo && preventivo.email_inviata_subject} className='form-control' />
        <textarea
          className='form-control mt-2'
          readOnly
          rows={10}
          defaultValue={preventivo && preventivo.email_inviata_body}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Chiudi</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalDetailMail
