import React, { useMemo } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { Formik, Field } from "formik"
import * as Yup from "yup"
import { InputField } from "../Form/Fields"
import {
  setNullOnEmptyValues,
  transformErrorForForm,
} from "../Form/Fields/utils"
import moment from "moment"
import find from "lodash/find"
import { RichTextNewsletterField } from "../Form/QuillField"

const InvioMailSchema = Yup.object().shape({
  testo_email: Yup.string().required(),
})

function getDefaulTesto(ordine) {
  const START_DATE = moment(ordine.data_consegna).format("DD/MM/YYYY")
  const END_DATE = moment(ordine.data_consegna)
    .add(7, "days")
    .format("DD/MM/YYYY")

  return (
    `
    <div>
      Gentile cliente, con la presente le comunichiamo che il suo ordine è stato
      inserito in produzione nella tabella qui sotto alcuni dati e la data di
      approntamento assegnata. 
      <div>DATA COMMESSA: ${ordine.data}</div>
      <div>NOME CLIENTE: ${ordine.cliente_data.nome}</div>
      <div>NUMERO COMMESSA: ${ordine.numero_commessa}</div>
      <div> RIFERIMENTO: ${ordine.riferimento ? ordine.riferimento : ""}</div>
      <div>NUMERO SERRAMENTI: ${ordine.numero_serramenti}</div>
      <div>DATA APPRONTAMENTO: Dal: ${START_DATE} al ${END_DATE}</div>
      <div>CODICE SISTEMA: ${ordine.codice_sistema}</div>
    </div>`
  )
}

const ModalUpdateStatoInvioMail = ({
  isOpen,
  toggle,
  save,
  ordine,
  messaggi,
}) => {
  const initialValues = useMemo(() => {
    let testoEmail
    const statoInvio = ordine && ordine.stato_invio_mail
    if (ordine) {
      if (ordine.testo_email && ordine.testo_email !== "") {
        testoEmail = ordine.testo_email
      } else if (ordine.cliente_data && ordine.cliente_data.testo_email) {
        testoEmail = ordine.cliente_data.testo_email
      } else {
        testoEmail = getDefaulTesto(ordine)
      }
    }
    return {
      testo_email: testoEmail,
      stato_invio_mail: statoInvio,
      messaggio_aggiuntivo: "",
    }
  }, [ordine])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" scrollable>
      <ModalHeader toggle={toggle}>
        {ordine && (
          <>
            {!ordine.data_invio_mail ? (
              <>
                {`Programmazione invio mail`} - {ordine.numero_commessa} -{" "}
                {ordine.cliente_data.nome}
              </>
            ) : (
              <>
                {`E-mail inviata`} - {ordine.numero_commessa} -{" "}
                {ordine.cliente_data.nome} (
                {moment(ordine.data_invio_mail).format("DD/MM/YYYY HH:mm")})
              </>
            )}
          </>
        )}
      </ModalHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={InvioMailSchema}
        onSubmit={(values, actions) => {
          return save(setNullOnEmptyValues(values)).then(
            () => {
              actions.setSubmitting(false)
            },
            (error) => {
              actions.setSubmitting(false)
              actions.setErrors(transformErrorForForm(error))
            }
          )
        }}
        render={({
          handleSubmit,
          values,
          isSubmitting,
          errors,
          setFieldValue,
        }) => (
          <>
            <ModalBody>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <Field
                      component={InputField}
                      label={"Stato invio mail"}
                      name="stato_invio_mail"
                      disabled={ordine && ordine.data_invio_mail}
                      type="select"
                    >
                      <option value={""}></option>
                      <option value={"in_attesa_invio"}>
                        In attesa di invio
                      </option>
                      {ordine && ordine.data_invio_mail && (
                        <option value={"inviata"}>Inviata</option>
                      )}
                    </Field>
                  </div>
                  {messaggi.length > 0 && !ordine.data_invio_mail && (
                    <div className="col-md-12">
                      <Field
                        component={InputField}
                        label="Messaggio aggiuntivo"
                        name="messaggio_aggiuntivo"
                        onChange={(e) => {
                          setFieldValue("messaggio_aggiuntivo", e.target.value)
                          const testoAppend = find(messaggi, {
                            id: +e.target.value,
                          })
                          setFieldValue(
                            "testo_email",
                            `${getDefaulTesto(ordine)}\n\n${
                              testoAppend ? testoAppend.testo : ""
                            }\n`
                          )
                        }}
                        type="select"
                      >
                        <option value={""}></option>
                        {messaggi.map((messaggio, i) => (
                          <option key={i} value={messaggio.id}>
                            {messaggio.titolo}
                          </option>
                        ))}
                      </Field>
                    </div>
                  )}
                  <div className="col-md-12 mt-3">
                    <Field
                      label={
                        ordine && !ordine.data_invio_mail
                          ? "Testo per email"
                          : "Testo e-mail inviata"
                      }
                      type="textarea"
                      name="testo_email"
                      rows={15}
                      disabled={ordine.data_invio_mail}
                      className="mb-2"
                      component={RichTextNewsletterField}
                    />
                  </div>
                </div>
                <input type="submit" className="d-none" />
              </form>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
              <Button
                disabled={isSubmitting}
                color="secondary"
                onClick={toggle}
              >
                Chiudi
              </Button>
              {ordine &&
                !ordine.data_invio_mail &&
                ordine.stato_invio_mail === "in_attesa_invio" && (
                  <Button
                    type="submit"
                    onClick={() => {
                      setFieldValue("stato_invio_mail", "")
                    }}
                    color="danger"
                  >
                    Resetta programmazione
                  </Button>
                )}
              {ordine && !ordine.data_invio_mail && (
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  color="success"
                >
                  Salva
                </Button>
              )}
            </ModalFooter>
          </>
        )}
      />
    </Modal>
  )
}

export default ModalUpdateStatoInvioMail
