import React, { Component } from 'react'
import { Button } from 'reactstrap'
import * as Sentry from '@sentry/browser'
import { USER } from './django'

class QrBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { eventId: null }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo)
        const eventId = Sentry.captureException(error)
        this.setState({ eventId })
      })
    }
  }

  render() {
    if (this.state.hasError) {
      //render fallback UI
      return (
        <div className="text-center mt-5">
          <h2>
            <i className="fa fa-bug mr-2" aria-hidden="true"></i>
            Ops, qualcosa è andato storto.
          </h2>
          <h4 className="mt-3">
            <i>Stiamo lavorando per risolvere il problema.</i>
          </h4>
          {USER?.is_superuser && process.env.NODE_ENV === 'production' && (
            <Button
              onClick={() =>
                Sentry.showReportDialog({ eventId: this.state.eventId })
              }
              outline
              color="primary"
              className="mt-3"
            >
              Riporta il Problema
            </Button>
          )}
        </div>
      )
    }

    //when there's not an error, render children untouched
    return this.props.children
  }
}

export default QrBoundary
