import React, { useState } from 'react'
import { useRunRj } from 'react-rocketjump'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { ConfermaOrdineState } from '../localstate/confermaOrdine'
import ModalFormMessaggio from './ModalFormMessaggio'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import ConfermaOrdinePageForm from './ConfermaOrdinePageForm'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getConfermaStato } from './utils'

function MessaggioCard({ messaggio }) {
  return (
    <div className="card mt-1">
      <div className="card-header">{messaggio.data}</div>
      <div className="card-body">
        <div>
          <b style={{ textTransform: 'capitalize' }}>
            {messaggio.tipo_mittente}
          </b>
        </div>
        <p>{messaggio.note}</p>
        {messaggio.allegato && (
          <Button
            outline
            size="sm"
            color="success"
            tag={'a'}
            href={messaggio.allegato}
          >
            <i className="fa fa-download" aria-hidden="true"></i> Allegato
          </Button>
        )}
      </div>
    </div>
  )
}

export default function ConfermaOrdineDetail({ match }) {
  const { id } = match.params
  const [
    { data: confermaOrdine },
    { addMessaggio, updateConferma, inviaConferma },
  ] = useRunRj(ConfermaOrdineState, [id])
  const [modalAdd, modalAddActions] = useModalTrigger()
  const [modalInvio, modalInvioActions] = useModalTrigger()
  const [editMode, setEditMode] = useState(false)
  const toggleEditMode = () => setEditMode(!editMode)
  const stato = confermaOrdine ? getConfermaStato(confermaOrdine) : null

  return (
    <div className="container-fluid mt-2">
      {confermaOrdine && (
        <div>
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <div>
                {confermaOrdine.nome_cliente} ({confermaOrdine.numero_commessa})
                {stato === 'inviato' && (
                  <span className="badge badge-success ml-2">
                    INVIATO{' '}
                    {moment(confermaOrdine.data_invio).format('DD-MM-YYYY')}
                  </span>
                )}
                {stato === 'in_attesa' && (
                  <span className="badge badge-warning ml-2">IN ATTESA</span>
                )}
                {stato === 'non_inviato' && (
                  <span className="badge badge-secondary ml-2">
                    NON INVIATO
                  </span>
                )}
              </div>
              <div>
                <Button
                  tag={Link}
                  outline
                  size="sm"
                  color="primary"
                  to="/conferme-ordini"
                >
                  <i className="fa fa-list" aria-hidden="true"></i> Lista
                  Conferme
                </Button>
                <Button
                  className="ml-2"
                  size="sm"
                  color="success"
                  outline
                  disabled={stato !== 'in_attesa'}
                  onClick={() => modalInvioActions.open()}
                >
                  <i className="fa fa-paper-plane " aria-hidden="true"></i>{' '}
                  Invia Conferma
                </Button>
                <Button
                  className="ml-2"
                  size="sm"
                  color="primary"
                  outline
                  disabled={stato === 'inviato'}
                  onClick={() => modalAddActions.open()}
                >
                  <i className="fa fa-envelope" aria-hidden="true"></i> Nuovo
                  Messaggio
                </Button>
                <Button
                  disabled={stato === 'inviato'}
                  onClick={toggleEditMode}
                  className="ml-2"
                  size="sm"
                  color="primary"
                  outline
                >
                  {!editMode && (
                    <>
                      <i className="fa fa-edit" aria-hidden="true"></i> Modifica
                    </>
                  )}
                  {editMode && (
                    <>
                      <i className="fa fa-eye" aria-hidden="true"></i>{' '}
                      Visualizza
                    </>
                  )}
                </Button>
              </div>
            </div>
            <div className="card-body">
              <ConfermaOrdinePageForm
                save={(conferma) =>
                  updateConferma.onSuccess(toggleEditMode).asPromise(conferma)
                }
                readOnly={!editMode}
                confermaOrdine={confermaOrdine}
              />
            </div>
          </div>
          <hr />
          {confermaOrdine.messaggi.length === 0 && (
            <div className="text-center">
              <i>Nessun messaggio da mostrare</i>
            </div>
          )}
          {confermaOrdine.messaggi.map((messaggio) => (
            <MessaggioCard key={messaggio.id} messaggio={messaggio} />
          ))}
        </div>
      )}
      <ModalFormMessaggio
        save={(messaggio) =>
          addMessaggio.onSuccess(modalAddActions.close).asPromise({
            conferma: confermaOrdine.id,
            ...messaggio,
          })
        }
        isOpen={modalAdd.isOpen}
        toggle={modalAddActions.toggle}
        onClosed={modalAddActions.onClosed}
      />
      <Modal isOpen={modalInvio.isOpen} toggle={modalInvioActions.toggle}>
        <ModalHeader toggle={modalInvioActions.toggle}>
          Invia Conferma
        </ModalHeader>
        <ModalBody>{confermaOrdine && <>Inviare conferma?</>}</ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button onClick={modalInvioActions.toggle}>Annulla</Button>
          <Button
            onClick={() =>
              inviaConferma
                .onSuccess(modalInvioActions.close)
                .run(confermaOrdine.id)
            }
            color="success"
          >
            Invia
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
