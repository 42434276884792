import React, { useState, useCallback } from 'react'
import { Field, connect } from 'formik'
import classNames from 'classnames'
import { Button } from 'reactstrap'
import { InputField } from '../../Fields'
import ModalChooseTipoSerramento from './ModalChooseTipoSerramento'
import ModalChooseTipoApertura from './ModalChooseTipoApertura'
import ModalAccessori from './ModalAccessori'

function FormSerramento({ tipoSerramento, serramento, index, onRemove, onUpdateSerramento, isValidSerramento }) {

  const [showAccessoriModal, setShowAccessoriModal] = useState(false)
  const toggleAccessoriModal = useCallback(() => {
    setShowAccessoriModal(show => !show)
  }, [])

  const [showModalTipoSerramento, setshowModalTipoSerramento] = useState(false)
  const toggleShowModalTipoSerramento = useCallback(() => {
    setshowModalTipoSerramento(show => !show)
  }, [])

  const [showModalTipoApertura, setshowModalTipoApertura] = useState(false)
  const toggleShowModalTipoApertura= useCallback(() => {
    setshowModalTipoApertura(show => !show)
  }, [])

  let tipoApertura = ''
  if (tipoSerramento.apertureById[serramento.tipo_apertura]) {
    tipoApertura = tipoSerramento.apertureById[serramento.tipo_apertura].nome
  }

  return (
    <div>
      <div className='card mt-4 mb-3 pb-3'>
        <div className='card-body row position-relative'>
          <div className='col-12 col-md-2 text-center pt-3 mb-2'>
            <img style={{maxWidth:100}} src={tipoSerramento.immagine} alt={tipoSerramento.nome}  />
          </div>
          <div className='col-12 col-md-8 d-flex flex-column'>
            <div className='d-flex'>
              <h5 className='pt-1 d-none d-md-block'>{tipoSerramento.nome}</h5>
              <Button type='button' size='sm' className='d-none d-md-block ml-4' color='secondary' onClick={toggleShowModalTipoSerramento}>Modifica</Button>
            </div>
            <div className='mb-1 text-center'>
              <h6 className='pt-1 d-block d-md-none text-center'>{tipoSerramento.nome}</h6>
              <Button type='button' size='sm' className='d-md-none text-center' color='secondary' onClick={toggleShowModalTipoSerramento}>Modifica</Button>
            </div>
            <div className='row'>
              <div className='col-12 col-md-2 mt-2'>
                <Field
                  label={'Quantità'}
                  component={InputField}
                  type='number'
                  name={`serramenti.${index}.quantita`}
                />
              </div>
              <div className='col-12 col-md-4 form-group mt-2'>
                <label>Tipi di apertura</label>
                <input
                  onClick={() => setshowModalTipoApertura(true)}
                  className='pl-2 form-control'
                  name={`serramenti.${index}.tipo_apertura`}
                  value={tipoApertura}
                  readOnly
                />
              </div>
              <div className='col-6 col-md-3 mt-2'>
                <Field
                  label={'Altezza'}
                  component={InputField}
                  name={`serramenti.${index}.altezza`}
                  type='number'
                  addonText={'mm'}
                />
              </div>
              <div className='col-6 col-md-3 mt-2'>
                <Field
                  label={'Larghezza'}
                  component={InputField}
                  name={`serramenti.${index}.larghezza`}
                  type='number'
                  addonText={'mm'}
                />
              </div>
            </div>
          </div>
          <div className='text-right'>
            <i onClick={() => onRemove(index)} style={{backgroundImage: 'radial-gradient(at center, white 40%, transparent 40%)', top: -15, right:0, zIndex:1000}} title={'Rimuovi serramento'} className="fas fa-2x fa-times-circle pointer position-absolute"></i>
          </div>
        </div>
        {serramento.accessori.length > 0 &&
          <div className='mt-1 mb-1 pl-3 text-md-left text-center'>Accessori: </div>
        }
        <div className='d-flex justify-content-center justify-content-md-start pl-0 pl-md-3'>
          {serramento.accessori.map(id => {
            const accessorio = tipoSerramento.accessoriById[id]
            return (
              <div key={id} className='badge badge-secondary mr-2'>
                {accessorio.nome}
              </div>
            )
          })}
        </div>
        {tipoSerramento.accessori.length > 0 &&
          <div className='pl-0 pl-md-3 text-md-left text-center'>
            <Button color='secondary' className='mt-4' onClick={toggleAccessoriModal}>Seleziona Accessori</Button>
          </div>
        }
        <div className={classNames('alert position-absolute d-none d-md-block',{
          'alert-success' : isValidSerramento,
          'alert-danger' : !isValidSerramento
        })} style={{height:'100%',right:0,top:0}}></div>
      </div>
      <ModalChooseTipoApertura
        isOpen={showModalTipoApertura}
        toggle={toggleShowModalTipoApertura}
        tipiApertura={tipoSerramento.aperture}
        onSelect={idTipoApertura => {
          onUpdateSerramento(index, {
            ...serramento,
            tipo_apertura: idTipoApertura
          })
          toggleShowModalTipoApertura()
        }}
      />
      <ModalChooseTipoSerramento
        onSelect={idTipoSerramento => {
          onUpdateSerramento(index, {
            ...serramento,
            tipo_serramento: idTipoSerramento,
            accessori: [],
          })
          toggleShowModalTipoSerramento()
        }}
        isOpen={showModalTipoSerramento}
        toggle={toggleShowModalTipoSerramento}
      />
      <ModalAccessori
        isOpen={showAccessoriModal}
        toggle={toggleAccessoriModal}
        index={index}
        accessori={tipoSerramento.accessori}
        serramento={serramento}
      />
    </div>
  )
}

export default connect(React.memo(FormSerramento))
