import { sumBy } from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'
import { Bar, BarChart, Tooltip, XAxis, YAxis } from 'recharts'

export default function BarChartAttivita({ groupedAttivitaByData }) {
  const data = useMemo(() => {
    return Object.keys(groupedAttivitaByData).map((data) => {
      const attivitaGiorno = groupedAttivitaByData[data].filter(
        (attivita) => attivita.ordine
      )
      const totaleTeorico = sumBy(attivitaGiorno, (attivita) => {
        return parseInt(moment.duration(attivita.tempo_teorico).as('minutes'))
      })
      const totaleEffettivo = sumBy(attivitaGiorno, (attivita) => {
        return parseInt(moment.duration(attivita.tempo).as('minutes'))
      })
      if (totaleEffettivo === 0)
        return {
          data: moment(data).format('DD-MM-YYYY'),
          performance: 0,
        }
      const percentPerformance = (totaleTeorico / totaleEffettivo) * 100
      // order by data

      return {
        data: moment(data).format('DD-MM-YYYY'),
        performance: percentPerformance.toFixed(0),
      }
    })
  }, [groupedAttivitaByData])

  const dataToUse = useMemo(() => {
    return data.sort((a, b) => {
      return moment(a.data, 'DD-MM-YYYY').diff(moment(b.data, 'DD-MM-YYYY'))
    })
  }, [data])

  return (
    <BarChart
      width={800}
      height={200}
      data={dataToUse}
      margin={{
        top: 0,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <XAxis dataKey="data" />
      <YAxis ticks={[0, 50, 100, 200, 300]} domain={[0, 400]} />
      <Tooltip />
      <Bar dataKey="performance" fill="#669AE9" />
    </BarChart>
  )
}
