import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ModalInviaMailAgente = ({ isOpen, toggle, preventivo, onSend, sending }) => {

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}><i className="fas fa-envelope"></i> Invia mail agente</ModalHeader>
      <ModalBody>
        <div className='container'>
          <div className='row'>
            Vuoi inviare l'e-mail all'agente: {preventivo.agente && preventivo.agente.user.first_name} {preventivo.agente && preventivo.agente.user.last_name}?
          </div>
        </div>
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between'>
        <Button color="secondary" disabled={sending} onClick={toggle}>Chiudi</Button>
        <Button onClick={() => onSend()} color="success" disabled={sending}>
          {sending ? 'Invio mail in corso ...' : 'Invia e-mail'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalInviaMailAgente
