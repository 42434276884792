import { rj } from "react-rocketjump"
import rjList, {
  nextPreviousPaginationAdapter,
} from "react-rocketjump/plugins/list"
import { map } from "rxjs/operators"
import rjDebounce from "react-rocketjump/plugins/debounce"
import qs from "query-string"
import { ajax } from "rxjs/ajax"
import { CSRF, SETTINGS } from "../../../django"
import magikApi from "magik-api"

const api = magikApi().trailingSlash(true).headers({
  "X-CSRFToken": CSRF,
})

export const AttivitaAdminState = rj(
  rjDebounce(),
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  {
    name: "AttivitaState",
    effect: (params = {}) =>
      ajax.getJSON(
        `/api/planner/lavorazioni-operatore-admin/?${qs.stringify(params)}`
      ),
    computed: {
      attivita: "getList",
      pagination: "getPagination",
      loading: "isLoading",
      error: "getError",
    },
  }
)

function removeControlloEffettuatoFromAttivita(attivitaList, id) {
  return attivitaList.map(attivita => {
    let changed = false
    const nextControlli = attivita.lavorazione_data.controlli.map(controllo => {
      if (controllo.controllo_effettuato.id === id) {
        changed = true
        return {
          ...controllo,
          controllo_effettuato: {
            ...controllo.controllo_effettuato,
            // TODO: This is bad ...
            id: null,
            note: null,
            operatore: null,
            operatore_data: null,
          }
        }
      }
      return controllo
    })
    if (changed) {
      return {
        ...attivita,
        lavorazione_data: {
          ...attivita.lavorazione_data,
          controlli: nextControlli,
        }
      }
    }
    return attivita
  })
}

export const AttivitaState = rj(
  rjDebounce(),
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  {
    name: "AttivitaState",
    effect: (params = {}) =>
      ajax.getJSON(
        `/api/planner/lavorazioni-operatore/?${qs.stringify(params)}`
      ),
    computed: {
      attivita: "getList",
      pagination: "getPagination",
      loading: "isLoading",
      error: "getError",
    },
    mutations: {
      deleteAttivita: rj.mutation.single({
        effect: (attivita) =>
          ajax({
            method: "delete",
            url: `/api/planner/lavorazioni-operatore/${attivita.id}`,
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": CSRF,
            },
          }).pipe(map(() => attivita)),
        updater: "deleteItem",
      }),
      addAttivita: {
        effect: (attivita) =>
          ajax({
            method: "post",
            url: `/api/planner/lavorazioni-operatore/`,
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": CSRF,
            },
            body: JSON.stringify(attivita),
          }).pipe(map((r) => r.response)),
        updater: "insertItem",
      },
      updateAttivita: {
        effect: (attivita) =>
          ajax({
            method: "put",
            url: `/api/planner/lavorazioni-operatore/${attivita.id}/`,
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": CSRF,
            },
            body: JSON.stringify(attivita),
          }).pipe(map((r) => r.response)),
        updater: "updateItem",
      },
      deleteControlloEffettuato: {
        optimisticUpdater: (state, id) => ({
          ...state,
          data: {
            ...state.data,
            list: removeControlloEffettuatoFromAttivita(state.data.list, id),
          }
        }),
        optimisticResult: id => id,
        effect: (controlloOrdineId) =>
          api.delete(`/api/planner/controlli-ordine/${controlloOrdineId}`),
      },
    },
  }
)


export const OrdiniAttivitaState = rj()
  .plugins(
    rjList({
      pageSize: SETTINGS.PAGE_SIZE,
      pagination: nextPreviousPaginationAdapter,
    })
  )
  .computed({
    lavorazioni: "getList",
  })
  .effect({
    name: "LavorazioniState",
    effect: (params = {}) => api.get("/api/planner/ordini/lista-attivita/", params),
  })

  export const AttivitaMonthState = rj()
  .computed({
    dates: "getData",
  })
  .effect({
    name: "AttivitaMonthState",
    effect: (data) => api.get(`/api/planner/lavorazioni-operatore/date_attivita_mese/?data=${data}`),
  })
