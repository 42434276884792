import React, { useState, useContext } from 'react'
import classNames from 'classnames'
import { FieldArray, connect } from 'formik'
import { Button } from 'reactstrap'
import ModalChooseTipoSerramento from './ModalChooseTipoSerramento'
import { useIsDesktop, useScrollOnTop } from '../../../../helpers'
import FormSerramento from './FormSerramento'
import FormInfoClienteMobile from './FormInfoCliente/FormInfoClienteMobile'
import FormInfoClienteDesktop from './FormInfoCliente/FormInfoClienteDesktop'
import FormTipoIntervento from './FormTipoIntervento'
import { ConfigPrevetiviContext } from '../../../../context'
import ModalConfirmRemoveSerramento from './ModalConfirmRemoveSerramento'

const FormListSerramenti = ({ form, push, replace, remove }) => {
  const { serramenti } = form.values

  const [askAddSerramento, setAskAddSerramento] = useState(false)
  const [askRemoveIndex, setAskRemoveIndex] = useState(null)

  const { tipiSerramentoById } = useContext(ConfigPrevetiviContext)

  return (
    <div>
      <ModalConfirmRemoveSerramento
        isOpen={askRemoveIndex !== null}
        onRemove={() => {
          remove(askRemoveIndex)
          setAskRemoveIndex(null)
        }}
        toggle={() => setAskRemoveIndex(null)}
      />
      <ModalChooseTipoSerramento
        isOpen={askAddSerramento}
        toggle={() => setAskAddSerramento(false)}
        onSelect={(idTipoSerramento) => {
          push({
            tipo_serramento: idTipoSerramento,
            tipo_apertura: '',
            accessori: [],
            quantita: '',
            altezza: '',
            larghezza: '',
          })
          setAskAddSerramento(false)
        }}
      />
      {serramenti.length > 0 &&
      <h4 className='text-primary'>Serramenti</h4>
      }
      {serramenti.map((serramento, index) => {
        const tipoSerramento = tipiSerramentoById[serramento.tipo_serramento]
        return (
          <FormSerramento
            key={index}
            serramento={serramento}
            tipoSerramento={tipoSerramento}
            index={index}
            onUpdateSerramento={replace}
            onRemove={setAskRemoveIndex}
            isValidSerramento={form.errors.serramenti && form.errors.serramenti[index] ? false : true}
          />
        )
      })}
      <div className='text-center text-md-left'>
        <Button color='info' onClick={() => setAskAddSerramento(true)}>
          Aggiungi serramento <i className="fas fa-plus-circle"></i>
        </Button>
      </div>
    </div>
  )
}

function Step2({ setStep }) {

  useScrollOnTop()
  const isDesktop = useIsDesktop()

  return (
    <div className={classNames('Step2 mt-md-3',{
      'p-mobile-bottom' : !isDesktop
    })}>
      <div className='container'>
        {isDesktop ?
          <FormInfoClienteDesktop />
        :
          <FormInfoClienteMobile />
        }
        <FormTipoIntervento />
        <FieldArray
          name='serramenti'
          component={FormListSerramenti}
        />
        <div className='mt-4 d-flex justify-content-between'>
          <Button className='d-none d-md-block' type='button' onClick={() => setStep(1)} color='secondary'>Step 1 <i className="fas fa-chevron-circle-left"></i><br />Scelta serramento</Button>
          <Button className='d-none d-md-block' type='button' onClick={() => setStep(3)} color='info'>Step 3 <i className="fas fa-chevron-circle-right"></i><br />Riferimenti</Button>
        </div>
        <div className='mt-4 text-center'>
          <Button className='d-md-none' size='md' type='button' onClick={() => setStep(3)} color='info'>Completa preventivo <i className="fas fa-chevron-circle-right"></i></Button>
        </div>
      </div>
    </div>
  )
}

export default connect(Step2)
