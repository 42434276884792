import React, { useContext, useMemo } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { InputField, CheckboxField, FileField } from '../../Form/Fields'
import {
  transformErrorForForm,
  setNullOnEmptyValues,
  fixInitialValuesNull,
} from '../../Form/Fields/utils'
import { ConfigPlannerContext } from '../../../context'

const FornitoreSchema = Yup.object().shape({
  nome: Yup.string().required(),
  tipologia: Yup.string().required(),
  tempo: Yup.number().required(),
  email: Yup.string().email().nullable(),
})

const ModalFormFornitore = ({ isOpen, toggle, save, fornitore }) => {
  const { materiali } = useContext(ConfigPlannerContext)

  const initialValues = useMemo(() => {
    if (fornitore) {
      return fixInitialValuesNull({
        ...fornitore,
        remove_immagine: false,
      })
    } else {
      return {
        nome: '',
        tipologia: '',
        email: '',
        codice: '',
        immagine: '',
        tempo: '',
        is_default: false,
        testata: '',
        testo_pdf: '',
      }
    }
  }, [fornitore])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" scrollable>
      <ModalHeader toggle={toggle}>
        {fornitore ? `Modifica ${fornitore.nome}` : 'Aggiungi fornitore'}
      </ModalHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={FornitoreSchema}
        onSubmit={(values, actions) => {
          return save(setNullOnEmptyValues(values)).then(
            () => {
              actions.setSubmitting(false)
            },
            (error) => {
              actions.setSubmitting(false)
              actions.setErrors(transformErrorForForm(error))
            }
          )
        }}
        render={({ handleSubmit, values, isSubmitting, errors }) => (
          <>
            <ModalBody>
              <form onSubmit={handleSubmit}>
                <Field
                  label="Nome *"
                  type="text"
                  name="nome"
                  className="mb-2"
                  component={InputField}
                />
                <Field
                  label="Codice"
                  type="text"
                  name="codice"
                  className="mb-2"
                  component={InputField}
                />
                <Field
                  component={InputField}
                  label="Tipologia *"
                  name="tipologia"
                  type="select"
                  className="mb-2"
                >
                  <option value=""></option>
                  <option value="vetro">Vetro</option>
                  <option value="legno">Legno</option>
                  <option value="verniciatura">Verniciatura</option>
                  <option value="telai">Telai</option>
                  {materiali.map((materiale) => (
                    <option key={materiale} value={materiale}>
                      {materiale}
                    </option>
                  ))}
                </Field>
                <Field
                  label="Email"
                  type="email"
                  name="email"
                  className="mb-2"
                  component={InputField}
                />
                <Field
                  label="Tempo *"
                  type="number"
                  name="tempo"
                  className="mb-2"
                  component={InputField}
                />
                <Field
                  label="Testata"
                  type="textarea"
                  name="testata"
                  className="mb-2"
                  component={InputField}
                />
                <Field
                  label="Testo pdf"
                  type="textarea"
                  name="testo_pdf"
                  className="mb-2"
                  component={InputField}
                />
                <Field
                  label="Immagine"
                  name="immagine"
                  type="file"
                  component={FileField}
                />
                {fornitore && fornitore.id && fornitore.immagine && (
                  <Field
                    component={CheckboxField}
                    label="Vuoi eliminare l'immagine?"
                    name="remove_immagine"
                    className="mb-2"
                  />
                )}

                {fornitore && fornitore.immagine && (
                  <div className="mt-1 mb-1">
                    <img
                      src={fornitore.immagine}
                      width="100"
                      alt={fornitore.id}
                    />
                  </div>
                )}
                <Field
                  component={CheckboxField}
                  label="Fornitore di default?"
                  name="is_default"
                  className="mb-2"
                />
                <input type="submit" className="d-none" />
              </form>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
              <Button
                disabled={isSubmitting}
                color="secondary"
                onClick={toggle}
              >
                Chiudi
              </Button>
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
                color="success"
              >
                Salva
              </Button>
            </ModalFooter>
          </>
        )}
      />
    </Modal>
  )
}

export default ModalFormFornitore
