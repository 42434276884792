import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { FileField } from '../Form/Fields'
import { transformErrorForForm } from '../Form/Fields/utils'

const AllegatoOrdineSchema = Yup.object().shape({
  allegato: Yup.mixed().required(),
})

const emptyAllegatoOrdine = {
  allegato: '',
}

const ModalFormFornitore = ({ isOpen, toggle, save, fornitore }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}>
        Allegato Ordine
      </ModalHeader>
        <Formik
          initialValues={emptyAllegatoOrdine}
          validationSchema={AllegatoOrdineSchema}
          onSubmit={(values, actions) => {
            return save((values)).then(
              () => {
                actions.setSubmitting(false)
              },
              error => {
                actions.setSubmitting(false)
                actions.setErrors(transformErrorForForm(error))
              }
            )
          }}
          render={({ handleSubmit, values, isSubmitting, errors }) => (
            <form onSubmit={handleSubmit} className='p-0 m-0'>
              <ModalBody>
                <Field
                  label='Allegato *'
                  name='allegato'
                  type='file'
                  component={FileField}
                />
              </ModalBody>
              <ModalFooter className='d-flex justify-content-between'>
                <Button color="secondary" disabled={isSubmitting} onClick={toggle}>Chiudi</Button>
                <Button type='submit' disabled={isSubmitting} color="success">Aggiungi</Button>
              </ModalFooter>
            </form>
          )}
        />
    </Modal>
  )
}

export default ModalFormFornitore
