import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ModalDeleteConfirm = ({ isOpen, toggle, onDelete, deleting, title }) => {
  return(
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}>Elimina {title}</ModalHeader>
      <ModalBody>
        Sei sicuro di voler eliminare {title}?
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between'>
        <Button color="secondary" disabled={deleting} onClick={toggle}>Annulla</Button>
        <Button color="danger" disabled={deleting} onClick={onDelete}>Elimina</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalDeleteConfirm
