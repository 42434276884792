import React from 'react'

const DetailDatiPosa = ({ preventivo }) => {
  return(
    <div className='card p-2'>
      <h5 className='font-weight-bold text-center text-primary'>Indirizzo di posa</h5>
      <div className='border-bottom pt-1 pb-1'>
        Messa in posa:
        {preventivo.messa_in_posa ?
          <i className="ml-2 fas fa-check-circle text-success"></i>
          :
          <i className="ml-2 fas fa-times-circle text-danger"></i>
        }
      </div>
      <div className='border-bottom pt-1 pb-1'>
        Regione di posa: <strong>{preventivo.regione_posa}</strong>
      </div>
      <div className='border-bottom pt-1 pb-1'>
        Indirizzo: <strong>{preventivo.indirizzo_posa}</strong>
      </div>
      <div className='border-bottom pt-1 pb-1'>
        Provincia: <strong>{preventivo.provincia_posa}</strong>
      </div>
      <div className='border-bottom pt-1 pb-1'>
        Città: <strong>{preventivo.citta_posa}</strong>
      </div>
      <div className='border-bottom pt-1 pb-1'>
        Piano di posa: <strong>{preventivo.piano_posa}</strong>
      </div>
      <div className='pt-1 pb-1'>
        Note: {preventivo.note}
      </div>
    </div>
  )
}

export default DetailDatiPosa
