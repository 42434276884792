import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { FieldArray } from 'formik'

const ModalAccessori = ({ isOpen, toggle, index, accessori, serramento }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Selezionare gli eventuali accessori</ModalHeader>
      <ModalBody>
        <FieldArray
          name={`serramenti.${index}.accessori`}
          render={arrayHelpers => (
            <div className='mb-1'>
              {accessori.map(accessorio => (
                <div key={accessorio.id} className='d-flex mr-3'>
                  <input
                    type='checkbox'
                    className='mt-2 mr-1'
                    id={accessorio.id}
                    checked={serramento.accessori.indexOf(accessorio.id) !== -1}
                    onChange={() => {
                      const index = serramento.accessori.indexOf(accessorio.id)
                      if (index === -1) {
                        arrayHelpers.push(accessorio.id)
                      } else {
                        arrayHelpers.remove(index)
                      }
                    }}
                  />
                  <label htmlFor={accessorio.id} className='mt-1 pointer'>
                    {accessorio.nome}
                  </label>
                </div>
              ))}
            </div>
          )}
        />
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={toggle}>Chiudi</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalAccessori
