import React, { useState, useMemo, useEffect } from 'react'
import { useRunRj, useRj } from 'react-rocketjump'
import moment from 'moment'
// 0.20
import { default as BaseCalendar } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { PersonaleState, AssenzePersoneState, FestivitaState } from './localstate'
import { Button } from 'reactstrap'
import omit from 'lodash/omit'
import { DATE_FORMAT, formatDateAsDay, makeRangeStartEnd } from './dateUtils'
import { DateTimeRangeModalForm } from '../../components/Planner/DateTimeRangeForm'
import { schemeSet3 } from 'd3-scale-chromatic'
import { scaleOrdinal } from 'd3-scale'

const Calendar = withDragAndDrop(BaseCalendar)

const colorScale = scaleOrdinal(schemeSet3)

const localizer = BaseCalendar.momentLocalizer(moment)

const FESTIVITA_KEY = '1312-FEST'

// Start with very large range
const INITIAL_DATE_RANGE = {
  from_date: moment().subtract(1, 'month').startOf('month').format(DATE_FORMAT),
  to_date: moment().add(1, 'month').endOf('month').format(DATE_FORMAT),
}

function ResourceSelector({
  label,
  subLabel,
  color,
  className,
  showed, edit,
  onToggleShow, onToggleEdit
}) {
  return (
    <div className='rounded text-darkgrey p-2 mb-1 border d-flex justify-content-between align-items-center' style={{ background: color }}>
      <div>
        <div><b>{label}</b></div>
        {subLabel && <div className='text-capitalize'><small>{subLabel}</small></div>}
      </div>
      <div>
        <Button
          size='sm'
          color={showed ? 'primary' : 'light'}
          onClick={() => onToggleShow()}
          >
            {showed
              ? <i className="fas fa-eye-slash"></i>
              : <i className="fas fa-eye"></i>
            }
          </Button>
          <Button
            className='ml-2'
            size='sm'
            color={edit ? 'danger' : 'light'}
            onClick={() => onToggleEdit()}
            >
              <i className="fas fa-pencil-alt"></i>
          </Button>
      </div>
    </div>
  )
}

export default function CalendarAzienda() {
  const [view, setView] = useState('month')
  const [selectedPersonale, setSelectedPersonale] = useState({})
  const [selectedEditPersonale, setSelectedEditPersonale] = useState(FESTIVITA_KEY)
  const [selectedAssenza, setSelectedAssenza] = useState(null)

  const toggleSelectedPersonale = (idPersona) => {
    let personale
    if(selectedPersonale[idPersona] !== undefined){
      personale = omit(selectedPersonale, [idPersona])
    } else {
      personale = {
        ...selectedPersonale,
        [idPersona]: true,
      }
    }
    setSelectedPersonale(personale)
  }

  const [{ persone }, { run: fetchPersonale }] = useRj(PersonaleState)
  // Set all personale viewed by default
  useEffect(() => {
    fetchPersonale
      .onSuccess((response) => {
        setSelectedPersonale(response.results.reduce((all, persona) => ({
          ...all,
          [persona.id]: true
        }), { [FESTIVITA_KEY]: true }))
      })
      .run()
  }, [fetchPersonale])

  const colorMap = useMemo(() => {
    if (persone === null) {
      return null
    }
    const ids = persone.map(p => p.id).sort()
    return ids.reduce((colorMap, id, index) => ({
      ...colorMap,
      [id]: colorScale(index + 1),
    }), { [FESTIVITA_KEY]: colorScale(0) })
  }, [persone])

  const [
    { list: assenze, deleting: deletingAsseza },
    {
      run: fetchAssenze, addAssenza, updateAssenza, deleteAssenza,
      updateItem: updateAssenzaItem,
    }
  ] = useRunRj(AssenzePersoneState, [INITIAL_DATE_RANGE])

  const [
    { festivita, deleting: deletingFestivita },
    {
      run: fetchFestivita, deleteFestivita, updateFestivita, addFestivita,
      updateItem: updateFestaItem,
    }
  ] = useRunRj(FestivitaState, [INITIAL_DATE_RANGE])

  function optimisticAssenzaUpdate(toUpdateAssenza, oldAssenza) {
    updateAssenzaItem(toUpdateAssenza)
    updateAssenza
      .onFailure(() => updateAssenzaItem(oldAssenza))
      .run(toUpdateAssenza)
  }
  function optimisticFestaUpdate(toUpdateFesta, oldFesta) {
    updateFestaItem(toUpdateFesta)
    updateFestivita
      .onFailure(() => updateFestaItem(oldFesta))
      .run(toUpdateFesta)
  }

  const allEvents = useMemo(() => {
    if (assenze === null || colorMap === null || festivita === null) {
      return []
    }
    const events = assenze.reduce((all, assenza, key) => {
      if (!selectedPersonale[assenza.persona_data.id]) {
        return all
      }
      const event = {
        title: `Assente: ${assenza.persona_data.nome}`,
        color: colorMap[assenza.persona_data.id],
        ...assenza,
        ...makeRangeStartEnd(assenza, view),
        type: 'assenza',
      }
      all.push(event)
      return all
    }, [])

    if (!selectedPersonale[FESTIVITA_KEY]) {
      return events
    }

    return events.concat(festivita.map((festa, k) => {
      const event = {
        title: 'Festa',
        color: colorMap[FESTIVITA_KEY],
        ...festa,
        ...makeRangeStartEnd(festa, view),
        type: 'festa',
      }
      return event
    }))

  }, [assenze, colorMap, festivita, view, selectedPersonale])

  return (
    <div className='container-fluid'>
      <div className='row h-100 pt-3 pb-3'>
        <div className='col-md-9 border-right'>
          <Calendar
            localizer={localizer}
            selectable
            draggableAccessor={event => {
              if (event.type === 'festa') {
                return selectedEditPersonale === FESTIVITA_KEY
              }
              return event.persona_data.id === selectedEditPersonale
            }}
            onSelectEvent={event => {
              setSelectedAssenza(event)
            }}
            onSelectSlot={selection => {
              if (selection.action === 'click') {
                // avoid create shit on click
                return
              }
              const range = {
                giorno_da: formatDateAsDay(selection.start),
                giorno_a: formatDateAsDay(selection.end),
              }
              if (view === 'week') {
                range.ora_da = moment(selection.start).format('HH:mm')
                range.ora_a = moment(selection.end).format('HH:mm')
              }
              if (selectedEditPersonale === FESTIVITA_KEY) {
                addFestivita(range)
              } else {
                addAssenza({
                  ...range,
                  persona: selectedEditPersonale,
                })
              }
            }}
            onEventDrop={dropped => {
              const oldRecord = dropped.event
              const newRecord = {
                ...dropped.event,
                giorno_da: formatDateAsDay(dropped.start),
                giorno_a: formatDateAsDay(dropped.end),
              }
              // Handle hours in drop only in week view and when
              // dropping in then same day (no multiple days ranges)
              if (view === 'week' && moment(dropped.end).diff(moment(dropped.start), 'days') === 0) {
                newRecord.ora_da = moment(dropped.start).format('HH:mm')
                newRecord.ora_a = moment(dropped.end).format('HH:mm')
              }
              if (dropped.event.type === 'festa') {
                optimisticFestaUpdate(newRecord, oldRecord)
              } else {
                optimisticAssenzaUpdate(newRecord, oldRecord)
              }
            }}
            onEventResize={resized => {
              const oldRecord = resized.event

              const shouldHandleHours = (
                view === 'week' &&
                moment(resized.end).diff(moment(resized.start), 'days') === 0
              )

              let newRecord = {
                ...resized.event,
              }

              let end = moment(resized.end)
              // Workaround to end resized bugged =(
              if (!shouldHandleHours && end.format('HH:mm') === '00:00') {
                end = end.subtract(1, 'day')
              }

              newRecord = {
                ...resized.event,
                giorno_da: formatDateAsDay(resized.start),
                giorno_a: formatDateAsDay(end.toDate()),
              }
              if (shouldHandleHours) {
                newRecord.ora_da = moment(resized.start).format('HH:mm')
                newRecord.ora_a = moment(resized.end).format('HH:mm')
              }
              if (resized.event.type === 'festa') {
                optimisticFestaUpdate(newRecord, oldRecord)
              } else {
                optimisticAssenzaUpdate(newRecord, oldRecord)
              }
            }}
            view={view}
            onView={setView}
            // The following line disable the click on day cell
            // for now noop maybe in future open the week at day
            // withoute the calendary try to open day view and crash ...
            onDrillDown={() => {}}
            views={{
              month: true,
              week: true,
              // agenda: ListAssenze,
            }}
            eventPropGetter={(event, start, end, isSelected) => {
              return {
                style: {
                  color: 'var(--dark)',
                  background: event.color,
                }
              }
            }}
            onRangeChange={(range, view) => {
              let params
              if (Array.isArray(range)) {
                // List of days (week)
                params = {
                  from_date: formatDateAsDay(range[0]),
                  to_date: formatDateAsDay(range[range.length - 1]),
                }
              } else {
                params = {
                  from_date: formatDateAsDay(range.start),
                  to_date: formatDateAsDay(range.end)
                }
              }
              fetchAssenze(params)
              fetchFestivita(params)
            }}
            events={allEvents}
            style={{ height: 'fit-height' }}
          />
        </div>
        <div className='col-md-3'>
          {persone && (
            <ResourceSelector
              label={'Festività'}
              color={colorMap[FESTIVITA_KEY]}
              showed={!!selectedPersonale[FESTIVITA_KEY]}
              edit={selectedEditPersonale === FESTIVITA_KEY}
              onToggleShow={() => toggleSelectedPersonale(FESTIVITA_KEY)}
              onToggleEdit={() => selectedEditPersonale !== FESTIVITA_KEY
                ? setSelectedEditPersonale(FESTIVITA_KEY)
                : setSelectedEditPersonale(null)}
            />
          )}
          {persone && persone.map((persona, key) => (
            <ResourceSelector
              key={persona.id}
              label={persona.nome}
              subLabel={persona.reparto}
              color={colorMap[persona.id]}
              showed={!!selectedPersonale[persona.id]}
              edit={selectedEditPersonale === persona.id}
              onToggleShow={() => toggleSelectedPersonale(persona.id)}
              onToggleEdit={() => selectedEditPersonale !== persona.id
                ? setSelectedEditPersonale(persona.id)
                : setSelectedEditPersonale(null)}
            />
          ))}
        </div>
      </div>
      <DateTimeRangeModalForm
        title={
          selectedAssenza
            ? selectedAssenza.type === 'festa'
              ? 'Festività'
              : `Assenza - ${selectedAssenza.persona_data.nome}`
            : null
        }
        deleting={deletingFestivita || deletingAsseza}
        isOpen={selectedAssenza !== null}
        toggle={() => setSelectedAssenza(null)}
        dateTimeRange={selectedAssenza}
        onDelete={() => {
          if (selectedAssenza.type === 'festa') {
            deleteFestivita
              .onSuccess(() => setSelectedAssenza(null))
              .run(selectedAssenza)
          } else {
            deleteAssenza
              .onSuccess(() => setSelectedAssenza(null))
              .run(selectedAssenza)
          }
        }}
        save={(assenza) => {
          if (assenza.type === 'festa') {
            return updateFestivita
              .onSuccess(() => setSelectedAssenza(null))
              .asPromise(assenza)
          } else {
            return updateAssenza
              .onSuccess(() => setSelectedAssenza(null))
              .asPromise(assenza)
          }
        }}
      />
    </div>
  )
}
