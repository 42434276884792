import React, { useEffect, useRef, useState } from 'react'
import { FastField } from 'formik'
import { PERMISSIONS } from '../../../django'
import { isNumeric } from '../../../helpers'
import { InputField } from '../../Form/Fields'
import RowExtraFields from '../RowExtraFields'
import RigaOrdineImmagini from '../RigaOrdineImmagini'
import { Button } from 'reactstrap'
import ModalBom from '../ModalBom'

function FormOrderRow({
  riga,
  index,
  remove,
  setFieldValue,
  tipiSerramento,
  codiciSerramento,
  fetchOrder,
  order,
}) {
  const { netto, sconto_articolo: sconto } = riga
  const didMountRef = useRef(false)
  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true
      return
    }
    if (isNumeric(netto) && isNumeric(sconto)) {
      const listino = netto / (1 - +(sconto / 100))
      if (!isNaN(listino) && isFinite(listino)) {
        setFieldValue(`righe_ordine[${index}].listino`, +listino.toFixed(2))
      }
    }
  }, [netto, sconto, index, setFieldValue])

  const [shoModalBom, setshowModalBom] = useState(false)
  const toggleShowModalBom = () => setshowModalBom((show) => !show)

  return (
    <div className="card mt-2 position-relative">
      <div className="card-header font-weight-bold text-center">
        Riga ordine {index + 1}
      </div>
      <div className="row pl-3 pr-3 mt-2">
        <div className="col-md-2">
          <FastField
            component={InputField}
            label="Tipo serramento *"
            name={`righe_ordine.${index}.tipo_serramento_id`}
            className="mb-2"
            type="select"
          >
            <option value="">Seleziona un tipo</option>
            {tipiSerramento &&
              tipiSerramento.map((tipo) => (
                <option key={tipo.id} value={tipo.id}>
                  {tipo.name}
                </option>
              ))}
          </FastField>
        </div>
        <div className="col-md-2">
          <FastField
            component={InputField}
            label="Codice serramento *"
            name={`righe_ordine.${index}.codice_serramento_id`}
            className="mb-2"
            type="select"
          >
            <option value="">Seleziona un codice</option>
            {codiciSerramento &&
              codiciSerramento.map((codice) => (
                <option key={codice.id} value={codice.id}>
                  {codice.categoria} - {codice.forma} - {codice.codice}
                </option>
              ))}
          </FastField>
        </div>
        <div className="col-md-2">
          <FastField
            label={'Numero *'}
            component={InputField}
            type="number"
            name={`righe_ordine.${index}.numero`}
          />
        </div>
        <div className="col-md-2">
          <FastField
            label={'Listino'}
            component={InputField}
            type="number"
            readOnly
            name={`righe_ordine.${index}.listino`}
          />
        </div>
        <div className="col-md-2">
          <FastField
            label={'Sconto'}
            component={InputField}
            step="any"
            type="number"
            name={`righe_ordine.${index}.sconto_articolo`}
          />
        </div>
        <div className="col-md-2">
          <FastField
            label={'Netto'}
            component={InputField}
            step="any"
            type="number"
            name={`righe_ordine.${index}.netto`}
          />
        </div>
      </div>
      <div className="row pl-3 pr-3 pb-3">
        <div className="col-md-2">
          <FastField
            label={'Altezza'}
            component={InputField}
            type="number"
            name={`righe_ordine.${index}.altezza`}
          />
        </div>
        <div className="col-md-2">
          <FastField
            label={'Larghezza'}
            component={InputField}
            type="number"
            name={`righe_ordine.${index}.larghezza`}
          />
        </div>
        <div className="col-md-2">
          <FastField
            label={'Ante'}
            component={InputField}
            type="number"
            name={`righe_ordine.${index}.ante`}
          />
        </div>
        <div className="col-md-2">
          <FastField
            label={'Forma'}
            component={InputField}
            type="text"
            name={`righe_ordine.${index}.forma`}
          />
        </div>
      </div>
      <div className="row pl-3 pr-3 pb-3">
        <div className="col-md-4">
          <FastField
            label={'Descrizione'}
            component={InputField}
            type="textarea"
            rows={4}
            name={`righe_ordine.${index}.descrizione`}
          />
        </div>
      </div>
      <div className="position-absolute" style={{ bottom: 20, left: 20 }}>
        <Button
          color="primary"
          outline
          onClick={() => {
            toggleShowModalBom()
          }}
        >
          Visualizza BOM
        </Button>
      </div>
      <RigaOrdineImmagini riga={riga} fetchOrder={fetchOrder} order={order} />
      {riga.extra_fields && (
        <RowExtraFields
          extraFields={riga.extra_fields}
          canEdit={PERMISSIONS.editOrder}
          rigaId={riga.id}
          fetchOrder={fetchOrder}
          order={order}
        />
      )}

      {PERMISSIONS.editOrder && (
        <div className="text-right">
          <i
            onClick={() => remove(index)}
            style={{
              backgroundImage:
                'radial-gradient(at center, white 40%, transparent 40%)',
              top: 2,
              right: 5,
              zIndex: 10,
            }}
            title={'Rimuovi serramento'}
            className="fas fa-2x fa-times-circle pointer position-absolute"
          ></i>
        </div>
      )}
      {shoModalBom && (
        <ModalBom
          isOpen={shoModalBom}
          toggle={toggleShowModalBom}
          indexRow={index}
          numero={riga.numero}
          order={order}
        />
      )}
    </div>
  )
}

export default React.memo(FormOrderRow)
