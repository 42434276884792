import React, { useContext, useMemo, useState } from 'react'
import qs from 'query-string'
import { Link } from 'react-router-dom'
import { useRunRj } from 'react-rocketjump'
import { useQsDataTable } from 'react-use-datatable/react'
import Select from 'react-select'
import Paginator from '../../components/Paginator'
import { Button } from 'reactstrap'
import uniqBy from 'lodash/uniqBy'
import classnames from 'classnames'
import {
  Legend,
  Tooltip,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts'
import { RigheOrdiniState } from './localstate'
import { AgentiState } from '../AdminPreventiviApp/localstate'
import dayjs from 'dayjs'
import CircularProgress from '@material-ui/core/CircularProgress'
import { PERMISSIONS } from '../../django'
import { ConfigPlannerContext } from '../../context'
import DateRangeQsFilter from '../../components/Planner/DateRangeQsFilter'
import PieChartNetto from '../../components/Planner/Charts/PieChartNetto'
import OrdersSelectAutoComplete from '../../components/OrdersSelectAutoComplete'

const qpList = {
  encode: (a) => a,
  decode: (a) => (Array.isArray(a) ? a : [a]).filter(Boolean),
}

const formatXAxis = (tickItem) => {
  return dayjs(tickItem).format('DD-MM-YYYY')
}

const formatYAxis = (tickItem) => {
  return `${tickItem} €`
}

const EMPTY_LIST = []

export default function RigheOrdini({ history }) {
  const [
    { righe, loading, pagination, totals },
    { page, ...filters },
    { goToPage, onFiltersChange, onFilterChangeDebounced, onFilterChange },
  ] = useQsDataTable(RigheOrdiniState, () => ({
    ordini: {
      value: [],
      encdec: qpList,
    },
    search: '',
    tipo_serramento: {
      value: [],
      encdec: qpList,
    },
    forma: {
      value: [],
      encdec: qpList,
    },
    categoria: {
      value: [],
      encdec: qpList,
    },
    codice: {
      value: [],
      encdec: qpList,
    },
    from_data: '',
    to_data: '',
    tipo_cliente: '',
    agente: {
      value: [],
      encdec: qpList,
    },
  }))

  const { tipiSerramento, codiciSerramento } = useContext(ConfigPlannerContext)

  const [{ data: agenti }] = useRunRj(AgentiState)

  const formeSerramenti = useMemo(() => {
    const formeTemp = codiciSerramento && uniqBy(codiciSerramento, 'forma')
    return formeTemp.map((c) => ({
      value: c.forma,
      label: c.forma,
    }))
  }, [codiciSerramento])
  const selectedForme = useMemo(() => {
    return filters.forma.map((forma) => ({
      label: forma,
      value: forma,
    }))
  }, [filters.forma])

  const codici = useMemo(() => {
    const codiciTemp = codiciSerramento && uniqBy(codiciSerramento, 'codice')
    return codiciTemp.map((c) => ({
      value: c.codice,
      label: c.codice,
    }))
  }, [codiciSerramento])
  const selectedCodici = useMemo(() => {
    return filters.codice.map((codice) => ({
      value: codice,
      label: codice,
    }))
  }, [filters.codice])

  const categorie = useMemo(() => {
    const categorieTemp =
      codiciSerramento && uniqBy(codiciSerramento, 'categoria')
    return categorieTemp.map((c) => ({
      value: c.categoria,
      label: c.categoria,
    }))
  }, [codiciSerramento])
  const selectedCategorie = useMemo(() => {
    return filters.categoria.map((cat) => ({
      value: cat,
      label: cat,
    }))
  }, [filters.categoria])

  const link = useMemo(() => {
    return `/api/planner/righe-ordini/csv?${qs.stringify(filters)}`
  }, [filters])

  const agentiById = useMemo(() => {
    if (!agenti) {
      return {}
    }
    return agenti.reduce((acc, agente) => {
      acc[agente.id] = agente.user.first_name + ' ' + agente.user.last_name
      return acc
    }, {})
  }, [agenti])
  const agentiMapped = useMemo(() => {
    return Object.keys(agentiById).map((id) => ({
      value: id,
      label: agentiById[id],
    }))
  }, [agentiById])
  const selectedAgenti = useMemo(() => {
    return filters.agente.map((id) => ({
      value: id,
      label: agentiById[id],
    }))
  }, [agentiById, filters.agente])

  const tipiSerramentoById = useMemo(() => {
    if (!tipiSerramento) {
      return {}
    }
    return tipiSerramento.reduce((acc, tipo) => {
      acc[tipo.id] = tipo.name
      return acc
    }, {})
  }, [tipiSerramento])
  const tipiSerramentoOptions = useMemo(() => {
    return Object.keys(tipiSerramentoById).map((id) => ({
      value: id,
      label: tipiSerramentoById[id],
    }))
  }, [tipiSerramentoById])
  const selectedTipiSerramento = useMemo(() => {
    return filters.tipo_serramento.map((id) => ({
      value: id,
      label: tipiSerramentoById[id],
    }))
  }, [tipiSerramentoById, filters.tipo_serramento])

  const [showTableOrCharts, setShowTableOrChart] = useState('Table')

  const toggleTableOrChart = (view) => {
    if (showTableOrCharts === 'Table') {
      setShowTableOrChart('Charts')
    } else {
      setShowTableOrChart('Table')
    }
  }

  return (
    <div className="container-fluid mt-2">
      {righe === null && loading && (
        <div className="text-center mt-4">
          <CircularProgress color="primary" />
        </div>
      )}
      {righe && (
        <>
          <div className="d-flex mb-2 justify-content-between">
            <div className="d-flex ">
              <input
                style={{ width: 'auto' }}
                placeholder="Cerca"
                name="search"
                onChange={onFilterChangeDebounced}
                className="form-control"
                type="text"
              />
            </div>
            <div>
              <Button
                color="info"
                className="mr-2"
                onClick={toggleTableOrChart}
              >
                Visualizza{' '}
                {showTableOrCharts === 'Table' ? 'grafici' : 'tabella'}
              </Button>
              <Button
                tag="a"
                href={link}
                color="primary"
                disabled={righe && righe.length === 0}
              >
                Esporta Csv
              </Button>
            </div>
          </div>
          <div className="table-responsive">
            <table
              className={classnames('table table-bordered', {
                'table-hover': showTableOrCharts === 'Table',
              })}
            >
              <thead>
                <tr>
                  <td style={{
                    minWidth: '200px',
                  }}>
                    <OrdersSelectAutoComplete
                      value={filters.ordini}
                      hasValue
                      isMulti
                      debounceTimeout={150}
                      loadOptionsOnMenuOpen
                      onChange={(newValues) => {
                        if (newValues) {
                          const items = newValues.map((item) => item.value)
                          onFilterChange(items, 'ordini')
                        } else {
                          onFilterChange([], 'ordini')
                        }
                      }}
                      noOptionsMessage={() => 'Nessun ordine trovato'}
                      loadingMessage={() => 'Caricamento ordini...'}
                      placeholder={'Filtra per ordine'}
                      isClearable={true}
                      name={'ordine'}
                      additional={{
                        page: 1,
                      }}
                    />
                  </td>
                  <td></td>
                  <td>
                    <input
                      style={{ width: 'auto' }}
                      placeholder="Cerca"
                      name="tipo_cliente"
                      value={filters.tipo_cliente ?? ''}
                      onChange={onFilterChangeDebounced}
                      className="form-control"
                      type="text"
                    />
                  </td>
                  <td>
                    <DateRangeQsFilter
                      filters={filters}
                      onFiltersChange={onFiltersChange}
                      startField="from_data"
                      endField="to_data"
                      withPortal={true}
                      customArrowIcon={<div />}
                      small={false}
                      noBorder
                    />
                  </td>
                  <td className="text-nowrap">
                    <Select
                      name="agente"
                      isMulti
                      placeholder={'Tutti'}
                      options={agentiMapped}
                      value={selectedAgenti}
                      className="basic-multi-select select-multi-options text-nowrap"
                      onChange={(newValues) => {
                        if (newValues) {
                          const items = newValues.map((item) => item.value)
                          onFilterChange(items, 'agente')
                        } else {
                          onFilterChange([], 'agente')
                        }
                      }}
                    />
                  </td>
                  <td className="text-center">
                    {totals && (
                      <span className="badge badge-primary">
                        {' '}
                        {totals.num_serramenti}
                      </span>
                    )}
                  </td>
                  <td>
                    <Select
                      placeholder={'Tutti'}
                      name="categoria"
                      isMulti
                      options={categorie}
                      value={selectedCategorie}
                      className="basic-multi-select select-multi-options text-nowrap"
                      onChange={(newValues) => {
                        if (newValues) {
                          const items = newValues.map((item) => item.value)
                          onFilterChange(items, 'categoria')
                        } else {
                          onFilterChange([], 'categoria')
                        }
                      }}
                    />
                  </td>
                  <td>
                    <Select
                      name="forma"
                      isMulti
                      placeholder={'Tutti'}
                      options={formeSerramenti}
                      value={selectedForme}
                      className="basic-multi-select select-multi-options text-nowrap"
                      onChange={(newValues) => {
                        if (newValues) {
                          const items = newValues.map((item) => item.value)
                          onFilterChange(items, 'forma')
                        } else {
                          onFilterChange([], 'forma')
                        }
                      }}
                    />
                  </td>
                  <td>
                    <Select
                      name="codice"
                      isMulti
                      placeholder={'Tutti'}
                      options={codici}
                      value={selectedCodici}
                      className="basic-multi-select select-multi-options text-nowrap"
                      onChange={(newValues) => {
                        if (newValues) {
                          const items = newValues.map((item) => item.value)
                          onFilterChange(items, 'codice')
                        } else {
                          onFilterChange([], 'codice')
                        }
                      }}
                    />
                  </td>
                  <td>
                    <Select
                      name="tipo_serramento"
                      isMulti
                      placeholder={'Tutti'}
                      options={tipiSerramentoOptions}
                      value={selectedTipiSerramento}
                      className="basic-multi-select select-multi-options text-nowrap"
                      onChange={(newValues) => {
                        if (newValues) {
                          const items = newValues.map((item) => item.value)
                          onFilterChange(items, 'tipo_serramento')
                        } else {
                          onFilterChange([], 'tipo_serramento')
                        }
                      }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  {PERMISSIONS.viewPricesOnOrders && (
                    <>
                      <td className="text-center">
                        {totals && (
                          <span className="badge badge-primary">
                            {' '}
                            {totals.listino}
                          </span>
                        )}
                      </td>
                      <td className="text-center">
                        {totals && (
                          <span className="badge badge-primary">
                            {' '}
                            {totals.netto}
                          </span>
                        )}
                      </td>
                      <td className="text-center">
                        {totals && totals.sconto && (
                          <span className="badge badge-primary">
                            {' '}
                            {totals.sconto.toFixed(2)}
                          </span>
                        )}
                      </td>
                    </>
                  )}
                  {/* <td></td> */}
                </tr>
                <tr>
                  <td>Numero commessa</td>
                  <td>Cliente</td>
                  <td>Tipo cliente</td>
                  <td>Data</td>
                  <td>Agente</td>
                  <td>Numero</td>
                  <td>Categoria</td>
                  <td>Forma</td>
                  <td>Codice</td>
                  <td>Tipo</td>
                  <td>Larghezza</td>
                  <td>Altezza</td>
                  <td>Ante</td>
                  <td>Listino</td>
                  <td>Netto</td>
                  <td>Sconto</td>
                  {/* <td>Descrizione</td> */}
                </tr>
              </thead>
              {showTableOrCharts === 'Table' && (
                <tbody>
                  {righe &&
                    righe.map((riga) => (
                      <tr key={riga.id}>
                        <td>
                          <Link
                            to={`/ordini/${riga.ordine.id}${
                              PERMISSIONS.editOrder ? '/edit' : ''
                            }`}
                          >
                            {riga.ordine.numero_commessa}
                          </Link>
                        </td>
                        <td>
                          {riga.ordine.cliente ? riga.ordine.cliente.nome : ''}
                        </td>
                        <td>
                          {riga.ordine.cliente
                            ? riga.ordine.cliente.tipo_cliente
                            : ''}
                        </td>
                        <td className="text-nowrap">
                          {riga.ordine.data
                            ? dayjs(riga.ordine.data).format('DD-MM-YYYY')
                            : null}
                        </td>
                        <td>
                          {riga.ordine.agente &&
                            riga.ordine.agente.user.last_name}{' '}
                          {riga.ordine.agente &&
                            riga.ordine.agente.user.first_name}
                        </td>
                        <td>{riga.numero}</td>
                        <td>{riga.codice_serramento.categoria}</td>
                        <td>{riga.codice_serramento.forma}</td>
                        <td>{riga.codice_serramento.codice}</td>
                        <td>{riga.tipo_serramento.name}</td>
                        <td>{riga.larghezza}</td>
                        <td>{riga.altezza}</td>
                        <td>{riga.ante}</td>
                        <td>{riga.listino}</td>
                        <td>{riga.netto}</td>
                        <td>{riga.sconto_articolo}</td>
                        {/* <td style={{ width: 300 }}>{riga.descrizione}</td> */}
                      </tr>
                    ))}
                </tbody>
              )}
              {showTableOrCharts === 'Charts' && (
                <tbody>
                  <tr>
                    <td colSpan="16">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card">
                            <div className="card-header">
                              Netto per tipo serramento
                            </div>
                            <div className="card-body d-flex justify-content-center">
                              {totals && totals.tipo_serramento_stat && (
                                <PieChartNetto
                                  data={totals.tipo_serramento_stat}
                                  nameKey={'tipo_serramento__name'}
                                  dataKey={'netto'}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card">
                            <div className="card-header">
                              Netto per categoria
                            </div>
                            <div className="card-body d-flex justify-content-center">
                              {totals && totals.categoria_stat && (
                                <PieChartNetto
                                  data={totals.categoria_stat}
                                  nameKey={'codice_serramento__categoria'}
                                  dataKey={'netto'}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="16">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card">
                            <div className="card-header">Netto per forma</div>
                            <div className="card-body d-flex justify-content-center">
                              {totals && totals.forma_stat && (
                                <PieChartNetto
                                  data={totals.forma_stat}
                                  nameKey={'codice_serramento__forma'}
                                  dataKey={'netto'}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card">
                            <div className="card-header">Netto per codice</div>
                            <div className="card-body d-flex justify-content-center">
                              {totals && totals.codice_stat && (
                                <PieChartNetto
                                  data={totals.codice_stat}
                                  nameKey={'codice_serramento__codice'}
                                  dataKey={'netto'}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="16">
                      <div className="card">
                        <div className="card-header">Netto nel tempo</div>
                        <div className="card-body">
                          <ResponsiveContainer width={'100%'} height={500}>
                            <LineChart
                              data={totals.netto_serie || EMPTY_LIST}
                              margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis
                                tickFormatter={formatXAxis}
                                dataKey="ordine__data"
                              />
                              <YAxis tickFormatter={formatYAxis} />
                              <Tooltip
                                formatter={(value, name, props) => {
                                  return `${value.toFixed(2)} €`
                                }}
                              />
                              <Legend />
                              <Line
                                scale="linear"
                                domain={['dataMin', 'dataMax']}
                                type="monotone"
                                strokeWidth={3}
                                dataKey="netto"
                                stroke="#8884d8"
                                activeDot={{ r: 8 }}
                              />
                            </LineChart>
                          </ResponsiveContainer>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="16">
                      <div className="card">
                        <div className="card-header">
                          Numero serramenti nel tempo
                        </div>
                        <div className="card-body">
                          <ResponsiveContainer width={'100%'} height={500}>
                            <LineChart
                              data={totals.n_serramenti_serie || EMPTY_LIST}
                              margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis
                                tickFormatter={formatXAxis}
                                dataKey="ordine__data"
                              />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                              <Line
                                type="monotone"
                                dataKey="numero"
                                scale="linear"
                                domain={['dataMin', 'dataMax']}
                                strokeWidth={3}
                                stroke="#8884d8"
                                activeDot={{ r: 8 }}
                              />
                            </LineChart>
                          </ResponsiveContainer>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
            {showTableOrCharts === 'Table' && (
              <Paginator
                numPages={pagination.numPages}
                currentPage={page}
                goToPage={goToPage}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}
