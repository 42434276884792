import React, { createContext } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useRunRj } from 'react-rocketjump'
import { UserDetailState } from './localstate'
import UserDetail from './UserDetail'
import ChangePassword from './ChangePassword'

export const UserAdminedContext = createContext([{}, {}])

export default function UserRoot({ match }) {
  const {
    params: { id },
  } = match

  const userStateActions = useRunRj(UserDetailState, [id])

  return (
    <UserAdminedContext.Provider value={userStateActions}>
      <Switch>
        <Route path={`${match.path}`} exact component={UserDetail} />
        <Route
          path={`${match.path}/change-password`}
          exact
          component={ChangePassword}
        />
      </Switch>
    </UserAdminedContext.Provider>
  )
}
