import { rj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import { map } from 'rxjs/operators'
import rjDebounce from 'react-rocketjump/plugins/debounce'
import qs from 'query-string'
import { ajax } from 'rxjs/ajax'
import { CSRF, SETTINGS } from '../../../django'

export const MessaggiOrdiniEmailsState = rj(
  rjDebounce(),
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  {
    name: 'MessaggiOrdiniEmailsState',
    mutations: {
      deleteMessaggio: rj.mutation.single({
        effect: (messaggio) => ajax({
          method: 'delete',
          url: `/api/planner/messaggi-ordine-emails/${messaggio.id}`,
          headers: {
           'Content-Type': 'application/json',
           'X-CSRFToken': CSRF,
          },
       }).pipe(map(() => messaggio)),
       updater: 'deleteItem',
     }),
     addMessaggio: {
       effect: (messaggio) => ajax({
         method: 'post',
         url: `/api/planner/messaggi-ordine-emails/`,
         headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': CSRF,
         },
         body: JSON.stringify(messaggio),
       }).pipe(map(r => r.response)),
       updater: 'insertItem',
     },
     updateMessaggio: {
       effect: (messaggio) => ajax({
         method: 'put',
         url: `/api/planner/messaggi-ordine-emails/${messaggio.id}/`,
         headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': CSRF,
         },
         body: JSON.stringify(messaggio),
       }).pipe(map(r => r.response)),
       updater: 'updateItem',
     },
    },
    effect: (filters = {}) =>
      ajax.getJSON(`/api/planner/messaggi-ordine-emails/?${qs.stringify(filters)}`),
    computed: {
      messaggi: 'getList',
      pagination: 'getPagination',
      loading: 'isLoading',
      error: 'getError',
    },
  }
)

