import React, { useMemo } from 'react'
import { Button } from 'reactstrap'
import { Formik, Field } from 'formik'
import { CheckboxField } from '../Form/Fields'
import { transformErrorForForm } from '../Form/Fields/utils'

const InitialOperatorValues = {
  modifica_stato_ordine: false,
  modifica_stato_fasi_ordine: false,
  modifica_stato_fasi_ordine_fornitore: false,
  vede_ordini: false,
}

export default function OperatorForm({
  save,
  user,
  onDelete,
  setOperatorForm,
}) {
  const operatorValues = useMemo(() => {
    if (!user.operatore) {
      return InitialOperatorValues
    }
    return user.operatore
  }, [user])

  return (
    <Formik
      onSubmit={(values, actions) => {
        return save({
          ...values,
          user: user.id,
        }).then(
          (data) => {
            actions.setSubmitting(false)
            // Update created id
            if (!values.id) {
              actions.setFieldValue('id', data.id)
            }
          },
          (error) => {
            actions.setSubmitting(false)
            actions.setErrors(transformErrorForForm(error))
          }
        )
      }}
      initialValues={operatorValues}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="p-0 m-0">
          <Field
            component={CheckboxField}
            label="Modifica stato"
            name="modifica_stato_ordine"
          />
          <Field
            component={CheckboxField}
            label="Modifica stato fasi ordine"
            name="modifica_stato_fasi_ordine"
          />
          <Field
            component={CheckboxField}
            label="Modifica stato fasi ordine fornitore"
            name="modifica_stato_fasi_ordine_fornitore"
          />
          <Field
            component={CheckboxField}
            label="Vede tutti gli ordini (senza informazioni sui prezzi)"
            name="vede_ordini"
          />

          <div className="mt-4 d-flex justify-content-between">
            {user.operatore && (
              <Button
                disabled={isSubmitting}
                onClick={onDelete}
                color="danger"
              >
                <i className="fa fa-trash mr-2" aria-hidden="true"></i>
                Elimina
              </Button>
            )}
            {!user.operatore && (
              <Button
                onClick={() => setOperatorForm(false)}
                disabled={isSubmitting}
                className="ml-2"
              >
                Annulla
              </Button>
            )}
            <Button
              disabled={isSubmitting}
              className="ml-2"
              color="success"
              type="submit"
            >
              Salva
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}
