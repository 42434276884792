import React, { useState, useCallback } from 'react'
import {
  PersonaleState
} from './localstate'
import Paginator from '../../components/Paginator'
import { useQsDataTable as useDataTable } from 'react-use-datatable/react'
import TablePersonale from '../../components/Planner/TablePersonale'
import FiltersPersonale from '../../components/Planner/FiltersPersonale'
import ModalDeletePersona from '../../components/Planner/ModalDeletePersona'
import ModalFormPersona from '../../components/Planner/ModalFormPersona'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button } from 'reactstrap'

export default function PersonaleList() {

  const [
    // Data State
    { persone, pagination, loading, deleting },
    // Table state
    { page, ...filters },
    {
      // DT Actions
      onFilterChange, onFiltersChange, onFilterChangeDebounced, goToPage,
      deletePersona, addPersona, updatePersona
    }
  ] = useDataTable(PersonaleState, () => ({
    startDate: {
      value: null,
      field: 'data_inizio_da',
    },
    endDate: {
      // onChangeReset: ['search'],
      value: null,
      field: 'data_fine_da',
    },
    search: '',
  }))

  const [selectedPersona, setPersona] = useState(null)

  const [showModalDeletePersona, setShowModalDeletePersona] = useState(false)
  const toggleShowModalDelete = useCallback(() => {
    setShowModalDeletePersona(show => !show)
  }, [])

  const [showModalFormPersona, setShowModalFormPersona] = useState(false)
  const toggleShowModalPersona = useCallback(() => {
    setPersona(null)
    setShowModalFormPersona(show => !show)
  }, [])

  return (
    <div className='container-fluid'>
      {persone === null && loading &&
        <div className='text-center mt-4'>
          <CircularProgress color="primary" />
        </div>
      }
      {persone &&
      <>
        <div className='d-flex justify-content-between'>
          <FiltersPersonale
            filters={filters}
            onFilterChange={onFilterChange}
            onFiltersChange={onFiltersChange}
            onFilterChangeDebounced={onFilterChangeDebounced}
          />
          <Button onClick={() => toggleShowModalPersona()} size='sm' className='mt-2' outline color='secondary'>
            <i className="fas fa-plus-circle"></i>{' '}Aggiungi personale
          </Button>
        </div>
        <TablePersonale
          persone={persone}
          loading={loading}
          setPersona={setPersona}
          setShowModalDeletePersona={setShowModalDeletePersona}
          setShowModalFormPersona={setShowModalFormPersona}
        />
        <Paginator
          numPages={pagination.numPages}
          currentPage={page}
          goToPage={goToPage}
        />
        <ModalDeletePersona
          toggle={toggleShowModalDelete}
          isOpen={showModalDeletePersona}
          persona={selectedPersona}
          deleting={deleting}
          onSetEliminato={() => {
            deletePersona
              .onSuccess(() => {
                toggleShowModalDelete()
              }).run(selectedPersona)
          }}
        />
        <ModalFormPersona
          isOpen={showModalFormPersona}
          toggle={toggleShowModalPersona}
          persona={selectedPersona}
          save={(persona) => {
            if(selectedPersona){
              return updatePersona
                .onSuccess(
                  setPersona(null),
                  toggleShowModalPersona()
                )
                .asPromise(persona)
            }
            else {
              return addPersona.onSuccess(toggleShowModalPersona()).asPromise(persona)
            }

          }}
        />
      </>
      }
    </div>
  )
}
