import { Switch, Route } from 'react-router-dom'
import React from 'react'
import StatsList from './StatsList'
import StatsDetail from './StatsDetail'
import { useRunRj } from 'react-rocketjump'
import { StatsAvailableFieldsState } from './localstate'
import { AvailbaleStatsFieldsContext } from './context'

export default function Stats({ match }) {
  const [{ data: availableFields }] = useRunRj(StatsAvailableFieldsState)

  if (!availableFields) {
    // TODO: Maybe spinny ..
    return null
  }

  return (
    <AvailbaleStatsFieldsContext.Provider value={availableFields}>
      <Switch>
        <Route path={`${match.path}`} exact component={StatsList} />
        <Route path={`${match.path}/:id`} exact component={StatsDetail} />
      </Switch>
    </AvailbaleStatsFieldsContext.Provider>
  )
}
