import { rj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import { map } from 'rxjs/operators'
import rjDebounce from 'react-rocketjump/plugins/debounce'
import qs from 'query-string'
import { ajax } from 'rxjs/ajax'
import { CSRF, SETTINGS } from '../../../django'
import { rjListWithTotals } from './common'

export const ClientiState = rj(
  rjDebounce(),
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  {
    name: 'ClientiState',
    mutations: {
      deleteCliente: rj.mutation.single({
        effect: (cliente) =>
          ajax({
            method: 'delete',
            url: `/api/planner/clienti/${cliente.id}`,
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CSRF,
            },
          }).pipe(map(() => cliente)),
        updater: 'deleteItem',
      }),
      addCliente: {
        effect: (cliente) =>
          ajax({
            method: 'post',
            url: `/api/planner/clienti/`,
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CSRF,
            },
            body: JSON.stringify(cliente),
          }).pipe(map((r) => r.response)),
        updater: 'insertItem',
      },
      updateCliente: {
        effect: (cliente) =>
          ajax({
            method: 'put',
            url: `/api/planner/clienti/${cliente.id}/`,
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CSRF,
            },
            body: JSON.stringify(cliente),
          }).pipe(map((r) => r.response)),
        updater: 'updateItem',
      },
    },
    effect: (filters = {}) =>
      ajax.getJSON(`/api/planner/clienti/?${qs.stringify(filters)}`),
    computed: {
      clienti: 'getList',
      pagination: 'getPagination',
      loading: 'isLoading',
      error: 'getError',
    },
  }
)

export const ClientiStatsState = rj(
  rjDebounce(),

  rjList({
    pageSize: 1000,
    pagination: nextPreviousPaginationAdapter,
  }),
  rjListWithTotals(),
  {
    name: 'ClientiStatsState',
    effect: (filters = {}) =>
      ajax.getJSON(
        `/api/planner/clienti/statistiche-clienti-ordini/?${qs.stringify(
          filters
        )}`
      ),
    computed: {
      clienti: 'getList',
      totals: 'getTotals',
      pagination: 'getPagination',
      loading: 'isLoading',
      error: 'getError',
    },
  }
)


export const AgentiStatsState = rj(
  rjDebounce(),

  rjList({
    pageSize: 1000,
    pagination: nextPreviousPaginationAdapter,
  }),
  rjListWithTotals(),
  {
    name: 'AgentiStatsState',
    effect: (filters = {}) =>
      ajax.getJSON(
        `/api/admin-users/agenti/statistiche-ordini-agenti/?${qs.stringify(
          filters
        )}`
      ),
    computed: {
      agenti: 'getList',
      totals: 'getTotals',
      pagination: 'getPagination',
      loading: 'isLoading',
      error: 'getError',
    },
  }
)

export const DetailClienteState = rj({
  name: 'DetailOrder',
  computed: {
    data: 'getData',
  },
  effect: (id) => ajax.getJSON(`/api/planner/clienti/${id}/`),
})
