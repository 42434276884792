import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ModalDeleteOrder = ({ isOpen, toggle, onSetEliminato, deleting, tipoSerramento, categoriaSerramento, codiceSerramento }) => {
  return(
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}>Elimina tempistica</ModalHeader>
      <ModalBody>
        <div className='container'>
          Sei sicuro di voler eliminare la tempistica per: {tipoSerramento && tipoSerramento.name} - {categoriaSerramento && categoriaSerramento.value}
          - {codiceSerramento && codiceSerramento.codice}?
        </div>
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between'>
        <Button color="secondary" onClick={toggle}>Chiudi</Button>
        <Button color="danger" disabled={deleting} onClick={onSetEliminato}>Elimina</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalDeleteOrder
