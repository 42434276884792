import React, { useMemo, useState } from 'react'
import { useRunRj } from 'react-rocketjump'
import { useQsDataTable as useDataTable } from 'react-use-datatable/react'
import Select from 'react-select'
import { LavorazioniState } from '../localstate/lavorazioni'
import Spinner from 'reactstrap/lib/Spinner'
import { OperatoriState } from '../localstate/operatori'
import DateRangeQsFilter from '../../../components/Planner/DateRangeQsFilter'
import OrdersSelectAutoComplete from '../../../components/OrdersSelectAutoComplete'
import moment from 'moment'
import it from 'date-fns/locale/it'
import { AttivitaAdminState } from '../localstate/attivita'
import { registerLocale } from 'react-datepicker'
import LineChartOperatori from './LineChartOperatori'
import OreLavorateCircle from './OreLavorateCircle'
import TableCompletezzaGiorno from './TableCompletezzaGiorno'
import classNames from 'classnames'
import TableAttivita from '../../../components/Planner/TableAttivita'

registerLocale('it', it)
const FORMAT = 'YYYY-MM-DD'

const BIG_PAGE_PARAMS = { page_size: 1000 }

export default function Completezza() {
  const [{ lavorazioni }] = useRunRj(LavorazioniState, [BIG_PAGE_PARAMS])
  const [{ operatori }] = useRunRj(OperatoriState, [BIG_PAGE_PARAMS])

  const [
    // Data State
    { attivita, loading },
    // Table state
    { page, ...filters },
    {
      // DT Actions
      onFiltersChange,
      onFilterChange,
    },
  ] = useDataTable(AttivitaAdminState, () => ({
    operatore: '',
    lavorazione: '',
    ordine: '',
    from_date: moment().startOf('week').format(FORMAT),
    to_date: moment().endOf('week').format(FORMAT),
    lavorazioni: [],
    operatori: [],
    page_size: 1000,
  }))

  const operatoriForSelect = useMemo(() => {
    if (!operatori) {
      return []
    }
    return operatori.map((operatore) => ({
      value: operatore.id,
      label: operatore.user.first_name + ' ' + operatore.user.last_name,
    }))
  }, [operatori])

  const selectedOperatori = useMemo(() => {
    if (!filters.operatori || !operatori) {
      return []
    }
    if (typeof filters.operatori === 'string') {
      return filters.operatori.split(',').map((id) => ({
        value: id,
        label:
          operatori.find((operatore) => operatore.id === Number(id)).user
            .first_name +
          ' ' +
          operatori.find((operatore) => operatore.id === Number(id)).user
            .last_name,
      }))
    } else {
      return filters.operatori.map((id) => ({
        value: id,
        label:
          operatori.find((operatore) => operatore.id === Number(id)).user
            .first_name +
          ' ' +
          operatori.find((operatore) => operatore.id === Number(id)).user
            .last_name,
      }))
    }
  }, [filters.operatori, operatori])

  const lavorazioniForSelect = useMemo(() => {
    if (!lavorazioni) {
      return []
    }
    return lavorazioni.map((lavorazione) => ({
      value: lavorazione.id,
      label: lavorazione.nome,
    }))
  }, [lavorazioni])

  const selectedLavorazioni = useMemo(() => {
    if (!filters.lavorazioni || !lavorazioni) {
      return []
    }
    if (typeof filters.lavorazioni === 'string') {
      return filters.lavorazioni.split(',').map((id) => ({
        value: id,
        label: lavorazioni.find((lavorazione) => lavorazione.id === Number(id))
          .nome,
      }))
    } else {
      return filters.lavorazioni.map((id) => ({
        value: id,
        label: lavorazioni.find((lavorazione) => lavorazione.id === Number(id))
          .nome,
      }))
    }
  }, [filters.lavorazioni, lavorazioni])

  const attivitaGroupedByOperatoreAndDay = useMemo(() => {
    if (!attivita) {
      return null
    }
    const grouped = attivita.reduce((acc, attivita) => {
      const operatoreId = attivita.operatore.id
      const operatore = acc[operatoreId]
      if (!operatore) {
        acc[operatoreId] = {
          operatore: attivita.operatore,
          days: {},
        }
      }
      const day = moment(attivita.data).format('YYYY-MM-DD')
      const operatoreDay = acc[operatoreId].days[day]
      if (!operatoreDay) {
        acc[operatoreId].days[day] = {
          day,
          attivita: [],
        }
      }
      acc[operatoreId].days[day].attivita.push(attivita)
      return acc
    }, {})
    return (
      Object.values(grouped)
        // ordina per data
        .map((operatore) => {
          return {
            ...operatore,
            days: Object.values(operatore.days).sort((a, b) => {
              return moment(a.day).isBefore(moment(b.day)) ? -1 : 1
            }),
          }
        })
    )
  }, [attivita])

  const dataForBarChart = useMemo(() => {
    // return an array of objects with this keys:
    // data: 'DD-MM-YYYY'
    // "operatore": numero di minuti lavorati
    // "operatore2": numero di minuti lavorati
    if (!attivitaGroupedByOperatoreAndDay) {
      return []
    }
    const data = attivitaGroupedByOperatoreAndDay.map((operatore) => {
      const dataForOperatore = operatore.days.map((day) => {
        const totalMinuti = day.attivita.reduce((acc, attivita) => {
          return acc + parseInt(moment.duration(attivita.tempo).as('minutes'))
        }, 0)
        return {
          data: moment(day.day).format('DD-MM-YYYY'),
          [operatore.operatore.user.first_name +
          ' ' +
          operatore.operatore.user.last_name]: totalMinuti,
        }
      })
      return dataForOperatore
    })
    // merge all data if operatore has no attivita for a day add 0
    const mergedData = data.reduce((acc, dataForOperatore) => {
      dataForOperatore.forEach((dataForDay) => {
        const day = dataForDay.data
        const operatore = Object.keys(dataForDay).find((key) => key !== 'data')
        const value = dataForDay[operatore]
        const dayData = acc.find((item) => item.data === day)
        if (!dayData) {
          acc.push({
            data: day,
            [operatore]: value,
          })
        } else {
          dayData[operatore] = value
        }
      })
      return acc
    }, [])

    return mergedData
  }, [attivitaGroupedByOperatoreAndDay])

  const operatoriInAttivitaFlat = useMemo(() => {
    if (!attivitaGroupedByOperatoreAndDay) {
      return []
    }
    const operatori = attivitaGroupedByOperatoreAndDay.map((operatore) => {
      return {
        id: operatore.operatore.id,
        first_name: operatore.operatore.user.first_name,
        last_name: operatore.operatore.user.last_name,
      }
    })
    // remove duplicates
    return operatori.filter(
      (operatore, index, self) =>
        index === self.findIndex((t) => t.id === operatore.id)
    )
  }, [attivitaGroupedByOperatoreAndDay])

  const percentualiLavorative = useMemo(() => {
    if (!attivitaGroupedByOperatoreAndDay) {
      return []
    }
    const percentuali = attivitaGroupedByOperatoreAndDay.map((operatore) => {
      const percentualiGiornaliere = operatore.days.map((day) => {
        const totalMinuti = day.attivita.reduce((acc, attivita) => {
          return acc + parseInt(moment.duration(attivita.tempo).as('minutes'))
        }, 0)
        const percentuale = (totalMinuti / 480) * 100
        return percentuale
      })
      const percentualeMedia =
        percentualiGiornaliere.reduce((acc, percentuale) => {
          return acc + percentuale
        }, 0) / percentualiGiornaliere.length
      return {
        operatore: operatore.operatore,
        percentualeMedia,
      }
    })
    return percentuali
  }, [attivitaGroupedByOperatoreAndDay])

  const percentualeMedia = useMemo(() => {
    if (!percentualiLavorative) {
      return 0
    }
    const percentualeMedia =
      percentualiLavorative.reduce((acc, percentuale) => {
        return acc + percentuale.percentualeMedia
      }, 0) / percentualiLavorative.length
    return percentualeMedia
  }, [percentualiLavorative])

  const [typeView, setTypeView] = useState('dati-originali')

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          {attivita === null && loading && (
            <div className="text-center mt-4">
              <Spinner color="primary" />
            </div>
          )}
          {attivita && (
            <>
              <div className="filters mt-2">
                <div className="row">
                  <div className="col-md-3">
                    <Select
                      name="operatori"
                      isMulti
                      placeholder={'Filtra per operatori'}
                      options={operatoriForSelect.filter(
                        (operatore) =>
                          !selectedOperatori.find(
                            (item) =>
                              Number(item.value) === Number(operatore.value)
                          )
                      )}
                      classNamePrefix="multi-select"
                      hideSelectedOptions={false}
                      isOptionSelected={(option) =>
                        selectedOperatori.find(
                          (item) => item.value === option.value
                        )
                      }
                      value={selectedOperatori}
                      className="basic-multi-select select-multi-options text-nowrap w-100"
                      onChange={(newValues) => {
                        if (newValues) {
                          const items = newValues.map((item) => item.value)
                          onFilterChange(items, 'operatori')
                        } else {
                          onFilterChange([], 'operatori')
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <Select
                      name="lavorazioni"
                      isMulti
                      placeholder={'Filtra per lavorazioni'}
                      options={lavorazioniForSelect.filter(
                        (lavorazione) =>
                          !selectedLavorazioni.find(
                            (item) =>
                              Number(item.value) === Number(lavorazione.value)
                          )
                      )}
                      classNamePrefix="multi-select"
                      hideSelectedOptions={false}
                      isOptionSelected={(option) =>
                        selectedLavorazioni.find(
                          (item) => item.value === option.value
                        )
                      }
                      value={selectedLavorazioni}
                      className="basic-multi-select select-multi-options text-nowrap w-100"
                      onChange={(newValues) => {
                        if (newValues) {
                          const items = newValues.map((item) => item.value)
                          onFilterChange(items, 'lavorazioni')
                        } else {
                          onFilterChange([], 'lavorazioni')
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <OrdersSelectAutoComplete
                      value={filters.ordine}
                      hasValue
                      debounceTimeout={150}
                      loadOptionsOnMenuOpen
                      onChange={(ordine) => {
                        if (ordine) {
                          onFiltersChange({ ordine: ordine.id })
                        } else {
                          onFiltersChange({ ordine: '' })
                        }
                      }}
                      noOptionsMessage={() => 'Nessun ordine trovato'}
                      loadingMessage={() => 'Caricamento ordini...'}
                      placeholder={'Filtra per ordine'}
                      isClearable={true}
                      name={'ordine'}
                      additional={{
                        page: 1,
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <DateRangeQsFilter
                      filters={filters}
                      onFiltersChange={onFiltersChange}
                      startField="from_date"
                      endField="to_date"
                      withPortal={true}
                      customArrowIcon={<div />}
                      small={false}
                      noBorder
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-8">
                  <LineChartOperatori
                    dataForBarChart={dataForBarChart}
                    operatoriInAttivitaFlat={operatoriInAttivitaFlat}
                  />
                </div>
                <div className="col-md-4 position-relative bg-light d-flex align-items-center justify-content-center">
                  <OreLavorateCircle percentage={percentualeMedia} />
                  <div className="ml-5  ps-4">
                    <h6 className="ps-5">Ore lavorate</h6>
                    <div className="ps-5">
                      <small>
                        {moment(filters.from_date).format('DD/MM/YYYY')}
                      </small>
                      {' - '}
                      <small>
                        {moment(filters.to_date).format('DD/MM/YYYY')}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3 mb-2">
                <div className="col-md-8 mt-3">
                  <div>
                    <div
                      className={classNames('btn pointer', {
                        'btn-primary': typeView === 'giorno-per-giorno',
                        'btn-outline-primary': typeView !== 'giorno-per-giorno',
                      })}
                      onClick={() => {
                        setTypeView('giorno-per-giorno')
                      }}
                    >
                      Giorno per giorno
                    </div>
                    <div
                      className={classNames('btn pointer', {
                        'btn-primary': typeView === 'dati-originali',
                        'btn-outline-primary': typeView !== 'dati-originali',
                      })}
                      onClick={() => {
                        setTypeView('dati-originali')
                      }}
                    >
                      Dati originali
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-end"></div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {typeView === 'giorno-per-giorno' && (
                    <TableCompletezzaGiorno
                      attivitaGroupedByOperatoreAndDay={
                        attivitaGroupedByOperatoreAndDay
                      }
                      lavorazioni={lavorazioni}
                    />
                  )}
                  {typeView === 'dati-originali' && (
                    <TableAttivita
                      attivita={attivita}
                      loading={loading}
                      isAdmin
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
