import React, { useState, useCallback, useMemo } from 'react'
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import province from '../../data/province'
import regioni from '../../data/regioni'
import classNames from 'classnames'
import moment from 'moment'
import 'moment/locale/it'
import { DateRangePicker } from 'react-dates'
import { USER } from '../../django'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

moment.locale('it')
const FORMAT = 'YYYY-MM-DD'

const FiltersPreventivi = ({
  filters,
  onFilterChange,
  onFiltersChange,
  onFilterChangeDebounced,
  agenti,
  statiPreventivo,
  toggleShowModalChooseAgent,
  toggleShowModalDelete,
  selectedPreventivi,
  toggleShowModalChooseStato }) => {
  const { search, citta, provincia, regione_posa, stato, agente, eliminato } = filters

  const startDate = useMemo(() => {
    if (filters.startDate === null || filters.startDate === '') {
      return null
    }
    const m = moment(filters.startDate, FORMAT)
    return m.isValid() ? m : null
  }, [filters.startDate])

  const endDate = useMemo(() => {
    if (filters.endDate === null || filters.endDate === '') {
      return null
    }
    const m = moment(filters.endDate, FORMAT)
    return m.isValid() ? m : null
  }, [filters.endDate])

  const [focusedInput, setFocusedInput] = useState(null)
  const [isOpenActions, setOpenActions] = useState(false)
  const toggleActions = useCallback(() => {
    setOpenActions(show => !show)
  }, [])

  return(
    <>
      <div className='row pt-2'>
        <div className='col-md-3'>
          <input
            name='search'
            type='text'
            className='form-control form-control-sm'
            placeholder={'Ricerca libera'}
            value={search}
            onChange={onFilterChangeDebounced}
          />
        </div>
        <div className='col-md-3'>
          <select name='regione_posa' value={regione_posa} className='custom-select custom-select-sm' onChange={onFilterChange}>
            <option value=''>Filtra per regione</option>
            {regioni.map(regione => (
              <option key={regione.nome} value={regione.nome}>{regione.nome}</option>
            ))}
          </select>
        </div>
        <div className='col-md-3'>
          <select name='provincia' value={provincia} className='custom-select custom-select-sm' onChange={onFilterChange}>
            <option value=''>Filtra per provincia</option>
            {province.map(provincia => (
              <option key={provincia.nome} value={provincia.nome}>{provincia.nome}</option>
            ))}
          </select>
        </div>
        <div className='col-md-3'>
          <input
            placeholder='Cerca città'
            name='citta'
            type='text'
            className='form-control form-control-sm'
            value={citta}
            onChange={onFilterChangeDebounced}
          />
        </div>
      </div>
      <div className='row mt-2'>
        {USER &&
        <div className='col-md-3 d-flex'>
          {!USER.agente && <>
          <ButtonDropdown isOpen={isOpenActions} toggle={toggleActions}>
            <DropdownToggle caret size='sm' color="secondary" disabled={selectedPreventivi.length === 0 || eliminato === 'true'}>
              Azioni
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => toggleShowModalChooseAgent()} className='pointer'><i className="fas fa-user-tie mr-2"></i> Assegna agente</DropdownItem>
              <DropdownItem divider/>
              <DropdownItem onClick={() => toggleShowModalDelete()} className='pointer'><i className="fas fa-times mr-2"></i> Elimina</DropdownItem>
              <DropdownItem divider/>
              <DropdownItem onClick={() => toggleShowModalChooseStato()} className='pointer'><i className="fas fa-bookmark mr-2"></i> Cambia stato</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
          </>}
          <select value={eliminato} name='eliminato' className={classNames('custom-select','custom-select-sm',{
            'ml-2': USER.agente === null
          })} onChange={onFilterChange}>
            <option value='false'>Preventivi correnti</option>
            <option value='true'>Preventivi eliminati</option>
          </select>
        </div>
        }
        {!USER.agente &&
        <div className='col-md-3'>
          <select name='agente' value={agente} className='custom-select custom-select-sm' onChange={onFilterChange}>
            <option value=''>Filtra per agente</option>
            {agenti && agenti.map(agente => (
              <option key={agente.id} value={agente.id}>{agente.user.first_name} {agente.user.last_name}</option>
            ))}
          </select>
        </div>
        }
        <div className='col-md-3'>
          <select name='stato' value={stato} className='custom-select custom-select-sm' onChange={onFilterChange}>
            <option value=''>Filtra per stato</option>
            {statiPreventivo && statiPreventivo.map(stato => (
              <option key={stato.id} value={stato.id}>{stato.stato}</option>
            ))}
          </select>
        </div>
        <div className='col-md-3'>
          <DateRangePicker
            startDate={startDate}
            startDateId="data_inizio"
            endDate={endDate}
            block={true}
            horizontalMargin={30}
            endDateId="data_fine"
            startDatePlaceholderText={'Data inizio'}
            endDatePlaceholderText={'Data fine'}
            hideKeyboardShortcutsPanel
            isOutsideRange={() => false}
            onDatesChange={({ startDate, endDate }) => {
              onFiltersChange({
                startDate: startDate ? startDate.format(FORMAT) : '',
                endDate: endDate ? endDate.format(FORMAT) : ''
              })
            }}
            focusedInput={focusedInput} //
            onFocusChange={focusedInput => setFocusedInput(focusedInput)}
          />
        </div>
      </div>
    </>
  )
}

export default FiltersPreventivi
