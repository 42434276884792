import React from 'react'
import {
  PieChart, Pie, Legend, Tooltip, Cell
} from 'recharts'
import range from 'lodash/range'
import { interpolateSpectral } from 'd3-scale-chromatic'
import { scaleOrdinal } from 'd3-scale'


// const colorScale = scaleOrdinal(schemeTableau10)
const colorScale = scaleOrdinal(
  range(20).map(i => {
    let k
    if (i % 2 === 0) {
      k = (1 / 20) * i
    } else {
      k = (1 / 20) * (20 - i)
    }
    return interpolateSpectral(k)
  })
)

export default function PieChartNetto ({ data, nameKey, dataKey }) {
  return (
    <PieChart width={400} height={400}>
     <Pie
       data={data}
       cx={200}
       cy={200}
       labelLine
       label={({ percent }) => `${(percent * 100).toFixed(0)}%`}
       nameKey={nameKey}
       outerRadius={140}
       fill="#8884d8"
       dataKey={dataKey}
     >
       {
         data.map((entry, index) => <Cell key={`cell-${index}`} fill={colorScale(index)} />)
       }
     </Pie>
     <Tooltip formatter={(value, name, props) => <span>{value+' €'}<br />
      Num. serramenti: {props.payload.num_serramenti}</span> } />
     <Legend verticalAlign="top" height={36}/>
   </PieChart>
  )
}
