import { Field, Formik } from 'formik'
import React from 'react'
import Button from 'reactstrap/lib/Button'
import Modal from 'reactstrap/lib/Modal'
import ModalBody from 'reactstrap/lib/ModalBody'
import ModalFooter from 'reactstrap/lib/ModalFooter'
import ModalHeader from 'reactstrap/lib/ModalHeader'
import { FileField, InputField } from '../../../components/Form/Fields'
import { transformErrorForForm } from '../../../components/Form/Fields/utils'

const initialValues = {
  note: '',
  tipo_mittente: 'interno',
  allegato: null,
}

function ModalContentFormMessaggio({ save, messaggio, toggle }) {
  return (
    <Formik
      onSubmit={(values, actions) => {
        return save(values).then(
          () => {
            // actions.setSubmitting(false)
          },
          (error) => {
            actions.setSubmitting(false)
            actions.setErrors(transformErrorForForm(error))
          }
        )
      }}
      // validationSchema={FolderSchema}
      initialValues={messaggio ?? initialValues}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="m-0 p-0">
          <ModalBody>
            <Field
              autoComplete="off"
              component={InputField}
              label="Note"
              name="note"
              type="textarea"
            />
            <Field
              component={InputField}
              label="Tipo Mittente"
              name="tipo_mittente"
              type="select"
              className="mb-2"
            >
              <option value="interno">Interno</option>
              <option value="cliente">Cliente</option>
            </Field>
            <Field
              component={FileField}
              type="file"
              label="Allegato"
              name="allegato"
            />
          </ModalBody>
          <ModalFooter className="d-flex justify-content-between">
            <Button disabled={isSubmitting} type="button" onClick={toggle}>
              Annulla
            </Button>
            <Button disabled={isSubmitting} type="submit" color="success">
              {messaggio && messaggio.id ? 'Modifica' : 'Crea'}
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  )
}

export default function ModalFormMessaggio({
  messaggio,
  isOpen,
  toggle,
  onClosed,
  ...props
}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
      <ModalHeader toggle={toggle}>Nuovo Messaggio</ModalHeader>
      <ModalContentFormMessaggio
        messaggio={messaggio}
        toggle={toggle}
        {...props}
      />
    </Modal>
  )
}
