import React, { Fragment, useContext, useMemo } from "react"
import { Button } from "reactstrap"
import moment from "moment"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import "moment/locale/it"
import { PERMISSIONS } from "../../../django"
import DateRangeQsFilter from "../DateRangeQsFilter"
import Column from "../../../components/Column"
import { ConfigPlannerContext } from "../../../context"
import keyBy from "lodash/keyBy"
import UncontrolledPopover from "reactstrap/lib/UncontrolledPopover"

moment.locale("it")

function CheckListFilter({ filters, onFiltersChange }) {
  const { checkList } = useContext(ConfigPlannerContext)

  const activeCount =
    filters.done_checklist.length + filters.undone_checklist.length

  function handleOnChange(id, value) {
    let nextDone = filters.done_checklist.filter((v) => v !== String(id))
    let nextUndone = filters.undone_checklist.filter((v) => v !== String(id))
    if (value === "1") {
      nextDone.push(id)
    } else if (value === "0") {
      nextUndone.push(id)
    }
    onFiltersChange({
      done_checklist: nextDone,
      undone_checklist: nextUndone,
    })
  }

  return (
    <div>
      <Button
        size="sm"
        outline
        color="primary"
        id="btn-filter-checklist"
        className="text-nowrap"
      >
        <i className="fa fa-check-square mr-1"></i>
        Check List{" "}
        {activeCount > 0 && (
          <span class="badge badge-primary">{activeCount}</span>
        )}
      </Button>
      <UncontrolledPopover target="btn-filter-checklist" placement="bottom">
        <div className="p-1">
          <Button
            onClick={() => {
              onFiltersChange({
                done_checklist: [],
                undone_checklist: [],
              })
            }}
            style={{ fontSize: 10, padding: 2 }}
            size="sm"
            color="secondary"
          >
            RESET
          </Button>
        </div>
        {checkList.map((check) => (
          <div key={check.id} className="p-1">
            <select
              onChange={(e) => handleOnChange(check.id, e.target.value)}
              value={
                filters.done_checklist.indexOf(String(check.id)) !== -1
                  ? "1"
                  : filters.undone_checklist.indexOf(String(check.id)) !== -1
                  ? "0"
                  : ""
              }
            >
              <option value="">--</option>
              <option value="1">{"\u2705"}</option>
              <option value="0">{"\u274C"}</option>
            </select>
            <span className="ml-2">{check.nome}</span>
          </div>
        ))}
      </UncontrolledPopover>
    </div>
  )
}

const OrderRow = React.memo(
  ({
    ordine,
    onOrderDeleteClick,
    onOrerStatoClick,
    onOpenCheckList,
    onOpenInvioMail,
  }) => {
    const { checkList } = useContext(ConfigPlannerContext)
    const byCheck = useMemo(
      () => keyBy(ordine.check_list, "checklist"),
      [ordine.check_list]
    )

    return (
      <tr key={ordine.id}>
        <td>
          <Link
            to={`/ordini/${ordine.id}${PERMISSIONS.editOrder ? "/edit" : ""}`}
          >
            {ordine.numero_commessa}
          </Link>
        </td>
        <td
          className={`text-nowrap ${
            PERMISSIONS.writeOrderCheckList ? "pointer" : ""
          }`}
          onClick={
            PERMISSIONS.writeOrderCheckList
              ? () => onOpenCheckList(ordine)
              : undefined
          }
        >
          {checkList.map((action) => (
            <div key={action.id}>
              <small>
                {byCheck[action.id]?.done ? (
                  <i className="fa fa-check text-success" />
                ) : (
                  <i className="fa fa-times text-danger" />
                )}{" "}
                - {action.nome}
              </small>
            </div>
          ))}
        </td>
        <td>
          <ul style={{ paddingInlineStart: 20 }}>
            {ordine.ordini_fornitore_mancanti &&
              ordine.ordini_fornitore_mancanti.map((miss) => (
                <li key={miss.materiale}>{miss.materiale}</li>
              ))}
          </ul>
        </td>
        <td>{ordine.riferimento}</td>
        <td className="text-nowrap">
          {ordine.data ? dayjs(ordine.data).format("DD-MM-YYYY") : null}
        </td>
        <td className="text-nowrap">
          {ordine.data_consegna
            ? dayjs(ordine.data_consegna).format("DD-MM-YYYY")
            : null}
        </td>
        <td className="text-nowrap">
          {ordine.data_posa
            ? dayjs(ordine.data_posa).format("DD-MM-YYYY")
            : null}
        </td>
        <td className="text-nowrap">
          {ordine.stato}
          {PERMISSIONS.editOrder ? " " : null}
          {PERMISSIONS.editOrder && (
            <Button
              color="success"
              outline
              size="sm"
              onClick={() => onOrerStatoClick(ordine)}
            >
              <i className="fa fa-edit" />
            </Button>
          )}
        </td>
        <td className="">
          {ordine.cliente_data ? ordine.cliente_data.nome : ""}
        </td>
        <td>{ordine.tipo_cliente}</td>
        <td>{ordine.codice_sistema}</td>
        {PERMISSIONS.viewPricesOnOrders && (
          <>
            <td>{ordine.listino}</td>
            <td>{ordine.sconto}</td>
            <td>{ordine.netto}</td>
          </>
        )}
        {PERMISSIONS.viewAgenteOnOrders && (
          <td>
            {ordine.agente
              ? `${ordine.agente_data.user.first_name} ${ordine.agente_data.user.last_name}`
              : ""}
          </td>
        )}
        <td>
          {ordine.schedulazione_ordine ? (
            <div>
              <small>
                <span className="mr-1">Inizio:</span>{" "}
                {dayjs(ordine.schedulazione_ordine.inizio).format(
                  "DD-MM-YYYY HH:mm"
                )}
              </small>
              <hr style={{ margin: "5px 0px 5px 0px" }} />
              {ordine.schedulazione_ordine.schedulazioni_fasi
                .filter((fase) => !fase.fornitore)
                .map((fase, key) => (
                  <Fragment key={key}>
                    <small>
                      <span className="mr-1">Inizio {fase.nome_fase}:</span>{" "}
                      <br /> {dayjs(fase.inizio).format("DD-MM-YYYY HH:mm")}
                    </small>
                    {ordine.schedulazione_ordine.schedulazioni_fasi.filter(
                      (fase) => !fase.fornitore
                    ).length !==
                      key + 1 && <hr style={{ margin: "5px 0px 5px 0px" }} />}
                  </Fragment>
                ))}
            </div>
          ) : (
            <small>Non schedulato</small>
          )}
        </td>
        <td>
          {ordine.schedulazione_ordine ? (
            <div>
              <small>
                <span className="mr-1">Fine:</span>{" "}
                {dayjs(ordine.schedulazione_ordine.fine).format(
                  "DD-MM-YYYY HH:mm"
                )}
              </small>
              <hr style={{ margin: "5px 0px 5px 0px" }} />
              {ordine.schedulazione_ordine.schedulazioni_fasi
                .filter((fase) => !fase.fornitore)
                .map((fase, key) => (
                  <Fragment key={key}>
                    <small>
                      <span className="mr-1">Fine {fase.nome_fase}:</span>{" "}
                      <br /> {dayjs(fase.fine).format("DD-MM-YYYY HH:mm")}
                    </small>
                    {ordine.schedulazione_ordine.schedulazioni_fasi.filter(
                      (fase) => !fase.fornitore
                    ).length !==
                      key + 1 && <hr style={{ margin: "5px 0px 5px 0px" }} />}
                  </Fragment>
                ))}
            </div>
          ) : (
            <small>Non schedulato</small>
          )}
        </td>
        {PERMISSIONS.adminOrders && (
          <>
            <td className="text-center text-nowrap">
              {ordine.schedulazione_ordine && ordine.data_consegna && (
                <>
                  {ordine.stato_invio_mail && ordine.stato_invio_mail !== "" ? (
                    ordine.stato_invio_mail === "inviata" ? (
                      <Button
                        onClick={() => onOpenInvioMail(ordine)}
                        size="sm"
                        disabled={!PERMISSIONS.editOrder}
                        outline
                        type="button"
                        color="primary"
                      >
                        <i className="fas fa-check"></i> Inviata
                      </Button>
                    ) : (
                      <Button
                        onClick={() => onOpenInvioMail(ordine)}
                        size="sm"
                        disabled={!PERMISSIONS.editOrder}
                        outline
                        type="button"
                        color="primary"
                      >
                        <i className="fas fa-clock-o"></i> In attesa invio
                      </Button>
                    )
                  ) : (
                    <Button
                      onClick={() => onOpenInvioMail(ordine)}
                      size="sm"
                      disabled={!PERMISSIONS.editOrder}
                      outline
                      type="button"
                      color="primary"
                    >
                      <i className="fas fa-envelope"></i> Programma
                    </Button>
                  )}
                </>
              )}
            </td>
            <td>
              {ordine.data_invio_mail
                ? dayjs(ordine.data_invio_mail).format("DD-MM-YYYY")
                : null}
            </td>
          </>
        )}
        <td className="text-center">{ordine.numero_serramenti}</td>

        <td className="text-center">
          <div className="d-flex justify-content-center">
            {PERMISSIONS.viewOrderFasi ? (
              <Link title="Dettaglio ordine" to={`/ordini/${ordine.id}/fasi`}>
                <i className="fas fa-search"></i>
              </Link>
            ) : (
              <Link title="Dettaglio ordine" to={`/ordini/${ordine.id}`}>
                <i className="fas fa-search"></i>
              </Link>
            )}
            {PERMISSIONS.editOrder && (
              <Link
                className="ml-2"
                title="Modifca ordine"
                to={`/ordini/${ordine.id}/edit`}
              >
                <i className="fas fa-edit"></i>
              </Link>
            )}
            {PERMISSIONS.scheduleOrder && (
              <Link
                title="Schedulazione ordine"
                to={`/schedulatore?id=${ordine.id}`}
              >
                <i className="fas fa-clock text-success ml-2"></i>
              </Link>
            )}
            {PERMISSIONS.deleteOrder && (
              <div
                title="Elimina ordine"
                className="pointer ml-2"
                onClick={() => {
                  onOrderDeleteClick(ordine)
                }}
              >
                <i className="fas fa-trash text-danger"></i>
              </div>
            )}
          </div>
        </td>
      </tr>
    )
  }
)
OrderRow.displayName = "OrderRow"

const TableOrders = ({
  // Data
  ordini,
  loading,
  agenti,
  totals,
  ordersDomain,
  // Events
  onOrderDeleteClick,
  onOrerStatoClick,
  onOpenCheckList,
  onOpenInvioMail,
  // Filters
  filters,
  onFilterChangeDebounced,
  onFilterChange,
  onFiltersChange,
}) => {
  const columnProps = {
    value: filters.ordering,
    onChange: (value) => onFilterChange(value, "ordering"),
  }

  return (
    <div className="table-responsive">
      <table className="table table-bordered table-striped mt-2">
        <thead>
          <tr>
            <td>
              <input
                name="numero_commessa_search"
                type="text"
                className="form-control form-control-sm"
                value={filters.numero_commessa_search}
                onChange={onFilterChangeDebounced}
              />
            </td>
            <td className="text-center">
              <CheckListFilter
                filters={filters}
                onFiltersChange={onFiltersChange}
              />
            </td>
            <td>
              <select
                name="ordini_fornitori_mancanti"
                className="form-control form-control-sm"
                value={filters.ordini_fornitori_mancanti}
                onChange={onFilterChange}
              >
                <option value="">Tutti</option>
                <option value="1">Mancanti</option>
                <option value="0">Completati</option>
              </select>
            </td>
            <td>
              <input
                name="riferimento_search"
                type="text"
                className="form-control form-control-sm"
                value={filters.riferimento_search}
                onChange={onFilterChangeDebounced}
              />
            </td>
            <td>
              <DateRangeQsFilter
                filters={filters}
                onFiltersChange={onFiltersChange}
                startField="from_data"
                endField="to_data"
                withPortal={true}
                customArrowIcon={<div />}
                small={false}
                noBorder
              />
            </td>
            <td>
              <DateRangeQsFilter
                filters={filters}
                onFiltersChange={onFiltersChange}
                startField="from_data_consegna"
                endField="to_data_consegna"
                withPortal={true}
                customArrowIcon={<div />}
                small={false}
                noBorder
              />
            </td>
            <td>
              <DateRangeQsFilter
                filters={filters}
                onFiltersChange={onFiltersChange}
                startField="from_data_posa"
                endField="to_data_posa"
                withPortal={true}
                customArrowIcon={<div />}
                small={false}
                noBorder
              />
            </td>
            <td>
              <select
                name="stato"
                value={filters.stato}
                className="form-control form-control-sm"
                onChange={onFilterChange}
              >
                <option value=""></option>
                {ordersDomain &&
                  ordersDomain.stati.map((stato) => (
                    <option key={stato} value={stato}>
                      {stato}
                    </option>
                  ))}
              </select>
            </td>
            <td>
              <input
                name="nome_cliente_search"
                type="text"
                className="form-control form-control-sm"
                value={filters.nome_cliente_search}
                onChange={onFilterChangeDebounced}
              />
            </td>
            <td>
              <select
                name="tipo_cliente"
                value={filters.tipo_cliente}
                className="form-control form-control-sm"
                onChange={onFilterChange}
              >
                <option value=""></option>
                {ordersDomain &&
                  ordersDomain.tipi_cliente.map((tipo) => (
                    <option key={tipo} value={tipo}>
                      {tipo}
                    </option>
                  ))}
              </select>
              {/* <input
                name='tipo_cliente_search'
                type='text'
                className='form-control form-control-sm'
                value={filters.tipo_cliente_search}
                onChange={onFilterChangeDebounced}
              /> */}
            </td>
            <td>
              <select
                name="codice_sistema"
                value={filters.codice_sistema}
                className="form-control form-control-sm"
                onChange={onFilterChange}
              >
                <option value=""></option>
                {ordersDomain &&
                  ordersDomain.codici_sistema.map((codice) => (
                    <option key={codice} value={codice}>
                      {codice}
                    </option>
                  ))}
              </select>
            </td>
            {PERMISSIONS.viewPricesOnOrders && (
              <>
                <td className="text-center">
                  {totals.listino && (
                    <span className="badge badge-primary">
                      {" "}
                      {totals.listino}
                    </span>
                  )}
                </td>
                <td className="text-center">
                  {totals.sconto && (
                    <span className="badge badge-primary">
                      {" "}
                      {totals.sconto.toFixed(2)}
                    </span>
                  )}
                </td>
                <td className="text-center">
                  {totals.netto && (
                    <span className="badge badge-primary"> {totals.netto}</span>
                  )}
                </td>
              </>
            )}
            {PERMISSIONS.viewAgenteOnOrders && (
              <td className="text-nowrap">
                <select
                  name="agente"
                  value={filters.agente}
                  className="form-control form-control-sm"
                  onChange={onFilterChange}
                >
                  <option value=""></option>
                  <option value="null">Nessun agente settato</option>
                  {agenti &&
                    agenti.map((agente) => (
                      <option key={agente.id} value={agente.id}>
                        {agente.user.first_name} {agente.user.last_name}
                      </option>
                    ))}
                </select>
              </td>
            )}
            <td>
              <div>
                <small>
                  <b>Filtro Inizio Macchine</b>
                </small>
              </div>
              <DateRangeQsFilter
                filters={filters}
                onFiltersChange={onFiltersChange}
                startField="from_inizio_macchine"
                endField="to_inizio_macchine"
                withPortal={true}
                customArrowIcon={<div />}
                small={false}
                noBorder
              />
              <div className="mt-2">
                <small>
                  <b>Filtro Inizio Banchi</b>
                </small>
              </div>
              <DateRangeQsFilter
                filters={filters}
                onFiltersChange={onFiltersChange}
                startField="from_inizio_banchi"
                endField="to_inizio_banchi"
                withPortal={true}
                customArrowIcon={<div />}
                small={false}
                noBorder
              />
            </td>
            <td>
              <div>
                <small>
                  <b>Filtro Fine Macchine</b>
                </small>
              </div>
              <DateRangeQsFilter
                filters={filters}
                onFiltersChange={onFiltersChange}
                startField="from_fine_macchine"
                endField="to_fine_macchine"
                withPortal={true}
                customArrowIcon={<div />}
                small={false}
                noBorder
              />
              <div className="mt-2">
                <small>
                  <b>Filtro Fine Banchi</b>
                </small>
              </div>
              <DateRangeQsFilter
                filters={filters}
                onFiltersChange={onFiltersChange}
                startField="from_fine_banchi"
                endField="to_fine_banchi"
                withPortal={true}
                customArrowIcon={<div />}
                small={false}
                noBorder
              />
            </td>
            {PERMISSIONS.adminOrders && (
              <>
                <td>
                  <select
                    name="stato_invio_mail"
                    value={filters.stato_invio_mail}
                    className="form-control form-control-sm"
                    onChange={onFilterChange}
                  >
                    <option value=""></option>
                    <option value="in_attesa_invio">In attesa invio</option>
                    <option value="inviata">Inviata</option>
                  </select>
                </td>
                <td></td>
              </>
            )}
            <td className="text-center">
              {totals.totale_numero_serramenti && (
                <span className="badge badge-primary">
                  {" "}
                  {totals.totale_numero_serramenti}
                </span>
              )}
            </td>
            <td></td>
          </tr>
          <tr>
            <Column {...columnProps} field="numero_commessa">
              Numero commessa
            </Column>
            <th className="text-nowrap">Check list</th>
            <th className="text-nowrap">
              Ordini fornitori <br />
              mancanti
            </th>
            <Column {...columnProps} field="riferimento">
              Riferimento
            </Column>
            <Column {...columnProps} field="data">
              Data Commessa
            </Column>
            <Column {...columnProps} field="data_consegna">
              Data approntamento
            </Column>
            <Column {...columnProps} field="data_posa">
              Data posa
            </Column>
            <Column {...columnProps} field="stato">
              Stato
            </Column>
            <Column {...columnProps} field="cliente__nome">
              Nome Cliente
            </Column>
            <Column {...columnProps} field="cliente__tipo_cliente">
              Tipo Cliente
            </Column>
            <Column {...columnProps} field="codice_sistema">
              Codice Sistema
            </Column>
            {PERMISSIONS.viewPricesOnOrders && (
              <>
                <Column {...columnProps} field="listino">
                  Listino
                </Column>
                <Column {...columnProps} field="sconto">
                  Sconto
                </Column>
                <Column {...columnProps} field="netto">
                  Netto
                </Column>
              </>
            )}
            {PERMISSIONS.viewAgenteOnOrders && (
              <Column {...columnProps} field="agente_full_name">
                Agente
              </Column>
            )}
            <th className="text-nowrap">Schedul. Inizio</th>
            <th className="text-nowrap">Schedul. Fine</th>
            {PERMISSIONS.adminOrders && (
              <>
                <Column {...columnProps} field="stato_invio_mail">
                  Stato Mail
                </Column>
                <Column
                  className="text-nowrap"
                  {...columnProps}
                  field="data_invio_mail"
                >
                  Data Invio Mail
                </Column>
              </>
            )}
            <Column {...columnProps} field="numero_serramenti">
              N° Serr.
            </Column>
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody>
          {ordini &&
            ordini.map((ordine) => (
              <OrderRow
                onOpenCheckList={onOpenCheckList}
                onOpenInvioMail={onOpenInvioMail}
                key={ordine.id}
                ordine={ordine}
                onOrderDeleteClick={onOrderDeleteClick}
                onOrerStatoClick={onOrerStatoClick}
              />
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default TableOrders
