import React from 'react'
import Paginator from '../../components/Paginator'

export default function TableAccountsCheck({ accounts, checked, numPages, page, goToPage, toggle }) {
  return (
    <div className='table-responsive'>
      <table className='table table-bordered table-hover'>
        <thead>
          <tr>
            <th>Condividi?</th>
            <th>Nome</th>
            <th>Cognome</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {accounts.map(account => (
            <tr key={account.id} onClick={() => toggle(account)} className='pointer'>
              <td className='text-center'><input
                onChange={() => {}}
                type='checkbox' checked={!!checked[account.id]} /> </td>
              <td>{account.user.first_name}</td>
              <td>{account.user.last_name}</td>
              <td>{account.user.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginator
        numPages={numPages}
        currentPage={page}
        goToPage={goToPage}
      />
    </div>
  )
}
