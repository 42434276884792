import React, { useMemo } from "react"
import { mergeMaterialiServerField } from "../../../apps/MaterialiApp/materialiUtils"

function humanizeCol(colonna) {
  return (
    <>
      {colonna.colonna}
      {colonna.order && (
        <small className="text-muted">
          {" "}
          Ordinemento{" "}
          <span className="text-primary">
            {parseInt(colonna.order) + 1}
            {"°"}
          </span>{" "}
          <span>
            {colonna.direction === "asc" ? "Crescente" : "Decrescente"}
          </span>
        </small>
      )}
      {colonna.label && <small className="text-dark"> ({colonna.label})</small>}
    </>
  )
}

// TODO: Improve props.......
function RigaMateriale({
  materiale,
  setMateriale,
  setShowModalFormMateriale,
  setShowModalDeleteMateriale,
}) {
  const materialeMerged = useMemo(() => mergeMaterialiServerField(materiale), [
    materiale,
  ])

  return (
    <tr>
      <td>{materiale.nome}</td>
      <td>
        {materialeMerged.colonne_list &&
          materialeMerged.colonne_list.map((colonna, key) => (
            <div key={key}>{humanizeCol(colonna)}</div>
          ))}
      </td>
      <td className="text-center">
        {materiale.show_reminder_ordine_fornitore ? (
          <i className="fa fa-check text-success" aria-hidden="true"></i>
        ) : (
          <i className="fa fa-times text-danger" aria-hidden="true"></i>
        )}
      </td>
      <td className="text-left">
        <div className="d-flex">
          <div
            className="pointer ml-2"
            onClick={() => {
              setMateriale(materiale)
              setShowModalFormMateriale(true)
            }}
          >
            <i
              title={`Modifica ${materiale.nome}`}
              className="fas fa-pencil-alt text-warning"
            ></i>
          </div>
          {!materiale.per_schedulazione && (
            <div
              className="pointer ml-2"
              onClick={() => {
                setMateriale(materiale)
                setShowModalDeleteMateriale(true)
              }}
            >
              <i
                title={`Elimina ${materiale.nome}`}
                className="fas fa-trash text-danger"
              ></i>
            </div>
          )}
        </div>
      </td>
    </tr>
  )
}

export default React.memo(RigaMateriale)
