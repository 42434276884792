import qs from 'query-string'
import moment from 'moment'
import { rj, rjPlugin, SUCCESS } from 'react-rocketjump'
import { ajax } from 'rxjs/ajax'
import { map } from 'rxjs/operators'
import rjPlainList from 'react-rocketjump/plugins/plainList'
import rjListInsert from 'react-rocketjump/plugins/listInsert'
import rjListUpdate from 'react-rocketjump/plugins/listUpdate'
import rjListDelete from 'react-rocketjump/plugins/listDelete'
import { CSRF } from '../../../django'

const rjPlainListWithRanges = () =>
  rjPlugin(
    rjListInsert({ path: 'data.list' }),
    rjListUpdate({ path: 'data.list' }),
    rjListDelete({ path: 'data.list' }),
    {
      reducer: (reducer) => (prevState, action) => {
        if (action.type === SUCCESS) {
          return {
            ...prevState,
            pending: false,
            data: {
              list: action.payload.data.results,
              dateRange: action.payload.data.date_range,
            },
          }
        }
        return reducer(prevState, action)
      },
      selectors: () => ({
        getList: (state) =>
          state.root.data === null ? null : state.root.data.list,
        getDateRange: (state) =>
          state.root.data === null ? null : state.root.data.dateRange,
      }),
      computed: {
        error: 'getError',
        loading: 'isLoading',
        list: 'getList',
        dateRange: 'getDateRange',
      },
    }
  )

export const AssenzePersonaState = rj(rjPlainListWithRanges(), {
  name: 'Assenze Persona',
  mutations: {
    addAssenza: {
      effect: (assenza) =>
        ajax({
          method: 'post',
          url: `/api/planner/assenze/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(assenza),
        }).pipe(map((r) => r.response)),
      updater: 'insertItem',
    },
    updateAssenza: {
      effect: (assenza) =>
        ajax({
          method: 'put',
          url: `/api/planner/assenze/${assenza.id}/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(assenza),
        }).pipe(map((r) => r.response)),
      updater: 'updateItem',
    },
    deleteAssenza: rj.mutation.single({
      effect: (assenza) =>
        ajax({
          method: 'delete',
          url: `/api/planner/assenze/${assenza.id}/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
        }).pipe(map(() => assenza)),
      updater: 'deleteItem',
    }),
  },
  computed: {
    deleting: (s) => s.mutations.deleteAssenza.pending,
  },
  effect: (id, params = {}) =>
    ajax.getJSON(
      `/api/planner/personale/${id}/assenze?${qs.stringify(params)}`
    ),
})

export const AssenzePersoneState = rj(rjPlainList(), {
  name: 'Assenze Persone',
  mutations: {
    addAssenza: {
      effect: (assenza) =>
        ajax({
          method: 'post',
          url: `/api/planner/assenze/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(assenza),
        }).pipe(map((r) => r.response)),
      updater: 'insertItem',
    },
    updateAssenza: {
      effect: (assenza) =>
        ajax({
          method: 'put',
          url: `/api/planner/assenze/${assenza.id}/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(assenza),
        }).pipe(map((r) => r.response)),
      updater: 'updateItem',
    },
    deleteAssenza: rj.mutation.single({
      effect: (assenza) =>
        ajax({
          method: 'delete',
          url: `/api/planner/assenze/${assenza.id}/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
        }).pipe(map(() => assenza)),
      updater: 'deleteItem',
    }),
  },
  computed: {
    list: 'getList',
    loading: 'isLoading',
    error: 'getError',
    deleting: (s) => s.mutations.deleteAssenza.pending,
  },
  effect: (params = {}) =>
    ajax.getJSON(`/api/planner/assenze?${qs.stringify(params)}`),
})

const dayIterator = (start, end, cb) => {
  const mStart = moment(start)
  const mEnd = moment(end)
  let day = mStart.startOf('day')
  while (day.isSameOrBefore(mEnd, 'date')) {
    cb(day)
    day = day.add(1, 'day')
  }
}

export const FestivitaState = rj(rjPlainList(), {
  name: 'Festivita',
  selectors: () => ({
    getFilledDay: (state) => {
      if (state.root.data === null) {
        return {}
      }
      return state.root.data.reduce((all, festa) => {
        // la festa
        dayIterator(festa.giorno_da, festa.giorno_a, (day) => {
          const dayStr = day.format('YYYY-MM-DD')
          all[dayStr] = true
          return all[dayStr]
        })
        return all
      }, {})
    },
  }),
  mutations: {
    addFestivita: {
      effect: (festa) =>
        ajax({
          method: 'post',
          url: `/api/planner/festivita/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(festa),
        }).pipe(map((r) => r.response)),
      updater: 'insertItem',
    },
    updateFestivita: {
      effect: (festa) =>
        ajax({
          method: 'put',
          url: `/api/planner/festivita/${festa.id}/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(festa),
        }).pipe(map((r) => r.response)),
      updater: 'updateItem',
    },
    deleteFestivita: rj.mutation.single({
      effect: (festa) =>
        ajax({
          method: 'delete',
          url: `/api/planner/festivita/${festa.id}/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
        }).pipe(map(() => festa)),
      updater: 'deleteItem',
    }),
  },
  computed: {
    loading: 'isLoading',
    error: 'getError',
    festivita: 'getData',
    filledDay: 'getFilledDay',
    deleting: (s) => s.mutations.deleteFestivita.pending,
  },
  effect: (params = {}) =>
    ajax.getJSON(`/api/planner/festivita/?${qs.stringify(params)}`),
})
