import React from 'react'
import { Link } from 'react-router-dom'
import './FolderRow.css'

function FolderRow({
  folder,
  location,
  onEditClick,
  onDeleteClick,
  showMeta = true,
  showSharedCount = true,
  colProps,
}) {
  return (
    <tr key={folder.id} className="FolderRow">
      <td {...colProps}>
        <Link
          to={{
            pathname: `/folders/${folder.id}`,
            state: {
              prevFolders: location,
            },
          }}
        >
          <div className="d-flex justify-content-between FolderRowContent">
            <div>
              <div>
                <i className="fa fa-folder mr-2" aria-hidden="true"></i>
                {folder.name}
              </div>
              <div className="text-muted">
                <small style={{ whiteSpace: 'pre-wrap' }}>
                  {folder.description}
                </small>
              </div>
            </div>
            <div className="d-flex">
              {showMeta && <div className="d-flex text-muted mr-5">
                {showSharedCount && <small
                  className="mr-2"
                  title={`Cartella condivisa con ${folder.shared_accounts_count} account.`}
                >
                  <i className="fa fa-users mr-1" aria-hidden="true"></i>
                  {folder.shared_accounts_count}
                </small>}
                <small
                  title={`${folder.shared_files_count} files in questa cartella.`}
                >
                  <i className="fa fa-files-o mr-1" aria-hidden="true"></i>
                  {folder.shared_files_count}
                </small>
              </div>}
              <div>
                {onEditClick && (
                  <i
                    onClick={(e) => onEditClick(folder, e)}
                    className="fa fa-edit text-primary mr-3"
                    aria-hidden="true"
                  ></i>
                )}
                {onDeleteClick && (
                  <i
                    onClick={(e) => onDeleteClick(folder, e)}
                    className="fa fa-trash text-danger mr-1"
                    aria-hidden="true"
                  ></i>
                )}
              </div>
            </div>
          </div>
        </Link>
      </td>
    </tr>
  )
}

export default React.memo(FolderRow)
