import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ModalDeletePersona = ({ isOpen, toggle, onSetEliminato, deleting, materiale }) => {
  return(
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}>Elimina materiale: {materiale && materiale.nome}</ModalHeader>
      <ModalBody>
        {materiale &&
        <div className='container'>
          Sei sicuro di voler eliminare il materiale: {materiale.nome}?
        </div>
        }
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between'>
        <Button color="secondary" onClick={toggle}>Chiudi</Button>
        <Button color="danger" disabled={deleting} onClick={onSetEliminato}>Elimina</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalDeletePersona
