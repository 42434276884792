import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Formik, Field } from 'formik'
import { InputField } from '../../Form/Fields'
import * as Yup from 'yup'

const VincoloSchema = Yup.object().shape({
  altezza_min: Yup.number().required(),
  altezza_max: Yup.number().when('altezza_min', (altezza_min, schema) => {
    return schema.min(altezza_min,'L\'altezza massima deve essere maggiore dell\'altezza minima')
  }).required(),
  larghezza_min: Yup.number().required(),
  larghezza_max: Yup.number().when('larghezza_min', (larghezza_min, schema) => {
    return schema.min(larghezza_min,'La larghezza massima deve essere maggiore della larghezza minima')
  }).required(),
})

const ModalVincolo = ({ isOpen, toggle, famiglia, tipoSerramento, vincolo, save, onSave }) => {

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}>Vincolo - {famiglia && famiglia.nome+' - '}{tipoSerramento && tipoSerramento.nome}</ModalHeader>
        <Formik
          initialValues={vincolo ? vincolo : {
            altezza_min: '',
            altezza_max: '',
            larghezza_max: '',
            larghezza_min: ''
          }}
          validationSchema={VincoloSchema}
          onSubmit={(values, actions) => {
            return save(values).then(
              (data) => {
                onSave(data)
                actions.setSubmitting(false)
              },
              error => {
                actions.setSubmitting(false)
              }
            )
          }}
          render={({ handleSubmit, values, isSubmitting, errors }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <Field
                  label='Altezza min'
                  type='number'
                  name='altezza_min'
                  addonText='mm'
                  rowLayout={true}
                  component={InputField}
                />
                <Field
                  label='Altezza max'
                  type='number'
                  rowLayout={true}
                  addonText='mm'
                  name='altezza_max'
                  component={InputField}
                />
                <Field
                  label='Larghezza min'
                  type='number'
                  rowLayout={true}
                  addonText='mm'
                  name='larghezza_min'
                  component={InputField}
                />
                <Field
                  label='Larghezza max'
                  type='number'
                  addonText='mm'
                  name='larghezza_max'
                  rowLayout={true}
                  component={InputField}
                />
              </ModalBody>

              <ModalFooter className='d-flex justify-content-between'>
                <Button color="secondary" onClick={toggle}>Chiudi</Button>
                <Button type='submit' disabled={isSubmitting} color="success">Salva</Button>
              </ModalFooter>
            </form>
          )}
        />
    </Modal>
  )
}

export default ModalVincolo
