import React from 'react'
import { ReadOnlyInput } from '../../Form/Fields'
import { PERMISSIONS } from '../../../django'
import RowExtraFields from '../RowExtraFields'
import RigaOrdineImmagini from '../RigaOrdineImmagini'

const PricedCols = ({ riga }) => (
  <>
    <div className="col-md-2">
      <ReadOnlyInput label={'Listino'} value={riga.listino} />
    </div>
    <div className="col-md-2">
      <ReadOnlyInput label={'Sconto'} value={riga.sconto_articolo} />
    </div>
    <div className="col-md-2">
      <ReadOnlyInput label={'Netto'} value={riga.netto} />
    </div>
  </>
)

function ReadOnlyOrderRow({ riga, index, fetchOrder, order }) {
  return (
    <div className="card mt-2 position-relative">
      <div className="card-header font-weight-bold text-center">
        Riga ordine {index + 1}
      </div>
      <div className="row pl-3 pr-3 mt-2">
        <div className="col-md-2">
          <ReadOnlyInput
            label="Tipo serramento"
            value={riga.tipo_serramento.name}
            className="mb-2"
          />
        </div>
        <div className="col-md-2">
          <ReadOnlyInput
            label="Codice serramento"
            value={`${riga.codice_serramento.categoria} ${riga.codice_serramento.forma} ${riga.codice_serramento.codice}`}
            className="mb-2"
          />
        </div>
        <div className="col-md-2">
          <ReadOnlyInput label={'Numero'} value={riga.numero} />
        </div>
        {PERMISSIONS.viewPricesOnOrders && <PricedCols riga={riga} />}
      </div>

      <div className="row pl-3 pr-3 pb-3">
        <div className="col-md-2">
          <ReadOnlyInput label={'Altezza'} value={riga.altezza} />
        </div>
        <div className="col-md-2">
          <ReadOnlyInput label={'Larghezza'} value={riga.larghezza} />
        </div>
        <div className="col-md-2">
          <ReadOnlyInput label={'Ante'} value={riga.ante} />
        </div>
        <div className="col-md-2">
          <ReadOnlyInput label={'Forma'} value={riga.forma} />
        </div>
      </div>

      <div className="row pl-3 pr-3 pb-3">
        <div className="col-md-4">
          <ReadOnlyInput
            label={'Descrizione'}
            type="textarea"
            rows={4}
            value={riga.descrizione}
          />
        </div>
      </div>
      <RigaOrdineImmagini riga={riga} fetchOrder={fetchOrder} order={order} />
      <div className="d-flex justify-content-center align-items-center">
        {riga.extra_fields && (
          <RowExtraFields extraFields={riga.extra_fields} />
        )}
      </div>
    </div>
  )
}

export default React.memo(ReadOnlyOrderRow)
