import React, { useState, useCallback } from "react"
import { ClientiState, OrdersDomainState } from "./localstate"
import Paginator from "../../components/Paginator"
import { useQsDataTable as useDataTable } from "react-use-datatable/react"
import CircularProgress from "@material-ui/core/CircularProgress"
import { Button } from "reactstrap"
import ModalFormCliente from "../../components/Planner/ModalFormCliente"
import ModalDeleteCliente from "../../components/Planner/ModalDeleteCliente"
import { TableClienti } from "../../components/Planner/TableClienti/TableClienti"
import { useRunRj } from "react-rocketjump"
import { AgentiState } from "../AdminPreventiviApp/localstate"

export default function Clienti() {
  const [
    // Data State
    { clienti, pagination, loading, deleting },
    // Table state
    { page, ...filters },
    {
      // DT Actions
      goToPage,
      onFilterChangeDebounced,
      onFilterChange,
      deleteCliente,
      addCliente,
      updateCliente,
    },
  ] = useDataTable(ClientiState, () => ({
    search: "",
    ordering: "",
    tipo_cliente: "",
  }))

  const [{ data: agenti }] = useRunRj(AgentiState)

  const [{ data: ordersDomain }] = useRunRj(OrdersDomainState)

  const [selectedCliente, setCliente] = useState(null)

  const [showModalDeleteCliente, setShowModalDeleteCliente] = useState(false)
  const toggleShowModalDelete = useCallback(() => {
    setShowModalDeleteCliente((show) => !show)
  }, [])

  const [showModalFormCliente, setShowModalFormCliente] = useState(false)
  const toggleShowModalCliente = useCallback(() => {
    setCliente(null)
    setShowModalFormCliente((show) => !show)
  }, [])

  return (
    <div className="container-fluid">
      {clienti === null && loading && (
        <div className="text-center mt-4">
          <CircularProgress color="primary" />
        </div>
      )}
      {clienti && (
        <>
          <div className="d-flex justify-content-between">
            <div>
              <div>
                <input
                  name="search"
                  type="text"
                  className="form-control form-control-sm mt-2 mr-2"
                  placeholder={"Ricerca libera"}
                  value={filters && filters.search}
                  onChange={onFilterChangeDebounced}
                />
              </div>
            </div>
            <div className="text-right">
              <Button
                onClick={() => toggleShowModalCliente()}
                size="sm"
                className="mt-2"
                outline
                color="secondary"
              >
                <i className="fas fa-plus-circle"></i> Aggiungi cliente
              </Button>
            </div>
          </div>
          <TableClienti
            clienti={clienti}
            agenti={agenti}
            loading={loading}
            ordersDomain={ordersDomain && ordersDomain}
            filters={filters}
            onFilterChange={onFilterChange}
            setCliente={setCliente}
            setShowModalDeleteCliente={setShowModalDeleteCliente}
            setShowModalFormCliente={setShowModalFormCliente}
          />
          <Paginator
            className=""
            numPages={pagination.numPages}
            currentPage={page}
            goToPage={goToPage}
          />
          <ModalFormCliente
            isOpen={showModalFormCliente}
            toggle={toggleShowModalCliente}
            agenti={agenti || []}
            cliente={selectedCliente}
            save={(cliente) => {
              if (selectedCliente) {
                return updateCliente
                  .onSuccess(() => {
                    setCliente(null)
                    toggleShowModalCliente()
                  })
                  .asPromise(cliente)
              } else {
                return addCliente
                  .onSuccess(toggleShowModalCliente)
                  .asPromise(cliente)
              }
            }}
          />
          <ModalDeleteCliente
            toggle={toggleShowModalDelete}
            isOpen={showModalDeleteCliente}
            cliente={selectedCliente}
            deleting={deleting}
            onSetEliminato={() => {
              deleteCliente
                .onSuccess(() => {
                  toggleShowModalDelete()
                })
                .run(selectedCliente)
            }}
          />
        </>
      )}
    </div>
  )
}
