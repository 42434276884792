import React, { useMemo, useRef, useState } from "react"
import Select from "react-select"
import { map } from "rxjs/operators"
import { ajax } from "rxjs/ajax"
import { useAsyncPaginate, useComponents } from "react-select-async-paginate"
import find from "lodash/find"
import { deps, rj, useRunRj } from "react-rocketjump"
import { FormGroup, Label } from "reactstrap"

function clientiAutocomplteApi(q = "", page = 1, id = "") {
  return ajax.getJSON(
    `/api/planner/clienti-autocomplete/?search=${q}&page=${page}&id=${id}`
  )
}

const InitalClienteState = rj({
  name: "InitalClienteAtucomplete",
  effect: (id) =>
    clientiAutocomplteApi("", 1, id).pipe(
      map((data) => makeOptions(data.results))
    ),
})

function makeOptions(list) {
  return list
    ? list.map((item) => ({
        ...item,
        label: item.codice ? item.nome + " - " + item.codice : item.nome,
        value: item.id,
      }))
    : []
}

function loadClientiOptions(inputValue, loadedOptions, options) {
  const { page = 1 } = options
  return clientiAutocomplteApi(inputValue, page)
    .pipe(
      map((data) => ({
        ...data,
        page,
      }))
    )
    .toPromise()
    .then((data) => ({
      options: makeOptions(data.results),
      hasMore: data.next !== null,
      additional: {
        page: data.page + 1,
      },
    }))
}

const ClientiSelectAutoComplete = ({
  loadOptions = loadClientiOptions,
  options,
  defaultOptions,
  additional,
  defaultAdditional,
  loadOptionsOnMenuOpen,
  debounceTimeout,
  reduceOptions,
  shouldLoadMore,
  filterOption,
  inputValue,
  menuIsOpen,
  defaultInputValue,
  defaultMenuIsOpen,
  onInputChange,
  onMenuClose,
  onMenuOpen,
  required,
  components: defaultComponents,
  label,
  value: valueId,
  onChange,
  ...props
}) => {
  const initialValueRef = useRef(valueId)
  const initialValue = initialValueRef.current
  const [{ data: initialOptions, pending: initialLoading }] = useRunRj(
    InitalClienteState,
    [deps.maybe(initialValue)]
  )


  const asyncPaginateProps = useAsyncPaginate({
    loadOptions,
    options,
    defaultOptions,
    additional,
    defaultAdditional,
    loadOptionsOnMenuOpen,
    debounceTimeout,
    reduceOptions,
    shouldLoadMore,
    filterOption,
    inputValue,
    menuIsOpen,
    defaultInputValue,
    defaultMenuIsOpen,
    onInputChange,
    onMenuClose,
    onMenuOpen,
    required,
  })

  const components = useComponents(defaultComponents)
  const [lastOptions, setLastOptions] = useState([])

  const dataOptions = asyncPaginateProps.options
  const value = useMemo(() => {
    if (!valueId) {
      return null
    }
    let myClienteValue = null
    if (dataOptions) {
      myClienteValue = find(dataOptions, { id: +valueId })
    }
    if (!myClienteValue && initialOptions) {
      myClienteValue = find(initialOptions, { id: +valueId })
    }
    if (!myClienteValue && lastOptions) {
      myClienteValue = find(lastOptions, { id: +valueId })
    }
    return myClienteValue ?? null
  }, [valueId, dataOptions, initialOptions, lastOptions])

  const requiredStar = required ? (
    <strong
      style={{
        paddingRight: 5,
      }}
    >
      *
    </strong>
  ) : undefined

  return (
    <FormGroup>
      {label && (
        <Label className="text-right">
          {label}
          {requiredStar}
        </Label>
      )}
      <div>
        <Select
          {...props}
          {...asyncPaginateProps}
          isLoading={asyncPaginateProps.isLoading || initialLoading}
          components={components}
          value={value}
          onChange={(cliente) => {
            // FIXME: FIXME: FIXME: FIXME: Workaround to avoid
            // out of pagination bug .....
            if (cliente) {
              setLastOptions(dataOptions)
              onChange(cliente.id)
            } else {
              onChange("")
            }
          }}
        />
      </div>
    </FormGroup>
  )
}

export default ClientiSelectAutoComplete
