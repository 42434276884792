import React, { useState, useContext, useCallback } from 'react'
import { Button } from 'reactstrap'
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { UserAdminedContext } from './UserRoot'
import UserForm from '../../components/Users/UserForm'
import ModalDeleteConfirm from '../../components/Users/ModalDeleteConfirm'
import OperatorForm from '../../components/Users/OperatorForm'
import AgenteForm from '../../components/Users/AgenteForm'
import AccountForm from '../../components/Users/AccountForm'
import AppAccessoClienti from '../../components/Users/AppAccessoClienti'

export default function UserDetail({ history }) {
  const [
    {
      user,
      updatingAccessoCliente,
      deletingOperatore,
      deletingAccount,
      deletingAgente,
      deletingUser,
    },
    {
      updateUser,
      createOperator,
      updateOperator,
      createAgente,
      updateAgente,
      updateAccount,
      createAccount,
      deleteAgente,
      deleteAccount,
      deleteOperatore,
      deleteUser,
      updateAppAccessoClienti,
    },
  ] = useContext(UserAdminedContext)

  const [activeTab, setActiveTab] = useState('Operatore')
  const [showOperatorForm, setOperatorForm] = useState(false)
  const [showAgenteForm, setAgenteForm] = useState(false)
  const [showAccountForm, setAccountForm] = useState(false)

  const [deleteModal, setDeleteModal] = useState(null)
  const toggleDelete = useCallback(() => {
    setDeleteModal(null)
  }, [])

  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <div className="card mt-2">
            <div className="card-header">
              <div className="d-flex justify-content-between">
                <div>
                  <Button size="sm" outline to="/" tag={Link}>
                    <i className="fa fa-list mr-2" aria-hidden="true"></i>
                    Lista utenti
                  </Button>
                  {user && (
                    <Button
                      className="ml-3"
                      size="sm"
                      outline
                      tag={Link}
                      to={`${user.id}/change-password`}
                    >
                      Cambia password
                    </Button>
                  )}
                </div>
                {user && (
                  <Button
                    onClick={() => {
                      setDeleteModal({
                        title: `${user.username}`,
                        instance: user,
                        deleteInstance: (user) =>
                          new Promise((resolve, reject) => {
                            deleteUser
                              .onSuccess(() => {
                                history.push('/')
                              })
                              .onFailure(reject)
                              .run(user)
                          }),
                      })
                    }}
                    className="ml-3"
                    size="sm"
                    color="danger"
                  >
                    <i className="fa fa-trash mr-2" aria-hidden="true"></i>
                    Elimina Utente
                  </Button>
                )}
              </div>
            </div>
            <div className="card-body">
              {user && (
                <UserForm
                  save={(user) => updateUser.asPromise(user)}
                  user={user}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card mt-2">
            <div className="card-header">Collega accounts</div>
            <div className="card-body">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames('pointer', {
                      active: activeTab === 'Operatore',
                    })}
                    onClick={() => {
                      toggleTabs('Operatore')
                    }}
                  >
                    Operatore
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames('pointer', {
                      active: activeTab === 'App',
                    })}
                    onClick={() => {
                      toggleTabs('App')
                    }}
                  >
                    App Account
                  </NavLink>
                </NavItem>
                {user && user.app_account && (
                  <NavItem>
                    <NavLink
                      className={classnames('pointer', {
                        active: activeTab === 'AppClienti',
                      })}
                      onClick={() => {
                        toggleTabs('AppClienti')
                      }}
                    >
                      App Accesso Clienti
                    </NavLink>
                  </NavItem>
                )}
                <NavItem>
                  <NavLink
                    className={classnames('pointer', {
                      active: activeTab === 'Agente',
                    })}
                    onClick={() => {
                      toggleTabs('Agente')
                    }}
                  >
                    Agente
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="Operatore">
                  <Row>
                    <Col sm="12" className="mt-3">
                      {user && (
                        <div>
                          <div className="alert alert-primary">
                            <i
                              className="fa fa-info-circle mr-2"
                              aria-hidden="true"
                            ></i>
                            L'Accesso Operatore abilita l'accesso al sito.
                            {!user.operatore && !showOperatorForm && (
                              <div className="mt-1">
                                <b>
                                  Non hai collegato nessun accesso operatore.
                                </b>
                              </div>
                            )}
                            {!user.operatore && showOperatorForm && (
                              <div className="mt-1">
                                <b>
                                  Stai colleganto un nuovo accesso operatore.
                                </b>
                              </div>
                            )}
                          </div>
                          {!user.operatore && !showOperatorForm ? (
                            <div>
                              <Button
                                color="primary"
                                outline
                                onClick={() => setOperatorForm(true)}
                              >
                                Collega operatore
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <OperatorForm
                                user={user}
                                onDelete={() =>
                                  setDeleteModal({
                                    title: 'Operatore',
                                    deleteInstance: deleteOperatore.asPromise.bind(
                                      deleteOperatore
                                    ),
                                    instance: user.operatore,
                                  })
                                }
                                setOperatorForm={setOperatorForm}
                                save={(operatore) =>
                                  user.operatore
                                    ? updateOperator.asPromise(operatore)
                                    : createOperator
                                        .onSuccess(() => setOperatorForm(false))
                                        .asPromise(operatore)
                                }
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="App">
                  <Row>
                    <Col sm="12" className="mt-3">
                      <div>
                        {user && (
                          <div>
                            <div className="alert alert-primary">
                              <i
                                className="fa fa-info-circle mr-2"
                                aria-hidden="true"
                              ></i>
                              L'App Account abilita l'accesso al App QrLegno.
                              {!user.app_account && showAccountForm && (
                                <div className="pt-1">
                                  <b>Stai collegando un nuovo App Account.</b>
                                </div>
                              )}
                              {!user.app_account && !showAccountForm && (
                                <div className="pt-1">
                                  <b>App Account non collegato.</b>
                                </div>
                              )}
                            </div>
                            {!user.app_account && !showAccountForm ? (
                              <div>
                                <Button
                                  color="primary"
                                  outline
                                  onClick={() => setAccountForm(true)}
                                >
                                  Collega App Account
                                </Button>
                              </div>
                            ) : (
                              <div>
                                <AccountForm
                                  user={user}
                                  setAccountForm={setAccountForm}
                                  onDelete={() =>
                                    setDeleteModal({
                                      title: 'App Account',
                                      instance: user.app_account,
                                      deleteInstance: deleteAccount.asPromise.bind(
                                        deleteAccount
                                      ),
                                    })
                                  }
                                  save={(account) =>
                                    user.app_account
                                      ? updateAccount.asPromise(account)
                                      : createAccount
                                          .onSuccess(() =>
                                            setAccountForm(false)
                                          )
                                          .asPromise(account)
                                  }
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                {user && user.app_account && (
                  <TabPane tabId="AppClienti">
                    <Row>
                      <Col sm="12" className="mt-3">
                        <AppAccessoClienti
                          save={updateAppAccessoClienti}
                          user={user}
                          saving={updatingAccessoCliente}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                )}
                <TabPane tabId="Agente">
                  <Row>
                    <Col sm="12" className="mt-3">
                      <div>
                        {user && (
                          <div>
                            <div className="alert alert-primary">
                              <i
                                className="fa fa-info-circle mr-2"
                                aria-hidden="true"
                              ></i>
                              L'accesso Agente consente l'accesso al sito, la
                              visualizzione in sola lettura di Ordini e
                              Preventivi assegnati all'agente stesso ed ai
                              propri sotto aganti nel caso di un capo area.
                              <br />
                              Nel caso un utente abbia associato anche un App
                              Account l'accesso agente permetterà di vedere gli
                              ordini relativi all'interno dell'App QrLegno.
                              {!user.agente && !showAgenteForm && (
                                <div className="pt-1">
                                  <b>Non hai collegato un accesso Agente.</b>
                                </div>
                              )}
                              {!user.agente && showAgenteForm && (
                                <div className="pt-1">
                                  <b>
                                    Stai associando un nuovo accesso Agente.
                                  </b>
                                </div>
                              )}
                            </div>
                            {!user.agente && !showAgenteForm ? (
                              <div>
                                <Button
                                  color="primary"
                                  outline
                                  onClick={() => setAgenteForm(true)}
                                >
                                  Collega Agente
                                </Button>
                              </div>
                            ) : (
                              <div>
                                <AgenteForm
                                  user={user}
                                  setAgenteForm={setAgenteForm}
                                  onDelete={() =>
                                    setDeleteModal({
                                      title: 'Accesso Agente',
                                      instance: user.agente,
                                      deleteInstance: deleteAgente.asPromise.bind(
                                        deleteAgente
                                      ),
                                    })
                                  }
                                  save={(agente) =>
                                    user.agente
                                      ? updateAgente.asPromise(agente)
                                      : createAgente
                                          .onSuccess(() => setAgenteForm(false))
                                          .asPromise(agente)
                                  }
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
      <ModalDeleteConfirm
        toggle={toggleDelete}
        isOpen={deleteModal !== null}
        title={deleteModal ? deleteModal.title : null}
        deleting={
          deletingOperatore || deletingAccount || deletingAgente || deletingUser
        }
        onDelete={() => {
          deleteModal
            .deleteInstance(deleteModal.instance)
            .then(toggleDelete, console.error)
        }}
      />
    </div>
  )
}
