import React from 'react'
import classNames from 'classnames'

const DetailMisuraSerramento = ({ serramento, numeroSerramento }) => {
  return(
    <div className='card p-2 mt-2'  style={{ minHeight:245}}>
      <h5 className='font-weight-bold text-center text-primary'><span className="badge badge-primary">{numeroSerramento}</span> - {serramento.tipo_serramento.nome}</h5>
      <div>
        <div className='mb-1'>
          <div className='border-bottom pt-1 pb-1'>
            Tipo apertura: <strong>{serramento.tipo_apertura.nome}</strong>
          </div>
          <div className='border-bottom pt-1 pb-1'>
            Quantita: <strong>{serramento.quantita}</strong>
          </div>
          <div className='border-bottom pt-1 pb-1'>
            Altezza: <strong>{serramento.altezza} mm</strong>
          </div>
          <div className={classNames('pt-1 pb-1',
            {
              'border-bottom': serramento.accessori.length > 0
            }
          )}>
            Larghezza: <strong>{serramento.larghezza} mm</strong>
          </div>
          {serramento.accessori.length > 0 &&
            <div className='pt-1 pb-1'>
              Accessori:{' '}
              {serramento.accessori.map((accessorio,key) => (
                <span key={key}>
                  <strong>{accessorio.nome}</strong>{' - '}
                </span>
              ))}
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default DetailMisuraSerramento
