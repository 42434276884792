import React, { useContext } from 'react'
import * as Yup from 'yup'
import { Button } from 'reactstrap'
import { Formik, Field } from 'formik'
import { Link } from 'react-router-dom'
import { InputField } from '../../components/Form/Fields'
import { transformErrorForForm } from '../../components/Form/Fields/utils'
import { UserAdminedContext } from './UserRoot'

const PasswordSchema = Yup.object().shape({
  password: Yup.string().required(),
  confirm_password: Yup.string()
    .required()
    .oneOf(
      [Yup.ref('password'), null],
      'Le due password inserite non sono uguali'
    ),
})

export default function ChangePassword({ match }) {
  const {
    params: { id },
  } = match
  const [{ user }, { updatePassword }] = useContext(UserAdminedContext)

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="offset-md-3 col-md-6">
          <div className="card mt-2">
            <div className="card-header">
              <div className="d-flex">
                <div>
                  <Button size="sm" outline to={`/${id}`} tag={Link}>
                    <i className="fa fa-search mr-2" aria-hidden="true"></i>
                    Dettaglio utente
                  </Button>
                </div>
                {user && (
                  <div className="ml-5 text-center mt-1">
                    Cambia password: {user.username}
                    {user.first_name || user.last_name
                      ? ` (${user.first_name || ''} ${user.last_name || ''})`
                      : null}
                  </div>
                )}
              </div>
            </div>
            <div className="card-body">
              <Formik
                onSubmit={(values, actions) => {
                  actions.setStatus({ pwChanged: false })
                  updatePassword
                    .onSuccess(() => {
                      actions.setSubmitting(false)
                      actions.setStatus({ pwChanged: true })
                    })
                    .onFailure((error) => {
                      actions.setSubmitting(false)
                      actions.setErrors(transformErrorForForm(error))
                    })
                    .run(user.id, values.password)
                }}
                initialValues={{
                  password: '',
                  confirm_password: '',
                }}
                validationSchema={PasswordSchema}
              >
                {({ handleSubmit, isSubmitting, status }) => (
                  <form onSubmit={handleSubmit} className="p-0 m-0">
                    <Field
                      autoComplete="off"
                      component={InputField}
                      label="Password *"
                      name="password"
                      type={'password'}
                    />
                    <Field
                      autoComplete="off"
                      component={InputField}
                      label="Conferma Password *"
                      name="confirm_password"
                      type="password"
                    />
                    <div className="mt-4 d-flex justify-content-end">
                      <Button
                        disabled={isSubmitting}
                        color="success"
                        type="submit"
                      >
                        Salva
                      </Button>
                    </div>
                    {status && status.pwChanged && (
                      <div className="alert alert-success mt-2">
                        Password cambiata con successo
                      </div>
                    )}
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
