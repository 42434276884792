import React, { useContext } from 'react'
import classNames from 'classnames'
import { Field, connect } from 'formik'
import { InputField, CheckboxField } from '../../Fields'
import { Button } from 'reactstrap'
import province from '../../../../data/province.json'
import { useIsDesktop, useScrollOnTop } from '../../../../helpers'
import ErrorsPreventivo from './ErrorsPreventivo'
import { ConfigPrevetiviContext } from '../../../../context'


function copyValuesAddress(values,setFieldValue) {
  setFieldValue('indirizzo_posa', values.indirizzo)
  setFieldValue('citta_posa', values.citta)
  setFieldValue('provincia_posa', values.provincia)
}

function Step3({ setStep, formik }) {

  useScrollOnTop()

  const { pianiPosa } = useContext(ConfigPrevetiviContext)
  const isDesktop = useIsDesktop()

  const { values, setFieldValue, errors, isSubmitting, isValid, setFieldError } = formik

  return (
    <div className={classNames('Step3 mt-4',{
      'p-mobile-bottom' : !isDesktop
    })}>
      <div className='container'>
        <h4 className='text-left text-primary'>Riferimenti</h4>
        <div className='row'>
          <div className='col-md-4 text-left'>
            <h6 className='mt-1'>Dati anagrafici</h6>
            <Field
              label='Nome e cognome *'
              type='text'
              name='nome_cognome'
              component={InputField}
              className='mb-2'
            />
            <Field
              label='Numero di telefono *'
              type='text'
              name='telefono'
              className='mb-2'
              component={InputField}
            />
            <Field
              label='Email *'
              type='email'
              name='email'
              component={InputField}
              className='mb-2'
            />
            <Field
              label='Indirizzo'
              type='text'
              name='indirizzo'
              component={InputField}
              className='mb-2'
            />
            <Field
              label='Città *'
              type='text'
              name='citta'
              component={InputField}
              className='mb-2'
            />
            <Field
              component={InputField}
              label='Provincia *'
              name='provincia'
              className='mb-2'
              type='select'
            >
              <option value=''></option>
              {province.map((provincia, i) => (
                <option key={i} value={provincia.nome}>{provincia.nome}</option>
              ))}
            </Field>
          </div>
          <div className='col-md-4 mt-2 text-left'>
            <div className='d-flex'>
              <h6 className='mt-1'>Luogo di installazione serramenti</h6>
              <Button disabled={!values.messa_in_posa} size='md' className='ml-3 d-none d-md-block' color='secondary' onClick={() => copyValuesAddress(values,setFieldValue)}>Idem (copia dati)</Button>
              <Button disabled={!values.messa_in_posa} size='sm' className='ml-3 d-block d-md-none' color='secondary' onClick={() => copyValuesAddress(values,setFieldValue)}>Idem (copia dati)</Button>
            </div>
            <Field
              label='Indirizzo'
              type='text'
              name='indirizzo_posa'
              component={InputField}
              disabled={!values.messa_in_posa}
              className='mb-2'
            />
            <Field
              type='text'
              disabled={!values.messa_in_posa}
              label={values.messa_in_posa ? 'Città *' : 'Città'}
              name='citta_posa'
              component={InputField}
              className='mb-2'
            />
            <Field
              component={InputField}
              label={values.messa_in_posa ? 'Provincia di posa *' : 'Provincia di posa'}
              disabled={!values.messa_in_posa}
              name='provincia_posa'
              type='select'
              className='mb-2'
            >
              <option value=''></option>
              {province.map((provincia, i) => (
                <option key={i} value={provincia.nome}>{provincia.nome}</option>
              ))}
            </Field>
            <Field
              component={InputField}
              label={values.messa_in_posa ? 'Piano di posa *' : 'Piano di posa'}
              name='piano_posa'
              disabled={!values.messa_in_posa}
              type='select'
              className='mb-2'
            >
              <option value=''></option>
              {pianiPosa.map((pianoPosa, i) => (
                <option key={i} value={pianoPosa[0]}>{pianoPosa[1]}</option>
              ))}
            </Field>
            <Field
              label='Note'
              type='textarea'
              name='note'
              component={InputField}
              className='mb-2'
            />
            <div className='form-group d-flex mt-1'>
            <Field
              name='privacy'
              component={CheckboxField}
              type='checkbox'
              id='privacy'
              label={"Ho letto e accetto l'informativa relativa al Trattamento dei Dati Personali ai sensi del Regolamento UE 2016/679 artt. 13 e 14"}
            />
          </div>
          </div>
          <div className='col-md-4 mt-2 text-left'>
            <ErrorsPreventivo errors={errors} values={values} setFieldError={setFieldError} />
            <Button className='d-none d-md-block' size='lg' disabled={isSubmitting || !isValid} color='info' type='submit'>
              {isSubmitting ? 'Inviando i dati...' : 'Invia dati'} <i className="fas fa-chevron-circle-right"></i>
            </Button>
            <div className='text-center'>
              <Button className='d-md-none' size='md' disabled={isSubmitting  || !isValid} color='info' type='submit'>
                {isSubmitting ? 'Inviando i dati...' : 'Invia dati'} <i className="fas fa-chevron-circle-right"></i>
              </Button>
            </div>
          </div>
        </div>
        <div className='mt-2 text-left'>
          <Button className='d-none d-md-block' type='button' color='secondary' onClick={() => setStep(2)}>Step 2 <i className="fas fa-chevron-circle-left"></i><br />Inserimento misure</Button>
        </div>
      </div>
    </div>
  )
}

export default connect(Step3)
