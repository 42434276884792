import React, { useContext, useEffect, useState } from 'react'
import { useRj } from 'react-rocketjump'
import {
  AvanzamentoOrdineState,
  WarningMissingOrdiniFornitori,
  WarningsOrdiniFornitori,
} from './localstate'
import moment from 'moment'
import { sumBy } from 'lodash'
import {
  useQsDataTable as useDataTable,
  useQsDataTable,
} from 'react-use-datatable/react'
import { useParams } from 'react-router-dom'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Field, Formik } from 'formik'
import { transformErrorForForm } from '../../components/Form/Fields/utils'
import { DateField, InputField } from '../../components/Form/Fields'
import { ConfigPlannerContext } from '../../context'

const AVANZAMENTI_TIPO_EVENTO = {
  attivita_operatore: 'Attività operatore',
  arrivo_materiale: 'Arrivo materiale',
  differenza_avanzamento: 'Differenza avanzamento',
}

function getTotaleMateriali(avanzamenti) {
  const totaleLavorazioni = avanzamenti.reduce((acc, av) => {
    if (av.tipo_evento === 'arrivo_materiale') {
      return acc + av.valore_economico
    }
    return acc
  }, 0)
  return totaleLavorazioni
}

export default function AvanzamentoOrdine({ order, match }) {
  const [{ data: avanzamento }, { run: fetchAvanzamento }] = useRj(
    AvanzamentoOrdineState
  )

  const { id } = useParams()

  useEffect(() => {
    if (order) {
      fetchAvanzamento(order.id)
    }
  }, [order, fetchAvanzamento])

  const [
    { list: warnings, pagination },
    { page },
    { updateStatoAndData, run: fetchWarnings },
  ] = useDataTable(WarningsOrdiniFornitori, () => ({
    page: 1,
    ordine: id,
  }))

  const [{ list: warningsMissingOrdiniFornitori }] = useQsDataTable(
    WarningMissingOrdiniFornitori,
    () => ({
      ordine: id,
    })
  )

  const [openModal, setOpenModal] = useState(null)

  const { valoriStato } = useContext(ConfigPlannerContext)
  const stati = valoriStato.ordine_fornitore || []

  return (
    <div className="container-fluid mt-3">
      {warningsMissingOrdiniFornitori &&
        warningsMissingOrdiniFornitori.length > 0 && (
          <div className="row mb-3">
            <div className="col-md-12">
              <div className="alert alert-warning">
                <h4 className="alert-heading">Attenzione!</h4>
                <p>
                  Attenzione mancano degli ordini fornitori per questo ordine
                  per i seguenti materiali:

                  {warningsMissingOrdiniFornitori.map((warning, i) => (
                    <div key={i} className='mt-2 text-capitalize'>
                      <strong>{warning.nome}</strong>
                    </div>
                  ))}
                </p>
              </div>
            </div>
          </div>
        )}

      {warnings && warnings.length > 0 && (
        <div className="row mb-3">
          <div className="col-md-12">
            <div className="alert alert-warning w-100">
              <h4 className="alert-heading">Attenzione!</h4>
              <p>
                Sono presenti delle attività operatore senza che gli ordini
                fornitori dei materiali corrispondenti siano impostati con stato
                "ARRIVATO" e con una data di consegna effettiva mancante.
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Ordine</th>
                  <th>Fornitore</th>
                  <th>Materiale</th>
                  <th>Val. Economico.</th>
                  <th>Stato</th>
                  <th>Data inserimento</th>
                </tr>
              </thead>
              <tbody>
                {warnings?.map((warning, i) => (
                  <tr key={i}>
                    <td>
                      {warning.ordine_data?.numero_commessa} -{' '}
                      {warning.ordine_data?.cliente?.nome}
                    </td>
                    <td>{warning.fornitore_data?.nome}</td>
                    <td>{warning.fornitore_data?.tipologia}</td>
                    <td>{warning.valore_percentuale + '%'}</td>
                    <td>{warning.stato}</td>
                    <td>{warning.data_inserimento}</td>
                    <td>
                      <div
                        onClick={() => {
                          setOpenModal(warning)
                        }}
                        className="pointer"
                      >
                        <i
                          title="Modifica ordine fornitore"
                          className="fas fa-pencil-alt text-warning"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Data</th>
            <th>Tipo Evento</th>
            <th>Lavorazione</th>
            <th>Operatore</th>
            <th>Tempo teo.</th>
            <th>Tempo lav.</th>
            <th>Numero</th>
            <th>Materiale</th>
            <th>Ord. Forn.</th>
            <th>N° Doc</th>
            <th>Valore economico</th>
          </tr>
        </thead>
        <tbody>
          {avanzamento &&
            avanzamento.map((av, i) => (
              <tr
                key={i}
                className={
                  av.tipo_evento === 'arrivo_materiale'
                    ? 'table-info'
                    : av.tipo_evento === 'differenza_avanzamento'
                    ? 'table-warning'
                    : ''
                }
              >
                <td>{moment(av.data).format('DD/MM/YYYY')}</td>
                <td>
                  {AVANZAMENTI_TIPO_EVENTO[av.tipo_evento] ?? av.tipo_evento}
                </td>
                <td>{av.lavorazione_data?.nome}</td>
                <td>
                  {av.operatore_data?.user?.first_name}{' '}
                  {av.operatore_data?.user?.last_name}
                </td>
                <td>
                  {AVANZAMENTI_TIPO_EVENTO[av.tipo_evento] ===
                    'Attività operatore' &&
                    parseInt(moment.duration(av.tempo_teorico).as('minutes')) +
                      ' min'}
                </td>
                <td>
                  {AVANZAMENTI_TIPO_EVENTO[av.tipo_evento] ===
                    'Attività operatore' &&
                    parseInt(
                      moment.duration(av.tempo_lavorazione).as('minutes')
                    ) + ' min'}
                </td>
                <td>
                  {AVANZAMENTI_TIPO_EVENTO[av.tipo_evento] ===
                    'Attività operatore' && (
                    <>
                      {av.numero_pezzi} / {av.numero_pezzi_totali}{' '}
                      {av.lavorazione_data?.attivita_numero === 'numero'
                        ? // ? check if is 1 or bigger than 1
                          ' Telai'
                        : av.lavorazione_data?.attivita_numero === 'n_vetri'
                        ? ' Vetri'
                        : ' Ante'}
                    </>
                  )}
                </td>
                <td className="text-capitalize">{av.materiale_data?.nome}</td>
                <td>{av.ordine_fornitore_data?.fornitore_data?.nome}</td>
                <td>{av.numero_documento}</td>
                <td className="text-right">
                  {av.valore_economico?.toFixed(2)}
                </td>
              </tr>
            ))}
          <tr>
            <td colSpan="10" className="bg-light text-right font-weight-bold">
              Totale tempo lavorato
            </td>
            <td className="bg-light text-right font-weight-bold">
              {sumBy(avanzamento, (av) =>
                parseInt(moment.duration(av.tempo_lavorazione).as('minutes'))
              )}{' '}
              min
            </td>
          </tr>
          <tr>
            <td colSpan="10" className="bg-light text-right font-weight-bold">
              Totale tempo teorico
            </td>
            <td className="bg-light text-right font-weight-bold">
              {sumBy(avanzamento, (av) =>
                parseInt(moment.duration(av.tempo_teorico).as('minutes'))
              )}{' '}
              min
            </td>
          </tr>
          <tr>
            <td colSpan="10" className="bg-light text-right font-weight-bold">
              Rimanenza da netto
            </td>
            <td className="bg-light text-right font-weight-bold">
              {(
                order?.netto - sumBy(avanzamento, 'valore_economico').toFixed(2)
              ).toFixed(2)}
            </td>
          </tr>
          <tr>
            <td colSpan="10" className="bg-light text-right font-weight-bold">
              Totale materiali
            </td>
            <td className="bg-light text-right font-weight-bold">
              {avanzamento && getTotaleMateriali(avanzamento).toFixed(2)}
            </td>
          </tr>
          <tr>
            <td colSpan="10" className="bg-light text-right font-weight-bold">
              Percentuale avanzamento
            </td>
            <td className="bg-light text-right font-weight-bold">
              {(
                (sumBy(avanzamento, 'valore_economico') / order?.netto) *
                100
              ).toFixed(2)}
              %
            </td>
          </tr>
          <tr>
            <td
              colSpan="10"
              className="
            bg-primary text-right text-uppercase font-weight-bold text-white
            "
            >
              Totale
            </td>

            <td className="bg-primary text-right font-weight-bold text-white">
              {sumBy(avanzamento, 'valore_economico').toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
      <Modal
        isOpen={openModal}
        toggle={() => setOpenModal(null)}
        size="md"
        centered
      >
        <ModalHeader toggle={() => setOpenModal(null)}>
          Modifica ordine fornitore {openModal?.fornitore_data?.nome}
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              data_arrivo: openModal?.data_arrivo,
              stato: openModal?.stato,
              valore_percentuale: openModal?.valore_percentuale,
            }}
            onSubmit={(values, actions) => {
              updateStatoAndData
                .onSuccess(() => {
                  actions.setSubmitting(false)
                  setOpenModal(null)
                  fetchWarnings(
                    {
                      ordine: id,
                    },
                    { page: 1 }
                  )
                  fetchAvanzamento(order.id)
                })
                .onFailure((error) => {
                  actions.setSubmitting(false)
                  actions.setErrors(transformErrorForForm(error))
                })
                .run(openModal.id, values)
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  label="Data arrivo"
                  type="text"
                  name="data_arrivo"
                  component={DateField}
                  className="form-control"
                />
                <div className="form-group mt-3">
                  <Field
                    label="Valore percentuale"
                    name="valore_percentuale"
                    component={InputField}
                    className="form-control"
                  />
                </div>
                <div className="form-group mt-3">
                  <Field
                    label="Stato"
                    type="select"
                    name="stato"
                    component={InputField}
                    className="form-control"
                  >
                    <option value=""></option>
                    {stati.map((stato) => (
                      <option key={stato.nome} value={stato.nome}>
                        {stato.nome}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="form-group mt-3 d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    Salva
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  )
}
