import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

export default function ModalUpdateStato({
  // Modal stuff
  isOpen,
  title,
  toggle,
  // Stati
  stati,
  initialStato,
  // Is stat required?
  required,
  // Data stuff
  saving,
  onSave,
}) {
  const [selectedStato, setSelectedStato] = useState(initialStato)

  // Reset selected stato on modal open / close
  useEffect(() => setSelectedStato(initialStato), [initialStato])

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        <select
          className='form-control'
          value={selectedStato || ''}
          onChange={e => setSelectedStato(e.target.value)}>
          <option value={''}>{'-- Seleziona Stato ---'}</option>
          {stati.map(stato => (
            <option key={stato.nome} value={stato.nome}>{stato.nome}</option>
          ))}
        </select>
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between'>
        <Button color="secondary" disabled={saving} onClick={toggle}>Chiudi</Button>
        <Button
          color="success"
          disabled={saving || (required ? !selectedStato : false)}
          onClick={() => onSave(selectedStato)}>Salva</Button>
      </ModalFooter>
    </Modal>
  )
}
