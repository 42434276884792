import React from 'react'
import { HashRouter as Router, Switch } from 'react-router-dom'
import UsersList from './UsersList'
import UserRoot from './UserRoot'
import UserCreate from './UserCreate'
import PermissionRoute from '../../components/PermissionRoute'
import { PERMISSIONS } from '../../django'

export default function AdminUsersApp() {
  return (
    <Router>
      <Switch>
        <PermissionRoute
          hasPermission={PERMISSIONS.adminUsers}
          path="/"
          exact
          component={UsersList}
        />
        <PermissionRoute
          hasPermission={PERMISSIONS.adminUsers}
          path="/create"
          exact
          component={UserCreate}
        />
        <PermissionRoute
          hasPermission={PERMISSIONS.adminUsers}
          path="/:id"
          component={UserRoot}
        />
      </Switch>
    </Router>
  )
}
