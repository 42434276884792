import { rj } from 'react-rocketjump'
import request from 'superagent'
import { ajax } from 'rxjs/ajax'
import { map } from 'rxjs/operators'
import rjPlainList from 'react-rocketjump/plugins/plainList'
import { CSRF } from '../../../django'

export const TempisticheState = rj(rjPlainList(), {
  mutations: {
    updateTempistica: {
      effect: tempistica => ajax({
        method: 'put',
        url: `/api/planner/tempistiche/${tempistica.id}/`,
        headers: {
         'Content-Type': 'application/json',
         'X-CSRFToken': CSRF,
        },
        body: JSON.stringify(tempistica),
      }).pipe(map(r => r.response)),
      updater: 'updateItem',
    },
    createTempistica: {
      effect: tempistica => ajax({
        method: 'post',
        url: `/api/planner/tempistiche/`,
        headers: {
         'Content-Type': 'application/json',
         'X-CSRFToken': CSRF,
        },
        body: JSON.stringify(tempistica),
      }).pipe(map(r => r.response)),
      updater: 'insertItem',
    },
    deleteTempistica: rj.mutation.single({
      effect: (tempistica) => ajax({
        method: 'delete',
        url: `/api/planner/tempistiche/${tempistica.id}`,
        headers: {
         'Content-Type': 'application/json',
         'X-CSRFToken': CSRF,
        },
     }).pipe(map(() => tempistica)),
     updater: 'deleteItem',
   }),
  },
  computed: {
    deleting: s => s.mutations.deleteTempistica.pending,
    list: 'getList',
    loading: 'isLoading',
    error: 'getError',
  },
  name: 'Tempistiche',
  effect: () => ajax.getJSON('/api/planner/tempistiche'),
})

export const ImportTempiState = rj({
  name: 'Import Tempi',
  effect: file => {
    const r = request.post('/api/planner/import-tempi/')
    r.set('X-CSRFToken', CSRF)
    r.attach('file', file)
    return r.then(r => r.body)
  }
})
