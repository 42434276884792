import { Field, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import Button from 'reactstrap/lib/Button'
import Modal from 'reactstrap/lib/Modal'
import ModalBody from 'reactstrap/lib/ModalBody'
import ModalFooter from 'reactstrap/lib/ModalFooter'
import ModalHeader from 'reactstrap/lib/ModalHeader'
import { FileField, InputField } from '../../../components/Form/Fields'
import { transformErrorForForm } from '../../../components/Form/Fields/utils'

const initialValues = {
  nome_cliente: '',
  tipo_cliente: '',
  codice_cliente: '',
  email_cliente: '',
  numero_commessa: '',
  preventivo_da_firmare: null,
  note: '',
}

const ConfermaOrdineSchema = Yup.object().shape({
  nome_cliente: Yup.string().required(),
  email_cliente: Yup.string().required(),
  preventivo_da_firmare: Yup.mixed().required(),
})

function ModalContentFormConfermaOrdine({ save, confermaOrdine, toggle }) {
  return (
    <Formik
      onSubmit={(values, actions) => {
        return save(values).then(
          () => {
            // actions.setSubmitting(false)
          },
          (error) => {
            actions.setSubmitting(false)
            actions.setErrors(transformErrorForForm(error))
          }
        )
      }}
      validationSchema={ConfermaOrdineSchema}
      initialValues={confermaOrdine ?? initialValues}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="m-0 p-0">
          <ModalBody>
            <Field
              autoComplete="off"
              component={InputField}
              label="Nome Cliente"
              name="nome_cliente"
            />
            <Field
              autoComplete="off"
              component={InputField}
              label="Tipo Cliente"
              name="tipo_cliente"
            />
            <Field
              autoComplete="off"
              component={InputField}
              label="Codice Cliente"
              name="codice_cliente"
            />
            <Field
              autoComplete="off"
              component={InputField}
              label="Email Cliente"
              type="email"
              name="email_cliente"
            />
            <Field
              autoComplete="off"
              component={InputField}
              label="Numero Commessa"
              name="numero_commessa"
            />
            <Field
              autoComplete="off"
              component={InputField}
              label="Note"
              type='textarea'
              name="note"
            />
            <Field
              component={FileField}
              type="file"
              label="Preventivo"
              name="preventivo_da_firmare"
            />
          </ModalBody>
          <ModalFooter className="d-flex justify-content-between">
            <Button disabled={isSubmitting} type="button" onClick={toggle}>
              Annulla
            </Button>
            <Button disabled={isSubmitting} type="submit" color="success">
              {confermaOrdine && confermaOrdine.id ? 'Modifica' : 'Crea'}
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  )
}

export default function ModalFormConfermaOrdine({
  confermaOrdine,
  isOpen,
  toggle,
  onClosed,
  ...props
}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
      <ModalHeader toggle={toggle}>Nuova Conferma Ordine</ModalHeader>
      <ModalContentFormConfermaOrdine
        confermaOrdine={confermaOrdine}
        toggle={toggle}
        {...props}
      />
    </Modal>
  )
}
