import React, { useContext, useMemo, useCallback } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import find from 'lodash/find'
import { ContextFornitori } from '../../apps/PlannerApp/contexts'
import moment from 'moment'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import it from 'date-fns/locale/it'
import CircularProgress from '@material-ui/core/CircularProgress'

registerLocale('it', it)

const UNITS_LABELS = {
  days: 'giorni',
  hours: 'ore',
  minutes: 'minuti',
}

const FaseSchedulazione = React.memo(
  ({
    faseSchedulazione,
    updateFornitore,
    updateDurata,
    updateDataInizio,
    index,
    height,
    errors,
    allErrors,
    scrollToDate,
    scheduling,
    showBorderBottom,
  }) => {
    const fornitori = useContext(ContextFornitori)
    const nomeFase = faseSchedulazione.nome_fase
    const durata =
      faseSchedulazione.durata_override !== null
        ? faseSchedulazione.durata_override
        : faseSchedulazione.durata || ''

    const hasFornitore = faseSchedulazione.with_fornitore
    const dataInizioSchedulazione = faseSchedulazione.inizio

    const errorOnInizio = useMemo(() => {
      const badInizio = errors['bad_start_time']
      if (badInizio && dataInizioSchedulazione) {
        return moment(badInizio).isSame(moment(dataInizioSchedulazione), 'date')
      }
      return false
    }, [errors, dataInizioSchedulazione])

    const canFixFase = useMemo(() => {
      // No needed at all
      if (!faseSchedulazione.isNeeded) {
        return false
      }
      // When no deps show fixer only when empty
      if (faseSchedulazione.dependencies.length === 0) {
        return !dataInizioSchedulazione
      }
      if (!faseSchedulazione.depsOk) {
        return false
      }
      return !faseSchedulazione.dependencies.some((dep) => !!allErrors[dep])
    }, [faseSchedulazione, allErrors, dataInizioSchedulazione])

    const autoUpdateDateInizio = useCallback(() => {
      if (faseSchedulazione.dependencies.length === 0) {
        // No deps go today
        if (faseSchedulazione.infinite_capacity) {
          updateDataInizio(
            nomeFase,
            moment().hour(0).seconds(0).minutes(0).toDate(),
            false
          )
        } else {
          updateDataInizio(nomeFase, new Date(), false)
        }
      } else {
        // Null means server can desume date from deps
        updateDataInizio(nomeFase, null, false)
      }
    }, [updateDataInizio, faseSchedulazione, nomeFase])

    return (
      <div
        style={{ height }}
        className={classNames('border-top p-1', {
          'border-bottom': showBorderBottom,
        })}
      >
        <div className="d-flex">
          <div className="flex-1">
            <div className="d-flex">
              <small>Data Inizio</small>
              {dataInizioSchedulazione && (
                <button
                  className="ml-1"
                  style={{ fontSize: '50%' }}
                  onClick={() => scrollToDate(dataInizioSchedulazione)}
                >
                  <i className="fa fa-calendar-o" aria-hidden="true"></i>
                </button>
              )}
              {canFixFase > 0 && (
                <button
                  disabled={scheduling}
                  className="ml-1 text-primary"
                  style={{ fontSize: '50%' }}
                  onClick={autoUpdateDateInizio}
                >
                  <i className="fa fa-magic" aria-hidden="true"></i>
                </button>
              )}
            </div>
            <DatePicker
              disabled={
                !faseSchedulazione.durata || !faseSchedulazione.isNeeded
              }
              autoComplete="off"
              locale={'it'}
              className={classNames('form-control-sm form-control', {
                'is-invalid': errorOnInizio,
              })}
              selected={dataInizioSchedulazione}
              dateFormat="dd-MM-yyyy"
              onChange={(date) => {
                updateDataInizio(nomeFase, date)
              }}
            />
          </div>
          <div className="ml-1 flex-1">
            <div className="d-flex">
              <small>Data Fine</small>
              {faseSchedulazione.fine && (
                <button
                  className="ml-1"
                  style={{ fontSize: '50%' }}
                  onClick={() => scrollToDate(faseSchedulazione.fine)}
                >
                  <i className="fa fa-calendar-o" aria-hidden="true"></i>
                </button>
              )}
            </div>
            <div className="d-inline-block">
              <input
                value={
                  faseSchedulazione.fine
                    ? moment(faseSchedulazione.fine).format('DD-MM-YYYY')
                    : ''
                }
                disabled
                className="form-control-sm form-control"
                type="text"
              />
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-1">
            <div>
              <small>
                Durata
                {faseSchedulazione.durata !== null && (
                  <span className="text-primary ml-1">
                    {`(${faseSchedulazione.durata} ${
                      UNITS_LABELS[faseSchedulazione.unit]
                    })`}
                  </span>
                )}
              </small>
            </div>
            <div className="d-inline-block">
              <input
                placeholder={faseSchedulazione.durata}
                className="form-control-sm form-control"
                onChange={(e) => updateDurata(nomeFase, e.target.value)}
                disabled={
                  (hasFornitore && !faseSchedulazione.fornitore) ||
                  !faseSchedulazione.isNeeded
                }
                type="text"
                value={durata}
              />
            </div>
          </div>
          <div className="flex-1 ml-1">
            {hasFornitore && (
              <div>
                <div>
                  <small>Fornitore</small>
                </div>
                <select
                  disabled={!faseSchedulazione.isNeeded}
                  className="form-control-sm form-control"
                  value={
                    faseSchedulazione.fornitore
                      ? faseSchedulazione.fornitore.id
                      : ''
                  }
                  onChange={(e) => {
                    const fornitoreId = e.target.value
                    let fornitore
                    if (!fornitoreId) {
                      fornitore = null
                    } else {
                      fornitore = find(fornitori, { id: +fornitoreId })
                    }
                    updateFornitore(nomeFase, fornitore)
                  }}
                >
                  <option value="">---</option>
                  {fornitori
                    .filter((f) => f.tipologia === nomeFase)
                    .map((fornitore) => (
                      <option key={fornitore.id} value={fornitore.id}>
                        {fornitore.nome}
                      </option>
                    ))}
                </select>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
)

const ListFasiSchedulazioneOrdine = React.memo(
  ({
    fasiSchedulazione,
    updateDataInizio,
    updateFornitore,
    updateDurata,
    faseHeight,
    errors,
    scrollToDate,
    scheduling,
  }) => {
    const fasiLength = fasiSchedulazione.length
    return (
      <>
        {fasiSchedulazione.map((faseSchedulazione, index) => (
          <FaseSchedulazione
            scheduling={scheduling}
            updateDataInizio={updateDataInizio}
            updateFornitore={updateFornitore}
            updateDurata={updateDurata}
            faseSchedulazione={faseSchedulazione}
            key={faseSchedulazione.nome_fase}
            index={index}
            height={faseHeight}
            allErrors={errors}
            errors={errors[faseSchedulazione.nome_fase] || NO_ERRORS}
            scrollToDate={scrollToDate}
            showBorderBottom={index === fasiLength - 1}
          />
        ))}
      </>
    )
  }
)

const NO_ERRORS = {}
export default function FasiSchedulazioneOrdine({
  autoUpdateDateInizio,
  saving,
  faseHeight,
  titleHeight,
  fasiSchedulazione,
  updateFornitore,
  updateDurata,
  updateDataInizio,
  ordine,
  onClose,
  errors,
  scheduling,
  saveSchedulazione,
  saved,
  isScheduleComplete,
  scrollToDate,
}) {
  const showSpinner = saving || scheduling
  const isSaveDisabled = showSpinner || !isScheduleComplete
  return (
    <div>
      <div className='bg-light' style={{ height: titleHeight }}>
        <div className="d-flex align-items-center pt-2">
          <b className="ml-2">
            Ordine:{' '}
            <Link target="_blank" to={`/ordini/${ordine.id}`}>
              {ordine.numero_commessa}
            </Link>
            <span
              style={{ visibility: saved === false ? undefined : 'hidden' }}
            >
              {'*'}
            </span>
          </b>
          {/* <button
          onClick={autoUpdateDateInizio}
          className='btn btn-primary btn-sm ml-3'>
          <i className="fa fa-magic" aria-hidden="true"></i>
        </button> */}
          <button
            onClick={saveSchedulazione}
            style={{ width: 70 }}
            className="btn btn-success btn-sm ml-1"
            disabled={isSaveDisabled}
          >
            {showSpinner && <CircularProgress size={'1rem'} color="inherit" />}
            {!showSpinner && (
              <span>
                <i className="fa fa-save" aria-hidden="true"></i>
                <small className="pl-1">Salva</small>
              </span>
            )}
          </button>
        </div>
        <div className='px-2' style={{ fontSize: '80%' }}>
          <div>
            <div><b>Riferimento</b></div>
            <div>{ordine.riferimento}</div>
          </div>
          <div className='d-flex'>
            <div><b>Data approntamento</b></div>
            <div className='ml-1'>{ordine.data_consegna}</div>
          </div>
        </div>
      </div>
      <ListFasiSchedulazioneOrdine
        scheduling={scheduling}
        fasiSchedulazione={fasiSchedulazione}
        updateDataInizio={updateDataInizio}
        updateFornitore={updateFornitore}
        updateDurata={updateDurata}
        faseHeight={faseHeight}
        errors={errors}
        scrollToDate={scrollToDate}
      />
    </div>
  )
}
