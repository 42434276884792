import React from 'react'
import { Link } from 'react-router-dom'
import 'moment/locale/it'
import { PERMISSIONS } from '../../../django'

const FiltersOrders = ({
  filters,
  onFilterChangeDebounced,
  onFilterChange,
  onFiltersChange,
  totals,
  pagination,
}) => {
  const { search, scheduled } = filters

  return (
    <div>
      <div className="row pt-2">
        <div className="col-md-2 mb-2 mb-md-0">
          <input
            name="search"
            type="text"
            className="form-control form-control-sm"
            placeholder={'Ricerca libera'}
            value={search}
            onChange={onFilterChangeDebounced}
          />
        </div>
        <div className="col-md-2 mb-2 mb-md-0">
          {/* <label>Filtro Schedulazione</label> */}
          <select
            name="scheduled"
            onChange={onFilterChange}
            value={scheduled}
            className="form-control form-control-sm"
          >
            <option value="">Tutti (schedulati e non)</option>
            <option value="1">Schedulati</option>
            <option value="0">Non schedulati</option>
            <option value="non_schedulabili">Non schedulabili</option>
          </select>
        </div>
        <div className="col-md-5 d-flex align-items-center">
          {pagination && (
            <div>
              Totale Ordini:{' '}
              <span className="badge badge-primary">{pagination.count}</span>
            </div>
          )}
        </div>
        {PERMISSIONS.addOrders && (
          <div className="col-md-3 text-right">
            <Link
              className="btn btn-sm btn-outline-dark mr-1"
              to={'/importa-ordini'}
            >
              <i className="fas fa-upload"></i> Importa ordini
            </Link>
            <Link
              className="btn btn-sm btn-outline-dark"
              to={'/aggiungi-ordine'}
            >
              <i className="fas fa-plus-circle"></i> Aggiungi ordine
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default FiltersOrders
