import React from 'react'
//import get from 'lodash/get'
import { UncontrolledCarousel } from 'reactstrap'



const DetailFamily = ({ famiglia, heightImg }) => {
  const items = famiglia.immagini.map(immagine => ({
    'src': immagine.immagine,
    'caption': ''
  }))

  return (
    <div className='DetailFamily mt-1'>
      <h4 className='text-primary'>{famiglia.nome}</h4>
      <div className='text-sm-center text-md-left pt-1 pb-1'>
        <UncontrolledCarousel indicators={false} controls={items.length > 1} items={items} />
      </div>
      <div dangerouslySetInnerHTML={{__html: famiglia.descrizione}}>
      </div>
    </div>
  )
}

export default React.memo(DetailFamily)
