import { scaleOrdinal } from 'd3-scale'
import React, { useMemo } from 'react'
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { schemeSet1 } from 'd3-scale-chromatic'


const colorScale = scaleOrdinal(schemeSet1)

export default function LineChartOperatori({
  dataForBarChart,
  operatoriInAttivitaFlat,
}) {
  
  const colorMap = useMemo(() => {
    if (operatoriInAttivitaFlat === null) {
      return null
    }
    const ids = operatoriInAttivitaFlat.map((p) => p.id).sort()
    if (ids.length === 0) {
      return {}
    }
    return ids.reduce((colorMap, id, index) => ({
      ...colorMap,
      [id]: colorScale(index + 1),
    }))
  }, [operatoriInAttivitaFlat])
  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart
        width={800}
        height={250}
        data={dataForBarChart}
        margin={{
          top: 0,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="data" />
        <YAxis
          ticks={[0, 50, 100, 200, 300, 400, 500, 750]}
          domain={[0, 600]}
        />
        <Tooltip />
        {operatoriInAttivitaFlat.map((operatore) => {
          return (
            <Bar
              dataKey={operatore.first_name + ' ' + operatore.last_name}
              strokeWidth={2}
                fill={colorMap[operatore.id]}
              width={10}
              key={operatore.id}
            />
          )
        })}
        <Legend />
      </BarChart>
    </ResponsiveContainer>
  )
}
