import React from 'react'
import { Row, Col, Card, CardText, CardBody, CardHeader } from 'reactstrap'
import './DemoApp.css'
import { PERMISSIONS } from '../../django'

const ALL_FEATURES = [
  {
    title: 'Clienti',
    text: `
      Lista clienti.
    `,
    link: '/planner/#/clienti',
    enabled: PERMISSIONS.adminOrders,
  },
  {
    title: 'Ordini',
    text: `
      Lista ordini.
    `,
    link: '/planner/#/ordini',
    enabled: PERMISSIONS.viewOrders,
  },
  {
    title: 'Importa Ordini',
    text: `
      Importa ordini da xls.
    `,
    link: '/planner/#/importa-ordini',
    enabled: PERMISSIONS.addOrders,
  },
  {
    title: 'Importa Clienti',
    text: `
      Importa clienti da xls.
    `,
    link: '/planner/#/importa-clienti',
    enabled: PERMISSIONS.addOrders,
  },
  {
    title: 'Ordini Fornitori',
    text: `
      Gestisci gli ordini ai tuoi fornitori.
    `,
    link: '/planner/#/ordini-fornitore',
    enabled: PERMISSIONS.viewOrderFornitore,
  },
  {
    title: 'Conferme Ordini',
    text: `
      Gestisci le conferme ordini.
    `,
    link: '/planner/#/conferme-ordini',
    enabled: PERMISSIONS.confermaOrdini,
  },
  {
    title: 'Personale',
    text: `
      Gestisci il personale.
    `,
    link: '/planner/#/personale',
    enabled: PERMISSIONS.adminOrders,
  },
  {
    title: 'Fornitori',
    text: `
      Gestisci i tuoi fornitori.
    `,
    link: '/planner/#/fornitori',
    enabled: PERMISSIONS.adminOrders,
  },
  {
    title: 'Calendario',
    text: `
      Gestisci il calendario aziendale e le ferie del personale.
    `,
    link: '/planner/#/calendario-aziendale',
    enabled: PERMISSIONS.adminOrders,
  },
  {
    title: 'Schedulatore',
    text: `
      Schedula i tuoi ordini.
    `,
    link: '/planner/#/schedulatore',
    enabled: PERMISSIONS.scheduleOrder,
  },
  {
    title: 'Fasi Schedulatore',
    text: `
      Vedi le fasi degli ordini già schedulate.
    `,
    link: '/planner/#/fasi-schedulate',
    enabled: PERMISSIONS.viewOrderFasi,
  },
  {
    title: 'Materiali',
    text: `
      Gestisci i materiali.
    `,
    link: '/materiali',
    enabled: PERMISSIONS.adminOrders,
  },
  {
    title: 'Tempistiche',
    text: `
      Gestisci le tempistiche di produzione dei tuoi serramenti.
    `,
    link: '/planner/#/tempistiche',
    enabled: PERMISSIONS.adminOrders,
  },
  {
    title: 'Documenti',
    text: `
      Accedi all'archivio documentale.
    `,
    link: '/app/#/folders',
    enabled: PERMISSIONS.viewFolders,
  },
  {
    title: 'Attività',
    text: `
      Gestisci le attività.
    `,
    link: '/planner/#/operatore',
    enabled: PERMISSIONS.addAttivita,
  },
  {
    title: 'Attività operatori',
    text: `
      Visualizza le attività dei vari operatori.
    `,
    link: '/planner/#/attivita-operatori',
    enabled: PERMISSIONS.adminOrders,
  },
  {
    title: 'Lavorazioni ordine operatore',
    text: `
      Visualizza le lavorazioni ordini operatore.
    `,
    link: '/planner/#/lavorazioni-ordine-operatore',
    enabled: PERMISSIONS.adminOrders,
  },
  {
    title: 'Analisi performance',
    text: `
      Visualizza le performance degli operatori.
    `,
    link: '/planner/#/analisi/performance',
    enabled: PERMISSIONS.adminOrders,
  },
  {
    title: 'Analisi completezza',
    text: `
      Visualizza la completezza delle attività.
    `,
    link: '/planner/#/analisi/completezza',
    enabled: PERMISSIONS.adminOrders,
  },
  {
    title: 'Manipolatore macchina',
    text: `
      Visualizza le performance del manipolatore macchina.
    `,
    link: '/planner/#/manipolatore-macchina',
    enabled: PERMISSIONS.adminOrders,
  },
  {
    title: 'Statistiche Clienti',
    text: `
      Visualizza le statistiche dei clienti.
    `,
    link: '/planner/#/statistiche-clienti',
    enabled: PERMISSIONS.adminOrders || PERMISSIONS.isCapoArea,
  },
  {
    title: 'Statistiche Agenti',
    text: `
      Visualizza le statistiche degli agenti.
    `,
    link: '/planner/#/statistiche-agenti',
    enabled: PERMISSIONS.adminOrders || PERMISSIONS.isCapoArea,
  },
  {
    title: 'Performance operatori',
    text: `
      Visualizza le performance degli operatori.
    `,
    link: '/planner/#/analisi/performance/operatori',
    enabled: PERMISSIONS.adminOrders, 
  },
  {
    title: 'Avanzamento ordini',
    text: `
      Visualizza l'avanzamento degli ordini.
    `,
    link: '/planner/#/avanzamento-ordini',
    enabled: PERMISSIONS.adminOrders, 
  }
]

const FEATURES = ALL_FEATURES.filter(f => f.enabled)

export default function DemoApp() {
  return (
    <div className='Demo container-fluid'>
      {/* <div className='text-center mb-4'>
        <h1>QR</h1>
      </div> */}
      <Row className='mt-2'>
        {FEATURES.map((feature, i) => (
          <Col sm={4} key={i} className='mt-3'>
            <a href={feature.link} className='Demo-link'>
              <Card className='h-100'>
                <CardHeader tag='h4'>{feature.title}</CardHeader>
                <CardBody>
                  {feature.text && <CardText>{feature.text}</CardText>}
                </CardBody>
              </Card>
            </a>
          </Col>
        ))}
      </Row>
    </div>
  )
}
