import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ConfermaOrdineDetail from './ConfermaOrdineDetail'
import ConfermaOrdineList from './ConfermaOrdineList'

export default function ConfermaOrdine({ match }) {
  return (
    <Switch>
      <Route exact path={`${match.path}`} component={ConfermaOrdineList} />
      <Route exact path={`${match.path}/:id`} component={ConfermaOrdineDetail} />
    </Switch>
  )
}