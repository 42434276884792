import React from 'react'
import classNames from 'classnames'

const TableFornitori = ({
  fornitori,
  setFornitore,
  setShowModalDeleteFornitore,
  setShowModalFormFornitore,
}) => {
  return (
    <table className="table table-bordered table-striped mt-2">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Codice</th>
          <th>Tipologia</th>
          <th>Email</th>
          <th>Default</th>
          <th>Tempo</th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody>
        {fornitori.map((fornitore, key) => (
          <tr key={key}>
            <td>{fornitore.nome}</td>
            <td>{fornitore.codice}</td>
            <td className="text-capitalize">{fornitore.tipologia}</td>
            <td>{fornitore.email}</td>
            <td
              className={classNames('text-capitalize text-center', {
                'text-success': fornitore.is_default,
                'text-danger': !fornitore.is_default,
              })}
            >
              {fornitore.is_default ? (
                <i className="fa fa-check" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-times" aria-hidden="true"></i>
              )}
            </td>
            <td>{fornitore.tempo}</td>
            <td className="text-center">
              <div className="d-flex justify-content-center">
                <div
                  className="pointer ml-2"
                  onClick={() => {
                    setFornitore(fornitore)
                    setShowModalFormFornitore(true)
                  }}
                >
                  <i
                    title={`Modifica ${fornitore.nome}`}
                    className="fas fa-pencil-alt text-warning"
                  ></i>
                </div>
                <div
                  className="pointer ml-2"
                  onClick={() => {
                    setFornitore(fornitore)
                    setShowModalDeleteFornitore(true)
                  }}
                >
                  <i
                    title={`Elimina ${fornitore.nome}`}
                    className="fas fa-trash text-danger"
                  ></i>
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default TableFornitori
