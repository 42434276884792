import React, { useState, useCallback } from 'react'
import get from 'lodash/get'
import classnames from 'classnames'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useRunRj, useRj } from 'react-rocketjump'
import {
  FamiglieState, TipiSerramentoState, VincoliMisuraState, DeleteVincoloState,
  UpdateTipoSerramentoState, TipiAperturaState, UpdateTipoSerramentoApertureState,
  apiAddVincolo, apiUpdateVincolo, apiUpdateTipoSerramento,
} from './localstate'
import ModalVincolo from '../../components/Admin/ModalVincolo'
import ModalDeleteVincolo from '../../components/Admin/ModalDeleteVincolo'
import ModalChooseTipiApertura from '../../components/Admin/ModalChooseTipiApertura'

const VincoliCell = React.memo(({
  vincoli, famiglia, tipoSerramento, setshowModalVincolo,
  setShowModalDeleteVincolo, isTipoSerramento
}) => {

  return (
    <div>
      {vincoli.length > 0 &&
      <table className={classnames('VincoloTable','table','table-borderless','font-weight-small',{
        'bg-gray-light': tipoSerramento.altezza_min
      })}>
        <thead>
          <tr className='border-bottom'>
            <td className='text-center'><small className='font-weight-bold'>Alt.</small></td>
            <td className='text-center'><small className='font-weight-bold'>Lar.</small></td>
            <td colSpan='2'></td>
          </tr>
        </thead>
        <tbody>
        {vincoli.map(vincolo => (
          <tr key={vincolo.id} className='border-bottom'>
            <td className='text-center'><small>{vincolo.altezza_min}<br /><i className="fas fa-arrow-down"></i><br />{vincolo.altezza_max}</small></td>
            <td className='text-center'><small>{vincolo.larghezza_min}<br /><i className="fas fa-arrow-down"></i><br />{vincolo.larghezza_max}</small></td>
            <td className='pt-3'><i onClick={() => setshowModalVincolo({ famiglia, tipoSerramento, vincolo, isTipoSerramento })} className="fas text-warning fa-pencil-alt pointer"></i></td>
            <td className='pt-3'><i onClick={() => setShowModalDeleteVincolo({ famiglia, tipoSerramento, vincolo, isTipoSerramento })} className="fas fa-remove pointer text-danger"></i></td>
          </tr>
        ))}
        </tbody>
      </table>
      }
    </div>
  )
})

const VincoloTipoSerramento = React.memo(({ tipoSerramento, setshowModalVincolo, setShowModalDeleteVincolo, isTipoSerramento }) => {

  const vincolo = {
    'id': tipoSerramento.id,
    'altezza_min' : tipoSerramento.altezza_min ? tipoSerramento.altezza_min : '',
    'altezza_max' : tipoSerramento.altezza_max ? tipoSerramento.altezza_max : '',
    'larghezza_min' : tipoSerramento.larghezza_min ? tipoSerramento.larghezza_min : '',
    'larghezza_max' : tipoSerramento.larghezza_max ? tipoSerramento.larghezza_max : ''
  }

  return (
    <div>
      {tipoSerramento.altezza_min && tipoSerramento.altezza_max && tipoSerramento.larghezza_min && tipoSerramento.larghezza_max ?
      <table className='VincoloTable table table-borderless font-weight-small'>
        <thead>
          <tr className='border-bottom'>
            <td className='text-center'><small className='font-weight-bold'>Alt.</small></td>
            <td className='text-center'><small className='font-weight-bold'>Lar.</small></td>
            <td colSpan='2'></td>
          </tr>
        </thead>
        <tbody>
          <tr className='border-bottom'>
            <td className='text-center'><small>{tipoSerramento.altezza_min}<br /><i className="fas fa-arrow-down"></i><br />{tipoSerramento.altezza_max}</small></td>
            <td className='text-center'><small>{tipoSerramento.larghezza_min}<br /><i className="fas fa-arrow-down"></i><br />{tipoSerramento.larghezza_max}</small></td>
            <td className='pt-3'><i onClick={() => setshowModalVincolo({ tipoSerramento, vincolo, isTipoSerramento })} className="fas text-warning fa-pencil-alt pointer"></i></td>
            <td className='pt-3'><i onClick={() => setShowModalDeleteVincolo({ tipoSerramento, isTipoSerramento })} className="fas fa-remove pointer text-danger"></i></td>
          </tr>
        </tbody>
      </table>
      :
      <div className='pointer pt-2 text-center' onClick={() => setshowModalVincolo({ tipoSerramento, vincolo, isTipoSerramento })}>
        <i title={'Aggiungi vincolo misura'} className="fas fa-plus-circle text-primary"></i>
      </div>
      }
    </div>
  )
})

const EMPTYLIST = []

export default function VincoliMisure() {
  const [{ famiglie }] = useRunRj(FamiglieState)
  const [{ tipiSerramento }, { updateTipoSerramento }] = useRunRj(TipiSerramentoState)
  const [{ tipiApertura }] = useRunRj(TipiAperturaState)
  const [{ updatingAperture }, { run: updateApertureSerramento }] = useRj(UpdateTipoSerramentoApertureState)
  const [{ vincoli }, { addVincolo, updateVincolo, removeVincolo }] = useRunRj(VincoliMisuraState)
  const [{ deleting }, { run: deleteVincolo }] = useRj(DeleteVincoloState)
  const [{ updating }, { run: runUpdateTipoSerramento }] = useRj(UpdateTipoSerramentoState)

  const [tipoSerramentoSelected,setTipoSerramento] = useState(null)

  const [showModalVincolo, setshowModalVincolo] = useState(null)
  const closeShowModalVincolo = useCallback(() => {
    setshowModalVincolo(null)
  }, [])

  const [showModalDeleteVincolo, setShowModalDeleteVincolo] = useState(null)
  const closeShowModalDeleteVincolo = useCallback(() => {
    setShowModalDeleteVincolo(null)
  }, [])

  const [showModalTipiApertura, setShowModalTipiApertura] = useState(null)
  const toggleShowTipiApertura = useCallback(() => {
    setShowModalTipiApertura(null)
  }, [])

  if (famiglie === null || tipiSerramento === null || vincoli === null) {
    return <div className='mt-2 text-center'><CircularProgress /></div>
  }

  const isTipoSerramento = false

  return (
    <div className='container'>
      <h4 className='font-weight-bold text-primary text-center pt-2'>Vincoli misure</h4>
      <p>
        <small className='font-weight-bold'>
          Inserendo un vincolo nella colonna del tipo serramento i valori dei vincoli per tipo serramento/famiglia non verranno
          presi in considerazione. <br />
          Lasciando vuoto il vincolo principale  sul tipo serramento o eliminandolo è quindi possibile inserire i vincoli per famiglia e tipo serramento.
        </small>
        </p>
      <table className='TableVincoli table table-bordered mt-2'>
        <thead>
          <tr>
            <th></th>
            {famiglie.map(famiglia => (
              <th className='text-center' key={famiglia.id}>{famiglia.nome}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tipiSerramento.map(tipoSerramento => (
            <tr key={tipoSerramento.id}>
              <td>
                <b>{tipoSerramento.nome}</b>
                <VincoloTipoSerramento
                  tipoSerramento={tipoSerramento}
                  setshowModalVincolo={setshowModalVincolo}
                  isTipoSerramento={true}
                  setShowModalDeleteVincolo={setShowModalDeleteVincolo}
                />
                <div className='pt-2'>
                  <small>
                    <strong>Tipi apertura:</strong>
                    <i onClick={() => {
                      setTipoSerramento(tipoSerramento)
                      setShowModalTipiApertura(true)
                    }} className="fas text-warning fa-pencil-alt pointer pl-3"></i><br />
                    {tipoSerramento.aperture.map(apertura => (
                      <div className='pt-1' key={apertura.id}>{apertura.nome}</div>
                    ))}
                  </small>
                </div>
              </td>
              {famiglie.map(famiglia => (
                <td key={famiglia.id} className={classnames('text-center',{
                  'bg-gray-light': tipoSerramento.altezza_min
                })}>
                  <VincoliCell
                    deleteVincolo={addVincolo}
                    famiglia={famiglia}
                    tipoSerramento={tipoSerramento}
                    vincoli={get(vincoli, `${famiglia.id}.${tipoSerramento.id}`, EMPTYLIST)}
                    setshowModalVincolo={setshowModalVincolo}
                    setShowModalDeleteVincolo={setShowModalDeleteVincolo}
                    isTipoSerramento={false}
                  />
                  {
                    !tipoSerramento.altezza_max &&
                      <div className='pointer' onClick={() => setshowModalVincolo({ famiglia, tipoSerramento, isTipoSerramento })}>
                        <i title={'Aggiungi vincolo misura'} className="fas fa-plus-circle text-primary"></i>
                      </div>
                  }

                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <ModalVincolo
        {...showModalVincolo}
        isOpen={showModalVincolo !== null}
        toggle={closeShowModalVincolo}
        save={(vincoloOrTipoSerramento) => {
          if (showModalVincolo.isTipoSerramento) {
            return apiUpdateTipoSerramento(vincoloOrTipoSerramento)
          } else {
            if (showModalVincolo.vincolo) {
              return apiUpdateVincolo(vincoloOrTipoSerramento)
            } else {
              return apiAddVincolo({
                ...vincoloOrTipoSerramento,
                famiglia: showModalVincolo.famiglia.id,
                tipo_serramento: showModalVincolo.tipoSerramento.id
              })
            }
          }
        }}
        onSave={(vincoloOrTipoSerramento) => {
          if (showModalVincolo.isTipoSerramento) {
            updateTipoSerramento(vincoloOrTipoSerramento)
          } else {
            if (showModalVincolo.vincolo){
              updateVincolo(vincoloOrTipoSerramento)
            } else {
              addVincolo(vincoloOrTipoSerramento)
            }
          }
          closeShowModalVincolo()
        }}
      />
      <ModalDeleteVincolo
        {...showModalDeleteVincolo}
        isOpen={showModalDeleteVincolo !== null}
        toggle={closeShowModalDeleteVincolo}
        deleting={
          (showModalDeleteVincolo && showModalDeleteVincolo.isTipoSerramento)
            ? updating
            : deleting
        }
        onDelete={() => {
          if (showModalDeleteVincolo.isTipoSerramento) {
            runUpdateTipoSerramento
              .onSuccess(tipoSerramentoUpdated => {
                updateTipoSerramento(tipoSerramentoUpdated)
                closeShowModalDeleteVincolo()
              })
              .run({
                altezza_min: null,
                altezza_max: null,
                larghezza_max: null,
                larghezza_min: null,
                id: showModalDeleteVincolo.tipoSerramento.id,
                nome: showModalDeleteVincolo.tipoSerramento.nome
              })
          } else {
            deleteVincolo
              .onSuccess(() => {
                removeVincolo(showModalDeleteVincolo.vincolo)
                closeShowModalDeleteVincolo()
              })
              .run(showModalDeleteVincolo.vincolo)
          }
        }}
      />
      <ModalChooseTipiApertura
        toggle={toggleShowTipiApertura}
        isOpen={showModalTipiApertura}
        tipoSerramento={tipoSerramentoSelected}
        tipiApertura={tipiApertura}
        saving={updatingAperture}
        save={(aperture) => {
          updateApertureSerramento
            .onSuccess(tipoSerramentoUpdated => {
              updateTipoSerramento(tipoSerramentoUpdated)
              toggleShowTipiApertura()
            })
            .run(tipoSerramentoSelected.id,aperture)
        }}
      />
    </div>
  )
}
