import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ModalDeleteVincolo = ({
  isOpen, vincolo, famiglia, tipoSerramento, toggle,
  onDelete, deleting, textModal, isTipoSerramento
}) => {
  return(
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}>{famiglia && famiglia.nome+' - '}{tipoSerramento && tipoSerramento.nome}</ModalHeader>
      <ModalBody>
        {vincolo && !isTipoSerramento && <div className='container'>
          <strong>Eliminare vincolo</strong>: <br /><br />
          Altezza min: {vincolo.altezza_min} mm <br />
          Altezza max: {vincolo.altezza_max} mm <br />
          Larghezza min: {vincolo.larghezza_min} mm <br />
          Larghezza min: {vincolo.larghezza_max} mm <br />
        </div>}
        {isTipoSerramento &&
          <div className='container'>
            <strong>Eliminare vincolo</strong>: <br /><br />
            Altezza min: {tipoSerramento.altezza_min} mm <br />
            Altezza max: {tipoSerramento.altezza_max} mm <br />
            Larghezza min: {tipoSerramento.larghezza_min} mm <br />
            Larghezza min: {tipoSerramento.larghezza_max} mm <br />
          </div>
        }
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between'>
        <Button color="secondary" disabled={deleting} onClick={toggle}>Chiudi</Button>
        <Button color="danger" disabled={deleting} onClick={onDelete}>Elimina</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalDeleteVincolo
