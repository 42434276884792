import { rj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import magikApi from 'magik-api'
import { SETTINGS, CSRF } from '../../../django'

const api = magikApi().trailingSlash(true).headers({
  'X-CSRFToken': CSRF,
})

export const ConfermeOrdiniListState = rj(
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  {
    mutations: {
      addConferma: {
        effect: (conferma) => {
          const fd = new FormData()
          Object.keys(conferma).forEach((key) => {
            fd.append(key, conferma[key])
          })
          return api.post('/api/planner/conferme-ordine', fd)
        },
        updater: 'insertItem',
      },
      removeConferma: {
        effect: (conferma) =>
          api
            .mapResponse(() => conferma)
            .delete(`/api/planner/conferme-ordine/${conferma.id}`),
        updater: 'deleteItem',
      },
    },
    name: 'ConfermeOrdiniList',
    effect: () => api.get('/api/planner/conferme-ordine'),
    computed: {
      list: 'getList',
      pagination: 'getPagination',
      loading: 'isLoading',
      error: 'getError',
    },
  }
)

export const ConfermaOrdineState = rj({
  name: 'ConfermaOrdine',
  mutations: {
    updateConferma: {
      effect: (conferma) => {
        const fd = new FormData()
        Object.keys(conferma).forEach((key) => {
          if (
            ['preventivo_da_firmare', 'preventivo_firmato'].indexOf(key) !== -1
          ) {
            if (conferma[key] instanceof File) {
              fd.append(key, conferma[key])
            }
          } else {
            fd.append(key, conferma[key])
          }
        })
        return api.put(`/api/planner/conferme-ordine/${conferma.id}`, fd)
      },
      updater: 'updateData',
    },
    inviaConferma: {
      effect: (id) => {
        return api.post(`/api/planner/conferme-ordine/${id}/invia`)
      },
      updater: 'updateData',
    },
    addMessaggio: {
      effect: (messaggio) => {
        const fd = new FormData()
        fd.append('conferma', messaggio.conferma)
        fd.append('note', messaggio.note)
        if (messaggio.allegato instanceof File) {
          fd.append('allegato', messaggio.allegato)
        }
        return api.post('/api/planner/messaggi-conferme-ordine', fd)
      },
      updater: (state, messaggio) => ({
        ...state,
        data: {
          ...state.data,
          messaggi: [messaggio].concat(state.data.messaggi),
        },
      }),
    },
  },
  effect: (id) => api.get(`/api/planner/conferme-ordine/${id}`),
})
