import React from 'react'
import classNames from 'classnames'

function parseFilterValue(rawValue) {
  let field = rawValue
  let direction = null

  if (rawValue === '') {
    return { field, direction }
  }

  direction = rawValue.charAt(0) === '-' ? 'down' : 'up'
  field = direction === 'down' ? field.substr(1) : field
  return { field, direction }
}

const Column = ({ children, value, onChange, field, noWrap = false, className = '' }) => {
  const { field: filterField, direction } = parseFilterValue(value)

  return (
    <th className={classNames(String(className), {
      'pointer': !!field,
      'text-text-nowrap': noWrap,
    })}
    onClick={() => {
      if (!field) {
        return
      }
      if (filterField !== field) {
        onChange(`${field}`)
      } else if (direction === 'up') {
        onChange(`-${field}`)
      } else {
        onChange('', false)
      }
    }}>
      <div className='position-relative'>
        <span className={classNames({ 'mr-3': !!field })}>{children}</span>
        {field && <i
          style={{
            position: 'absolute',
            top: 'calc(50% - 9px)',
            right: 0,
            color: field === filterField && direction === 'up' ? 'black' : '#ccc'
          }}
          className='fas fa-sort-up'
        />}
        {field && <i
          style={{
            position: 'absolute',
            top: 'calc(50% - 7px)',
            right: 0,
            color: field === filterField && direction === 'down' ? 'black' : '#ccc'
          }}
          className='fas fa-sort-down'
        />}
      </div>
    </th>
  )
}

export default Column
