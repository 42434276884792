import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Formik, Field } from 'formik'
import { InputField, DateField } from '../../Form/Fields'
import { transformErrorForForm, setNullOnEmptyValues, fixInitialValuesNull } from '../../Form/Fields/utils'
import * as Yup from 'yup'
import moment from 'moment'

const PersonaSchema = Yup.object().shape({
  nome: Yup.string().required(),
  reparto: Yup.string().required(),
  data_inizio: Yup.date().required(),
  data_fine: Yup.date().when('data_inizio', (data_inizio,schema) => {
    return data_inizio ? schema.min(data_inizio, `Data fine deve essere maggiore alla data inizio: ${moment(data_inizio).format("DD-MM-YYYY")}`) : schema
  }),
})


const ModalFormPersona = ({ isOpen, toggle, save, persona }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}>
        {persona ? `Modifica ${persona.nome}`: 'Aggiungi personale'}
      </ModalHeader>
        <Formik
          initialValues={persona ? fixInitialValuesNull(persona) : {
            nome: '',
            reparto: '',
            data_inizio: '',
            data_fine: '',
          }}
          validationSchema={PersonaSchema}
          onSubmit={(values, actions) => {
            return save((setNullOnEmptyValues(values))).then(
              () => {
                actions.setSubmitting(false)
              },
              error => {
                actions.setSubmitting(false)
                actions.setErrors((transformErrorForForm(error)))
              }
            )
          }}
          render={({ handleSubmit, values, isSubmitting, errors }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <Field
                  label='Nome *'
                  type='text'
                  name='nome'
                  className='mb-2'
                  component={InputField}
                />
                <Field
                  component={InputField}
                  label='Reparto *'
                  name='reparto'
                  type='select'
                  className='mb-2'
                >
                  <option value=''></option>
                  <option value='ufficio'>Ufficio</option>
                  <option value='macchine'>Macchine</option>
                  <option value='banchi'>Banchi</option>
                </Field>
                <Field
                  label='Data inizio *'
                  type='text'
                  name='data_inizio'
                  wrapperClassName='w-100'
                  className='mb-2 form-control'
                  component={DateField}
                />
                <Field
                  label='Data fine'
                  type='text'
                  name='data_fine'
                  wrapperClassName='w-100'
                  className='mb-2 form-control'
                  component={DateField}
                />
              </ModalBody>

              <ModalFooter className='d-flex justify-content-between'>
                <Button color="secondary" onClick={toggle}>Chiudi</Button>
                <Button type='submit' disabled={isSubmitting} color="success">Salva</Button>
              </ModalFooter>
            </form>
          )}
        />
    </Modal>
  )
}

export default ModalFormPersona
