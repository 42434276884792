import React from "react"
import { useRunRj } from "react-rocketjump"
import { Link } from "react-router-dom"
import { Button, Card, CardHeader } from "reactstrap"
import CardBody from "reactstrap/lib/CardBody"
import { DetailClienteState } from "./localstate"

export default function ClienteDetail({ history, match }) {
  const { id } = match.params
  const [{ data: cliente }] = useRunRj(DetailClienteState, [id])
  return (
    <div className="ClienteDetail ml-4 mr-4">
      <Button
        className={"mt-3"}
        tag={Link}
        size="sm"
        to={"/clienti"}
        color="secondary"
        outline
      >
        <i className="fas fa-arrow-left"></i> Lista clienti
      </Button>
      {cliente && (
        <Card className={"mt-3"}>
          <CardHeader>
            {cliente.nome} ({cliente.codice})
          </CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-md-4">
                <h6>Indirizzo</h6>
                <hr />
                <div>
                  {cliente.indirizzo}, {cliente.citta} ({cliente.provincia}){" "}
                  {cliente.cap}
                </div>
              </div>
              <div className="col-md-2">
                <h6>Tipo Cliente</h6>
                <hr />
                <div>
                  {cliente.tipo_cliente} <br />
                  {cliente.piva && <>Partita Iva: {cliente.piva}</>}
                </div>
              </div>
              <div className="col-md-2">
                <h6>Contatti</h6>
                <hr />
                <div>
                  {cliente.telefono}
                  <br /> {cliente.telefono_2}
                  <br /> {cliente.telefax}
                </div>
              </div>
              <div className="col-md-2">
                <h6>Pagamento</h6>
                <hr />
                <div>{cliente.pagamento}</div>
              </div>
              <div className="col-md-2">
                <h6>Agente</h6>
                <hr />
                <div>
                  {cliente.agente_data
                    ? `${cliente.agente_data.user.first_name || " "} ${
                        cliente.agente_data.user.last_name || " "
                      }`.trim()
                    : ""}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      )}
    </div>
  )
}
