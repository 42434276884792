import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import sumBy from 'lodash/sumBy'
import dayjs from 'dayjs'
import { USER } from '../../django'

const TablePreventivi = ({ preventivi, loading, selectedPreventivi, toggleSelectedPreventivo, setshowModalInviaMailAgente, setshowModalInviaMail, setPreventivo }) => {
  return(
    <table className='table table-bordered table-striped mt-2'>
      <thead>
        <tr className='font-weight-bold'>
          <td>

          </td>
          <td>N°</td>
          <td>Data</td>
          <td>Nome e cognome</td>
          <td>Regione</td>
          <td>Città</td>
          <td>Provincia</td>
          <td>N° Serr.</td>
          {!USER.agente &&
            <td>Agente</td>
          }
          <td>Stato</td>
          <td className='text-nowrap'>Invio e-mail</td>
          <td>Dettaglio</td>
        </tr>
      </thead>
      <tbody>
        {preventivi && preventivi.map((preventivo,key) => (
          <tr key={preventivo.id}>
            <td className='text-center'>
              {!USER.agente &&
              <input
                checked={!!selectedPreventivi[preventivo.id]}
                onChange={() => toggleSelectedPreventivo(preventivo.id)}
                type='checkbox'
              />
              }
            </td>
            <td className='text-center'><Link to={`/${preventivo.id}`}>{preventivo.id}</Link></td>
            <td className='text-nowrap'>{dayjs(preventivo.data_creazione).format('DD-MM-YYYY')}</td>
            <td>{preventivo.nome_cognome}</td>
            <td>{preventivo.regione_posa}</td>
            <td>{preventivo.citta}</td>
            <td>{preventivo.provincia}</td>
            <td className='text-center'>{sumBy(preventivo.serramenti,'quantita')}</td>
            {!USER.agente &&
              <td className='text-center'>{preventivo.agente
                ?
                <div>
                  {preventivo.agente.user.first_name} {preventivo.agente.user.last_name} <br />
                  {preventivo.email_inviata_agente_il
                    ? <Button disabled className='pt-1' type='button' size='sm' outline color='dark'>
                        <small>
                          <i className="fas fa-envelope"></i>{' '}
                          {dayjs(preventivo.email_inviata_agente_il).format('DD-MM-YYYY')}
                        </small>
                      </Button>
                    : <Button className='mt-1' type='button' size='sm' outline color='dark' onClick={() => {
                      setPreventivo(preventivo)
                      setshowModalInviaMailAgente(true)
                    }}>
                    <small>
                    <i className="fas fa-envelope"></i>{' '}Invia mail
                    </small>
                    </Button>}
                </div>
                : null}</td>
            }
            <td>{preventivo.stato ? preventivo.stato.stato : ''}</td>
            <td className='text-nowrap text-center'>
              {preventivo.email_inviata_il
                ? <Button type='button' size='sm' outline color='dark' onClick={() => {
                  setPreventivo(preventivo)
                  setshowModalInviaMail(true)
                }}><div className='d-flex'>
                    <i className="fas fa-envelope"></i>
                    {' '}
                    <small className='pl-2'>
                      {dayjs(preventivo.email_inviata_il).format('DD-MM-YYYY')}
                    </small>
                    </div>
                  </Button>
                : <Button type='button' size='sm' outline color='dark' onClick={() => {
                  setPreventivo(preventivo)
                  setshowModalInviaMail(true)
                }}>
                  <small>
                  <i className="fas fa-envelope"></i>{' '}Invia mail
                  </small>
                  </Button>}
            </td>
            <td className='text-center'>
              <Link to={`/${preventivo.id}`}><i className="fas fa-search"></i></Link>
              <a href={`/api/preventivo-pdf/${preventivo.id}`} target='_blank' rel='noopener noreferrer'>
                <i style={{color: 'red'}} className="fas fa-file-pdf pl-3"></i>
              </a>
              <Link to={`/${preventivo.id}`}></Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default TablePreventivi
