import React, { useMemo } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { Formik, Field } from "formik"
import * as Yup from "yup"
import { InputField } from "../../Form/Fields"
import {
  transformErrorForForm,
  setNullOnEmptyValues,
  fixInitialValuesNull,
} from "../../Form/Fields/utils"

const ClienteSchema = Yup.object().shape({
  nome: Yup.string().required(),
})

const ModalFormCliente = ({ isOpen, toggle, save, cliente, agenti }) => {
  const initialValues = useMemo(() => {
    if (cliente) {
      return fixInitialValuesNull(cliente)
    } else {
      return {
        nome: "",
        codice: "",
      }
    }
  }, [cliente])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" scrollable>
      <ModalHeader toggle={toggle}>
        {cliente
          ? `Modifica ${cliente.nome} ${cliente.codice}`
          : "Aggiungi cliente"}
      </ModalHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={ClienteSchema}
        onSubmit={(values, actions) => {
          return save(setNullOnEmptyValues(values)).then(
            () => {
              actions.setSubmitting(false)
            },
            (error) => {
              actions.setSubmitting(false)
              actions.setErrors(transformErrorForForm(error))
            }
          )
        }}
        render={({ handleSubmit, values, isSubmitting, errors }) => (
          <>
            <ModalBody>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <Field
                      label="Nome *"
                      type="text"
                      name="nome"
                      className="mb-2"
                      component={InputField}
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                      label="Codice"
                      type="text"
                      name="codice"
                      className="mb-2"
                      component={InputField}
                    />
                  </div>
                  <div className="col-md-4">
                    <Field
                      label="Tipo cliente"
                      type="text"
                      name="tipo_cliente"
                      className="mb-2"
                      component={InputField}
                    />
                  </div>
                  <div className="col-md-4">
                    <Field
                      label="Partita Iva"
                      type="text"
                      name="piva"
                      className="mb-2"
                      component={InputField}
                    />
                  </div>
                  <div className="col-md-4">
                    <Field
                      label="Codice fatturazione"
                      type="text"
                      name="cod_fatturazione"
                      className="mb-2"
                      component={InputField}
                    />
                  </div>
                  <div className="col-md-4">
                    <Field
                      label="Agente"
                      name="agente"
                      type="select"
                      component={InputField}
                      className="mb-2"
                    >
                      <option value={""}>--</option>
                      {agenti.map((agente) => (
                        <option key={agente.id} value={agente.id}>
                          {agente.user.first_name} {agente.user.last_name}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="col-md-12">
                    <Field
                      label="E-mail"
                      type="text"
                      name="email"
                      className="mb-2"
                      component={InputField}
                    />
                  </div>
                  <div className="col-md-12">
                    <Field
                      label="Indirizzo"
                      type="text"
                      name="indirizzo"
                      className="mb-2"
                      component={InputField}
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                      label="Città"
                      type="text"
                      name="citta"
                      className="mb-2"
                      component={InputField}
                    />
                  </div>
                  <div className="col-md-4">
                    <Field
                      label="Cap"
                      type="text"
                      name="cap"
                      className="mb-2"
                      component={InputField}
                    />
                  </div>
                  <div className="col-md-2">
                    <Field
                      label="Provincia"
                      type="text"
                      name="provincia"
                      className="mb-2"
                      component={InputField}
                    />
                  </div>
                  <div className="col-md-4">
                    <Field
                      label="Telefono"
                      type="text"
                      name="telefono"
                      className="mb-2"
                      component={InputField}
                    />
                  </div>
                  <div className="col-md-4">
                    <Field
                      label="Telefono 2"
                      type="text"
                      name="telefono_2"
                      className="mb-2"
                      component={InputField}
                    />
                  </div>
                  <div className="col-md-4">
                    <Field
                      label="Telefax"
                      type="text"
                      name="telefax"
                      className="mb-2"
                      component={InputField}
                    />
                  </div>
                  <div className="col-md-12">
                    <Field
                      label="Note"
                      type="textarea"
                      name="note"
                      rows={4}
                      className="mb-2"
                      component={InputField}
                    />
                  </div>
                  <div className="col-md-12">
                    <Field
                      label="Testo per email"
                      type="textarea"
                      name="testo_email"
                      rows={4}
                      className="mb-2"
                      component={InputField}
                    />
                  </div>
                </div>

                <input type="submit" className="d-none" />
              </form>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
              <Button
                disabled={isSubmitting}
                color="secondary"
                onClick={toggle}
              >
                Chiudi
              </Button>
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
                color="success"
              >
                Salva
              </Button>
            </ModalFooter>
          </>
        )}
      />
    </Modal>
  )
}

export default ModalFormCliente
