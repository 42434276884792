import React, { useMemo } from 'react'
import { useRunRj } from 'react-rocketjump'
import { useQsDataTable as useDataTable } from 'react-use-datatable/react'
import Select from 'react-select'
import { LavorazioniOrdineOperatoreAdminState } from './localstate/lavorazioniOrdineOperatore'
import { LavorazioniState } from './localstate/lavorazioni'
import Paginator from '../../components/Paginator'
import Spinner from 'reactstrap/lib/Spinner'
import { OperatoriState } from './localstate/operatori'
import DateRangeQsFilter from '../../components/Planner/DateRangeQsFilter'
import TableLavorazioniOrdine from '../../components/Planner/TableLavorazioniOrdine'
import OrdersSelectAutoComplete from '../../components/OrdersSelectAutoComplete'

const BIG_PAGE_PARAMS = { page_size: 1000 }

const COLOR_ACTIVITIES = 'rgba(255, 0, 0, 0.3)'

export default function LavorazioniOrdineOperatoreAdmin() {
  const [{ lavorazioni }] = useRunRj(LavorazioniState, [BIG_PAGE_PARAMS])
  const [{ operatori }] = useRunRj(OperatoriState, [BIG_PAGE_PARAMS])

  const [
    // Data State
    { lavorazioniOrdine, pagination, loading, totals },
    // Table state
    { page, ...filters },
    {
      // DT Actions
      onFiltersChange,
      onFilterChange,
      goToPage,
    },
  ] = useDataTable(LavorazioniOrdineOperatoreAdminState, () => ({
    operatore: '',
    lavorazione: '',
    ordine: '',
    from_date: '',
    to_date: '',
    lavorazioni: [],
  }))

  const lavorazioniForSelect = useMemo(() => {
    if (!lavorazioni) {
      return []
    }
    return lavorazioni.map((lavorazione) => ({
      value: lavorazione.id,
      label: lavorazione.nome,
    }))
  }, [lavorazioni])

  const selectedLavorazioni = useMemo(() => {
    if (!filters.lavorazioni || !lavorazioni) {
      return []
    }
    if (typeof filters.lavorazioni === 'string') {
      return filters.lavorazioni.split(',').map((id) => ({
        value: id,
        label: lavorazioni.find((lavorazione) => lavorazione.id === Number(id))
          .nome,
      }))
    } else {
      return filters.lavorazioni.map((id) => ({
        value: id,
        label: lavorazioni.find((lavorazione) => lavorazione.id === Number(id))
          .nome,
      }))
    }
  }, [filters.lavorazioni, lavorazioni])

  return (
    <div className="container-fluid">
      {lavorazioniOrdine === null && loading && (
        <div className="text-center mt-4">
          <Spinner color="primary" />
        </div>
      )}
      {lavorazioniOrdine && (
        <>
          <div className="text-center d-flex justify-content-center text-primary mt-2"></div>
          <div className="d-flex align-items-center">
            <div
              style={{
                height: 20,
                width: 30,
                background: COLOR_ACTIVITIES,
              }}
            ></div>
            <div className="ml-2">Attività fuori tempo </div>
          </div>
          <div className="filters mt-2">
            <div className="row">
              <div className="col-md-3">
                <select
                  className="form-control"
                  name="operatore"
                  value={filters.operatore}
                  onChange={onFilterChange}
                >
                  <option value="">Filtra per operatore</option>
                  {operatori &&
                    operatori.map((operatore) => (
                      <option value={operatore.id} key={operatore.id}>
                        {operatore.user.first_name} {operatore.user.last_name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-3">
                <Select
                  name="lavorazioni"
                  isMulti
                  placeholder={'Filtra per lavorazioni'}
                  options={lavorazioniForSelect.filter(
                    (lavorazione) =>
                      !selectedLavorazioni.find(
                        (item) =>
                          Number(item.value) === Number(lavorazione.value)
                      )
                  )}
                  classNamePrefix="multi-select"
                  hideSelectedOptions={false}
                  isOptionSelected={(option) =>
                    selectedLavorazioni.find(
                      (item) => item.value === option.value
                    )
                  }
                  value={selectedLavorazioni}
                  className="basic-multi-select select-multi-options text-nowrap w-100"
                  onChange={(newValues) => {
                    if (newValues) {
                      const items = newValues.map((item) => item.value)
                      onFilterChange(items, 'lavorazioni')
                    } else {
                      onFilterChange([], 'lavorazioni')
                    }
                  }}
                />
              </div>
              <div className="col-md-3">
                <OrdersSelectAutoComplete
                  value={filters.ordine}
                  hasValue
                  debounceTimeout={150}
                  loadOptionsOnMenuOpen
                  onChange={(ordine) => {
                    if (ordine) {
                      onFiltersChange({ ordine: ordine.id })
                    } else {
                      onFiltersChange({ ordine: '' })
                    }
                  }}
                  noOptionsMessage={() => 'Nessun ordine trovato'}
                  loadingMessage={() => 'Caricamento ordini...'}
                  placeholder={'Filtra per ordine'}
                  isClearable={true}
                  name={'ordine'}
                  additional={{
                    page: 1,
                  }}
                />
              </div>
              <div className="col-md-3">
                <DateRangeQsFilter
                  filters={filters}
                  onFiltersChange={onFiltersChange}
                  startField="from_date"
                  endField="to_date"
                  withPortal={true}
                  customArrowIcon={<div />}
                  small={false}
                  noBorder
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <TableLavorazioniOrdine
                lavorazioniOrdine={lavorazioniOrdine}
                loading={loading}
                totals={totals}
              />
              <Paginator
                numPages={pagination.numPages}
                currentPage={page}
                goToPage={goToPage}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}
