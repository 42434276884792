import React, { useContext } from 'react'
import { useRj } from 'react-rocketjump'
import FormOrder from '../../components/Planner/FormOrder'
import { AddOrderState } from './localstate'
import { ConfigPlannerContext } from '../../context'

export default function AddOrder({ history }){
  const { tipiSerramento, codiciSerramento } = useContext(ConfigPlannerContext)
  const { run: addOrder } = useRj(AddOrderState)[1]

  return(
    <div className='container-fluid'>
      <FormOrder
        tipiSerramento={tipiSerramento}
        codiciSerramento={codiciSerramento}
        history={history}
        save={ordine => {
          return addOrder
            .onSuccess((createdOrder) => {
              history.push(`/ordini/${createdOrder.id}/edit`)
            })
            .asPromise(ordine)
        }}
      />
    </div>
  )
}
