import qs from 'query-string'
import { ajax } from 'rxjs/ajax'
import { map } from 'rxjs/operators'
import omit from 'lodash/omit'
import { rj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import rjDebounce from 'react-rocketjump/plugins/debounce'
import { SETTINGS, CSRF } from '../../django'

export const UsersState = rj(
  rjDebounce(),
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  {
    name: 'UsersState',
    mutations: {
      deleteUser: rj.mutation.single({
        effect: (user) =>
          ajax({
            method: 'delete',
            url: `/api/admin-users/users/${user.id}`,
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CSRF,
            },
          }).pipe(map(() => user)),
        updater: 'deleteItem',
      }),
    },
    computed: {
      deleting: s => s.mutations.deleteUser.pending,
      users: 'getList',
      pagination: 'getPagination',
      loading: 'isLoading',
      error: 'getError',
    },
    effect: (params = {}) =>
      ajax.getJSON(`/api/admin-users/users/?${qs.stringify(params)}`),
  }
)

export const UserDetailState = rj({
  mutations: {
    createUser: {
      effect: (user) =>
        ajax({
          method: 'POST',
          url: `/api/admin-users/users/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(user),
        }).pipe(map((r) => r.response)),
      updater: 'updateData',
    },
    updateUser: {
      effect: (user) =>
        ajax({
          method: 'PUT',
          url: `/api/admin-users/users/${user.id}/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(user),
        }).pipe(map((r) => r.response)),
      updater: 'updateData',
    },
    createOperator: {
      effect: (operator) =>
        ajax({
          method: 'POST',
          url: `/api/admin-users/operators/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(operator),
        }).pipe(map((r) => r.response)),
      updater: (state, operatore) => ({
        ...state,
        data: {
          ...state.data,
          operatore,
        },
      }),
    },
    updateOperator: {
      effect: (operator) =>
        ajax({
          method: 'PUT',
          url: `/api/admin-users/operators/${operator.id}/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(operator),
        }).pipe(map((r) => r.response)),
      updater: (state, operatore) => ({
        ...state,
        data: {
          ...state.data,
          operatore,
        },
      }),
    },
    deleteOperatore: rj.mutation.single({
      effect: (operatore) =>
        ajax({
          method: 'DELETE',
          url: `/api/admin-users/operators/${operatore.id}/`,
          headers: {
            'X-CSRFToken': CSRF,
          },
        }).pipe(map((r) => r.response)),
      updater: (state, operatore) => ({
        ...state,
        data: {
          ...state.data,
          operatore: null,
        },
      }),
    }),
    createAgente: {
      effect: (agent) =>
        ajax({
          method: 'POST',
          url: `/api/admin-users/agenti/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(agent),
        }).pipe(map((r) => r.response)),
      updater: (state, agente) => ({
        ...state,
        data: {
          ...state.data,
          agente: {
            ...omit(agente, 'capo_area_data'),
            capo_area: agente.capo_area_data,
          }
        },
      }),
    },
    updateAgente: {
      effect: (agente) =>
        ajax({
          method: 'PUT',
          url: `/api/admin-users/agenti/${agente.id}/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(agente),
        }).pipe(map((r) => r.response)),
      updater: (state, agente) => ({
        ...state,
        data: {
          ...state.data,
          agente: {
            ...omit(agente, 'capo_area_data'),
            capo_area: agente.capo_area_data,
          }
        },
      }),
    },
    deleteAgente: rj.mutation.single({
      effect: (agente) =>
        ajax({
          method: 'DELETE',
          url: `/api/admin-users/agenti/${agente.id}/`,
          headers: {
            'X-CSRFToken': CSRF,
          },
        }).pipe(map((r) => r.response)),
      updater: (state, agente) => ({
        ...state,
        data: {
          ...state.data,
          agente: null,
        },
      }),
    }),
    createAccount: {
      effect: (account) =>
        ajax({
          method: 'POST',
          url: `/api/admin-users/accounts/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(account),
        }).pipe(map((r) => r.response)),
      updater: (state, app_account) => ({
        ...state,
        data: {
          ...state.data,
          app_account,
        },
      }),
    },
    updateAccount: {
      effect: (account) =>
        ajax({
          method: 'PUT',
          url: `/api/admin-users/accounts/${account.id}/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(account),
        }).pipe(map((r) => r.response)),
      updater: (state, app_account) => ({
        ...state,
        data: {
          ...state.data,
          app_account,
        },
      }),
    },
    deleteAccount: rj.mutation.single({
      effect: (account) =>
        ajax({
          method: 'DELETE',
          url: `/api/admin-users/accounts/${account.id}/`,
          headers: {
            'X-CSRFToken': CSRF,
          },
        }).pipe(map((r) => r.response)),
      updater: (state, account) => ({
        ...state,
        data: {
          ...state.data,
          app_account: null,
        },
      }),
    }),
    updateAppAccessoClienti: rj.mutation.single({
      effect: (accountId, clientiIds) =>
        ajax({
          method: 'PUT',
          url: `/api/admin-users/accounts/${accountId}/accesso_clienti/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify({
            ids: clientiIds,
          }),
        }).pipe(map((r) => r.response)),
      updater: (state, app_account) => ({
        ...state,
        data: {
          ...state.data,
          app_account,
        },
      }),
    }),
    deleteUser: rj.mutation.single({
      effect: (user) =>
        ajax({
          method: 'DELETE',
          url: `/api/admin-users/users/${user.id}/`,
          headers: {
            'X-CSRFToken': CSRF,
          },
        }).pipe(map((r) => r.response)),
      updater: (state) => state,
    }),
    updatePassword: {
      effect: (userId, password) =>
        ajax({
          method: 'PUT',
          url: `/api/admin-users/users/${userId}/update_password/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify({ password }),
        }).pipe(map((r) => r.response)),
      updater: (state) => state,
    },
  },
  name: 'UserDetail',
  computed: {
    user: 'getData',
    deletingUser: s => s.mutations.deleteUser.pending,
    deletingOperatore: s => s.mutations.deleteOperatore.pending,
    deletingAccount: s => s.mutations.deleteAccount.pending,
    deletingAgente: s => s.mutations.deleteAgente.pending,
    updatingAccessoCliente: s => s.mutations.updateAppAccessoClienti.pending,
  },
  effect: (id) => ajax.getJSON(`/api/admin-users/users/${id}/`),
})
