import React, { useContext, useState } from 'react'
import {
  useQsDataTable as useDataTable,
  useQsDataTable,
} from 'react-use-datatable/react'
import { WarningsOrdiniFornitori } from '../localstate'
import Paginator from '../../../components/Paginator'
import OrdersSelectAutoComplete from '../../../components/OrdersSelectAutoComplete'
import { Field, Formik } from 'formik'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { ConfigPlannerContext } from '../../../context'
import { DateField, InputField } from '../../../components/Form/Fields'
import { transformErrorForForm } from '../../../components/Form/Fields/utils'

export default function WarningOrdiniFornitore() {
  const [
    { list: warnings, pagination },
    { page, ...filters },
    { goToPage, onFiltersChange, updateStatoAndData, run: fetchWarnings },
  ] = useDataTable(WarningsOrdiniFornitori, () => ({
    page: 1,
    ordine: '',
  }))

  const [openModal, setOpenModal] = useState(null)

  const { valoriStato } = useContext(ConfigPlannerContext)
  const stati = valoriStato.ordine_fornitore || []

  return (
    <div className="container-fluid mt-3">
      <div className="row mb-3">
        <div className="col-md-3">
          <OrdersSelectAutoComplete
            value={filters.ordine}
            hasValue
            debounceTimeout={150}
            loadOptionsOnMenuOpen
            onChange={(ordine) => {
              if (ordine) {
                onFiltersChange({ ordine: ordine.id })
              } else {
                onFiltersChange({ ordine: '' })
              }
            }}
            noOptionsMessage={() => 'Nessun ordine trovato'}
            loadingMessage={() => 'Caricamento ordini...'}
            placeholder={'Filtra per ordine'}
            isClearable={true}
            name={'ordine'}
            additional={{
              page: 1,
            }}
          />
        </div>
      </div>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Ordine</th>
            <th>Fornitore</th>
            <th>Materiale</th>
            <th>Stato</th>
            <th>Data inserimento</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {warnings?.map((warning, i) => (
            <tr key={i}>
              <td>
                {warning.ordine_data?.numero_commessa} -{' '}
                {warning.ordine_data?.cliente?.nome}
              </td>
              <td>{warning.fornitore_data?.nome}</td>
              <td>{warning.fornitore_data?.tipologia}</td>
              <td>{warning.stato}</td>
              <td>{warning.data_inserimento}</td>
              <td>
                <div
                  onClick={() => {
                    setOpenModal(warning)
                  }}
                  className="pointer"
                >
                  <i
                    title="Modifica ordine fornitore"
                    className="fas fa-pencil-alt text-warning"
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginator
        numPages={pagination.numPages}
        currentPage={page}
        goToPage={goToPage}
      />
      <Modal
        isOpen={openModal}
        toggle={() => setOpenModal(null)}
        size="md"
        centered
      >
        <ModalHeader toggle={() => setOpenModal(null)}>
          Modifica ordine fornitore {openModal?.fornitore_data?.nome}
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              data_arrivo: openModal?.data_arrivo,
              stato: openModal?.stato,
              valore_percentuale: openModal?.valore_percentuale,
            }}
            onSubmit={(values, actions) => {
              updateStatoAndData
                .onSuccess(() => {
                  actions.setSubmitting(false)
                  setOpenModal(null)
                  fetchWarnings(filters)
                })
                .onFailure((error) => {
                  actions.setSubmitting(false)
                  actions.setErrors(transformErrorForForm(error))
                })
                .run(openModal.id, values)
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  label="Data arrivo"
                  type="text"
                  name="data_arrivo"
                  component={DateField}
                  className="form-control"
                />
                <div className="form-group mt-3">
                  <Field
                    label="Valore percentuale"
                    name="valore_percentuale"
                    component={InputField}
                    className="form-control"
                  />
                </div>
                <div className="form-group mt-3">
                  <Field
                    label="Stato"
                    type="select"
                    name="stato"
                    component={InputField}
                    className="form-control"
                  >
                    <option value=""></option>
                    {stati.map((stato) => (
                      <option key={stato.nome} value={stato.nome}>
                        {stato.nome}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="form-group mt-3 d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    Salva
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  )
}
