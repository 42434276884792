import React from 'react'
import { HashRouter as Router, Redirect, Switch } from 'react-router-dom'
import FoldersList from './FoldersList'
import FolderDetail from './FolderDetail'
import { PERMISSIONS } from '../../django'
import PermissionRoute from '../../components/PermissionRoute'

export default function QrApp() {
  return (
    <Router>
      <Switch>
        <PermissionRoute
          hasPermission={PERMISSIONS.viewFolders}
          path="/folders/:id"
          exact
          component={FolderDetail}
        />
        <PermissionRoute
          hasPermission={PERMISSIONS.viewFolders}
          path="/folders"
          exact
          component={FoldersList}
        />
        <Redirect from="/" to="/folders" />
      </Switch>
    </Router>
  )
}
