import React, { useMemo } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap'
import * as Yup from 'yup'
import repeat from 'lodash/repeat'
import { Formik, Field } from 'formik'
import { InputField } from '../Form/Fields'
import { transformErrorForForm } from '../Form/Fields/utils'

const InitialFolderValues = { name: '', parent_folder: '' }
const FolderSchema = Yup.object().shape({
  name: Yup.string().required(),
})

function recursiveOptions(foldersTree, id, deep = 0) {
  const options = foldersTree.reduce((flatTree, folder) => {
    if (folder.id !== id) {
      return flatTree
        .concat({
          text: repeat("\u00A0", deep * 4) + folder.name,
          value: folder.id,
        })
        .concat(recursiveOptions(folder.child_folders, id, deep + 1))
    }
    return flatTree
  }, [])
  return options
}

const RecursiveFoldersOptions = React.memo(({ foldersTree, folderId }) => {
  const options = [{ value: '', text: '--' }]
    .concat(recursiveOptions(foldersTree, folderId))
  return options.map((option) => (
    <option key={option.value} value={option.value}>
      {option.text}
    </option>
  ))
})

function FormFolder({
  folder = null,
  toggle,
  save,
  foldersTree,
  loadingFoldersTree,
  withParentFolder = false,
}) {
  const initalValues = useMemo(() => {
    if (!folder) {
      return InitialFolderValues
    }
    return {
      ...folder,
      parent_folder: folder.parent_folder === null ? '' : folder.parent_folder,
    }
  }, [folder])
  return (
    <Formik
      onSubmit={(values, actions) => {
        return save(values).then(
          () => {
            // actions.setSubmitting(false)
          },
          (error) => {
            actions.setSubmitting(false)
            actions.setErrors(transformErrorForForm(error))
          }
        )
      }}
      validationSchema={FolderSchema}
      initialValues={initalValues}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="m-0 p-0">
          <ModalBody>
            <Field
              autoComplete="off"
              component={InputField}
              label="Nome"
              name="name"
            />
            <Field
              autoComplete="off"
              component={InputField}
              type="textarea"
              label="Descrizione"
              name="description"
            />
            {withParentFolder && (
              <Field
                disabled={loadingFoldersTree}
                component={InputField}
                label="Cartella padre"
                name="parent_folder"
                type="select"
              >
                {foldersTree && (
                  <RecursiveFoldersOptions
                    folderId={folder.id}
                    foldersTree={foldersTree}
                  />
                )}
              </Field>
            )}
          </ModalBody>
          <ModalFooter className="d-flex justify-content-between">
            <Button disabled={isSubmitting} type="button" onClick={toggle}>
              Annulla
            </Button>
            <Button
              disabled={isSubmitting || loadingFoldersTree}
              type="submit"
              color="success"
            >
              {folder && folder.id ? 'Modifica' : 'Crea'}
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  )
}

export default function ModalFormFolder({
  isOpen,
  folder,
  toggle,
  header,
  save,
  ...props
}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      {header && <ModalHeader toggle={toggle}>{header}</ModalHeader>}
      <FormFolder folder={folder} toggle={toggle} save={save} {...props} />
    </Modal>
  )
}
