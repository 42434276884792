import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ModalDeleteOrder = ({ isOpen, toggle, onSetEliminato, deleting, ordine }) => {
  return(
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}>Elimina ordine</ModalHeader>
      <ModalBody>
        <div className='container'>
          Sei sicuro di voler eliminare l'ordine con numero commessa {ordine && ordine.numero_commessa}{' '}
          per il cliente {ordine && ordine.cliente_data ? ordine.cliente_data.nome : ''} ?
        </div>
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between'>
        <Button color="secondary" onClick={toggle}>Chiudi</Button>
        <Button color="danger" disabled={deleting} onClick={onSetEliminato}>Elimina</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalDeleteOrder
