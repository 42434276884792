import React from 'react'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'

const TablePersonale = ({ persone, setPersona, setShowModalDeletePersona, setShowModalFormPersona }) => {
  return(
    <table className='table table-bordered table-striped mt-2'>
      <thead>
        <tr>
          <th>Nome</th>
          <th>Reparto</th>
          <th>Data inizio</th>
          <th>Data fine</th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody>
        {persone.map((persona,key) => (
          <tr key={key}>
            <td>{persona.nome}</td>
            <td>{persona.reparto}</td>
            <td>{dayjs(persona.data_inizio).format('DD-MM-YYYY')}</td>
            <td>{persona.data_fine && dayjs(persona.data_fine).format('DD-MM-YYYY')}</td> 
            <td className='text-center'>
              <div className='d-flex justify-content-center'>
                <Link to={`/personale/${persona.id}`}><i title={`Dettaglio ${persona.nome}`} className="fas fa-search"></i></Link>
                <div className='pointer ml-2' onClick={() => {
                  setPersona(persona)
                  setShowModalFormPersona(true)
                  }}>
                  <i title={`Modifica ${persona.nome}`} className="fas fa-pencil-alt text-warning"></i>
                </div>
                <div className='pointer ml-2' onClick={() => {
                  setPersona(persona)
                  setShowModalDeletePersona(true)
                  }}>
                  <i title={`Elimina ${persona.nome}`} className="fas fa-trash text-danger"></i>
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default TablePersonale
