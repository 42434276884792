import React, { useMemo } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { InputField, AutoCompleteField } from '../../Form/Fields'
import {
  transformErrorForForm,
  setNullOnEmptyValues,
} from '../../Form/Fields/utils'
import moment from 'moment'

const AttivitaSchema = Yup.object().shape({
  lavorazione: Yup.number().required(),
  // ora_inizio: Yup.string().required(),
})

const ModalFormAttivita = ({
  isOpen,
  toggle,
  save,
  lavorazioni,
  date,
  lavorazioniLocked,
}) => {
  const initialValues = useMemo(() => {
    return {
      lavorazione: '',
      seconda_lavorazione: '',
      ordine: '',
      ora_inizio: '',
    }
  }, [])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" scrollable>
      <ModalHeader toggle={toggle}>
        {'Aggiungi attività - ' + moment(date).format('DD/MM/YYYY')}
      </ModalHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={AttivitaSchema}
        onSubmit={(values, actions) => {
          return save(setNullOnEmptyValues(values)).then(
            () => {
              actions.setSubmitting(false)
            },
            (error) => {
              actions.setSubmitting(false)
              actions.setErrors(transformErrorForForm(error))
            }
          )
        }}
        render={({
          handleSubmit,
          values,
          isSubmitting,
          errors,
          setFieldValue,
        }) => (
          <>
            <ModalBody>
              <form onSubmit={handleSubmit}>
                <Field
                  component={InputField}
                  label="Lavorazione *"
                  name="lavorazione"
                  type="select"
                  className="mb-2"
                >
                  <option value=""></option>
                  {lavorazioni &&
                    lavorazioni.map((lavorazione) => (
                      <option key={lavorazione.id} value={lavorazione.id}>
                        {lavorazione.nome}
                      </option>
                    ))}
                </Field>
                <Field
                  label="Ordine"
                  noOptionsMessage={() => 'Nessun ordine trovato'}
                  loadingMessage={() => 'Caricamento ordini...'}
                  type="text"
                  name="ordine"
                  autocompleteUrl={
                    values.lavorazione
                      ? '/api/planner/ordini/lista-attivita/' +
                        values.lavorazione +
                        '/'
                      : null
                  }
                  disabled={values.lavorazione ? false : true}
                  component={AutoCompleteField}
                  cacheUniqs={[values.lavorazione]}
                  className="mb-2"
                />
                <Field
                  label="Seconda Lavorazione"
                  noOptionsMessage={() => 'Nessun ordine trovato'}
                  loadingMessage={() => 'Caricamento ordini...'}
                  name="seconda_lavorazione"
                  autocompleteUrl={
                    values.lavorazione && values.ordine
                      ? '/api/planner/ordini/' +
                        values.ordine.id +
                        '/lista-lavorazioni-secondarie/' +
                        values.lavorazione +
                        '/'
                      : null
                  }
                  disabled={values.lavorazione && values.ordine ? false : true}
                  component={AutoCompleteField}
                  makeOptionsCall={(list) => {
                    return list && list.results 
                      ? list.results.map((item) => ({
                          ...item,
                          label: item.nome,
                          value: item.id,
                        }))
                      : []
                  }}
                  cacheUniqs={[values.ordine, values.lavorazione]}
                  className="mb-2"
                />
                <input type="submit" className="d-none" />
              </form>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
              <Button
                disabled={isSubmitting}
                color="secondary"
                onClick={toggle}
              >
                Chiudi
              </Button>
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
                color="success"
              >
                Avanti
              </Button>
            </ModalFooter>
          </>
        )}
      />
    </Modal>
  )
}

export default ModalFormAttivita
