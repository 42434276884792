import { createContext } from 'react'

export const ContextFornitori = createContext([])

export const ContextFornitoriActions = createContext({})

export const ContextOrdineOrdiniFornitoriActions = createContext({})

export const ContextOrdineOrdiniFornitoriRigheActions = createContext({})

export const ContextOrderDetailEvents = createContext(null)
