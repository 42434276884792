import React from 'react'
import { Form } from 'formik'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap'
import DateTimeRangeForm from './DateTimeRangeForm'

export default function DateTimeRangeModalForm({ title, isOpen, toggle, onDelete, deleting = false, ...props }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <DateTimeRangeForm
        {...props}
        renderForm={({ isSubmitting, setFieldValue }, fields, { allDay, toggleAllDay }) => (
          <Form className='m-0 p-0'>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
              {fields}
            </ModalBody>
            <ModalFooter className='d-flex justify-content-between'>
              <Button onClick={toggle} type='button' color='secondary'>Annulla</Button>
              <div>
                <Button onClick={() => {
                  setFieldValue('ora_da', '07:00')
                  setFieldValue('ora_a', '12:00')
                  if (allDay) {
                    toggleAllDay()
                  }
                }}
                className='mr-1' type='button' color='primary'>Mattina</Button>
                <Button onClick={() => {
                  setFieldValue('ora_da', '13:00')
                  setFieldValue('ora_a', '19:00')
                  if (allDay) {
                    toggleAllDay()
                  }
                }}
                className='mr-1' type='button' color='primary'>Pomerggio</Button>
                {onDelete && <Button
                  onClick={onDelete}
                  className='mr-2'
                  disabled={isSubmitting || deleting} type='button' color='danger'>Elimina</Button>}
                <Button disabled={isSubmitting || deleting} type='submit' color='success'>Salva</Button>
              </div>
            </ModalFooter>
          </Form>
        )}
      />
    </Modal>
  )
}
