import React, { useState, useMemo } from 'react'
// import { rj, useRunRj } from 'react-rocketjump'
// import { ajax } from 'rxjs/ajax'
import camelCase from 'lodash/camelCase'
import mapKeys from 'lodash/mapKeys'
import StepperForm from './StepperForm'
import { Button } from 'reactstrap'
import { ConfigPrevetiviContext } from '../../context'
import { makeFamiglieById, makeTipiSerramentoById } from '../../helpers'
import { readDjangoJson } from '../../django'

const PREVENTIVI_CONFIG = readDjangoJson('preventivi-config')

// Uncomment 2 reading conf from API
// const ConfigPreventiviState = rj(
//   () => ajax.getJSON(`/api/preventivi-config/`)
// )

export default function FormApp() {

  // Grab config from API
  // const [{ data: rawConfig }] = useRunRj(ConfigPreventiviState)

  // Grab config from Django
  const rawConfig = PREVENTIVI_CONFIG

  // Is a preventivo created in this "session"?
  // null or last created preventivo
  const [preventivoCreated, setPreventivoCreated] = useState(null)

  // Enhance raw config from api \w sum good stuff
  const configPreventivi = useMemo(() => {
    if (rawConfig === null) {
      return null
    }

    const maCamelConfig = mapKeys(rawConfig, (_, k) => camelCase(k))
    return {
      ...maCamelConfig,
      famiglieById: makeFamiglieById(maCamelConfig.tipologieFamiglia),
      tipiSerramentoById: makeTipiSerramentoById(maCamelConfig.tipiSerramento),
    }
  }, [rawConfig])

  return (
    <div className='App'>
      <div className="grey-container p-2 d-block d-md-none">
        <h3 className="grey-title" style={{ fontSize: 17}}>Fai un preventivo</h3>
        <span className='text-white' style={{ fontSize: 12}}>Preventivo gratuito serramenti - Calcola il costo dei tuoi infissi</span>
      </div>
      <div className="grey-container p-2 d-none d-md-block">
        <h3 className="grey-title">Fai un preventivo</h3>
        <span className='text-white'>Preventivo gratuito serramenti - Calcola il costo dei tuoi infissi</span>
      </div>
      <div className='position-relative'>
        <div className='pt-3 pb-2 container text-center d-block d-md-none' style={{ fontSize: 12}}>
          Acquista direttamente in fabbrica, visita il sito <a href='http://www.qrlegno.it' rel='noopener noreferrer' target='_blank'>www.qrlegno.it</a><br />
          per conoscere più dettagliatamente i nostri prodotti.
        </div>
        <div className='pt-3 pb-2 container text-center d-none d-md-block'>
          Acquista direttamente in fabbrica, visita il sito <a href='http://www.qrlegno.it' rel='noopener noreferrer' target='_blank'>www.qrlegno.it</a><br />
          per conoscere più dettagliatamente i nostri prodotti.
        </div>
        <div className='text-right position-absolute d-none d-md-block' style={{right:20,top:10}}>
          <img src='/static/ui/images/numeroverde_small.png' alt='Numero Verde' />
        </div>
      </div>
      <div suppressHydrationWarning={true}>
        {configPreventivi && <ConfigPrevetiviContext.Provider value={configPreventivi}>
          <>
            {/* Show the step by step form  */}
            {preventivoCreated === null && <StepperForm
              onPreventivoCreated={setPreventivoCreated}
            />}

            {/* Show Success message  */}
            {preventivoCreated !== null && (
              <div className='container'>
                <div className='row d-flex flex-column align-items-center mt-4'>
                  <div className="alert alert-success" role="alert">
                    Richiesta preventivo inviata con successo.
                  </div>
                  <br />
                  <Button color='secondary' onClick={() => setPreventivoCreated(null)}>
                    <i className="fas fa-chevron-circle-left"></i> Crea nuovo preventivo
                  </Button>
                </div>
              </div>
            )}
          </>
        </ConfigPrevetiviContext.Provider>}
      </div>
    </div>
  )
}
