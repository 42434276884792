import React, { useState, useMemo, useEffect } from 'react'
import find from 'lodash/find'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { rj, useRunRj } from 'react-rocketjump'
import { ajax } from 'rxjs/ajax'

const TemplatesMail = rj(preventivoId => ajax.getJSON(`/api/email-templates?preventivo=${preventivoId}`))

const ModalInviaMail = ({ isOpen, toggle, preventivo, onSend, sending }) => {

  const [{ data: templates }] = useRunRj(TemplatesMail, [preventivo.id])
  const [templateId, setTemplateId] = useState('')

  useEffect(() => setTemplateId(''), [isOpen])

  const template = useMemo(() => find(templates, { id: +templateId }), [templates, templateId])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}><i className="fas fa-envelope"></i> Invia mail</ModalHeader>
      <ModalBody>
        <div className='container'>
          <div className='row'>
            <select name='agente' className='form-control' value={templateId} onChange={e => setTemplateId(e.target.value)}>
              <option value=''>Seleziona un template</option>
              {templates && templates.map(template => (
                <option key={template.id} value={template.id}>{template.label}</option>
              ))}
            </select>
          </div>

          <div className='row'>
            {template && <div className='col-md-12 p-0'>
              <hr />
              <div>
                <input readOnly className='form-control mt-2' value={template.subject_compiled} />
              </div>
              <textarea
                value={template.body} readOnly rows={10} className='border mt-2 form-control' style={{ whiteSpace: 'pre' }}
              />
            </div>}
          </div>
        </div>
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between'>
        <Button color="secondary" disabled={sending} onClick={toggle}>Chiudi</Button>
        <Button onClick={() => onSend(template)} color="success" disabled={!template || sending}>
          {sending ? 'Invio mail in corso ...' : 'Invia e-mail'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalInviaMail
