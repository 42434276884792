import React, { useCallback, useContext } from 'react'
import { getFamilyOptionsValuesDefault, useIsDesktop } from '../../../../helpers'
import { connect } from 'formik'
import Step1Mobile from './Step1Mobile'
import Step1Desktop from './Step1Desktop'
import { ConfigPrevetiviContext } from '../../../../context'

function Step1({ setStep, formik }) {

  const { famiglieById, tipologieFamiglia } = useContext(ConfigPrevetiviContext)

  const isDesktop = useIsDesktop()

  const { values, setFieldValue } = formik

  const selectedFamily = values.famiglia ? famiglieById[values.famiglia] : null

  const resetFamilyOptions = useCallback(familyId =>
    setFieldValue('options', getFamilyOptionsValuesDefault(famiglieById[familyId])), [setFieldValue, famiglieById])

  const props = {
    tipologieFamiglia,
    setStep,
    selectedFamily,
    resetFamilyOptions,
    values,
  }

  if (isDesktop) {
    return (
      <Step1Desktop {...props} />
    )
  } else {
    return (
      <Step1Mobile {...props} />
    )
  }
}

export default connect(Step1)
