import qs from 'query-string'
import { ajax } from 'rxjs/ajax'
import { map } from 'rxjs/operators'
import { rj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import rjDebounce from 'react-rocketjump/plugins/debounce'
import { rjListWithTotals } from './common'
import { SETTINGS, CSRF } from '../../../django'

export const ImportRigheOrdiniFornitoreState = rj({
  name: 'ImportRigheOrdiniFornitoreState',
  effect: (file, options) => {
    const form = new FormData()
    form.set('file', file)
    form.set('generate_ordini_fornitori', options.generate_ordini_fornitori)
    form.set('collapse_righe', options.collapse_righe)
    form.set('import_riga_ordine_column', options.import_riga_ordine_column)
    if (options.import_only_order) {
      form.set('import_only_order', Number(options.import_only_order))
    }
    return ajax({
      url: '/api/planner/import-righe-ordini-fornitore/',
      method: 'post',
      headers: {
        'X-CSRFToken': CSRF,
      },
      body: form,
    }).pipe(map((r) => r.response))
  },
})

export const ScattaFotoRigaOrdineState = rj({
  name: 'ScattaFotoRigaOrdineState',
  effect: () => {
    return ajax({
      url: '/api/localservices/svc/shot',
      method: 'post',
      headers: {
        'X-CSRFToken': CSRF,
      },
      body: null,
    }).pipe(map((r) => r.response))
  },
})

export const BomRigaOrdineState = rj({
  name: 'BomRigaOrdineState',
  computed: {
    bom: 'getData',
    loading: 'isLoading',
    error: 'getError',
  },
  effect: (order, riga, posizione) => {
    return ajax.getJSON(`/api/localservices/svc/bom?order=${order}&riga=${riga}&posizione=${posizione}`)
  }
})

export const ImmaginiRigaOrdineState = rj({
  name: 'ImmaginiRigaOrdineState',
  effect: (id) => ajax.getJSON(`/api/planner/righe-ordini/${id}/immagini/`),
})

export const DeleteImmagineRigaOrdineState = rj({
  name: 'DeleteImmagineRigaOrdineState',
  effect: (id) => ajax.delete(`/api/planner/immagini-righe-ordini/${id}/`),
})

export const UploadImmagineRigaOrdineState = rj({
  name: 'UploadImmagineRigaOrdineState',
  effect: (id, values) => {
    return ajax({
      url: `/api/planner/righe-ordini/${id}/save-immagine/`,
      method: 'post',
      headers: {
        'X-CSRFToken': CSRF,
      },
      body: values,
    }).pipe(map((r) => r.response))
  },
})

export const UpdateExtraFieldsState = rj({
  name: 'UpdateExtraFieldsState',
  effect: (id, fields) =>
    ajax({
      url: `/api/planner/righe-ordini/${id}/update-extra-fields/`,
      method: 'put',
      headers: {
        'X-CSRFToken': CSRF,
      },
      body: fields,
    }).pipe(map((r) => r.response)),
})

export const RigheOrdiniState = rj(
  rjDebounce(),
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  rjListWithTotals(),
  {
    name: 'RigheOrdiniState',
    computed: {
      righe: 'getList',
      totals: 'getTotals',
      pagination: 'getPagination',
      loading: 'isLoading',
      error: 'getError',
    },
    effect: (params = {}) =>
      ajax.getJSON(
        `/api/planner/righe-ordini/?${qs.stringify({
          ...params,
          categoria:
            params.categoria.length === 0 ? undefined : params.categoria,
          forma: params.forma.length === 0 ? undefined : params.forma,
          codice: params.codice.length === 0 ? undefined : params.codice,
        })}`
      ),
  }
)
