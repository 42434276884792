import React from 'react'
import moment from 'moment'
import { OrdiniFornitoreState } from './localstate'
import { useQsDataTable as useDataTable } from 'react-use-datatable/react'
import Paginator from '../../components/Paginator'
import Column from '../../components/Column'
import { FORNITORE_CONFIG } from '../../django'

const OrdineFornitoreRow = React.memo(({ ordineFornitore, onStatoClicked }) => (
  <tr>
    <td>{ordineFornitore.ordine.numero_commessa}</td>
    <td>{ordineFornitore.ordine.numero_serramenti}</td>
    <td>
      {ordineFornitore.ordine.tipi_serramenti.map((tipo, i) => (
        <div key={i}>{tipo}</div>
      ))}
    </td>
    <td>{ordineFornitore.ordine.riferimento}</td>
    <td>{ordineFornitore.ordine.nome_cliente}</td>
    <td>{ordineFornitore.ordine.note}</td>
    <td>
      {ordineFornitore.schedulazione
        ? moment(ordineFornitore.schedulazione.inizio).format('DD/MM/YYYY')
        : null}
    </td>
    <td>
      {ordineFornitore.schedulazione
        ? moment(ordineFornitore.schedulazione.fine).format('DD/MM/YYYY')
        : null}
    </td>
    <td>{ordineFornitore.fornitore.nome}</td>
    <td>{ordineFornitore.stato}</td>
    <td>
      <ul>
        {ordineFornitore.allegati_data.map((allegato) => (
          <li key={allegato.id}>
            <a href={allegato.allegato}>
              {allegato.original_name || 'Allegato'}
            </a>
          </li>
        ))}
      </ul>
    </td>
    <td>{ordineFornitore.note}</td>
  </tr>
))

const FilterRow = ({
  filters,
  onFilterChange,
  onFiltersChange,
  onFilterChangeDebounced,
}) => {
  const statiOrdine = FORNITORE_CONFIG.valori_stato
  return (
    <tr>
      <th></th>
      <th></th>
      <th></th>
      <th>
        <input
          className="form-control"
          placeholder="Cerca riferimento"
          autoComplete="off"
          type="text"
          name="ordine_riferimento"
          value={filters.ordine_riferimento}
          onChange={onFilterChangeDebounced}
        />
      </th>
      <th>
        <input
          className="form-control"
          placeholder="Cerca cliente"
          autoComplete="off"
          type="text"
          name="ordine_cliente"
          value={filters.ordine_cliente}
          onChange={onFilterChangeDebounced}
        />
      </th>
      <th>
        <input
          className="form-control"
          placeholder="Cerca note ordine"
          autoComplete="off"
          type="text"
          name="ordine_note"
          value={filters.ordine_note}
          onChange={onFilterChangeDebounced}
        />
      </th>
      <th></th>
      <th></th>
      <th></th>
      <th>
        <select
          value={filters.stato}
          onChange={onFilterChange}
          name="stato"
          className="form-control"
        >
          <option value=""></option>
          {statiOrdine.map((stato) => (
            <option value={stato.nome} key={stato.nome}>
              {stato.nome}
            </option>
          ))}
        </select>
      </th>
      <th></th>
      <th>
        <input
          placeholder="Cerca note"
          autoComplete="off"
          className="form-control"
          type="text"
          name="note"
          value={filters.note}
          onChange={onFilterChangeDebounced}
        />
      </th>
    </tr>
  )
}

export default function OrdiniFornitore() {
  const [
    { list: ordiniFornitore, pagination },
    { page, ...filters },
    {
      onFilterChange,
      onFiltersChange,
      onFilterChangeDebounced,
      goToPage,
      // TODO: Maybe polling reload?
      // reload,
    },
  ] = useDataTable(OrdiniFornitoreState, () => ({
    stato: '',
    note: '',
    ordering: '',
    ordine_cliente: '',
    ordine_note: '',
  }))

  const columnProps = {
    value: filters.ordering,
    onChange: (value) => onFilterChange(value, 'ordering'),
  }

  return (
    <div className="container-fluid">
      <div className="table-responsive mt-2">
        <table className="table table-bordered">
          <thead>
            <FilterRow
              filters={filters}
              onFilterChange={onFilterChange}
              onFiltersChange={onFiltersChange}
              onFilterChangeDebounced={onFilterChangeDebounced}
            />
            <tr>
              <Column {...columnProps} field="ordine__numero_commessa">
                Ordine <br /> N° Commessa
              </Column>
              <th>
                Ordine <br /> N° Serr
              </th>
              <th>
                Ordine <br /> Tipo serramenti
              </th>
              <Column {...columnProps} field="ordine__riferimento">
                Ordine <br /> Riferimento
              </Column>
              <Column {...columnProps} field="ordine__nome_cliente">
                Ordine <br /> Cliente
              </Column>
              <th>
                Ordine <br /> Note
              </th>
              <th>Inizio</th>
              <th>
                {FORNITORE_CONFIG.fornitore.tipologia === 'verniciatura'
                  ? 'Consegna in QR'
                  : 'Fine'}
              </th>
              <th>Fornitore</th>
              <Column {...columnProps} field="stato">
                Stato
              </Column>
              <th>Allegati</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            {ordiniFornitore &&
              ordiniFornitore.map((ordineFornitore) => (
                <OrdineFornitoreRow
                  key={ordineFornitore.id}
                  ordineFornitore={ordineFornitore}
                />
              ))}
          </tbody>
        </table>
      </div>
      <Paginator
        numPages={pagination.numPages}
        currentPage={page}
        goToPage={goToPage}
      />
    </div>
  )
}
