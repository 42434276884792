import React, { useState, useMemo } from 'react'
import { useRunRj } from 'react-rocketjump'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Paginator from '../Paginator'
import { OrdiniState } from '../../apps/PlannerApp/localstate'

function OrdersTable({ setSelectedOrder, toggle, selectedOrder }){
  //console.log(selectedOrder)
  const [query, setQuery] = useState('')
  const [scheduled, setScheduled] = useState('schedulabili')
  const [page, setPage] = useState(1)
  const params = useMemo(() => ({
    search: query,
    page: page,
    scheduled: scheduled,
    non_schedulabile: false,
  }),[query, page, scheduled])
  const [{ ordini, pagination }, { withNextMeta }] = useRunRj(OrdiniState,[params],false)
  function handleChange(e){
    const newQuery =  e.target.value
    setQuery(newQuery)
    withNextMeta({ debounced: true })
  }

  return (
    <>
      <div className='row'>
        <div className='col-md-6'>
          <input
            onChange={handleChange}
            type='text'
            value={query}
            className='form-control mb-2'
            placeholder='Ricerca ordine'
          />
        </div>
        <div className='col-md-6'>
          <select
            name='scheduled'
            onChange={(e) => setScheduled(e.target.value)}
            value={scheduled}
            className='form-control'>
            <option value='schedulabili'>Tutti</option>
            <option value='1'>Schedulati</option>
            <option value='0'>Non schedulati</option>
          </select>
        </div>
      </div>
      <table className='table table-bordered table-hover'>
        <thead>
          <tr>
            <th>Numero commessa</th>
            <th>Data ordine</th>
            <th>Nome cliente</th>
            <th>Inizio schedulazione</th>
          </tr>
        </thead>
        <tbody>
          {ordini && ordini.map((ordine,key) => (
            <tr
              className={classNames('pointer',{
              'table-primary': selectedOrder && selectedOrder.id === ordine.id
            })}
              key={ordine.id}
              onClick={() => {
                setSelectedOrder(ordine)
                toggle()
              }}
            >
              <td>{ordine.numero_commessa}</td>
              <td className='text-nowrap'>{dayjs(ordine.data).format('DD-MM-YYYY')}</td>
              <td>{ordine.cliente_data.nome}</td>
              <td>
                {ordine.schedulazione_ordine
                  ? <div>
                      <small><span className='mr-1'>Inizio:</span> {dayjs(ordine.schedulazione_ordine.inizio).format('DD-MM-YYYY HH:mm')}</small>
                      <hr style={{ margin: '5px 0px 5px 0px' }} />
                      <small><span className='mr-2'>Fine:</span> {dayjs(ordine.schedulazione_ordine.fine).format('DD-MM-YYYY HH:mm')}</small>
                    </div>
                  : <small>Non schedulato</small>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginator
        numPages={pagination.numPages}
        goToPage={setPage}
        currentPage={page}
      />
    </>
  )
}


export default function OrderSelector({ selectedOrder, setSelectedOrder, btnProps, renderBtn }) {
  const [isOpen, setShowModalOrder] = useState(null)
  const toggle = () => setShowModalOrder(false)
  return (
    <>
    {typeof renderBtn === 'function' ? renderBtn({
      onClick: () => setShowModalOrder(true),
    }) : (
      <Button
        size='md'
        color='success'
        outline
        onClick={() => setShowModalOrder(true)}
        {...btnProps}
        >
          {selectedOrder ? `Ordine #${selectedOrder.numero_commessa}` : 'Seleziona ordine'}
        </Button>
    )}
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader toggle={toggle}>Ricerca ordine da schedulare</ModalHeader>
      <ModalBody>
        <OrdersTable
          toggle={toggle}
          setSelectedOrder={setSelectedOrder}
          selectedOrder={selectedOrder}
        />
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between'>
        <Button color="secondary" onClick={toggle}>Chiudi</Button>
      </ModalFooter>
    </Modal>
    </>
  )
}
