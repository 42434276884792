import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ModalDeleteCliente = ({ isOpen, toggle, onSetEliminato, deleting, cliente }) => {
  return(
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}>Elimina fornitore</ModalHeader>
      <ModalBody>
        {cliente &&
        <div className='container'>
          Sei sicuro di voler eliminare il cliente {cliente.nome}?
        </div>
        }
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between'>
        <Button color="secondary" onClick={toggle}>Chiudi</Button>
        <Button color="danger" disabled={deleting} onClick={onSetEliminato}>Elimina</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalDeleteCliente
