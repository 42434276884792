import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ModalDeleteUser = ({ isOpen, toggle, onDelete, deleting, user }) => {
  return(
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}>Elimina ordine</ModalHeader>
      <ModalBody>
        {user &&
        <div>
          Sei sicuro di voler eliminare l'utente {user.first_name}{' '}{user.last_name}?
        </div>
        }
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between'>
        <Button color="secondary" disabled={deleting} onClick={toggle}>Chiudi</Button>
        <Button color="danger" disabled={deleting} onClick={onDelete}>Elimina</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalDeleteUser
