import React, { useState, useCallback } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { useRunRj, useRj } from 'react-rocketjump'
import {
  PreventivoState, SetAgenteOnPreventivo, SetEliminatoPreventivo,
  SetStatoOnPreventivo, SendMailOnPreventivo, SendMailOnPreventivoAgente
} from './localstate'
import { Button } from 'reactstrap'
import ModalChooseAgente from '../../components/Admin/ModalChooseAgente'
import ModalChooseStato from '../../components/Admin/ModalChooseStato'
import ModalInviaMail from '../../components/Admin/ModalInviaMail'
import ModalDetailMail from '../../components/Admin/ModalDetailMail'
import ModalDeletePreventivo from '../../components/Admin/ModalDeletePreventivo'
import DetailDatiAnagrafici from '../../components/Admin/DetailDatiAnagrafici'
import DetailDatiPosa from '../../components/Admin/DetailDatiPosa'
import DetailSerramento from '../../components/Admin/DetailSerramento'
import DetailMisuraSerramento from '../../components/Admin/DetailMisuraSerramento'
import ModalInviaMailAgente from '../../components/Admin/ModalInviaMailAgente'
import { USER } from '../../django'

export default function DetailPreventivo({ match, history }) {
  const { id } = match.params
  const [{ preventivo }, { setPreventivo, patchPreventivo }] = useRunRj(PreventivoState, [id])
  const [{ pending: savingAgente }, { run: runSetAgente }] = useRj(SetAgenteOnPreventivo)
  const [{ pending: savingStato }, { run: runSetStato }] = useRj(SetStatoOnPreventivo)
  const [{ pending: savingEliminato }, { run: runSetEliminato }] = useRj(SetEliminatoPreventivo)
  const [{ pending: sendingMail }, { run: sendMail }] = useRj(SendMailOnPreventivo)
  const [{ pending: sendingMailAgente }, { run: sendMailAgente }] = useRj(SendMailOnPreventivoAgente)

  const [showModalChooseAgent, setshowModalChooseAgent] = useState(false)
  const toggleShowModalChooseAgent = useCallback(() => {
    setshowModalChooseAgent(show => !show)
  }, [])

  const [showModalDeletePreventivo, setshowModalDelete] = useState(false)
  const toggleShowModalDelete= useCallback(() => {
    setshowModalDelete(show => !show)
  }, [])

  const [showModalChooseStato, setshowModalChooseStato] = useState(false)
  const toggleShowModalChooseStato= useCallback(() => {
    setshowModalChooseStato(show => !show)
  }, [])

  const [showModalInviaMail, setshowModalInviaMail] = useState(false)
  const toggleShowModalInviaMail= useCallback(() => {
    setshowModalInviaMail(show => !show)
  }, [])

  const [showModalInviaMailAgente, setshowModalInviaMailAgente] = useState(false)
  const toggleShowModalInviaMailAgente = useCallback(() => {
    setshowModalInviaMailAgente(show => !show)
  }, [])

  return (
    <div className='container pt-2 pb-2 mt-2 rounded position-relative'>
      {preventivo === null &&
        <div className='text-center mt-4'>
          <CircularProgress color="primary" />
        </div>
      }
      {preventivo &&
      <>
        <div className='d-flex justify-content-between'>
          <div className='d-flex'>
            {history.length > 2
              ? <span className='pointer text-primary' onClick={() => history.goBack()}>
                  <i className="fas fa-2x fa-arrow-circle-left"></i></span>
              : <Link to={'/'}><i className="fas fa-2x fa-arrow-circle-left"></i></Link>
            }
            <h4 className='font-weight-bold text-primary ml-4 pb-2'>Preventivo #{preventivo.id}</h4>
          </div>
          {USER && <div>
            <Button type='button' size='sm' outline color='dark' onClick={() => setshowModalInviaMail(true)}>
              <i className="fas fa-envelope"></i>{' '}
              {preventivo.email_inviata_il ? 'Inviata il '+dayjs(preventivo.email_inviata_il).format('DD-MM-YYYY') : 'Invia mail'}
            </Button>
            {!USER.agente && <>
              {' '}
              <Button type='button' size='sm' outline color='dark' onClick={() => setshowModalChooseAgent(true)}>
                <i className="fas fa-user-tie"></i>{' '}{preventivo.agente ? `Agente: ${preventivo.agente.user.first_name} ${preventivo.agente.user.last_name}` : 'Associa un agente'}
              </Button>
            </>}
            {' '}
            <Button type='button' size='sm' outline color='dark' onClick={() => setshowModalChooseStato(true)}>
              <i className="fas fa-bookmark"></i>{' '}{preventivo.stato ? `Stato: ${preventivo.stato.stato}` : 'Assegna uno stato'}
            </Button>
            {' '}
            {!USER.agente && preventivo.agente && <>
              {' '}
              <Button disabled={preventivo.email_inviata_agente_il} type='button' size='sm' outline color='dark' onClick={() => setshowModalInviaMailAgente(true)}>
                <i className="fas fa-envelope"></i>{' '}{preventivo.email_inviata_agente_il ? 'E-mail agente inviata il '+dayjs(preventivo.email_inviata_agente_il).format('DD-MM-YYYY') : 'Invia e-mail agente'}
              </Button>
            </>}
          </div>}
          {USER && preventivo.eliminato === false &&
          <div>
            <Button type='button' size='sm' outline color='danger' onClick={() => setshowModalDelete(true)}>
              Elimina <i className="fas fa-times"></i>
            </Button>
          </div>
          }
          {USER && preventivo.eliminato === true &&
          <div>
            <Button type='button' size='sm' outline color='success' onClick={() => setshowModalDelete(true)}>
              Ripristina <i className="fas fa-plus"></i>
            </Button>
          </div>
          }
        </div>
        <div className='row mt-3'>
          <div className='col-md-4'>
            <DetailDatiAnagrafici
              preventivo={preventivo}
            />
          </div>
          <div className='col-md-4'>
            <DetailDatiPosa
              preventivo={preventivo}
            />
          </div>
          <div className='col-md-4'>
            <DetailSerramento
              preventivo={preventivo}
            />
          </div>
        </div>
        <div className='text-center'>
          <h5 className='font-weight-bold pt-3 pb-1 text-primary'>Misure serramenti</h5>
        </div>
        <div className='row'>
          {preventivo.serramenti.map((serramento,key) => (
          <div className='col-md-4' key={key}>
            <DetailMisuraSerramento
              serramento={serramento}
              numeroSerramento={key+1}
            />
          </div>
          ))}
        </div>
        <ModalChooseAgente
          initialIdAgente={preventivo.agente ? preventivo.agente.id : ''}
          onSelect={(agente) => {
            runSetAgente
              .onSuccess(preventivo => {
                setPreventivo(preventivo)
                toggleShowModalChooseAgent()
              })
              .run(preventivo.id, agente ? agente.id : '')
          }}
          saving={savingAgente}
          isOpen={showModalChooseAgent}
          toggle={toggleShowModalChooseAgent}
        />
        <ModalDeletePreventivo
          isOpen={showModalDeletePreventivo}
          toggle={toggleShowModalDelete}
          titleModal={preventivo.eliminato ? 'Ripristina preventivo' : 'Elimina preventivo'}
          textModal={preventivo.eliminato ?
            `Sei sicuro di voler ripristinare il preventivo n° ${preventivo.id} per il cliente: ${preventivo.nome_cognome}?`
            :
            `Sei sicuro di voler eliminare il preventivo n° ${preventivo.id} per il cliente: ${preventivo.nome_cognome}?`}
          saving={savingEliminato}
          classButton={preventivo.eliminato ? 'success' : 'danger'}
          onSetEliminato={() =>
            runSetEliminato
              .onSuccess(() => history.push(`/`))
              .run(preventivo.id, !preventivo.eliminato)
          }
        />
        <ModalChooseStato
          initialStato={preventivo.stato ? preventivo.stato.id : ''}
          isOpen={showModalChooseStato}
          toggle={toggleShowModalChooseStato}
          onSelect={(stato) => {
            runSetStato
              .onSuccess(preventivo => {
                setPreventivo(preventivo)
                toggleShowModalChooseStato()
              })
              .run(preventivo.id, stato ? stato.id : '')
          }}
          saving={savingStato}
        />
        {!preventivo.email_inviata_il && <ModalInviaMail
          initialTemplate={''}
          onSend={(emailTemplate) => {
            sendMail
              .onSuccess((data) => {
                patchPreventivo(data)
                toggleShowModalInviaMail()
              })
              .run(preventivo.id, emailTemplate.id)
          }}
          preventivo={preventivo}
          sending={sendingMail}
          isOpen={showModalInviaMail}
          toggle={toggleShowModalInviaMail}
        />}
        {!preventivo.email_inviata_agente_il && <ModalInviaMailAgente
          onSend={() => {
            sendMailAgente
              .onSuccess((data) => {
                patchPreventivo(data)
                //patchPreventivo(data)
                toggleShowModalInviaMailAgente()
              })
              .run(preventivo.id)
          }}
          preventivo={preventivo}
          sending={sendingMailAgente}
          isOpen={showModalInviaMailAgente}
          toggle={toggleShowModalInviaMailAgente}
        />}
        {preventivo.email_inviata_il && (
          <ModalDetailMail
            isOpen={showModalInviaMail}
            toggle={toggleShowModalInviaMail}
            preventivo={preventivo}
          />
        )}
      </>
    }
    </div>
  )
}
