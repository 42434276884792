import React, { useState, useCallback } from 'react'
import { FornitoriState } from './localstate'
import Paginator from '../../components/Paginator'
import { useQsDataTable as useDataTable } from 'react-use-datatable/react'
import TableFornitori from '../../components/Planner/TableFornitori'
import ModalDeleteFornitore from '../../components/Planner/ModalDeleteFornitore'
import ModalFormFornitore from '../../components/Planner/ModalFormFornitore'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button } from 'reactstrap'

export default function Fornitori() {
  const [
    // Data State
    { fornitori, pagination, filters, loading, deleting },
    // Table state
    { page },
    {
      // DT Actions
      goToPage,
      onFilterChangeDebounced,
      deleteFornitore,
      addFornitore,
      updateFornitore,
    },
  ] = useDataTable(FornitoriState, () => ({
    search: '',
  }))

  const [selectedFornitore, setFornitore] = useState(null)

  const [showModalDeleteFornitore, setShowModalDeleteFornitore] = useState(
    false
  )
  const toggleShowModalDelete = useCallback(() => {
    setShowModalDeleteFornitore((show) => !show)
  }, [])

  const [showModalFormFornitore, setShowModalFormFornitore] = useState(false)
  const toggleShowModalFornitore = useCallback(() => {
    setFornitore(null)
    setShowModalFormFornitore((show) => !show)
  }, [])

  return (
    <div className="container-fluid">
      {fornitori === null && loading && (
        <div className="text-center mt-4">
          <CircularProgress color="primary" />
        </div>
      )}
      {fornitori && (
        <>
          <div className="d-flex justify-content-between">
            <div>
              <div>
                <input
                  name="search"
                  type="text"
                  className="form-control form-control-sm mt-2 mr-2"
                  placeholder={'Ricerca libera'}
                  value={filters && filters.search}
                  onChange={onFilterChangeDebounced}
                />
              </div>
              
            </div>
            <div className="text-right">
              <Button
                onClick={() => toggleShowModalFornitore()}
                size="sm"
                className="mt-2"
                outline
                color="secondary"
              >
                <i className="fas fa-plus-circle"></i> Aggiungi fornitore
              </Button>
            </div>
          </div>
          <TableFornitori
            fornitori={fornitori}
            loading={loading}
            setFornitore={setFornitore}
            setShowModalDeleteFornitore={setShowModalDeleteFornitore}
            setShowModalFormFornitore={setShowModalFormFornitore}
          />
          <Paginator
            numPages={pagination.numPages}
            currentPage={page}
            goToPage={goToPage}
          />
          <ModalDeleteFornitore
            toggle={toggleShowModalDelete}
            isOpen={showModalDeleteFornitore}
            fornitore={selectedFornitore}
            deleting={deleting}
            onSetEliminato={() => {
              deleteFornitore
                .onSuccess(() => {
                  toggleShowModalDelete()
                })
                .run(selectedFornitore)
            }}
          />
          <ModalFormFornitore
            isOpen={showModalFormFornitore}
            toggle={toggleShowModalFornitore}
            fornitore={selectedFornitore}
            save={(fornitore) => {
              if (selectedFornitore) {
                return updateFornitore
                  .onSuccess(() => {
                    setFornitore(null)
                    toggleShowModalFornitore()
                  })
                  .asPromise(fornitore)
              } else {
                return addFornitore
                  .onSuccess(toggleShowModalFornitore)
                  .asPromise(fornitore)
              }
            }}
          />
        </>
      )}
    </div>
  )
}
