import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ModalConfirmRemoveSerramento = ({ isOpen, toggle, onRemove, tipoSerramento}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}>Rimuovi serramento</ModalHeader>
      <ModalBody>
        Vuoi rimuovere il serramento?
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between'>
        <Button color="secondary" onClick={toggle}>Annulla</Button>
        <Button color="info" onClick={() => onRemove()}>Rimuovi</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalConfirmRemoveSerramento
