import React, { useState, useCallback } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useReactDataTable } from 'react-use-datatable/react'
import TableAccountsCheck from '../../../components/QrApp/TableAccountsCheck'
import { AccountsState } from '../localstate'

function AccountsPicker({ sharedAccounts, onSave, toggle, sharing }) {
  const [
    { accounts, pagination },
    { page, search },
    { goToPage, onFilterChangeDebounced }
  ] = useReactDataTable(AccountsState, () => ({
    search: '',
  }))

  const [checked, setChecked] = useState(() => {
    return sharedAccounts.reduce((all, account) => ({
      ...all,
      [account.id]: true,
    }), {})
  })

  const toggleCheck = useCallback((account) => {
    setChecked(checked => ({
      ...checked,
      [account.id]: !checked[account.id],
    }))
  }, [])

  const handleSave = useCallback(() => {
    onSave(Object.keys(checked).filter(id => checked[id]))
  }, [onSave, checked])

  if (accounts === null) {
    return (
      <div className='text-center p-5'>
        <CircularProgress color='primary' />
      </div>
    )
  }

  return (
    <>
      <ModalBody>
        <div className='d-flex mb-2'>
          <div>
            <Input
              placeholder='Cerca account'
              value={search}
              onChange={onFilterChangeDebounced}
              name='search'
              autoComplete='off'
            />
          </div>
        </div>
        <TableAccountsCheck
          toggle={toggleCheck}
          checked={checked}
          accounts={accounts}
          goToPage={goToPage}
          numPages={pagination.numPages}
          page={page}
        />
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between'>
        <Button disabled={sharing} onClick={toggle}>Annulla</Button>
        <Button disabled={sharing} color='success' onClick={handleSave}>Salva</Button>
      </ModalFooter>
    </>
  )
}

function ShareAccounts({ sharedAccounts, onShare, sharing }) {
  const [showModal, setShowModal] = useState(false)
  const toggleModal = useCallback(() => setShowModal(a => !a), [])

  const handleShare = useCallback(
    (ids) => onShare(ids, toggleModal),
    [toggleModal, onShare]
  )

  return (
    <div className='mb-2 text-right'>
      <Button color='primary' onClick={toggleModal}>
        Condividi con altri account
        <i className="fa fa-user ml-2" aria-hidden="true"></i>
      </Button>
      <Modal isOpen={showModal} toggle={toggleModal} size='lg'>
        <ModalHeader toggle={toggleModal}>Condividi con altri account</ModalHeader>
        <AccountsPicker
          sharing={sharing}
          onSave={handleShare}
          toggle={toggleModal}
          sharedAccounts={sharedAccounts}
        />
      </Modal>
    </div>
  )
}

export default function SharedAccounts({ sharedAccounts, onShare, sharing }) {
  return (
    <div>
      <ShareAccounts
        sharing={sharing}
        onShare={onShare}
        sharedAccounts={sharedAccounts}
      />
      <table className='table table-bordered'>
        <tbody>
          {sharedAccounts.length === 0 && (
            <tr className='bg-light'>
              <td>
                <i className="fa fa-user mr-2" aria-hidden="true"></i>
                <i>Cartella condivisa ancora con nessun account</i>
              </td>
            </tr>
          )}
          {sharedAccounts.map(account => (
            <tr key={account.id}>
              <td>
                <div className='mb-1'>
                  <i className="fa fa-user mr-2" aria-hidden="true"></i>
                  {account.user.first_name}
                  {' '}
                  {account.user.last_name}
                </div>
                <div className='ml-4'><small>{account.user.email}</small></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
