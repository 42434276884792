import React, { useMemo } from 'react'

const PREVIEW_SIZE = 26
const IMGS_PREVIEW_EXT = [
  'bmp',
  'apng',
  'png',
  'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp',
  'svg',
  'gif',
  'ico', 'cur',
  'webp',
  'tif', 'tiff'
]
const regexExtPreview = new RegExp(`(\\.)(${IMGS_PREVIEW_EXT.join('|')})$`, 'i')

function SharedFileRow({ sharedFile, onDeleteClick, onEditClick }) {
  const imagePreview = useMemo(() => {
    return !!sharedFile.name.match(regexExtPreview)
  }, [sharedFile.name])

  return (
    <tr>
      <td className='border-right-0'>
        <div className='d-flex'>
          <div className='mr-3'>
            {imagePreview && (
              <img
                style={{ borderRadius: '50%', objectFit: 'cover' }}
                alt={sharedFile.name}
                height={PREVIEW_SIZE} width={PREVIEW_SIZE} src={sharedFile.file} />
              )}
            {!imagePreview && (
              <i
                style={{ height: PREVIEW_SIZE, width: PREVIEW_SIZE }}
                className="fa fa-file text-center"
                aria-hidden="true"
              />
            )}
          </div>
          <div>
            <div>{sharedFile.title || sharedFile.name}</div>
            {sharedFile.title && <div className='text-muted'>
              <small><i>{sharedFile.name}</i></small>
            </div>}
            {sharedFile.description && (
              <div className='text-muted mt-1'><small>{sharedFile.description}</small></div>
            )}
            {sharedFile.permalink_url && (
              <div className='text-muted mt-1'><small>
                Permalink: <a href={sharedFile.permalink_url}>{sharedFile.permalink_url}</a></small>
              </div>
            )}
          </div>
        </div>
      </td>
      <td className='border-left-0 text-right'>
        <a target='_blank' rel='noreferrer noopener' href={sharedFile.file} download>
          <i className="fa fa-download" aria-hidden="true"></i>
        </a>
        {onEditClick && <i
          onClick={e => onEditClick(sharedFile)}
          className="fa fa-edit text-primary ml-3 pointer" aria-hidden="true"></i>}
        {onDeleteClick && <i
          onClick={e => onDeleteClick(sharedFile)}
          className="fa fa-trash text-danger ml-3 pointer" aria-hidden="true"></i>}
      </td>
    </tr>
  )
}

export default React.memo(SharedFileRow)
