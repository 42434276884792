import useRouterQueryParams from 'magik-react-hooks/useRouterQueryParams'
import React from 'react'
import { useRunRj } from 'react-rocketjump'
import { Link } from 'react-router-dom'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import Paginator from '../../../components/Paginator'
import { ConfermeOrdiniListState } from '../localstate/confermaOrdine'
import ModalFormConfermaOrdine from './ModalFormConfermaOrdine'
import { getConfermaStato } from './utils'
import moment from 'moment'

export default function ConfermaOrdineList() {
  const [queryParams, setQueryParams] = useRouterQueryParams()
  const [{ list, pagination }, { addConferma, removeConferma }] = useRunRj(
    ConfermeOrdiniListState,
    [queryParams],
    false
  )
  const [modalAdd, modalAddActions] = useModalTrigger()
  const [modalDelete, modalDeleteActions] = useModalTrigger()

  return (
    <div className="container-fluid mt-2">
      <div className="mb-2">
        <Button
          size="sm"
          outline
          color="primary"
          onClick={() => modalAddActions.open()}
        >
          <i className="fa fa-plus" aria-hidden="true"></i> Nuova Conferma
        </Button>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Data</th>
            <th>Cliente</th>
            <th>Codice Cliente</th>
            <th>Tipo Cliente</th>
            <th>Email Cliente</th>
            <th>Numero Commessa</th>
            <th>Preventivo</th>
            <th>Preventivo Firmato</th>
            <th>Stato</th>
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody>
          {list &&
            list.map((conferma) => {
              const stato = getConfermaStato(conferma)
              return (
                <tr key={conferma.id}>
                  <td>
                    <Link to={`/conferme-ordini/${conferma.id}`}>
                      {conferma.data}
                    </Link>
                  </td>
                  <td>{conferma.nome_cliente}</td>
                  <td>{conferma.codice_cliente}</td>
                  <td>{conferma.tipo_cliente}</td>
                  <td>{conferma.email_cliente}</td>
                  <td>
                    {conferma.ordine && (
                      <Link to={`/ordine/${conferma.ordine.id}`}>
                        {'#'}
                        {conferma.ordine.numero_commessa}
                      </Link>
                    )}
                    {!conferma.ordine && conferma.numero_commessa}
                  </td>
                  <td className="text-center">
                    <a href={conferma.preventivo_da_firmare}>
                      <i className="fa fa-download" aria-hidden="true"></i>
                    </a>
                  </td>
                  <td className="text-center">
                    {conferma.preventivo_firmato && (
                      <a href={conferma.preventivo_firmato}>
                        <i className="fa fa-download" aria-hidden="true"></i>
                      </a>
                    )}
                  </td>
                  <td>
                    {stato === 'inviato' && (
                      <span className="badge badge-success ml-2">
                        INVIATO{' '}
                        {moment(conferma.data_invio).format('DD-MM-YYYY')}
                      </span>
                    )}
                    {stato === 'in_attesa' && (
                      <span className="badge badge-warning ml-2">
                        IN ATTESA
                      </span>
                    )}
                    {stato === 'non_inviato' && (
                      <span className="badge badge-secondary ml-2">
                        NON INVIATO
                      </span>
                    )}
                  </td>
                  <td>
                    {/* <Button
                    // onClick={() => openEditModal(config)}
                    size="sm"
                    color="primary"
                    outline
                  >
                    <i className="fas fa-edit " />
                  </Button> */}
                    <Button
                      onClick={() => modalDeleteActions.open(conferma)}
                      className="ml-2"
                      size="sm"
                      color="danger"
                      outline
                    >
                      <i className="fas fa-trash " />
                    </Button>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>

      <Paginator
        numPages={pagination.numPages}
        currentPage={+queryParams.page || 1}
        goToPage={(page) => setQueryParams({ page })}
      />
      <ModalFormConfermaOrdine
        save={(conferma) =>
          addConferma
            .onSuccess(() => {
              modalAddActions.close()
            })
            .asPromise(conferma)
        }
        isOpen={modalAdd.isOpen}
        toggle={modalAddActions.toggle}
        onClosed={modalAddActions.onClosed}
      />
      <Modal isOpen={modalDelete.isOpen} toggle={modalDeleteActions.toggle}>
        <ModalHeader toggle={modalDeleteActions.toggle}>
          Elimina Conferma Ordine
        </ModalHeader>
        <ModalBody>
          {modalDelete.value && (
            <>
              Eliminare conferma ordine <b>{modalDelete.value.nome_cliente}</b>{' '}
              commessa <b>{modalDelete.value.numero_commessa}</b>?
            </>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button onClick={modalDeleteActions.toggle}>Annulla</Button>
          <Button
            onClick={() =>
              removeConferma
                .onSuccess(modalDeleteActions.close)
                .run(modalDelete.value)
            }
            color="danger"
          >
            Elimina
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
