import React from 'react'
import isEmpty from 'lodash/isEmpty'

const ErrorsPreventivo = ({ errors, values }) => {
  return (
    !isEmpty(errors) &&
    <div className='alert alert-warning'>
      <h4 className='mt-1 text-primary'>Invia dati</h4>
        Mancano alcuni dati per la richiesta del preventivo:
        <ol>
          {errors && Object.keys(errors).map(errorField => (
            errorField === 'serramenti' ?
              <li key={errorField}>Devi inserire un serramento completo di tutti i dati richiesti.</li>
            :
              <li key={errorField}>{errors[errorField]}</li>
          ))}
        </ol>
    </div>
  )
}

export default ErrorsPreventivo
