import React, { Fragment, useState, useCallback } from 'react'
import { Button } from 'reactstrap'
import { Formik, Form, Field } from 'formik'
import { DateField, HourField } from '../../Form/Fields'
import { transformErrorForForm, setNullOnEmptyValues } from '../../Form/Fields/utils'
import * as Yup from 'yup'

const DateTimeRangeSchema = Yup.object().shape({
  giorno_da: Yup.string().required(),
  giorno_a: Yup.string().required(),
})

function renderFormFields({ values, setFieldValue, isSubmitting, handleChange, errors, allDay, toggleAllDay }) {
  return (
    <Fragment>
      <div className='text-center'>
        <input
          id='allDay'
          type='checkbox'
          onChange={() => {
            if (!allDay){
              setFieldValue('ora_da', '')
              setFieldValue('ora_a', '')
            }
            toggleAllDay()
          }}
          name='allDay'
          checked={allDay}
        />
        <label className='ml-2' htmlFor='allDay'>Tutto il giorno</label>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <Field
            label='Giorno da'
            type='text'
            name='giorno_da'
            component={DateField}
            className='mb-2 form-control'
          />
          <Field
            label='Giorno a'
            type='text'
            name='giorno_a'
            component={DateField}
            className='mb-2 form-control'
          />
        </div>
        <div className='col-md-6'>
          <Field
            label='Ora da'
            type='text'
            disabled={allDay}
            name='ora_da'
            component={HourField}
            className='mb-2 form-control'
          />
          <Field
            label='Ora a'
            disabled={allDay}
            type='text'
            name='ora_a'
            component={HourField}
            className='mb-2 form-control'
          />
        </div>
      </div>
    </Fragment>
  )
}

function defaultRenderForm(formikBag, fields) {
  return (
    <Form>
      {fields}
      <div>
        <Button type='submit' color='success'>Salva</Button>
      </div>
    </Form>
  )
}

export default function DateTimeRangeForm({ save, dateTimeRange, renderForm = defaultRenderForm }) {
  const [allDay, setAllDay] = useState(
    dateTimeRange
      ? dateTimeRange.ora_da === null && dateTimeRange.ora_a === null
      : false
  )
  const toggleAllDay = useCallback(() => setAllDay(a => !a), [])
  return (
    <Formik
      onSubmit={(values, actions) => {
        return save(setNullOnEmptyValues(values)).then(
          () => {
            actions.setSubmitting(false)
          },
          error => {
            actions.setSubmitting(false)
            actions.setErrors(transformErrorForForm(error))
          }
        )
      }}
      initialValues={dateTimeRange || {
        'giorno_da': '',
        'giorno_a': '',
        'ora_a': '',
        'ora_da': '',
      }}
      validationSchema={DateTimeRangeSchema}
    >
      {formikBag => (
        renderForm(formikBag, renderFormFields({ ...formikBag, allDay, toggleAllDay }), { allDay, toggleAllDay })
      )}
    </Formik>
  )
}
