import React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import ListPreventivi from './ListPreventivi'
import DetailPreventivo from './DetailPreventivo'
import VincoliMisure from './VincoliMisure'

export default function AdminPreventiviApp() {
  return (
    <Router>
      <Switch>
        <Route path='/' exact component={ListPreventivi} />
        <Route path='/vincoli' exact component={VincoliMisure} />
        <Route path='/:id' exact component={DetailPreventivo} />
      </Switch>
    </Router>
  )
}
