import React from 'react'
import { Field } from 'formik'
import { Button } from 'reactstrap'
import DetailFamily from '../../DetailFamily'
import OptionDetail from '../../OptionDetail'
import { FieldRadio } from '../../Fields'

const Step1Desktop = ({ tipologieFamiglia, setStep, selectedFamily, resetFamilyOptions, values }) => {

  return (
    <div className='Step1Desktop mt-4'>
      <div className='container pt-2'>
        <div className='row'>
          {tipologieFamiglia.map(tipologia => (
            <div className='col-md-3 text-left' key={tipologia.id}>
              <h5>{tipologia.nome}</h5>
              {tipologia && tipologia.famiglie.map(family => (
                <div className="form-check d-flex" key={family.id}>
                  <Field
                    onClick={() => resetFamilyOptions(family.id)}
                    component={FieldRadio}
                    className='form-check-input'
                    name="famiglia"
                    id={family.nome}
                    value={`${family.id}`}
                  />
                  <label htmlFor={family.nome} className='form-check-label'>{family.nome}</label>
                </div>
              ))}
            </div>
          ))}
        </div>
        <hr />
        <div className='row mt-4 text-left'>
          <div className='col-md-6 border-right'>
            {selectedFamily && <DetailFamily heightImg={400} famiglia={selectedFamily} />}
          </div>
          <div className='col-md-6'>
            {selectedFamily && (
              <>
                {selectedFamily.opzioni.map(option => (
                  <div key={option.id}>
                    <OptionDetail opzione={option} valuesForm={values}  />
                    <hr />
                  </div>
                ))}
              </>
            )}
            <div className='mt-4 text-right'>
              <Button type='button' color='info' onClick={() => setStep(2)}>Step 2 <i className="fas fa-chevron-circle-right"></i><br />Inserimento misure</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step1Desktop
