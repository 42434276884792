import React, { useMemo } from 'react'
import sum from 'lodash/sum'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Formik, Field, FieldArray } from 'formik'
import { InputField } from '../Form/Fields'
import {
  transformErrorForForm,
  setNullOnEmptyValues,
} from '../Form/Fields/utils'

const FormRigaQuantita = ({ form, push, replace, remove, values }) => {
  return (
    <div>
      <div className="text-right">
        <Button color="success" outline onClick={() => push(0)}>
          <i className="fas fa-plus-circle"></i> Aggiungi riga
        </Button>
      </div>
      {form.values.quantities.map((quantity, index) => (
        <div className="d-flex">
          <div className="mt-2">
            <Field
              label={'Quantità'}
              className="form-control"
              type="number"
              name={`quantities[${index}]`}
              component={InputField}
            />
          </div>
          {form.values.quantities.length > 1 && (
            <div className="mt-4 pt-2 ml-2">
              <Button size="sm" color="danger" onClick={() => remove(index)}>
                <i className="fas fa-trash"></i> Rimuovi
              </Button>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

function ModalFormSplit({ save, riga, toggle }) {
  const initialValues = useMemo(
    () => ({
      quantities: [riga?.quantita ?? 0],
    }),
    [riga]
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        return save(values).then(
          () => {
            actions.setSubmitting(false)
          },
          (error) => {
            actions.setSubmitting(false)
            actions.setErrors(transformErrorForForm(error))
          }
        )
      }}
      render={({ handleSubmit, values, isSubmitting }) => {
        const sumOfQuantities = sum(values.quantities)
        const isSameQuantieties = sumOfQuantities === riga?.quantita ?? 0

        return (
          <form onSubmit={handleSubmit} className="p-0 m-0">
            <ModalBody>
              <FieldArray name="quantities" component={FormRigaQuantita} />
              {!isSameQuantieties && (
                <div className="mt-3 alert alert-danger">
                  <i className="fas fa-ban"></i> La somma delle quantità deve
                  essere {riga?.quantita ?? 0}.
                </div>
              )}
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
              <Button
                color="secondary"
                disabled={isSubmitting}
                onClick={toggle}
              >
                Chiudi
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting || !isSameQuantieties}
                color="success"
              >
                Salva
              </Button>
            </ModalFooter>
          </form>
        )
      }}
    />
  )
}

const ModalSplitRigheOrdiniFornitori = ({ isOpen, save, toggle, ...props }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalHeader toggle={toggle}>Dividi riga ordine fornitore</ModalHeader>
      <ModalFormSplit {...props} save={save} toggle={toggle} />
    </Modal>
  )
}

export default ModalSplitRigheOrdiniFornitori
