import { Subject } from 'rxjs'
import useConstant from 'magik-react-hooks/useConstant'
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { ContextOrderDetailEvents } from './contexts'

export default function OrderDetailEventsProvider({ children }) {
  const eventsSubject = useConstant(() => new Subject())
  return (
    <ContextOrderDetailEvents.Provider value={eventsSubject}>
      {children}
    </ContextOrderDetailEvents.Provider>
  )
}

export function useEmitOrderDetailEvents() {
  /**
   * @type Subject
   */
  const eventsSubject = useContext(ContextOrderDetailEvents)

  return useCallback((payload) => {
    eventsSubject.next(payload)
  }, [eventsSubject])
}

/**
 * @param {(payload: any) => void} cb
 */
export function useSubscribeToOrderDetailEvents(cb) {
  /**
   * @type Subject
   */
  const eventsSubject = useContext(ContextOrderDetailEvents)

  const lastCb = useRef(cb)

  useEffect(() => {
    lastCb.current = cb
  })

  useEffect(() => {
    const sub = eventsSubject.subscribe((event) => {
      const notifyCb = lastCb.current
      if (notifyCb) {
        notifyCb(event)
      }
    })
    return () => {
      sub.unsubscribe()
    }
  }, [eventsSubject])
}