import React, { useMemo } from 'react'
import { Button } from 'reactstrap'
import { Formik, Field } from 'formik'
import { CheckboxField } from '../Form/Fields'
import { transformErrorForForm } from '../Form/Fields/utils'

const InitialAccountValues = {
  visualizza_stati_ordine: false,
}

export default function AccountForm({ save, user, onDelete, setAccountForm }) {
  const accountValues = useMemo(() => {
    if (!user.app_account) {
      return InitialAccountValues
    }
    return user.app_account
  }, [user])

  return (
    <Formik
      onSubmit={(values, actions) => {
        return save({
          ...values,
          user: user.id,
        }).then(
          (data) => {
            actions.setSubmitting(false)
            // Update created id
            if (!values.id) {
              actions.setFieldValue('id', data.id)
            }
          },
          (error) => {
            actions.setSubmitting(false)
            actions.setErrors(transformErrorForForm(error))
          }
        )
      }}
      initialValues={accountValues}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="p-0 m-0">
          <div>
            <Field
              checked={user.is_superuser ? true : undefined}
              disabled={user.is_superuser}
              autoComplete="off"
              component={CheckboxField}
              label="Visualizza stati ordine"
              name="visualizza_stati_ordine"
            />
            <small>
              {user.is_superuser && (
                <div className='mb-1'>
                  <b>Questo account ha già i privilegi di admin questo flag verrà ignorato.</b>
                </div>
              )}
              Questo flag abilita la visualizzazione degli stati dell'ordine
              all'interno dell App e la ricezione delle push notification
              quando un nuovo ordine viene inserito o il suo stato cambia.
            </small>
          </div>
          <div className='mt-2'>
            <Field
              checked={user.is_superuser ? true : undefined}
              disabled={user.is_superuser}
              autoComplete="off"
              component={CheckboxField}
              label="Visualizza ecommerce"
              name="visualizza_ecommerce"
            />
            <small>
              {user.is_superuser && (
                <div className='mb-1'>
                  <b>Questo account ha già i privilegi di admin questo flag verrà ignorato.</b>
                </div>
              )}
              Questo flag abilita la visualizzazione della sezione ecommerce nell'App.
            </small>
          </div>
          <div className="mt-4 d-flex justify-content-between">
            <div>
              {user.app_account && (
                <Button disabled={isSubmitting} onClick={onDelete} color="danger">
                  <i className="fa fa-trash mr-2" aria-hidden="true"></i>
                  Elimina
                </Button>
              )}
              {!user.app_account && (
                <Button
                  onClick={() => setAccountForm(false)}
                  disabled={isSubmitting}
                  className="ml-2"
                >
                  Annulla
                </Button>
              )}
            </div>
            <Button
              disabled={isSubmitting}
              className="ml-2"
              color="success"
              type="submit"
            >
              Salva
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}
