import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useRunRj, useRj } from 'react-rocketjump'
import { useQsDataTable as useDataTable } from 'react-use-datatable/react'
import Paginator from '../../components/Paginator'
import TablePreventivi from '../../components/Admin/TablePreventivi'
import FiltersPreventivi from '../../components/Admin/FiltersPreventivi'
import ModalChooseAgente from '../../components/Admin/ModalChooseAgente'
import ModalChooseStato from '../../components/Admin/ModalChooseStato'
import ModalDeletePreventivo from '../../components/Admin/ModalDeletePreventivo'
import ModalDetailMail from '../../components/Admin/ModalDetailMail'
import ModalInviaMail from '../../components/Admin/ModalInviaMail'
import ModalInviaMailAgente from '../../components/Admin/ModalInviaMailAgente'
import {
  PreventiviListState,
  AgentiState,
  StatiPreventivoState,
  SetAgenteOnPreventivi,
  SetStatoOnPreventivi,
  SetEliminatoOnPreventivi,
  SendMailOnPreventivo,
  SendMailOnPreventivoAgente
} from './localstate'
import CircularProgress from '@material-ui/core/CircularProgress'

export default function AdminPreventiviApp() {
  const [
    // Data State
    { preventivi, pagination, loading },
    // Table state
    { page, ...filters },
    {
      // DT Actions
      onFilterChange, onFiltersChange, onFilterChangeDebounced, goToPage,
      // RJ Actions
      updateAgente, updateStato, updateEliminato, patchPreventivo
    }
  ] = useDataTable(PreventiviListState, () => ({
    startDate: {
      value: null,
      field: 'start_date',
    },
    endDate: {
      // onChangeReset: ['search'],
      value: null,
      field: 'end_date',
    },
    search: '',
    citta: '',
    provincia: '',
    regione_posa: '',
    stato: '',
    agente: '',
    eliminato: false,
  }))

  const [{ data: agenti }] = useRunRj(AgentiState)
  const [{ data: statiPreventivo }] = useRunRj(StatiPreventivoState)
  const [{ pending: sendingMail }, { run: sendMail }] = useRj(SendMailOnPreventivo)
  const [{ pending: sendingMailAgente }, { run: sendMailAgente }] = useRj(SendMailOnPreventivoAgente)

  const [
    { pending: settingAgente },
    { run: runMultiSetAgente }
  ] = useRj(SetAgenteOnPreventivi)

  const [
    { pending: settingStato },
    { run: runMultiSetStato }
  ] = useRj(SetStatoOnPreventivi)

  const [
    { pending: settingEliminato },
    { run: runMultiSetEliminato }
  ] = useRj(SetEliminatoOnPreventivi)

  const [selectedPreventivi, setSelectedPreventivi] = useState({})
  const toggleSelectedPreventivo = useCallback((id) => {
    setSelectedPreventivi(selected => ({
      ...selected,
      [id]: !selected[id]
    }))
  },[])

  const [selectedPreventivo, setPreventivo] = useState(null)

  const selectedPreventiviList = useMemo(() => {
    return Object.keys(selectedPreventivi)
      .filter(key => selectedPreventivi[key])
      .map(k => +k)
  },[selectedPreventivi])

  //const [{ pending: saving }, { run: runSetAgente }] = useRj(SetAgenteOnPreventivo)
  const [showModalChooseAgent, setshowModalChooseAgent] = useState(false)
  const toggleShowModalChooseAgent = useCallback(() => {
    setshowModalChooseAgent(show => !show)
  }, [])
  const [showModalChooseStato, setshowModalChooseStato] = useState(false)
  const toggleShowModalChooseStato = useCallback(() => {
    setshowModalChooseStato(show => !show)
  }, [])
  const [showModalDeletePreventivo, setshowModalDelete] = useState(false)
  const toggleShowModalDelete = useCallback(() => {
    setshowModalDelete(show => !show)
  }, [])
  const [showModalInviaMail, setshowModalInviaMail] = useState(false)
  const toggleShowModalInviaMail = useCallback(() => {
    setshowModalInviaMail(show => !show)
  }, [])
  const [showModalInviaMailAgente, setshowModalInviaMailAgente] = useState(false)
  const toggleShowModalInviaMailAgente = useCallback(() => {
    setshowModalInviaMailAgente(show => !show)
  }, [])

  useEffect(() =>
    setSelectedPreventivi({}) , [filters.eliminato])

  return (
    <div className='container-fluid'>
      {preventivi === null && loading &&
        <div className='text-center mt-4'>
          <CircularProgress color="primary" />
        </div>
      }
      {preventivi &&
        <>
          <FiltersPreventivi
            filters={filters}
            onFilterChange={onFilterChange}
            onFiltersChange={onFiltersChange}
            onFilterChangeDebounced={onFilterChangeDebounced}
            agenti={agenti}
            statiPreventivo={statiPreventivo}
            toggleShowModalChooseAgent={toggleShowModalChooseAgent}
            toggleShowModalChooseStato={toggleShowModalChooseStato}
            toggleShowModalDelete={toggleShowModalDelete}
            selectedPreventivi={selectedPreventiviList}
          />
          <TablePreventivi
            preventivi={preventivi}
            loading={loading}
            selectedPreventivi={selectedPreventivi}
            toggleSelectedPreventivo={toggleSelectedPreventivo}
            setshowModalInviaMail={setshowModalInviaMail}
            setshowModalInviaMailAgente={setshowModalInviaMailAgente}
            setPreventivo={setPreventivo}
          />
          <Paginator
            numPages={pagination.numPages}
            currentPage={page}
            goToPage={goToPage}
          />
          <ModalDeletePreventivo
            isOpen={showModalDeletePreventivo}
            toggle={toggleShowModalDelete}
            textModal={`Sei sicuro di eliminare questi ${selectedPreventiviList.length} preventivi?`}
            titleModal={'Elimina preventivi'}
            classButton={'danger'}
            saving={settingEliminato}
            onSetEliminato={() =>
              runMultiSetEliminato
                .onSuccess(({ preventivi, eliminato }) => {
                  updateEliminato(preventivi, eliminato)
                  toggleShowModalDelete()
                  setSelectedPreventivi({})
                })
                .run(selectedPreventiviList, true)
            }
          />
          <ModalChooseAgente
            initialIdAgente={''}
            saving={settingAgente}
            onSelect={(agente) => {
              runMultiSetAgente
                .onSuccess(({ preventivi, agente }) => {
                  updateAgente(preventivi, agente)
                  toggleShowModalChooseAgent()
                  setSelectedPreventivi({})
                })
                .run(selectedPreventiviList, agente)
            }}
            isOpen={showModalChooseAgent}
            toggle={toggleShowModalChooseAgent}
          />
          <ModalChooseStato
            initialStato={''}
            saving={settingStato}
            onSelect={(stato) => {
              runMultiSetStato
                .onSuccess(({ preventivi, stato }) => {
                  updateStato(preventivi, stato)
                  toggleShowModalChooseStato()
                  setSelectedPreventivi({})
                })
                .run(selectedPreventiviList, stato)
            }}
            isOpen={showModalChooseStato}
            toggle={toggleShowModalChooseStato}
          />
          {selectedPreventivo && selectedPreventivo.email_inviata_il &&
          <ModalDetailMail
            preventivo={selectedPreventivo}
            toggle={toggleShowModalInviaMail}
            isOpen={showModalInviaMail}
          />
          }
          {selectedPreventivo && !selectedPreventivo.email_inviata_il &&
          <ModalInviaMail
            initialTemplate={''}
            onSend={(emailTemplate) => {
              sendMail
                .onSuccess((data) => {
                  patchPreventivo(selectedPreventivo.id, data)
                  //patchPreventivo(data)
                  toggleShowModalInviaMail()
                })
                .run(selectedPreventivo.id, emailTemplate.id)
            }}
            preventivo={selectedPreventivo}
            sending={sendingMail}
            isOpen={showModalInviaMail}
            toggle={toggleShowModalInviaMail}
          />
        }
        {selectedPreventivo && !selectedPreventivo.email_inviata_agente_il &&
        <ModalInviaMailAgente
          onSend={() => {
            sendMailAgente
              .onSuccess((data) => {
                patchPreventivo(selectedPreventivo.id, data)
                //patchPreventivo(data)
                toggleShowModalInviaMailAgente()
              })
              .run(selectedPreventivo.id)
          }}
          preventivo={selectedPreventivo}
          sending={sendingMailAgente}
          isOpen={showModalInviaMailAgente}
          toggle={toggleShowModalInviaMailAgente}
        />
      }
        </>
      }
    </div>
  )
}
