import React, { useMemo } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Formik, Field, FieldArray } from 'formik'
import * as Yup from 'yup'
import { CheckboxField, InputField } from '../../Form/Fields'
import range from 'lodash/range'
import orderBy from 'lodash/orderBy'
import {
  transformErrorForForm,
  setNullOnEmptyValues,
  fixInitialValuesNull,
} from '../../Form/Fields/utils'
import { mergeMaterialiServerField } from '../../../apps/MaterialiApp/materialiUtils'

const MaterialeSchema = Yup.object().shape({
  nome: Yup.string().required(),
})


function makeOrdersOptions(values, index) {
  const otherOrder = values.colonne_list.reduce((all, col, i) => {
    if (col.order && i !== index) {
      all[col.order] = true
    }
    return all
  }, {})
  return range(values.colonne_list.length).filter((i) => !otherOrder[i])
}

function removeColonna(colonne, index) {
  const nextColonne = colonne.filter((c, i) => i !== index)
  let colonneSorted = nextColonne.map((col, i) => ({
    ...col,
    originalIdndex: i,
    order: col.order === '' ? '' : +col.order,
  }))
  colonneSorted = orderBy(colonneSorted, ['order'], ['desc'])
  let n = colonneSorted.length
  colonneSorted.forEach((col) => {
    if (col.order !== '' && col.order >= n) {
      nextColonne[col.originalIdndex].order = String(col.order - 1)
      n--
    } else {
      nextColonne[col.originalIdndex].order = String(col.order)
    }
  })
  return nextColonne
}

const ModalFormMateriale = ({ isOpen, toggle, save, materiale }) => {
  const initialValues = useMemo(() => {
    if (materiale) {
      return mergeMaterialiServerField(
        fixInitialValuesNull({
          ...materiale,
        })
      )
    } else {
      return {
        nome: '',
        colonne_list: [],
        show_reminder_ordine_fornitore: false,
      }
    }
  }, [materiale])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        {materiale ? `Modifica ${materiale.nome}` : 'Aggiungi materiale'}
      </ModalHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={MaterialeSchema}
        onSubmit={(values, actions) => {
          const materialePayload = {
            ...values,
            colonne_list: values.colonne_list.map((c) => c.colonna),
            sort: orderBy(
              values.colonne_list.filter((c) => c.order !== '' && c.direction),
              'order',
              'asc'
            ).map((c) => [c.colonna.toLowerCase(), c.direction]),
            labels: values.colonne_list.reduce(
              (all, c) => ({
                ...all,
                ...(c.label !== ''
                  ? { [c.colonna.toLowerCase().trim()]: c.label }
                  : {}),
              }),
              {}
            ),
          }

          return save(setNullOnEmptyValues(materialePayload)).then(
            () => {
              actions.setSubmitting(false)
            },
            (error) => {
              actions.setSubmitting(false)
              actions.setErrors(transformErrorForForm(error))
            }
          )
        }}
        render={({ handleSubmit, values, isSubmitting, errors, setValues }) => (
          <form encType="multipart/form-data" onSubmit={handleSubmit}>
            <ModalBody>
              <Field
                label="Nome *"
                type="text"
                name="nome"
                disabled={materiale?.per_schedulazione}
                className="mb-2"
                component={InputField}
              />
              <Field
                label="Reminder ordini fornitori"
                type="text"
                name="show_reminder_ordine_fornitore"
                className="mb-2"
                component={CheckboxField}
              />
              <FieldArray
                name="colonne_list"
                render={(arrayHelpers) => (
                  <div>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Colonna</th>
                          <th>Ordinamento</th>
                          <th>Direzione</th>
                          <th>PDF Label</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.colonne_list &&
                          values.colonne_list.length > 0 &&
                          values.colonne_list.map((colonna, index) => (
                            <tr key={index}>
                              <td>
                                <Field
                                  component={InputField}
                                  name={`colonne_list.${index}.colonna`}
                                />
                              </td>
                              <td>
                                <Field
                                  component={InputField}
                                  type="select"
                                  name={`colonne_list.${index}.order`}
                                >
                                  <option value={''}>{'--'}</option>
                                  {makeOrdersOptions(values, index).map((i) => (
                                    <option key={i} value={i}>{`${
                                      i + 1
                                    } °`}</option>
                                  ))}
                                </Field>
                              </td>
                              <td>
                                <Field
                                  component={InputField}
                                  type="select"
                                  name={`colonne_list.${index}.direction`}
                                >
                                  <option value={''}>{'--'}</option>
                                  <option value={'asc'}>{'Crescente'}</option>
                                  <option value={'desc'}>
                                    {'Decrescente'}
                                  </option>
                                </Field>
                              </td>
                              <td>
                                <Field
                                  component={InputField}
                                  name={`colonne_list.${index}.label`}
                                />
                              </td>
                              <td>
                                <div className="d-flex">
                                  <Button
                                    type="button"
                                    color="danger"
                                    outline
                                    className="mr-2"
                                    onClick={() => {
                                      setValues({
                                        ...values,
                                        colonne_list: removeColonna(
                                          values.colonne_list,
                                          index
                                        ),
                                      })
                                    }}
                                  >
                                    Rimuovi
                                  </Button>
                                  <Button
                                    color="dark"
                                    outline
                                    disabled={index === 0}
                                    onClick={() =>
                                      arrayHelpers.move(index, index - 1)
                                    }
                                  >
                                    <i className="fas fa-arrow-up"></i>
                                  </Button>
                                  <Button
                                    className="ml-1"
                                    color="dark"
                                    outline
                                    disabled={
                                      index === values.colonne_list.length - 1
                                    }
                                    onClick={() =>
                                      arrayHelpers.move(index, index + 1)
                                    }
                                  >
                                    <i className="fas fa-arrow-down"></i>
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <p>
                      Come nome colonna è possibile inserire <i>“quantita”</i>,{' '}
                      <i>“codice”</i>, <i>“descrizione”</i> o le colonne
                      importate con importazione righe ordini fornitori.
                    </p>
                    <Button
                      type="button"
                      className="mt-2"
                      outline
                      color="success"
                      onClick={() =>
                        arrayHelpers.push({
                          colonna: '',
                          direction: '',
                          order: '',
                          label: '',
                        })
                      }
                    >
                      Aggiungi colonna
                    </Button>
                  </div>
                )}
              />
            </ModalBody>

            <ModalFooter className="d-flex justify-content-between">
              <Button color="secondary" onClick={toggle}>
                Chiudi
              </Button>
              <Button type="submit" disabled={isSubmitting} color="success">
                Salva
              </Button>
            </ModalFooter>
          </form>
        )}
      />
    </Modal>
  )
}

export default ModalFormMateriale
