import React, { useCallback, useMemo } from 'react'
import Select from 'react-select'
import { Button } from 'reactstrap'
import difference from 'lodash/difference'

function MaterialeFilterRow({
  materiale,
  stati,
  selectedMateriali,
  materialiOptions,
  valoriStatoOptions,
  onMaterialeChange,
  onStatiChange,
  onRemove,
  placeholderMateriale = '',
  makeLabelMateriale,
}) {
  const selectedStati = useMemo(
    () => stati.map((o) => ({ value: o, label: o })),
    [stati]
  )
  const deMateriali = useMemo(() => {
    const out = difference(materialiOptions, selectedMateriali)
    if (materiale) {
      out.push(materiale)
    }
    return out
  }, [materialiOptions, materiale, selectedMateriali])

  return (
    <div className="row my-2">
      <div className="col-md-2">
        <select
          className="form-control"
          value={materiale}
          onChange={(e) => {
            onMaterialeChange(e.target.value)
          }}
        >
          {materiale === '' && (
            <option value={''}>{placeholderMateriale}</option>
          )}
          {deMateriali.map((m) => (
            <option key={m} value={m}>
              {makeLabelMateriale ? makeLabelMateriale(m) : m}
            </option>
          ))}
        </select>
      </div>
      <div className="col-md-5">
        <Select
          name="stati"
          isMulti
          placeholder={'Seleziona uno o più stati'}
          options={materiale ? valoriStatoOptions : []}
          closeMenuOnSelect={true}
          value={selectedStati}
          className="basic-multi-select select-multi-options text-nowrap w-100"
          onChange={(newValues) => {
            if (onStatiChange) {
              onStatiChange((newValues ?? []).map((v) => v.value))
            }
          }}
        />
      </div>
      <div className="col-md-1 d-flex align-items-center">
        {onRemove && (
          <Button color="danger" size="sm" outline onClick={() => onRemove()}>
            <i className="fa fa-times" />
          </Button>
        )}
      </div>
    </div>
  )
}

export default function MultipleMaterialiFilters({
  filters,
  onFiltersChange,
  valoriStatoOptions,
  materialiOptions,
  placeholderMateriale,
  makeLabelMateriale,
}) {
  const [flatMateriali, materialiStati] = useMemo(() => {
    const map = {}
    filters.materiali.forEach((m, i) => {
      if (!map[m]) {
        map[m] = []
      }
      const stato = filters.stati_materiali[i]
      if (stato) {
        map[m].push(stato)
      }
    })
    const flatMateriali = Object.keys(map)
    return [
      flatMateriali,
      flatMateriali.map((materiale) => ({
        materiale,
        stati: map[materiale].filter((m) => m !== '_'),
      })),
    ]
  }, [filters.materiali, filters.stati_materiali])

  const normalizeUpdateFilters = useCallback(
    (newList) => {
      const materiali = []
      const stati_materiali = []
      newList.forEach((o) => {
        if (o.stati.length > 0) {
          o.stati.forEach((stato) => {
            materiali.push(o.materiale)
            stati_materiali.push(stato)
          })
        } else {
          materiali.push(o.materiale)
          stati_materiali.push('_')
        }
      })
      onFiltersChange({
        materiali,
        stati_materiali,
      })
    },
    [onFiltersChange]
  )

  return (
    <div>
      {materialiStati.map((o, i) => (
        <MaterialeFilterRow
          placeholderMateriale={placeholderMateriale}
          makeLabelMateriale={makeLabelMateriale}
          key={i}
          materiale={o.materiale}
          stati={o.stati}
          selectedMateriali={flatMateriali}
          materialiOptions={materialiOptions}
          valoriStatoOptions={valoriStatoOptions}
          onMaterialeChange={(materiale) => {
            const nextList = [...materialiStati]
            nextList[i] = {
              stati: [],
              materiale,
            }
            normalizeUpdateFilters(nextList)
          }}
          onStatiChange={(stati) => {
            const nextList = [...materialiStati]
            nextList[i] = {
              ...nextList[i],
              stati,
            }
            normalizeUpdateFilters(nextList)
          }}
          onRemove={() => {
            normalizeUpdateFilters(materialiStati.filter((_, j) => i !== j))
          }}
        />
      ))}
      {materialiOptions.length > materialiStati.length && (
        <MaterialeFilterRow
          placeholderMateriale={placeholderMateriale}
          makeLabelMateriale={makeLabelMateriale}
          materiale={''}
          stati={[]}
          materialiOptions={materialiOptions}
          valoriStatoOptions={[]}
          selectedMateriali={flatMateriali}
          onMaterialeChange={(materiale) => {
            normalizeUpdateFilters(
              materialiStati.concat({
                materiale,
                stati: [],
              })
            )
          }}
        />
      )}
    </div>
  )
}
