import React, { useContext, useMemo } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Formik, Field, FieldArray } from 'formik'
import * as Yup from 'yup'
import { CheckboxField } from '../../Form/Fields'
import {
  transformErrorForForm,
  setNullOnEmptyValues,
} from '../../Form/Fields/utils'
import keyBy from 'lodash/keyBy'
import { ConfigPlannerContext } from '../../../context'

const ModalCheckList = ({
  isOpen,
  toggle,
  save,
  onClosed,
  compileCheckList,
}) => {
  const { checkList } = useContext(ConfigPlannerContext)

  const initialValues = useMemo(() => {
    if (!compileCheckList) {
      return {
        check_list: [],
      }
    }

    const byCheck = keyBy(compileCheckList, 'checklist')
    const checkListValue = checkList.map((checkList) => ({
      done: byCheck[checkList.id]?.done || false,
      checklist: checkList.id,
    }))
    return {
      check_list: checkListValue,
    }
  }, [checkList, compileCheckList])

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      onClosed={onClosed}
      size="lg"
      scrollable
    >
      <ModalHeader toggle={toggle}>Check list</ModalHeader>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          return save(setNullOnEmptyValues(values)).then(
            () => {
              actions.setSubmitting(false)
            },
            (error) => {
              actions.setSubmitting(false)
              actions.setErrors(transformErrorForForm(error))
            }
          )
        }}
        render={({ handleSubmit, values, isSubmitting, setFieldValue }) => (
          <>
            <ModalBody>
              <div className="my-2">
                {values.check_list.map((check, i) => (
                  <div key={i}>
                    <Field
                      label={checkList[i].nome}
                      name={`check_list[${i}].done`}
                      component={CheckboxField}
                    />
                  </div>
                ))}
              </div>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
              <Button
                disabled={isSubmitting}
                color="secondary"
                onClick={toggle}
              >
                Chiudi
              </Button>
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
                color="success"
              >
                Salva
              </Button>
            </ModalFooter>
          </>
        )}
      />
    </Modal>
  )
}

export default ModalCheckList
