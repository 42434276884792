import React, { useContext, useMemo } from 'react'
import { Field, connect } from 'formik'
import find from 'lodash/find'
import { InputField, CheckboxField } from '../../../Fields'
import { ConfigPrevetiviContext } from '../../../../../context'
import AlertMessageRegione from './AlertMessageRegione'
import regioni from '../../../../../data/regioni.json'

function FormInfoCliente({ formik }){

  const { tipiCliente } = useContext(ConfigPrevetiviContext)
  const { values } = formik
  const regionePosa = values.regione_posa

  const selectedRegione = useMemo(() => {
    if(!regionePosa){
      return null
    }
    return find(regioni,{ nome: regionePosa })
  },[regionePosa])

  return (
    <div className='FormInfoCliente'>
      <div className='alert alert-info'>
        <h5>Inserimento misure</h5>
        <p>
          Per poter stimare il tuo preventivo è necessario inserire il numero di serramenti che vuoi sostituire e le relative misure.
        </p>
        <div className='row'>
          <div className='col-md-6'>
            <h6>Tipo di cliente</h6>
            <div>
              Se sei un professionista del settore e sai esattamente come misurare i serramenti, specificalo di seguito.
              Che tipo di misure stai riportando?
            </div>
            <Field
              component={InputField}
              name='tipo_cliente'
              type='select'
              style={{
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {tipiCliente.map((tipo, i) => (
                <option key={i} value={tipo[0]}>{tipo[1]}</option>
              ))}
            </Field>
          </div>
          <div className='col-md-5'>
            <h6 className='mt-2'>Messa in posa</h6>
            <Field
              component={CheckboxField}
              name='messa_in_posa'
              type='checkbox'
              checked={values.messa_in_posa}
              label={'Desidero la messa in posa dei serramenti'}
            />
            <h6 className='mt-2'>Regione di posa{/*values.messa_in_posa && <span>*</span>*/}</h6>
            <Field
              component={InputField}
              name='regione_posa'
              type='select'
              disabled={!values.messa_in_posa}
            >
              <option value=''></option>
              {regioni.map((regione, i) => (
                <option key={i} value={regione.nome}>{regione.nome}</option>
              ))}
            </Field>
          </div>
        </div>
        {selectedRegione && selectedRegione.showAlert &&
          <AlertMessageRegione />
        }
      </div>
    </div>
  )
}

export default React.memo(connect(FormInfoCliente))
