import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import find from 'lodash/find'
import { rj, useRunRj } from 'react-rocketjump'
import { ajax } from 'rxjs/ajax'

const StatoPreventivoState = rj(() => ajax.getJSON('/api/stati-preventivo'))

const ModalChooseStato = ({ isOpen, toggle, initialStato, saving, onSelect }) => {

  const [{ data: statiPreventivo }] = useRunRj(StatoPreventivoState)
  const [statoPreventivoSelected, setStatoPreventivo] = useState(initialStato === null ? '' : initialStato)

  useEffect(() => setStatoPreventivo(initialStato), [isOpen, initialStato])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}>Cambia stato preventivo</ModalHeader>
      <ModalBody>
        <div className='container'>
          <div className='row'>
            <select name='stato' value={statoPreventivoSelected} onChange={e => setStatoPreventivo(e.target.value)} className='form-control'>
              <option value=''>Selezionato uno stato</option>
              {statiPreventivo && statiPreventivo.map(stato => (
                <option key={stato.id} value={stato.id}>{stato.stato}</option>
              ))}
            </select>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between'>
        <Button color="secondary" onClick={toggle}>Chiudi</Button>
        <Button color="success" disabled={saving} onClick={() => {
          let selectedStato
          if (selectedStato === '') {
            selectedStato = null
          } else {
            selectedStato = find(statiPreventivo, { id: parseInt(statoPreventivoSelected) })
          }
          onSelect(selectedStato)
        }}>Salva</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalChooseStato
