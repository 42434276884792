import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ChecboxTipiAperture = ({ tipiApertura, tipoSerramento, toggle, saving, save }) => {

  const [tipiAperturaSelected, setTipiAperturaSelected] = useState(() => {
    return tipoSerramento.aperture.reduce((selected, apertura) => ({
      ...selected,
      [apertura.id]: true
    }),{})
  })

  return (
    <>
    <ModalHeader toggle={toggle}>Tipi apertura per {tipoSerramento && tipoSerramento.nome}</ModalHeader>
    <ModalBody>
      <div>
        {tipiApertura.map(apertura => (
          <div key={apertura.id}>
            <input
              type='checkbox'
              id={apertura.id}
              value={apertura.id}
              checked={!!tipiAperturaSelected[apertura.id]}
              onChange={() => setTipiAperturaSelected({
                ...tipiAperturaSelected,
                [apertura.id]: !tipiAperturaSelected[apertura.id]
              })}
            />
            <label className='pl-2' htmlFor={apertura.id}>
              {apertura.nome}
            </label>
          </div>
        ))}
      </div>

    </ModalBody>
    <ModalFooter className='d-flex justify-content-between'>
      <Button color="secondary" onClick={toggle}>Chiudi</Button>
      <Button color="success" disabled={saving} onClick={() => {
        save(Object.keys(tipiAperturaSelected).filter(id => tipiAperturaSelected[id]))
      }}>Salva</Button>
    </ModalFooter>
    </>

  )
}

const ModalChooseTipiApertura = ({ isOpen, toggle, tipiApertura, tipoSerramento, save, saving }) => {

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ChecboxTipiAperture
        tipiApertura={tipiApertura}
        tipoSerramento={tipoSerramento}
        toggle={toggle}
        saving={saving}
        save={save}
      />

    </Modal>
  )
}

export default ModalChooseTipiApertura
