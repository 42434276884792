import React from 'react'

const AlertMessageRegione = () => {
  return (
    <div className='row mt-3'>
      <div className='col-md-12'>
        <div className='alert alert-danger'>
          <strong>Attenzione</strong>: prima di procedere alla compilazione contattare l'ufficio commerciale al numero 0363.969108 per
          verificare la fattibilità dell'offerta per la regione di posa selezionata
        </div>
      </div>
    </div>
  )
}

export default AlertMessageRegione
