import React from 'react'

const DetailSerramento = ({ preventivo }) => {
  return(
    <div className='card p-2'>
      <h5 className='font-weight-bold text-center text-primary'>Serramento</h5>
      <div className='border-bottom pt-1 pb-1'>
        Tipologia: <strong>{preventivo.famiglia.tipologia_famiglia.nome}</strong>
      </div>
      <div className='border-bottom pt-1 pb-1'>
        Famiglia: <strong>{preventivo.famiglia.nome}</strong>
      </div>
      <div className='border-bottom pt-1 pb-1'>
        {preventivo.opzioni.map((opzione,key) => (
          <div className='mb-1' key={key}>
            <span>{opzione.opzione.nome}</span>: <strong>{opzione.valore.nome}</strong>
          </div>
        ))}
      </div>
      <div className='pt-1 pb-1'>
        Tipo intervento: <strong>{preventivo.tipo_intervento}</strong>
      </div>
    </div>
  )
}

export default DetailSerramento
