import React, { useCallback, useContext, useMemo, useState } from 'react'
import { qpBool, qpInt, qpList, qpNullable } from 'magik-react-hooks/qpUtils'
import useDebounceQueryParams from 'magik-react-hooks/useRouterDebounceQueryParams'
import uniqBy from 'lodash/unionBy'
import qs from 'query-string'
import { useRunRj } from 'react-rocketjump'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import moment from 'moment'
import { Button } from 'reactstrap'
import Paginator from '../../../components/Paginator'
import DateRangeQsFilter from '../../../components/Planner/DateRangeQsFilter'
import { ConfigPlannerContext } from '../../../context'
import { AgentiState } from '../../AdminPreventiviApp/localstate'
import { OPERATIONS } from './consts'
import { AvailbaleStatsFieldsContext } from './context'
import { StatsConfigDetailState, StatsRigheOrdiniState } from './localstate'
import StatConfigModal from './StatConfigModal'
import { OrdersDomainState } from '../localstate'
import { DateRangePicker } from 'react-dates'
import OrdersSelectAutoComplete from '../../../components/OrdersSelectAutoComplete'

const FORMATTER = window.Intl
  ? new Intl.NumberFormat('it-It', {
      style: 'decimal',
      maximumFractionDigits: 2,
    })
  : null

function prettyNumber(value) {
  if (typeof value === 'number') {
    if (FORMATTER) {
      return FORMATTER.format(value)
    }
    return value.toFixed(2)
  }
  return value ?? ''
}

function getFieldValue(riga, name) {
  let value
  if (riga[name] === undefined) {
    value = riga.extra_fields[name] ?? ''
  } else {
    value = riga[name]
  }
  return prettyNumber(value)
}

function renderFieldTotals(field, totals) {
  return (
    <div>
      {field.operations
        .slice()
        .sort()
        .map((op) => (
          <div key={op} className="d-flex justify-content-between mb-2">
            <span>
              <small className="text-muted">{OPERATIONS[op]}</small>
            </span>
            <span className="badge badge-primary ml-2">
              {prettyNumber(totals[field.name]?.[op])}
            </span>
          </div>
        ))}
    </div>
  )
}

const qpConfig = {
  tipo_serramento: qpList(),
  agente: qpList(),
  codice: qpList(),
  categoria: qpList(),
  forma: qpList(),
  page: qpInt(),
  stato: qpList(),
  from_date: qpNullable(),
  ordini: qpList(),
  to_date: qpNullable(),
  nome_fase: qpNullable(),
  empty: qpBool(),
}

export default function StatsDetail({ match }) {
  const { id } = match.params

  const [{ data: statsConfig }, { updateConfig }] = useRunRj(
    StatsConfigDetailState,
    [id]
  )

  const [focusedInput, setFocusedInput] = useState(null)

  const FORMAT = 'YYYY-MM-DD'

  const [params, setParams, debouncedParams, setDebouncedParams] =
    useDebounceQueryParams(qpConfig)

  const { tipiSerramento, codiciSerramento } = useContext(ConfigPlannerContext)

  const formeOptions = useMemo(() => {
    const formeTemp = codiciSerramento && uniqBy(codiciSerramento, 'forma')
    return formeTemp.map((c) => ({
      value: c.forma,
      label: c.forma,
    }))
  }, [codiciSerramento])
  const selectedForme = useMemo(() => {
    return params.forma.map((forma) => ({
      label: forma,
      value: forma,
    }))
  }, [params.forma])

  const categorieOptions = useMemo(() => {
    const categorieTemp =
      codiciSerramento && uniqBy(codiciSerramento, 'categoria')
    return categorieTemp.map((c) => ({
      value: c.categoria,
      label: c.categoria,
    }))
  }, [codiciSerramento])
  const selectedCategorie = useMemo(() => {
    return params.categoria.map((cat) => ({
      value: cat,
      label: cat,
    }))
  }, [params.categoria])

  const codiciOptions = useMemo(() => {
    const codiciTemp = codiciSerramento && uniqBy(codiciSerramento, 'codice')
    return codiciTemp.map((c) => ({
      value: c.codice,
      label: c.codice,
    }))
  }, [codiciSerramento])
  const selectedCodici = useMemo(() => {
    return params.codice.map((codice) => ({
      value: codice,
      label: codice,
    }))
  }, [params.codice])

  const tipiSerramentoById = useMemo(() => {
    if (!tipiSerramento) {
      return {}
    }
    return tipiSerramento.reduce((acc, tipo) => {
      acc[tipo.id] = tipo.name
      return acc
    }, {})
  }, [tipiSerramento])
  const tipiSerramentoOptions = useMemo(() => {
    return Object.keys(tipiSerramentoById).map((id) => ({
      value: id,
      label: tipiSerramentoById[id],
    }))
  }, [tipiSerramentoById])
  const selectedTipiSerramento = useMemo(() => {
    return params.tipo_serramento.map((id) => ({
      value: id,
      label: tipiSerramentoById[id],
    }))
  }, [tipiSerramentoById, params.tipo_serramento])

  const [{ data: agenti }] = useRunRj(AgentiState)

  const agentiById = useMemo(() => {
    if (!agenti) {
      return {}
    }
    return agenti.reduce((acc, agente) => {
      acc[agente.id] = agente.user.first_name + ' ' + agente.user.last_name
      return acc
    }, {})
  }, [agenti])
  const agentiOptions = useMemo(() => {
    return Object.keys(agentiById).map((id) => ({
      value: id,
      label: agentiById[id],
    }))
  }, [agentiById])
  const selectedAgenti = useMemo(() => {
    return params.agente.reduce((selected, id) => {
      if (agentiById[id]) {
        selected.push({
          value: id,
          label: agentiById[id],
        })
      }
      return selected
    }, [])
  }, [agentiById, params.agente])

  const [
    { list: righeOrdiniStats, totals, loading, pagination },
    { run: fetchRighe },
  ] = useRunRj(StatsRigheOrdiniState, [id, debouncedParams], false)

  const [{ data: ordersDomain }] = useRunRj(OrdersDomainState)

  const availableFields = useContext(AvailbaleStatsFieldsContext)

  const [isModalOpen, setModalOpen] = useState(false)
  const closeModal = useCallback(() => setModalOpen(false), [])
  const openModal = useCallback(() => setModalOpen(true), [])

  const linkCsv = useMemo(() => {
    return `/api/planner/righe-ordini-stats/${id}/csv?${qs.stringify(
      debouncedParams
    )}`
  }, [debouncedParams, id])

  const { valoriStato } = useContext(ConfigPlannerContext)

  const EMPTY_LIST = []

  const selectedStati = useMemo(() => {
    return params.stato.map((stato) => ({
      label: stato,
      value: stato,
    }))
  }, [params.stato])

  const valoriForFase = useMemo(() => {
    return valoriStato[params.nome_fase] || EMPTY_LIST
  }, [valoriStato, params.nome_fase, EMPTY_LIST])

  function parseDate(rawDate) {
    if (rawDate === null || rawDate === '') {
      return null
    } else {
      const m = moment(rawDate, FORMAT)
      return m.isValid() ? m : null
    }
  }

  const valoriStatoToUse = useMemo(() => {
    return valoriForFase
      .map((c) => ({
        value: c.nome,
        label: c.nome,
      }))
      .concat([
        {
          value: 'NESSUNO STATO',
          label: 'NESSUNO STATO',
        },
      ])
  }, [valoriForFase])

  const { from_date, to_date } = params
  const [startDate, endDate] = useMemo(
    () => [parseDate(from_date), parseDate(to_date)],
    [from_date, to_date]
  )

  return (
    <div className="container-fluid mt-2">
      <div className="mb-2 d-flex justify-content-between">
        <div>
          <Button tag={Link} to="/stats" color="secondary" outline>
            <i className="fa fa-list" /> Configurazioni
          </Button>
          {statsConfig && (
            <Button
              onClick={openModal}
              className="ml-2"
              color="primary"
              outline
            >
              <i className="fa fa-list" /> Modifica configurazione:{' '}
              {statsConfig.name}
            </Button>
          )}
        </div>
        <div>
          <Button
            tag="a"
            href={linkCsv}
            color="primary"
            disabled={righeOrdiniStats && righeOrdiniStats.length === 0}
          >
            <i className="fa fa-file" /> CSV
          </Button>
        </div>
      </div>
      <div className="mb-2 row">
        <div className="col-md-3">
          <OrdersSelectAutoComplete
            value={params.ordini}
            hasValue
            isMulti
            debounceTimeout={150}
            loadOptionsOnMenuOpen
            onChange={(ordine) => {
              if (ordine.length === 0) {
                setParams({ ordini: null, page: 1 })
                return
              } else {
                setParams({ ordini: ordine.map((o) => o.id), page: 1 })
              }
            }}
            noOptionsMessage={() => 'Nessun ordine trovato'}
            loadingMessage={() => 'Caricamento ordini...'}
            placeholder={'Filtra per ordine'}
            isClearable={true}
            name={'ordine'}
            additional={{
              page: 1,
            }}
          />
        </div>
        <div className="col-md-2">
          <input
            type="text"
            placeholder="Cerca"
            className="form-control"
            value={params.search ?? ''}
            onChange={(e) =>
              setDebouncedParams({
                search: e.target.value,
                page: 1,
              })
            }
          />
        </div>
        <div className="col-md-2">
          <select
            className="form-control"
            onChange={(e) => setParams({ nome_fase: e.target.value, page: 1 })}
            name="nome_fase"
            value={params.nome_fase}
          >
            <option value={''}>Fase</option>
            <option value={'banchi'}>Banchi</option>
            <option value={'macchine'}>Macchine</option>
          </select>
        </div>
        <div className="col-md-2">
          <Select
            name="stato"
            isMulti
            placeholder={'Stati'}
            options={valoriStatoToUse}
            closeMenuOnSelect={true}
            isDisabled={loading || !params.nome_fase}
            // blurInputOnSelect={true}
            isOptionDisabled={(option) => {
              const filterEmpty =
                selectedStati.filter((o) => o.value === 'NESSUNO STATO')
                  .length > 0
              const hasFilter = selectedStati.length
              if (hasFilter !== 0 && !filterEmpty) {
                return option.value === 'NESSUNO STATO'
              } else if (filterEmpty && hasFilter === 1) {
                return true
              }
            }}
            value={selectedStati}
            className="basic-multi-select select-multi-options text-nowrap w-100"
            onChange={(newValues) => {
              if (newValues) {
                const items = newValues.map((item) => item.value)
                setParams({
                  stato: items,
                  page: 1,
                })
              } else {
                setParams({
                  stato: [],
                  page: 1,
                })
              }
            }}
          />
        </div>
        <div className="col-md-3">
          <DateRangePicker
            startDate={startDate}
            startDateId="from_date"
            endDate={endDate}
            block={true}
            disabled={!params.nome_fase}
            showClearDates={true}
            horizontalMargin={30}
            endDateId="to_date"
            startDatePlaceholderText={'Data inizio fase'}
            endDatePlaceholderText={'Data fine fase'}
            hideKeyboardShortcutsPanel
            isOutsideRange={() => false}
            onDatesChange={({ startDate, endDate }) => {
              setParams({
                from_date: startDate ? moment(startDate).format(FORMAT) : '',
                to_date: endDate ? moment(endDate).format(FORMAT) : '',
              })
            }}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
          />
        </div>
      </div>
      <div className="table-responsive" style={{ minHeight: 400 }}>
        {statsConfig && (
          <table className="table table-bordered">
            <thead>
              <tr>
                <td></td>
                <td></td>
                <td>
                  <select
                    name="tipo_cliente"
                    value={params.tipo_cliente ?? ''}
                    className="form-control form-control-sm"
                    onChange={(e) =>
                      setParams({
                        tipo_cliente: e.target.value,
                      })
                    }
                  >
                    <option value=""></option>
                    {ordersDomain &&
                      ordersDomain.tipi_cliente.map((tipo) => (
                        <option key={tipo} value={tipo}>
                          {tipo}
                        </option>
                      ))}
                  </select>
                </td>
                <td>
                  <DateRangeQsFilter
                    filters={params}
                    onFiltersChange={setParams}
                    startField="from_data"
                    endField="to_data"
                    withPortal={true}
                    customArrowIcon={<div />}
                    small={false}
                    noBorder
                  />
                </td>
                <td>
                  <Select
                    name="agente"
                    isMulti
                    placeholder={'Tutti'}
                    options={agentiOptions}
                    value={selectedAgenti}
                    className="basic-multi-select select-multi-options text-nowrap"
                    onChange={(newValues) => {
                      if (newValues) {
                        const items = newValues.map((item) => item.value)
                        setParams({
                          agente: items,
                          page: 1,
                        })
                      } else {
                        setParams({
                          agente: [],
                          page: 1,
                        })
                      }
                    }}
                  />
                </td>
                <td></td>
                <td>
                  <Select
                    placeholder={'Tutti'}
                    name="categoria"
                    isMulti
                    options={categorieOptions}
                    value={selectedCategorie}
                    className="basic-multi-select select-multi-options text-nowrap"
                    onChange={(newValues) => {
                      if (newValues) {
                        const items = newValues.map((item) => item.value)
                        setParams({
                          categoria: items,
                          page: 1,
                        })
                      } else {
                        setParams({
                          categoria: [],
                          page: 1,
                        })
                      }
                    }}
                  />
                </td>
                <td>
                  <Select
                    name="forma"
                    isMulti
                    placeholder={'Tutti'}
                    options={formeOptions}
                    value={selectedForme}
                    className="basic-multi-select select-multi-options text-nowrap"
                    onChange={(newValues) => {
                      if (newValues) {
                        const items = newValues.map((item) => item.value)
                        setParams({
                          forma: items,
                          page: 1,
                        })
                      } else {
                        setParams({
                          forma: [],
                          page: 1,
                        })
                      }
                    }}
                  />
                </td>
                <td>
                  <Select
                    name="codice"
                    isMulti
                    placeholder={'Tutti'}
                    options={codiciOptions}
                    value={selectedCodici}
                    className="basic-multi-select select-multi-options text-nowrap"
                    onChange={(newValues) => {
                      if (newValues) {
                        const items = newValues.map((item) => item.value)
                        setParams({
                          codice: items,
                          page: 1,
                        })
                      } else {
                        setParams({
                          codice: [],
                          page: 1,
                        })
                      }
                    }}
                  />
                </td>
                <td>
                  <Select
                    name="tipo_serramento"
                    isMulti
                    placeholder={'Tutti'}
                    options={tipiSerramentoOptions}
                    value={selectedTipiSerramento}
                    className="basic-multi-select select-multi-options text-nowrap"
                    onChange={(newValues) => {
                      if (newValues) {
                        const items = newValues.map((item) => item.value)
                        setParams({
                          tipo_serramento: items,
                          page: 1,
                        })
                      } else {
                        setParams({
                          tipo_serramento: [],
                          page: 1,
                        })
                      }
                    }}
                  />
                </td>
                {statsConfig.fields_conf.fields.map((field) => (
                  <td key={field.name}>
                    {totals ? renderFieldTotals(field, totals) : ''}
                  </td>
                ))}
              </tr>
              <tr>
                <th>Numero commessa</th>
                <th>Cliente</th>
                <th>Tipo cliente</th>
                <th>Data</th>
                <th>Agente</th>
                <th>Numero</th>
                <th>Categoria</th>
                <th>Forma</th>
                <th>Codice</th>
                <th>Tipo</th>
                {statsConfig.fields_conf.fields.map((field) => (
                  <th key={field.name}>{field.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {righeOrdiniStats &&
                righeOrdiniStats.map((riga) => (
                  <tr key={riga.id}>
                    <td>
                      <Link to={`/ordini/${riga.ordine.id}`}>
                        {riga.ordine.numero_commessa}
                      </Link>
                    </td>
                    <td>{riga.ordine.cliente.nome}</td>
                    <td>{riga.ordine.cliente.tipo_cliente}</td>
                    <td className="text-nowrap">{riga.ordine.data}</td>
                    <td>
                      {riga.ordine.agente
                        ? `${riga.ordine.agente.user.first_name} ${riga.ordine.agente.user.last_name}`
                        : null}
                    </td>
                    <td>{riga.numero}</td>
                    <td>{riga.codice_serramento.categoria}</td>
                    <td>{riga.codice_serramento.forma}</td>
                    <td>{riga.codice_serramento.codice}</td>
                    <td>{riga.tipo_serramento.name}</td>
                    {statsConfig.fields_conf.fields.map((field) => (
                      <td key={field.name}>
                        {getFieldValue(riga, field.name)}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
      <Paginator
        numPages={pagination.numPages}
        currentPage={debouncedParams.page ?? 1}
        goToPage={(page) => setParams({ page })}
      />
      <StatConfigModal
        config={statsConfig}
        save={(config) => {
          return updateConfig
            .onSuccess(() => {
              closeModal()
              fetchRighe(id, debouncedParams)
            })
            .asPromise(config)
        }}
        availableFields={availableFields}
        toggle={closeModal}
        isOpen={isModalOpen}
      />
    </div>
  )
}
