import React, { useMemo } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { DateField } from '../../Form/Fields'
import moment from 'moment'
import {
  transformErrorForForm,
  setNullOnEmptyValues,
} from '../../Form/Fields/utils'

const FaseSchedulataSchema = Yup.object().shape({
  fine: Yup.date().required(),
})

const ModalFormMoveFaseSchedulata = ({
  isOpen,
  toggle,
  save,
  faseSchedulata,
}) => {
  const initialValues = useMemo(
    () => ({
      nome_fase: faseSchedulata?.nome_fase,
      fine: faseSchedulata?.fine
        ? moment(faseSchedulata.fine).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
    }),
    [faseSchedulata]
  )

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" scrollable>
      <ModalHeader toggle={toggle}>Sposta fine schedulazione</ModalHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={FaseSchedulataSchema}
        onSubmit={(values, actions) => {
          return save(
            setNullOnEmptyValues({
              fine: values.fine,
              nome_fase: values.nome_fase,
            })
          ).then(
            () => {
              actions.setSubmitting(false)
            },
            (error) => {
              actions.setSubmitting(false)
              actions.setErrors(transformErrorForForm(error))
            }
          )
        }}
        render={({ handleSubmit, values, isSubmitting, errors }) => (
          <>
            <ModalBody>
              <form onSubmit={handleSubmit} style={{ minHeight: 350 }}>
                <Field label="Fine *" name="fine" component={DateField} />
                <div className="py-5 d-flex justify-content-center">
                  {faseSchedulata && (
                    <div>
                      <div className="mb-3 d-flex justify-content-between">
                        <div className="mr-3">Inizio:</div>
                        <div>
                          <b>
                            {moment(faseSchedulata.inizio).format('DD-MM-YYYY')}
                          </b>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mb-3">
                        <div>Fine:</div>
                        <div>
                          <b>
                            {moment(faseSchedulata.fine).format('DD-MM-YYYY')}
                          </b>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>Durata:</div>
                        <div>
                          <b>
                            {moment
                              .duration(
                                faseSchedulata.durata_override ??
                                  faseSchedulata.durata
                              )
                              .asDays()}{' '}
                            giorni
                          </b>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
              <Button
                disabled={isSubmitting}
                color="secondary"
                onClick={toggle}
              >
                Chiudi
              </Button>
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
                color="success"
              >
                Salva
              </Button>
            </ModalFooter>
          </>
        )}
      />
    </Modal>
  )
}

export default ModalFormMoveFaseSchedulata
