import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import find from 'lodash/find'
import { rj, useRunRj } from 'react-rocketjump'
import { ajax } from 'rxjs/ajax'

const AgentiState = rj(() => ajax.getJSON('/api/agenti'))

const ModalChooseAgente = ({ isOpen, toggle, onSelect, saving, initialIdAgente }) => {

  const [{ data: agenti }] = useRunRj(AgentiState)
  const [idAgente, setIdAgente] = useState(initialIdAgente === null ? '' : initialIdAgente)

  // Reset intial id agente when model open / close
  useEffect(() => setIdAgente(initialIdAgente), [isOpen, initialIdAgente])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}>Associa agente a preventivo</ModalHeader>
      <ModalBody>
        <div className='container'>
          <div className='row'>
            <select name='agente' className='form-control' value={idAgente} onChange={e => setIdAgente(e.target.value)}>
              <option value=''>Nessun agente</option>
              {agenti && agenti.map(agente => (
                <option key={agente.id} value={agente.id}>{agente.user.first_name} {agente.user.last_name}</option>
              ))}
            </select>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between'>
        <Button color="secondary" onClick={toggle}>Chiudi</Button>
        <Button color="success" disabled={saving} onClick={() => {
          let selectedAgente
          if (idAgente === '') {
            selectedAgente = null
          } else {
            selectedAgente = find(agenti, { id: parseInt(idAgente) })
          }
          onSelect(selectedAgente)
        }}>Salva</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalChooseAgente
