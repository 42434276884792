import { rj } from 'react-rocketjump'
import magikApi from 'magik-api'
import { CSRF, SETTINGS } from '../../../django'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'

// TODO: Unify API
const api = magikApi().trailingSlash(true).headers({
  'X-CSRFToken': CSRF,
})

export const OperatoriState = rj()
  .plugins(
    rjList({
      pageSize: SETTINGS.PAGE_SIZE,
      pagination: nextPreviousPaginationAdapter,
    })
  )
  .computed({
    operatori: 'getList',
  })
  .effect({
    name: 'OperatoriState',
    effect: (params = {}) => api.get('/api/planner/operatori', params),
  })
