import { useEffect, useContext, useMemo, useCallback } from 'react'
import deepEqual from 'fast-deep-equal'
import qs from 'query-string'
import { useRunRj, deps, rj, SUCCESS } from 'react-rocketjump'
import { map } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import moment from 'moment'
import keyBy from 'lodash/keyBy'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import first from 'lodash/first'
import range from 'lodash/range'
import last from 'lodash/last'
import find from 'lodash/find'
import memoize from 'memoize-one'
import { padTimeStr } from '../dateUtils'
import { ConfigPlannerContext } from '../../../context'
import { CSRF } from '../../../django'
import { getFasiNeededByOrder } from './common'

function mergeSlots(oldSlots, newSlots) {
  return Object.keys(oldSlots).reduce((slots, faseName) => ({
    ...slots,
    [faseName]: { ...oldSlots[faseName], ...newSlots[faseName] }
  }), {})
}

function mergeLoadedItems(prevItems, rangeDates) {
  const days = moment(rangeDates.to_date).diff(rangeDates.from_date, 'days')
  return range(days + 1).reduce((newItems, i) => {
    const date = moment(rangeDates.from_date).add(i, 'days').format('YYYY-MM-DD')
    return {
      ...newItems,
      [date]: true,
    }
  }, prevItems)
}

function computeRange(loadedItems) {
  const dates = Object.keys(loadedItems).sort()
  if (dates.length) {
    return {
      fromDate: moment(dates[0] + ' 00:00:00').toDate(),
      toDate: moment(dates[dates.length - 1] + ' 00:00:00').toDate(),
    }
  }
  return { fromDate: null, toDate: null }
}

export const SchedulerSlotsState = rj({
  name: 'SchedulerSlots',
  takeEffect: 'every',
  reducer: defaultReducer => (state, action) => {
    const { type, payload } = action
    switch (type) {
      case SUCCESS: {
        const range = payload.params[0]
        const prevLoadedItmes = state.data === null ? {} : state.data.loadedItems
        return {
          ...state,
          pending: false,
          data: {
            loadedItems: mergeLoadedItems(prevLoadedItmes, range),
            slots: state.data === null
              ? payload.data
              : mergeSlots(state.data.slots, payload.data),
          }
        }
      }
      default:
        return defaultReducer(state, action)
    }
  },
  selectors: () => {
    const calculateRange = memoize(computeRange)
    return {
      getLoadedItems: state => state.root.data === null ? {} : state.root.data.loadedItems,
      getSlots: state => state.root.data === null ? {} : state.root.data.slots,
      getRange: state => state.root.data === null ? null : calculateRange(state.root.data.loadedItems),
    }
  },
  computed: {
    loadedItems: 'getLoadedItems',
    slots: 'getSlots',
    range: 'getRange',
    loading: 'isLoading',
  },
  effect: (filters = {}) =>
    ajax.getJSON(`/api/planner/scheduler-slots/?${qs.stringify(filters)}`),
})

function fromDateTimeStrToDate(dateTimeStr) {
  if (dateTimeStr === null) {
    return null
  }
  return moment(dateTimeStr).toDate()
}

function fromDateToDateTimeStr(date) {
  if (date === null || date === '') {
    return null
  }
  return moment(date).format('YYYY-MM-DD HH:mm:ss')
}

function fromDateToScheduleDateTimeStr(date) {
  if (date === null || date === '') {
    return null
  }
  return moment(date).format('YYYY-MM-DD HH')
}

function fromDurationToNumber(durationStr, unit) {
  if (durationStr === null) {
    return null
  }
  if (unit === 'minutes') {
    return parseInt(moment.duration(durationStr).asMinutes())
  } else if (unit === 'days') {
    return parseInt(moment.duration(durationStr).asDays())
  } else {
    throw new Error(`Invalid unit ${unit}.`)
  }
}

function fromNumberToDuration(n, unit) {
  if (n === null || n === '') {
    return null
  }
  let duration
  if (unit === 'minutes') {
    duration = moment.duration({ minutes: n })
  } else if (unit === 'days') {
    duration = moment.duration({ days: n })
  } else {
    throw new Error(`Invalid unit ${unit}.`)
  }
  let str = `${padTimeStr(duration.hours())}:${padTimeStr(duration.minutes())}:${padTimeStr(duration.seconds())}`
  if (duration.days() > 0) {
    return duration.days() + ' ' + str
  }
  return str
}

function mergeSchedulazioneWithFasi(schedulazioniByFase, fasiScheduler, fasiNeeded) {
  if (schedulazioniByFase === null) {
    return null
  }

  function areDepsOk(dependencies) {
    if (dependencies.length === 0) {
      return true
    }
    return !dependencies.some(dep => {
      if (!fasiNeeded[dep]) {
        return false
      }
      return !schedulazioniByFase[dep].inizio
    })
  }

  return fasiScheduler.map(fase => {
    return {
      ...fase,
      ...schedulazioniByFase[fase.nome_fase],
      depsOk: areDepsOk(fase.dependencies),
      isNeeded: fasiNeeded[fase.nome_fase]
    }
  })
}

function updateFornitore(fasiSchedulazione, { fornitore, nomeFase }) {
  return {
    ...fasiSchedulazione,
    [nomeFase]: {
      ...fasiSchedulazione[nomeFase],
      durata_override: null,
      durata: fornitore ? fornitore.tempo : null,
      fornitore: fornitore,
    }
  }
}

const digitsRegex = /^\d+$/

function updateDurata(fasiSchedulazione, { durata, nomeFase }) {
  if (durata !== '' && !durata.match(digitsRegex)) {
    return fasiSchedulazione
  }
  return {
    ...fasiSchedulazione,
    [nomeFase]: {
      ...fasiSchedulazione[nomeFase],
      durata_override: durata,
    }
  }
}

function updateDataInizio(fasiSchedulazione, { data, nomeFase }) {
  return {
    ...fasiSchedulazione,
    [nomeFase]: {
      ...fasiSchedulazione[nomeFase],
      inizio: data,
    }
  }
}

function autoUpdateDateInizio(fasiSchedulazione, { data, fasiScheduler, fasiNeeded }) {
  const newFasiSchedulazione = { ...fasiSchedulazione }
  fasiScheduler.forEach(fase => {
    const nomeFase = fase.nome_fase
    if (fase.infinite_capacity && fase.dependencies.length === 0 && fasiNeeded[nomeFase]) {
      newFasiSchedulazione[nomeFase] = {
        ...fasiSchedulazione[nomeFase],
        inizio: data,
      }
    }
  })
  return newFasiSchedulazione
}

function computeIsScheduleComplete(fasiSchedulazione, scheduleErrors, fasiNeeded) {
  if (fasiSchedulazione === null) {
    return false
  }
  // NOTE this skip the checks on errors
  // if (Object.keys(scheduleErrors).length) {
  //   return false
  // }
  return !Object.keys(fasiNeeded).some(nomeFase => {
    const isNeeded = fasiNeeded[nomeFase]
    // Non needed laways ok!
    if (!isNeeded) {
      return false
    }
    const schedulazione = fasiSchedulazione[nomeFase]
    const isIncomplete = !(schedulazione.inizio && schedulazione.fine)
    return isIncomplete
  })
}

function groupOccupations(occupations) {
  const byDate = groupBy(occupations, 'date')
  return mapValues(byDate, occupationsInDate => keyBy(occupationsInDate, 'time'))
}

function mergeOccupationsWithFasi(fasiSchedulazione, occupationsResponse) {
  return mapValues(fasiSchedulazione, faseSchedulazione => {
    const faseName = faseSchedulazione.nome_fase
    const occupationsFase = occupationsResponse.occupations[faseName]
    if (occupationsFase && occupationsFase.length > 0) {
      const firstOccupation = occupationsFase[0]
      const lastOccupation = occupationsFase[occupationsFase.length - 1]
      return {
        ...faseSchedulazione,
        inizio: fromDateTimeStrToDate(
          firstOccupation.date + ' ' + padTimeStr(firstOccupation.time) + ':00'
        ),
        fine: fromDateTimeStrToDate(
          lastOccupation.date + ' ' + padTimeStr(lastOccupation.time) + ':00'
        ),
        occupations: groupOccupations(occupationsFase)
      }
    } else {
      return {
        ...faseSchedulazione,
        fine: null,
        occupations: {}
      }
    }
  })
}

function calculateSchedulazioniRange(schedulazioni) {
  // Calculate the range of ALL order schedule
  const fromDates = schedulazioni
    .filter(s => s.occupations.length)
    .map(s => moment(first(s.occupations).date + ' 00:00:00').toDate())
  const toDates = schedulazioni
    .filter(s => s.occupations.length)
    .map(s => moment(last(s.occupations).date + ' 00:00:00').toDate())

  if (fromDates.length && toDates.length) {
    const fromDate = new Date(Math.min(...fromDates))
    const toDate = new Date(Math.max(...toDates))
    return { fromDate, toDate }
  }

  return null
}

function mapSchedulazioneOrdineFromServer(data, fasiScheduler, fasiNeeded, orderId, fornitori = []) {
  const schedulazioniByFase = keyBy(data.schedulazioni, 'nome_fase')
  const mappedSchedulazioni = fasiScheduler.map(fase => {
    let mappedSchedulazione
    const schedulazione = schedulazioniByFase[fase.nome_fase]
    const isNeeded = fasiNeeded[fase.nome_fase] === undefined
      ? true
      : fasiNeeded[fase.nome_fase]
    if (schedulazione) {
      mappedSchedulazione = {
        ...schedulazione,
        nome_fase: fase.nome_fase,
        // Convert str date to Date object
        inizio: fromDateTimeStrToDate(schedulazione.inizio),
        fine: fromDateTimeStrToDate(schedulazione.fine),
        // Convert durations 2 minutes
        durata: fromDurationToNumber(schedulazione.durata, fase.unit),
        durata_override: fromDurationToNumber(schedulazione.durata_override, fase.unit),
        occupations: groupOccupations(schedulazione.occupations),
      }
    } else {
      let durata
      if (data.tempi[fase.nome_fase]) {
        durata = data.tempi[fase.nome_fase]
      } else {
        durata = null
      }
      let fornitore = null
      if (fase.with_fornitore) {
        fornitore = find(fornitori, {
          tipologia: fase.nome_fase,
          is_default: true
        }) || null
      }

      if (!durata && fornitore) {
        durata = fornitore.tempo
      }

      let durata_override
      if (isNeeded) {
        durata_override = null
      } else {
        // When fase not needed set duratta override to 0
        // ... and set other data to null
        durata_override = 0
        fornitore = null
        durata = null
      }

      mappedSchedulazione = {
        nome_fase: fase.nome_fase,
        fornitore,
        durata,
        durata_override,
        inizio: null,
        fine: null,
        occupations: {},
      }
    }
    return mappedSchedulazione
  })
  const mappedSchedulazioniByFase = keyBy(mappedSchedulazioni, 'nome_fase')
  return {
    prevRangeSchedulazione: calculateSchedulazioniRange(data.schedulazioni),
    scheduleOrderId: orderId,
    fasiSchedulazione: mappedSchedulazioniByFase,
    tempi: data.tempi,
    scheduleErrors: data.errors,
    scheduleConfig: null,
    scheduled: true,
    lastFaseEdited: null,
    saved: true,
    relaxed: true,
  }
}

function mapSchedulazioneOrdineToServer(schedulazioni, fasiNeeded, fasiScheduler) {
  const onlyFasi = Object.keys(schedulazioni).filter(nomeFase => {
    if (!fasiNeeded[nomeFase]) {
      return true
    }
    const schedulazione = schedulazioni[nomeFase]
    return schedulazione.inizio && schedulazione.fine
  })
  const fasiSchedulerByFasi = keyBy(fasiScheduler, 'nome_fase')
  return onlyFasi.map(nomeFase => {
    const schedulazione = schedulazioni[nomeFase]
    const unit = fasiSchedulerByFasi[nomeFase].unit
    return {
      ...schedulazione,
      inizio: fromDateToDateTimeStr(schedulazione.inizio),
      fine: fromDateToDateTimeStr(schedulazione.fine),
      fornitore: schedulazione.fornitore ? schedulazione.fornitore.id : null,
      durata: fromNumberToDuration(schedulazione.durata, unit),
      durata_override: fromNumberToDuration(schedulazione.durata_override, unit),
    }
  })
}

function makeNotRelaxedFasiQueryParam({ relaxed, lastFaseEdited }) {
  if (relaxed) {
    return ''
  }
  if (!lastFaseEdited) {
    return 'ALL'
  }
  return lastFaseEdited
}

export const SchedulazioneOrdineState = rj({
  name: 'SchedulazioneOrdineState',
  effect: id =>
    ajax.getJSON(`/api/planner/ordini/${id}/tempi/`),
  mutations: {
    reSchedule: rj.mutation.single({
      takeEffect: 'latest',
      effect: (idOrdine, scheduleConfig) => ajax({
        method: 'POST',
        url: `/api/planner/order-schedule/${idOrdine}/?not_relaxed_fasi=${makeNotRelaxedFasiQueryParam(scheduleConfig.options)}`,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': CSRF,
        },
        body: JSON.stringify(scheduleConfig.config)
      }).pipe(map(r => [r.response, scheduleConfig])),
      updater: (state, [occupationsResponse, scheduleConfig]) => ({
        ...state,
        data: {
          ...state.data,
          scheduleConfig,
          scheduled: true,
          scheduleErrors: occupationsResponse.errors,
          fasiSchedulazione: mergeOccupationsWithFasi(
            state.data.fasiSchedulazione,
            occupationsResponse,
          ),
        }
      }),
    }),
    saveSchedulazione: rj.mutation.single({
      effect: (idOrdine, schedulazione, fasiScheduler, fasiNeeded) => ajax({
        method: 'PUT',
        url: `/api/planner/ordini/${idOrdine}/save-tempi/`,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': CSRF,
        },
        body: JSON.stringify(mapSchedulazioneOrdineToServer(schedulazione, fasiNeeded, fasiScheduler))
      }).pipe(map(r => [r.response, fasiScheduler, fasiNeeded, idOrdine])),
      updater: (prevState, [schedulazione, fasiScheduler, fasiNeeded, idOrdine]) => ({
        ...prevState,
        data: mapSchedulazioneOrdineFromServer(
          schedulazione,
          fasiScheduler,
          fasiNeeded,
          idOrdine
        ),
      })
    })
  },
  actions: () => ({
    updateFornitore: (nomeFase, fornitore) => ({
      type: 'UPDATE_FORNITORE',
      payload: { nomeFase, fornitore }
    }),
    updateDurata: (nomeFase, durata) => ({
      type: 'UPDATE_DURATA',
      payload: { nomeFase, durata }
    }),
    updateDataInizio: (nomeFase, data, relaxed = true) => ({
      type: 'UPDATE_DATA_INIZIO',
      payload: { nomeFase, data, relaxed }
    }),
    autoUpdateDateInizio: (fasiScheduler, fasiNeeded) => ({
      type: 'AUTO_UPDATE_DATE_INIZIO',
      payload: {
        fasiScheduler,
        fasiNeeded,
        relaxed: false,
        data: moment().hours(0).minutes(0).seconds(0).toDate()
      }
    }),
  }),
  composeReducer: (prevState, { type, payload, meta }) => {
    if (type === 'UPDATE_DATA_INIZIO') {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          saved: false,
          scheduled: false,
          relaxed: payload.relaxed,
          lastFaseEdited: payload.nomeFase,
          fasiSchedulazione: updateDataInizio(prevState.data.fasiSchedulazione, payload),
        }
      }
    } else if (type === 'AUTO_UPDATE_DATE_INIZIO') {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          saved: false,
          scheduled: false,
          relaxed: payload.relaxed,
          lastFaseEdited: null,
          fasiSchedulazione: autoUpdateDateInizio(prevState.data.fasiSchedulazione, payload),
        }
      }
    } else if (type === 'UPDATE_DURATA') {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          saved: false,
          scheduled: false,
          lastFaseEdited: payload.nomeFase,
          relaxed: true,
          fasiSchedulazione: updateDurata(prevState.data.fasiSchedulazione, payload),
        }
      }
    } else if (type === 'UPDATE_FORNITORE') {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          saved: false,
          scheduled: false,
          lastFaseEdited: payload.nomeFase,
          relaxed: true,
          fasiSchedulazione: updateFornitore(prevState.data.fasiSchedulazione, payload),
        }
      }
    } else if (type === SUCCESS) {
      return {
        ...prevState,
        data: mapSchedulazioneOrdineFromServer(
          payload.data,
          meta.fasiScheduler,
          meta.fasiNeeded,
          meta.orderId,
          meta.fornitori,
        ),
      }
    }
    return prevState
  },
  computed: {
    loading: 'isLoading',
    scheduling: s => s.mutations.reSchedule.pending,
    saving: s => s.mutations.saveSchedulazione.pending,
    data: 'getData',
  },
})

function createScheduleConfig(fasiSchedulazione, fasiNeeded, relaxed, lastFaseEdited) {
  const config = fasiSchedulazione.reduce((config, fase) => {
    // Fase dont't needed at all
    if (!fasiNeeded[fase.nome_fase]) {
      config[fase.nome_fase] = {
        fornitore: null,
        durata_override: 0,
      }
    } else if (
      (relaxed && fase.inizio) ||
      (!relaxed &&
        (
          (fase.inizio || fase.dependencies.length > 0) &&
          (!fase.with_fornitore || (fase.with_fornitore && fase.fornitore))
        )
      )
    ) {
      config[fase.nome_fase] = {}
      if (fase.inizio) {
        config[fase.nome_fase].from_date = fromDateToScheduleDateTimeStr(fase.inizio)
      }
      if (fase.fornitore) {
        config[fase.nome_fase].fornitore = fase.fornitore.id
      }
      if (fase.durata_override !== null && fase.durata_override !== '') {
        config[fase.nome_fase].durata_override = fase.durata_override
      }
    }
    return config
  }, {})
  return {
    config,
    options: {
      relaxed,
      lastFaseEdited,
    }
  }
}

const NO_SCHEDULE_ORDER = {
  scheduleOrderId: null,
  prevRangeSchedulazione: null,
  fasiSchedulazione: null,
  saved: null,
  scheduled: null,
  relaxed: true,
  lastFaseEdited: null,
  scheduleConfig: null,
  scheduleErrors: null,
  tempi: null,
}

export function useOrderScheduler(ordine, fornitori = [], refreshSlots) {
  const { fasiScheduler } = useContext(ConfigPlannerContext)
  const fasiNeeded = useMemo(() => {
    if (ordine === null) {
      return {}
    }
    return getFasiNeededByOrder(ordine, fasiScheduler)
  }, [ordine, fasiScheduler])
  const [state, actions] = useRunRj(SchedulazioneOrdineState, [
    deps.maybeGet(ordine, 'id').withMeta({
      orderId: ordine ? ordine.id : null,
      fasiScheduler,
      fornitori,
      fasiNeeded,
    }),
  ], false)
  const { scheduling, loading, saving } = state
  const {
    scheduleErrors,
    prevRangeSchedulazione,
    fasiSchedulazione,
    saved,
    scheduled,
    scheduleConfig,
    tempi,
    relaxed,
    lastFaseEdited,
  } = state.data === null ? NO_SCHEDULE_ORDER : state.data
  const { reSchedule, saveSchedulazione, autoUpdateDateInizio, clean } = actions

  const autoUpdateDateInizioWihFasi = useCallback(() => {
    autoUpdateDateInizio(fasiScheduler, fasiNeeded, fasiNeeded)
  }, [fasiScheduler, fasiNeeded, autoUpdateDateInizio])

  const scheduleOrderId = (
    state.data !== null &&
    !loading &&
    ordine !== null &&
    state.data.scheduleOrderId === ordine.id
  ) ? state.data.scheduleOrderId : null

  const [scheduleOrder, tempiOrdine] = useMemo(() => {
    if (scheduleOrderId && ordine && scheduleOrderId === ordine.id) {
      return [ordine, tempi]
    }
    return [null, null]
  }, [ordine, scheduleOrderId, tempi])

  const fasiSchedulazioneList = useMemo(
    () => mergeSchedulazioneWithFasi(fasiSchedulazione, fasiScheduler, fasiNeeded),
    [fasiSchedulazione, fasiScheduler, fasiNeeded]
  )

  const isScheduleComplete = useMemo(
    () => computeIsScheduleComplete(fasiSchedulazione, scheduleErrors, fasiNeeded),
    [fasiSchedulazione, scheduleErrors, fasiNeeded]
  )

  const mergedState = useMemo(() => ({
    saving,
    // isScheduleComplete,
    saved: loading ? null : saved, // While loading turn off saved flag
    isScheduleComplete,
    scheduleOrder,
    scheduling,
    scheduleErrors,
    fasiSchedulazione,
    fasiNeeded,
    fasiSchedulazioneList,
    tempiOrdine,
  }), [
    fasiSchedulazione, scheduleErrors, scheduling, fasiSchedulazioneList,
    saved, loading, saving, isScheduleComplete, scheduleOrder, fasiNeeded,
    tempiOrdine,
  ])

  const saveCurrentSchedulazione = useCallback(() => {
    if (scheduleOrderId) {
      saveSchedulazione
        .onSuccess(([schedulazioneResponse, fasiScheduler, idOrdine]) => {
          const nextRangeSchedulazione = calculateSchedulazioniRange(schedulazioneResponse.schedulazioni)
          let { fromDate, toDate } = nextRangeSchedulazione
          if (prevRangeSchedulazione) {
            fromDate = new Date(Math.min(fromDate, prevRangeSchedulazione.fromDate))
            toDate = new Date(Math.max(toDate, prevRangeSchedulazione.toDate))
          }
          refreshSlots(fromDate, toDate)
        })
        .run(scheduleOrderId, fasiSchedulazione, fasiScheduler, fasiNeeded)
    }
  }, [
    fasiSchedulazione, scheduleOrderId, fasiScheduler, saveSchedulazione,
    refreshSlots, prevRangeSchedulazione, fasiNeeded,
  ])

  const mergedActions = useMemo(() => ({
    ...actions,
    autoUpdateDateInizio: autoUpdateDateInizioWihFasi,
    saveSchedulazione: saveCurrentSchedulazione,
  }), [actions, saveCurrentSchedulazione, autoUpdateDateInizioWihFasi])

  useEffect(() => {
    if (saved === false && scheduled === false) {
      const newScheduleConfig = createScheduleConfig(fasiSchedulazioneList, fasiNeeded, relaxed, lastFaseEdited)
      // NOTE: Whene not relaxed skip deep equal check ....
      const shouldSchedule = !relaxed || !deepEqual(scheduleConfig, newScheduleConfig)
      if (Object.keys(newScheduleConfig).length && shouldSchedule) {
        reSchedule(scheduleOrderId, newScheduleConfig)
      }
    }
  }, [
    fasiSchedulazioneList, saved, scheduled, scheduleOrderId,
    reSchedule, scheduleConfig, fasiNeeded, relaxed, lastFaseEdited,
  ])

  // Whe request order is cleared clear related schedule
  const orderId = ordine ? ordine.id : null
  const isStateEmpty = state.data === null
  useEffect(() => {
    if (!isStateEmpty && orderId === null) {
      clean()
    }
  }, [orderId, isStateEmpty, clean])

  return useMemo(() => [mergedState, mergedActions], [mergedState, mergedActions])
}
