import React, { useContext } from 'react'
import useQueryParams from 'magik-react-hooks/useRouterQueryParams'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { useCallback } from 'react'
import { useRunRj } from 'react-rocketjump'
import { useHistory } from 'react-router-dom'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { StatsConfigListState } from './localstate'
import StatConfigModal from './StatConfigModal'
import { AvailbaleStatsFieldsContext } from './context'
import Paginator from '../../../components/Paginator'
import { OPERATIONS } from './consts'

function renderListFields(config) {
  const fieldsList = config?.fields_conf?.fields ?? []
  return fieldsList.map((field, i) => (
    <div className={i === 0 ? '' : 'ml-2'} key={field.name}>
      {field.name}
      {field.operations.length > 0 && (
        <small className='text-muted'>
          {' ('}
          {field.operations.map((op, i) => (
            <span className={i === 0 ? '' : 'ml-1'} key={op}>
              {OPERATIONS[op]}
            </span>
          ))}
          {') '}
        </small>
      )}
    </div>
  ))
}

export default function Stats() {
  const history = useHistory()

  const availableFields = useContext(AvailbaleStatsFieldsContext)

  const [queryParams, setQueryParams] = useQueryParams()

  const [
    { list: configList, removing, pagination },
    { addConfig, updateConfig, removeConfig, run },
  ] = useRunRj(StatsConfigListState, [queryParams], false)

  const [modalConfig, setModalConfig] = useState({
    open: false,
    record: null,
  })
  const closeModal = useCallback(
    () =>
      setModalConfig({
        open: false,
        record: null,
      }),
    []
  )
  const openAddModal = useCallback(
    () =>
      setModalConfig({
        open: true,
        record: null,
      }),
    []
  )
  const openEditModal = useCallback(
    (record) =>
      setModalConfig({
        open: true,
        record,
      }),
    []
  )

  const [modalDeleteConfig, setModalDeleteConfig] = useState(null)
  const closeDeleteModal = useCallback(() => setModalDeleteConfig(null), [])

  return (
    <div className="container-fluid mt-2">
      <div className="mb-2">
        <Button size="xs" color="primary" outline onClick={openAddModal}>
          Nuova configurazione
        </Button>
      </div>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Campi</th>
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody>
          {configList &&
            configList.map((config) => (
              <tr key={config.id}>
                <td>
                  <Link to={`/stats/${config.id}`}>{config.name}</Link>
                </td>
                <td>
                  <div className="d-flex">{renderListFields(config)}</div>
                </td>
                <td>
                  <Button
                    onClick={() => openEditModal(config)}
                    size="sm"
                    color="primary"
                    outline
                  >
                    <i className="fas fa-edit " />
                  </Button>
                  <Button
                    onClick={() => setModalDeleteConfig(config)}
                    className="ml-2"
                    size="sm"
                    color="danger"
                    outline
                  >
                    <i className="fas fa-trash " />
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <Paginator
        numPages={pagination.numPages}
        currentPage={+queryParams.page || 1}
        goToPage={(page) => setQueryParams({ page })}
      />

      <Modal isOpen={modalDeleteConfig !== null} toggle={closeDeleteModal}>
        <ModalHeader toggle={closeDeleteModal}>
          {modalDeleteConfig &&
            `Rimuovere confiurezione "${modalDeleteConfig.name}"`}
        </ModalHeader>
        <ModalBody>
          {modalDeleteConfig &&
            `Rimuovere la confiurezione "${modalDeleteConfig.name}"?`}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button
            disabled={removing}
            onClick={closeDeleteModal}
            color="secondary"
          >
            Annulla
          </Button>
          <Button
            disabled={removing}
            onClick={() =>
              removeConfig
                .onSuccess(() => {
                  closeDeleteModal()
                  run(queryParams)
                })
                .run(modalDeleteConfig)
            }
            color="danger"
          >
            Rimuovi
          </Button>
        </ModalFooter>
      </Modal>

      <StatConfigModal
        config={modalConfig.record}
        save={(config) => {
          if (modalConfig.record) {
            return updateConfig.onSuccess(() => closeModal()).asPromise(config)
          }
          return addConfig
            .onSuccess((createdConfig) => {
              history.push(`/stats/${createdConfig.id}`)
            })
            .asPromise(config)
        }}
        availableFields={availableFields}
        toggle={closeModal}
        isOpen={modalConfig.open}
      />
    </div>
  )
}
