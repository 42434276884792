/**
 *
 * @return 'inviato' | 'non_inviato' | 'in_attesa'
 */
export function getConfermaStato(confermaOrdine) {
  if (confermaOrdine.data_invio) {
    return 'inviato'
  }
  if (
    confermaOrdine.preventivo_firmato &&
    confermaOrdine?.ordine?.data_consegna
  ) {
    return 'in_attesa'
  }
  return 'non_inviato'
}
