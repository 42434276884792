import React from 'react'
import range from 'lodash/range'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const Paginator = ({
  numPages,
  currentPage,
  goToPage,
  className = 'd-flex justify-content-center',
}) => {
  const maxVisiblePages = 10
  
  // Determine pages to display
  const getPageNumbers = () => {
    if (numPages <= maxVisiblePages + 2) {
      return range(1, numPages + 1)
    }

    const start = Math.max(2, currentPage - Math.floor(maxVisiblePages / 2))  // Start from 2 to avoid duplicate '1'
    const end = Math.min(numPages - 1, start + maxVisiblePages - 1)  // End before last page to avoid duplicate 'numPages'

    const pages = [
      1,
      ...(start > 2 ? ['...'] : []),
      ...range(start, end + 1),
      ...(end < numPages - 1 ? ['...'] : []),
      numPages,
    ]

    return pages
  }

  const pages = getPageNumbers()

  return (
    <Pagination className={className}>
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink previous onClick={() => goToPage(currentPage - 1)} />
      </PaginationItem>
      {pages.map((page, index) =>
        typeof page === 'string' ? (
          <PaginationItem key={`ellipsis-${index}`} disabled>
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        ) : (
          <PaginationItem key={page} active={page === currentPage}>
            <PaginationLink onClick={() => goToPage(page)}>
              {page}
            </PaginationLink>
          </PaginationItem>
        )
      )}
      <PaginationItem disabled={currentPage === numPages}>
        <PaginationLink next onClick={() => goToPage(currentPage + 1)} />
      </PaginationItem>
    </Pagination>
  )
}

export default Paginator
