import React, { useEffect, useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap'
import {
  ImmaginiRigaOrdineState,
  ScattaFotoRigaOrdineState,
  UploadImmagineRigaOrdineState,
} from '../../apps/PlannerApp/localstate'
import { deps, useRj } from 'react-rocketjump'
import { USER } from '../../django'
function checkImmagine(i, immagini) {
  const immagine = immagini.find((img) => img.posizione === i)
  if (immagine) {
    return immagine
  }
  return null
}

export default function RigaOrdineImmagini({ riga, fetchOrder, order }) {
  const [open, setOpen] = useState(false)

  const [{ data: immagini }, { run }] = useRj(ImmaginiRigaOrdineState, [
    deps.maybeGet(riga, 'id'),
  ])

  const [showScattaFoto, setShowScattaFoto] = useState(false)
  const toggleShowScattaFoto = () => setShowScattaFoto((show) => !show)

  const [immagine, setImmagine] = useState(null)

  const [{ data: foto, loading }, { run: runScatta, clean }] = useRj(
    ScattaFotoRigaOrdineState
  )

  const [{ data: immagineSaved }, { run: runSave }] = useRj(
    UploadImmagineRigaOrdineState
  )

  useEffect(() => {
    if (open && riga && riga.id) {
      run(riga.id)
    }
  }, [open])

  const [loadingScatta, setLoadingScatta] = useState(false)
  const [loadingFromList, setLoadingFromList] = useState(null)
  const [loadingSave, setLoadingSave] = useState(false)
  const [error, setError] = useState(null)
  const [errorFromList, setErrorFromList] = useState(null)

  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-center mb-1">
          <Button
            outline
            type="button"
            size="sm"
            onClick={() => {
              setOpen(!open)
            }}
          >
            {!open && (
              <small>
                {'+ '}Mostra immagini (
                {
                  order?.righe_ordine?.find((r) => r.id === riga.id)
                    ?.numero_immagini
                }
                /{riga.numero})
              </small>
            )}
            {open && (
              <small>
                {'- '}Nascondi immagini (
                {
                  order?.righe_ordine?.find((r) => r.id === riga.id)
                    ?.numero_immagini
                }
                /{riga.numero})
              </small>
            )}
          </Button>
        </div>
      </div>
      {open && (
        <div
          className="d-flex align-items-center mt-3 mb-3 px-4 justify-content-center"
          style={{
            flexWrap: 'wrap',
          }}
        >
          {[...Array(riga.numero)].map((_, i) => (
            <div
              key={i}
              onClick={() => {
                if (!checkImmagine(i + 1, immagini)) {
                  setLoadingFromList(i + 1)
                  setErrorFromList(null)
                  runScatta
                    .onSuccess((data) => {

                      setLoadingFromList(null)
                      setImmagine({
                        ...immagine,
                        posizione: i + 1,
                        immagine: data.result.image,
                        riga: riga.id,
                        user: USER.id,
                        name_user: USER.first_name + ' ' + USER.last_name,
                      })
                    })
                    .onFailure((e) => {
                      setLoadingFromList(null)
                      setErrorFromList(i + 1)
                    })
                    .run()
                } else {
                  setImmagine(checkImmagine(i + 1, immagini))
                }
                toggleShowScattaFoto()
              }}
              className="mr-2 mb-2 d-flex justify-content-center align-items-center pointer flex-column position-relative"
              style={{
                height: '300px',
                width: '300px',
                border: '1px solid #ccc',
                backgroundColor: '#f5f5f5',
              }}
            >
              <div className="d-flex justify-content-center align-items-center mb-2">
                <small>
                  <strong>Posizione {i + 1}</strong>
                </small>
              </div>
              {errorFromList && errorFromList === i + 1 && (
                <div className="alert alert-danger mx-2 text-center position-absolute" role="alert" style={{
                  bottom: '0',
                  left: '0',
                  right: '0',
                }}>
                  Errore connessione con la fotocamera
                </div>
              )}
              {immagini && checkImmagine(i + 1, immagini) && (
                <img
                  src={checkImmagine(i + 1, immagini).immagine}
                  alt="immagine"
                  style={{
                    width: '80%',
                    height: 'auto',
                    maxHeight: '90%',
                  }}
                />
              )}
              {immagini && !checkImmagine(i + 1, immagini) && (
                <div>
                  {loadingFromList && loadingFromList === i + 1 ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        height: '200px',
                      }}
                    >
                      <Spinner color="primary" />
                    </div>
                  ) : (
                    <small>
                      <i className="fas fa-camera fa-3x"></i>
                    </small>
                  )}
                </div>
              )}
              {/* button small on the right bottom corner to show image in another page full screen */}
              {immagini && checkImmagine(i + 1, immagini) && (
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                    window.open(
                      checkImmagine(i + 1, immagini).immagine,
                      '_blank'
                    )
                  }}
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    position: 'absolute',
                    bottom: '5px',
                    right: '5px',
                    backgroundColor: 'rgba(255,255,255,0.8)',
                    borderRadius: '50%',
                    width: '30px',
                    height: '30px',
                  }}
                >
                  <i className="fas fa-search-plus fa-sm"></i>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {immagine && (
        <Modal
          isOpen={showScattaFoto}
          toggle={() => {
            toggleShowScattaFoto()
            setImmagine(null)
          }}
          size="lg"
          centered
        >
          <ModalHeader toggle={toggleShowScattaFoto}>
            Scatta foto - Posizione {immagine?.posizione} - Riga:{' '}
            {riga.tipo_serramento.name} - {riga.codice_serramento.categoria}{' '}
            {riga.codice_serramento.forma} {riga.codice_serramento.codice}
          </ModalHeader>
          <ModalBody>
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            {loading && !immagine.immagine ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  height: '300px',
                }}
              >
                <Spinner color="primary" />
              </div>
            ) : (
              <img
                src={immagine.immagine}
                alt="immagine"
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            )}
          </ModalBody>
          <ModalFooter className="d-flex justify-content-between">
            <Button
              outline
              disabled={loadingScatta}
              color="success"
              onClick={() => {
                setLoadingScatta(true)
                setError(null)
                runScatta
                  .onSuccess((data) => {
                    setError(null)
                    setLoadingScatta(false)
                    setImmagine({
                      ...immagine,
                      immagine: data.result.image,
                    })
                  })
                  .onFailure((e) => {
                    setError('Errore con la fotocamera')
                    setLoadingScatta(false)
                  })
                  .run()
              }}
            >
              {loadingScatta ? (
                <span className="mr-2">
                  <Spinner size="sm" color="success" />
                </span>
              ) : (
                <i className="fas fa-camera mr-2"></i>
              )}
              Scatta di nuovo
            </Button>
            <Button
              disabled={loadingSave}
              color="success"
              onClick={() => {
                setLoadingSave(true)
                runSave
                  .onSuccess(() => {
                    setLoadingSave(false)
                    toggleShowScattaFoto()
                    setImmagine(null)
                    run(riga.id)
                    fetchOrder(order.id)
                    
                  })
                  .run(riga.id, immagine)
              }}
            >
              <i className="fas fa-save mr-2"></i>
              Salva
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
