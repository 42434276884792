import { rj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import rjDebounce from 'react-rocketjump/plugins/debounce'
import qs from 'query-string'
import { ajax } from 'rxjs/ajax'
import { map } from 'rxjs/operators'
import { CSRF, SETTINGS } from '../../../django'

export const MaterialiState = rj(
  rjDebounce(),
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  {
    name: 'MaterialiState',
    mutations: {
      deleteMateriale: rj.mutation.single({
        effect: (materiale) =>
          ajax({
            method: 'delete',
            url: `/api/planner/materiali/${materiale.nome}`,
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CSRF,
            },
          }).pipe(map(() => materiale)),
        updater: (state, materiale) => ({
          ...state,
          data: {
            pagination: {
              ...state.data.pagination,
              count: state.data.count - 1,
            },
            list: state.data.list.filter((m) => m.nome !== materiale.nome),
          },
        }),
      }),
      addMateriale: {
        effect: (materiale) =>
          ajax({
            method: 'post',
            url: `/api/planner/materiali/`,
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CSRF,
            },
            body: JSON.stringify(materiale),
          }).pipe(map((r) => r.response)),
        updater: 'insertItem',
      },
      updateMateriale: {
        effect: (nome, materiale) =>
          ajax({
            method: 'put',
            url: `/api/planner/materiali/${nome}/`,
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CSRF,
            },
            body: JSON.stringify(materiale),
          }).pipe(map((r) => [nome, r.response])),
        updater: (state, [nome, materiale]) => ({
          ...state,
          data: {
            ...state.data,
            list: state.data.list.map(
              (m) => m.nome === nome ? materiale : m,
            ),
          },
        }),
      },
    },
    effect: (params = {}) =>
      ajax.getJSON(`/api/planner/materiali/?${qs.stringify(params)}`),
    computed: {
      materiali: 'getList',
      pagination: 'getPagination',
      loading: 'isLoading',
      error: 'getError',
      deleting: s => s.mutations.deleteMateriale.pending,
    },
  }
)
