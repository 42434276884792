import { rj } from "react-rocketjump"
import request from "superagent"
import qs from "query-string"
import { ajax } from "rxjs/ajax"
import { map } from "rxjs/operators"
import rjDebounce from "react-rocketjump/plugins/debounce"
import rjList, {
  nextPreviousPaginationAdapter,
} from "react-rocketjump/plugins/list"
import { CSRF, SETTINGS } from "../../../django"

export const FornitoriState = rj(
  rjDebounce(),
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  {
    name: "Fornitori",
    mutations: {
      deleteFornitore: rj.mutation.single({
        effect: (fornitore) =>
          ajax({
            method: "delete",
            url: `/api/planner/fornitori/${fornitore.id}`,
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": CSRF,
            },
          }).pipe(map(() => fornitore)),
        updater: "deleteItem",
      }),
      addFornitore: {
        effect: (fornitore) => {
          let r = request.post(`/api/planner/fornitori/`)
          r.field("nome", fornitore.nome)
            .field("tipologia", fornitore.tipologia)
            .field("testata", fornitore.testata || "")
            .field("testo_pdf", fornitore.testo_pdf || "")
            .field("email", fornitore.email || "")
            .field("is_default", fornitore.is_default)
            .field("tempo", fornitore.tempo)
            .field("codice", fornitore.codice || '')
            .set("X-CSRFToken", CSRF)
          if (fornitore.immagine) {
            r.attach("immagine", fornitore.immagine)
          }
          return r.then(({ body }) => body)
        },
        updater: "insertItem",
      },
      updateFornitore: {
        effect: (fornitore) => {
          let r = request.put(`/api/planner/fornitori/${fornitore.id}/`)
          r.field("nome", fornitore.nome)
            .field("tipologia", fornitore.tipologia)
            .field("testata", fornitore.testata || "")
            .field("testo_pdf", fornitore.testo_pdf || "")
            .field("email", fornitore.email || "")
            .field("is_default", fornitore.is_default)
            .field("tempo", fornitore.tempo)
            .field("codice", fornitore.codice || '')
            .set("X-CSRFToken", CSRF)
          if (fornitore.immagine && typeof fornitore.immagine !== 'string'){
            r.attach("immagine",fornitore.immagine)
          } else if (fornitore.remove_immagine) {
            r.attach("immagine", new File([], ''))
          }
          return r.then(({ body }) => body)
        },
        updater: "updateItem",
      },
    },
    effect: (filters = {}) =>
      ajax.getJSON(`/api/planner/fornitori/?${qs.stringify(filters)}`),
    computed: {
      fornitori: "getList",
      pagination: 'getPagination',
      loading: 'isLoading',
      error: 'getError',
      deleting: s => s.mutations.deleteFornitore.pending,
    },
  }
)
