import qs from 'query-string'
import { rj, rjPlugin, PENDING, SUCCESS, INIT } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import rjDebounce from 'react-rocketjump/plugins/debounce'
import { ajax } from 'rxjs/ajax'
import { map } from 'rxjs/operators'
import orderBy from 'lodash/orderBy'
import { SETTINGS, CSRF } from '../../django'

const rjWithLatParams = () =>
  rjPlugin({
    composeReducer: (state, action) => {
      if (action.type === INIT) {
        return {
          ...state,
          lastParams: null,
        }
      }
      if (action.type === PENDING) {
        return {
          ...state,
          lastParams: null,
        }
      }
      if (action.type === SUCCESS) {
        return {
          ...state,
          lastParams: action.payload.params,
        }
      }
      return state
    },
    selectors: () => ({
      getLastParams: (state) => state.root.lastParams,
    }),
  })

export const FoldersState = rj(
  rjDebounce(),
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  rjWithLatParams(),
  {
    name: 'FoldersState',
    mutations: {
      addFolder: {
        effect: (folder) =>
          ajax({
            method: 'POST',
            url: `/api/qrapp/admin/folders/`,
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CSRF,
            },
            body: JSON.stringify(folder),
          }).pipe(map((r) => r.response)),
        updater: (state) => state,
      },
      patchFolder: {
        effect: (folder) =>
          ajax({
            method: 'PATCH',
            url: `/api/qrapp/admin/folders/${folder.id}/`,
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CSRF,
            },
            body: JSON.stringify(folder),
          }).pipe(map((r) => r.response)),
        updater: (state, updatedFolder) => ({
          ...state,
          data: {
            ...state.data,
            list: state.data.list.map((folder) => {
              if (folder.id === updatedFolder.id) {
                return {
                  ...updatedFolder,
                  shared_accounts_count: updatedFolder.accounts.length,
                  shared_files_count: updatedFolder.shared_files.length,
                }
              }
              return folder
            }),
          },
        }),
      },
      deleteFolder: rj.mutation.single({
        effect: (id) =>
          ajax({
            method: 'DELETE',
            url: `/api/qrapp/admin/folders/${id}/`,
            headers: {
              'X-CSRFToken': CSRF,
            },
          }).pipe(map(() => ({ id }))),
        updater: 'deleteItem',
      }),
    },
    computed: {
      deleting: s => s.mutations.deleteFolder.pending,
      folders: 'getList',
      loading: 'isLoading',
      lastParams: 'getLastParams',
      pagination: 'getPagination',
      error: 'getError',
    },
    effect: (params = {}) =>
      ajax.getJSON(`/api/qrapp/admin/folders/?${qs.stringify(params)}`),
  }
)

function sortFiles(files) {
  return orderBy(files, (f) =>
    (f.title === null ? f.name : f.title).toLowerCase()
  )
}

export const FolderState = rj({
  name: 'FolderState',
  computed: {
    folder: 'getData',
    error: 'getError',
    uploading: s => s.mutations.addFile.pending,
    sharing: s => s.mutations.shareFolder.pending,
    deletingFile: s => s.mutations.deleteFile.pending,
    deletingFolder: s => s.mutations.deleteFolder.pending,
    updating: s => s.mutations.patchFolder.pending,
  },
  mutations: {
    patchFolder: rj.mutation.single({
      effect: (folder) =>
        ajax({
          method: 'PATCH',
          url: `/api/qrapp/admin/folders/${folder.id}/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(folder),
        }).pipe(map((r) => r.response)),
      updater: 'updateData',
    }),
    patchFile: {
      effect: (fileData) =>
        ajax({
          method: 'PATCH',
          url: `/api/qrapp/admin/files/${fileData.id}/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(fileData),
        }).pipe(map((r) => r.response)),
      updater: (state, sharedFile) => ({
        ...state,
        data: {
          ...state.data,
          shared_files: sortFiles(
            state.data.shared_files.map((fileData) => {
              if (fileData.id === sharedFile.id) {
                return sharedFile
              }
              return fileData
            })
          ),
        },
      }),
    },
    shareFolder: rj.mutation.single({
      effect: (id, accountsIds) =>
        ajax({
          method: 'PUT',
          url: `/api/qrapp/admin/folders/${id}/share_folder/`,
          headers: {
            'X-CSRFToken': CSRF,
            'Content-Type': 'application/json',
          },
          body: { ids: accountsIds },
        }).pipe(map((r) => r.response)),
      updater: 'updateData',
    }),
    addFile: rj.mutation.single({
      effect: (sharedFile) =>
        ajax({
          method: 'POST',
          url: `/api/qrapp/admin/files/`,
          headers: {
            'X-CSRFToken': CSRF,
          },
          body: sharedFile,
        }).pipe(map((r) => r.response)),
      updater: (state, sharedFile) => ({
        ...state,
        data: {
          ...state.data,
          shared_files: sortFiles(state.data.shared_files.concat(sharedFile)),
        },
      }),
    }),
    deleteFile: rj.mutation.single({
      effect: (id) =>
        ajax({
          method: 'DELETE',
          url: `/api/qrapp/admin/files/${id}/`,
          headers: {
            'X-CSRFToken': CSRF,
          },
        }).pipe(map(() => id)),
      updater: (state, id) => ({
        ...state,
        data: {
          ...state.data,
          shared_files: state.data.shared_files.filter((a) => a.id !== id),
        },
      }),
    }),
    deleteFolder: rj.mutation.single({
      effect: (id) =>
        ajax({
          method: 'DELETE',
          url: `/api/qrapp/admin/folders/${id}/`,
          headers: {
            'X-CSRFToken': CSRF,
          },
        }).pipe(map(() => id)),
      updater: (state) => state,
    }),
    addFolder: {
      effect: (folder) =>
        ajax({
          method: 'POST',
          url: `/api/qrapp/admin/folders/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(folder),
        }).pipe(map((r) => r.response)),
      updater: (state, folder) => ({
        ...state,
        data: {
          ...state.data,
          child_folders: orderBy(
            state.data.child_folders.concat(folder),
            ['name', 'desc']
          ),
        },
      }),
    },
  },
  effect: (id) => ajax.getJSON(`/api/qrapp/admin/folders/${id}/`),
})

export const FoldersTreeState = rj({
  name: 'FoldersTree',
  effect: () => ajax.getJSON(`/api/qrapp/admin/folders/tree/`),
  computed: {
    foldersTree: 'getData',
    loading: 'isLoading',
  }
})

export const AccountsState = rj(
  rjDebounce(),
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  {
    name: 'AccountsState',
    computed: {
      accounts: 'getList',
      pagination: 'getPagination',
      loading: 'isLoading',
      error: 'getError',
    },
    effect: (params = {}) =>
      ajax.getJSON(`/api/qrapp/admin/accounts/?${qs.stringify(params)}`),
  }
)
