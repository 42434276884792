import qs from 'query-string'
import { rj } from 'react-rocketjump'
import { ajax } from 'rxjs/ajax'
import { map } from 'rxjs/operators'
import mapValues from 'lodash/mapValues'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import rjDebounce from 'react-rocketjump/plugins/debounce'
import rjList, { nextPreviousPaginationAdapter } from 'react-rocketjump/plugins/list'
import { CSRF, SETTINGS } from '../../django'

export const PreventiviListState = rj(
  rjDebounce(),
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    actions: () => ({
      updateAgente: (preventivi, agente) => ({
        type: 'UPDATE_AGENTE',
        payload: { preventivi, agente }
      }),
      updateStato: (preventivi, stato) => ({
        type: 'UPDATE_STATO',
        payload: { preventivi, stato }
      }),
      patchPreventivo: (id, preventivo) => ({
        type: 'PATCH_PREVENTIVO',
        payload: { id, preventivo }
      }),
      updateEliminato: (preventivi, eliminato) => ({
        type: 'UPDATE_ELIMINATO',
        payload: { preventivi, eliminato }
      })
    }),
    name: 'Preventivi',
    composeReducer: (state, { type, payload }) => {
      if (type === 'UPDATE_AGENTE') {
        return {
          ...state,
          data: {
            ...state.data,
            list: state.data.list.map(preventivo => {
              const { preventivi, agente } = payload
              if (preventivi.indexOf(preventivo.id) !== -1) {
                return {
                  ...preventivo,
                  agente,
                }
              }
              return preventivo
            })
          }
        }
      }
      else if (type === 'UPDATE_STATO') {
        return {
          ...state,
          data: {
            ...state.data,
            list: state.data.list.map(preventivo => {
              const { preventivi, stato } = payload
              if (preventivi.indexOf(preventivo.id) !== -1) {
                return {
                  ...preventivo,
                  stato,
                }
              }
              return preventivo
            })
          }
        }
      }
      else if (type === 'PATCH_PREVENTIVO') {
        return {
          ...state,
          data: {
            ...state.data,
            list: state.data.list.map(preventivoItem => {
              const { preventivo, id } = payload
              if (preventivoItem.id === id) {
                return {
                  ...preventivoItem,
                  ...preventivo,
                }
              }
              return preventivoItem
            })
          }
        }
      }
      else if (type === 'UPDATE_ELIMINATO') {
        return {
          ...state,
          data: {
            ...state.data,
            list: state.data.list.filter(preventivo => {
              const { preventivi } = payload
              return preventivi.indexOf(preventivo.id) === -1
            })
          }
        }
      }
      return state
    },
    effect: (filters = {}) =>
      ajax.getJSON(`/api/preventivi/?${qs.stringify(filters)}`),
    computed: {
      preventivi: 'getList',
      pagination: 'getPagination',
      loading: 'isLoading',
      error: 'getError',
    }
  }
)

export const PreventivoState = rj({
  effect: id => ajax.getJSON(`/api/preventivi/${id}/`),
  composeReducer: (prevState, action) => {
    if (action.type === 'SET_DATA') {
      return {
        ...prevState,
        data: action.payload,
      }
    } else if (action.type === 'PATCH_DATA') {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          ...action.payload,
        },
      }
    }
    return prevState
  },
  actions: () => ({
    setPreventivo: data => ({ type: 'SET_DATA', payload: data }),
    patchPreventivo: data => ({ type: 'PATCH_DATA', payload: data }),
  }),
  computed: {
    preventivo: 'getData',
    error: 'getError',
    loading: 'isPending',
  }
})

export const SetAgenteOnPreventivo = rj({
  effect: (idPreventivo, idAgente) => ajax({
    method: 'PUT',
    url: `/api/preventivi/${idPreventivo}/set_agente/`,
    body: { agente: idAgente },
    headers: {
      'X-CSRFToken': CSRF,
    }
  }).pipe(map(r => r.response)),
  takeEffect: 'exhaust',
})

export const SetEliminatoPreventivo = rj({
  effect: (idPreventivo, eliminato) => ajax({
    method: 'PUT',
    url: `/api/preventivi/${idPreventivo}/set_eliminato/`,
    body: { eliminato: eliminato },
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': CSRF,
    }
  }).pipe(map(r => r.response)),
  takeEffect: 'exhaust',
})

export const AgentiState = rj({
  name: 'Agenti',
  effect: () => ajax.getJSON('/api/agenti')
})
export const StatiPreventivoState = rj(() => ajax.getJSON('/api/stati-preventivo'))

export const SetAgenteOnPreventivi = rj({
  effect: (preventivi, agente) => ajax({
    method: 'PUT',
    url: `/api/preventivi/set_agente_multi/`,
    body: {
      preventivi,
      agente: agente ? agente.id : null,
    },
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': CSRF,
    }
  }).pipe(map(() => ({
    preventivi,
    agente,
  }))),
  takeEffect: 'exhaust',
})

export const SetEliminatoOnPreventivi = rj({
  effect: (preventivi, eliminato) => ajax({
    method: 'PUT',
    url: `/api/preventivi/set_eliminato_multi/`,
    body: {
      preventivi,
      eliminato: eliminato
    },
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': CSRF,
    }
  }).pipe(map(() => ({
    preventivi,
    eliminato,
  }))),
  takeEffect: 'exhaust',
})

export const SetStatoOnPreventivi = rj({
  effect: (preventivi, stato) => ajax({
    method: 'PUT',
    url: `/api/preventivi/set_stato_multi/`,
    body: {
      preventivi,
      stato: stato ? stato.id : null,
    },
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': CSRF,
    }
  }).pipe(map(() => ({
    preventivi,
    stato,
  }))),
  takeEffect: 'exhaust',
})

export const SetStatoOnPreventivo = rj({
  effect: (idPreventivo, stato) => ajax({
    method: 'PUT',
    url: `/api/preventivi/${idPreventivo}/set_stato/`,
    body: { stato: stato },
    headers: {
      'X-CSRFToken': CSRF,
    }
  }).pipe(map(r => r.response)),
  takeEffect: 'exhaust',
})

export const SendMailOnPreventivo = rj({
  effect: (idPreventivo, emailTemplateId) => ajax({
    method: 'POST',
    url: `/api/preventivi/${idPreventivo}/send_email/`,
    body: { email_template: emailTemplateId },
    headers: {
      'X-CSRFToken': CSRF,
    }
  }).pipe(map(r => r.response)),
  takeEffect: 'exhaust',
})

export const SendMailOnPreventivoAgente = rj({
  effect: (idPreventivo) => ajax({
    method: 'POST',
    url: `/api/preventivi/${idPreventivo}/send_email_agente/`,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': CSRF,
    }
  }).pipe(map(r => r.response)),
  takeEffect: 'exhaust',
})

export const FamiglieState = rj({
  effect: () => ajax({
    url: `/api/famiglie/`,
    headers: {
      'X-CSRFToken': CSRF,
    }
  }).pipe(map(r => r.response)),
  computed: {
    famiglie: 'getData',
  }
})

// Vincoli[famiglia][tipo_serramento] => [list vincoli]
const mapVincoli = vincoli =>
  mapValues(groupBy(orderBy(vincoli,'altezza_min'), 'famiglia'), a => groupBy(a, 'tipo_serramento'))

const updateVincoliState = (vincoli, vincolo, updater) => ({
  ...vincoli,
  [vincolo.famiglia]: {
    ...vincoli[vincolo.famiglia],
    [vincolo.tipo_serramento]: updater(get(vincoli, `${vincolo.famiglia}.${vincolo.tipo_serramento}`, [])),
  }
})

export function apiAddVincolo(vincolo) {
  return ajax({
    url: `/api/vincoli-misura/`,
    method: 'POST',
    body: vincolo,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': CSRF,
    }
  }).pipe(map(r => r.response)).toPromise()
}

export function apiUpdateVincolo(vincolo) {
  return ajax({
    url: `/api/vincoli-misura/${vincolo.id}/`,
    method: 'PUT',
    body: vincolo,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': CSRF,
    }
  }).pipe(map(r => r.response)).toPromise()
}

export function apiUpdateTipoSerramento(tipoSerramento){
  return ajax({
    url: `/api/tipi-serramento/${tipoSerramento.id}/`,
    method: 'PATCH',
    body: tipoSerramento,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': CSRF,
    }
  }).pipe(map(r => r.response)).toPromise()
}

function apiDeleteVincolo(vincolo) {
  return ajax({
    url: `/api/vincoli-misura/${vincolo.id}/`,
    method: 'DELETE',
    headers: {
      'X-CSRFToken': CSRF,
    }
  }).pipe(map(r => r.response)).toPromise()
}

export const DeleteVincoloState = rj({
  effect: apiDeleteVincolo,
  takeEffect: 'exhaust',
  computed: {
    deleting: 'isPending',
  }
})

export const UpdateTipoSerramentoState = rj({
  effect: apiUpdateTipoSerramento,
  takeEffect: 'exhaust',
  computed: {
    updating: 'isPending',
  }
})

export const UpdateTipoSerramentoApertureState = rj({
  effect: (id,aperture) => ajax({
    url: `/api/tipi-serramento/${id}/update_aperture/`,
    method: 'PUT',
    body: {
      aperture
    },
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': CSRF,
    }
  }).pipe(map(r => r.response)),
  takeEffect: 'exhaust',
  computed: {
    updatingAperture: 'isPending',
  }
})

export const TipiSerramentoState = rj({
  effect: () => ajax({
    url: `/api/tipi-serramento/`,
    headers: {
      'X-CSRFToken': CSRF,
    }
  }).pipe(map(r => r.response)),
  actions: () => ({
    updateTipoSerramento: v => ({
      type: 'UPDATE',
      payload: v,
    })
  }),
  composeReducer: (state, { type, payload }) => {
    switch (type) {
      case 'UPDATE':
        return {
          ...state,
          data: state.data.map(tipo => tipo.id === payload.id ? payload : tipo)
        }
      default:
        return state
    }
  },
  computed: {
    tipiSerramento: 'getData',
  }
})

export const TipiAperturaState = rj({
  effect: () => ajax({
    url: `/api/tipi-apertura/`,
    headers: {
      'X-CSRFToken': CSRF,
    }
  }).pipe(map(r => r.response)),
  computed: {
    tipiApertura: 'getData',
  }
})

export const VincoliMisuraState = rj({
  effect: () => ajax({
    url: `/api/vincoli-misura/`,
    headers: {
      'X-CSRFToken': CSRF,
    }
  }).pipe(map(r => mapVincoli(r.response))),
  actions: () => ({
    removeVincolo: v => ({
      type: 'REMOVE_VINCOLO',
      payload: v,
    }),
    addVincolo: v => ({
      type: 'ADD_VINCOLO',
      payload: v,
    }),
    updateVincolo: v => ({
      type: 'UPDATE_VINCOLO',
      payload: v,
    })
  }),
  composeReducer: (state, { type, payload }) => {
    switch (type) {
      case 'REMOVE_VINCOLO':
        return {
          ...state,
          data: updateVincoliState(
            state.data,
            payload,
            vincoli => vincoli.filter(vincolo => vincolo.id !== payload.id)
          ),
        }
      case 'UPDATE_VINCOLO':
        return {
          ...state,
          data: updateVincoliState(
            state.data,
            payload,
            vincoli => orderBy(vincoli.map(vincolo => vincolo.id === payload.id ? payload : vincolo),'altezza_min'),
          ),
        }
      case 'ADD_VINCOLO':
        return {
          ...state,
          data: updateVincoliState(
            state.data,
            payload,
            vincoli => orderBy(vincoli.concat(payload),'altezza_min'),
          ),
        }
      default:
        return state
    }
  },
  computed: {
    vincoli: 'getData',
  }
})
