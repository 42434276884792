import React, { useContext, useMemo, Fragment, useCallback, useState, useRef } from 'react'
import { Alert } from 'reactstrap'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ConfigPlannerContext } from '../../context'
import { TempisticheState, ImportTempiState } from './localstate'
import { useRunRj, useRj } from 'react-rocketjump'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import filter from 'lodash/filter'
import mapValues from 'lodash/mapValues'
import get from 'lodash/get'
import ModalTempistica from '../../components/Planner/ModalTempistica'
import ModalDeleteTempistica from '../../components/Planner/ModalDeleteTempistica'

function makeTempisticheTable(tempistiche) {
  return mapValues(
    groupBy(tempistiche, 'tipo_serramento.id'),
    grouppedByTipo => (
      mapValues(
        groupBy(grouppedByTipo, 'codice_serramento.categoria'),
        byTipo => mapValues(
          groupBy(byTipo, 'codice_serramento.forma'),
          byForma => keyBy(byForma, 'codice_serramento.codice')
        )
      )
    )
  )
}

export default function Tempistiche({ history }) {
  const {
    categorieWithCodici,
    tipiSerramento,
    refreshCodiciSerramento
  } = useContext(ConfigPlannerContext)
  const [filterSerramento, setFilterSerramento] = useState(null)

  const [
    { list: tempistiche, deleting },
    { run: fetchTempistiche, updateTempistica, createTempistica, deleteTempistica }
  ] = useRunRj(TempisticheState, [], false)
  const tempisticheTable = useMemo(
    () => tempistiche === null ? null : makeTempisticheTable(tempistiche),
    [tempistiche]
  )

  const [showModalTempistica, setShowModalTempistica] = useState(null)
  const closeShowModalTempistica = useCallback(() => {
    setShowModalTempistica(null)
  }, [])

  const [showModalDeleteTempistica, setShowModalDeleteTempistica] = useState(null)
  const closeShowDeleteTempistica = useCallback(() => {
    setShowModalDeleteTempistica(null)
  }, [])

  const [tempistica, setTempistica] = useState(null)

  const tipiSerramentoFiltered = useMemo(() => {
    if (tipiSerramento && filterSerramento) {
      return filter(tipiSerramento, { id: parseInt(filterSerramento)})
    }
    return tipiSerramento
  }, [tipiSerramento, filterSerramento])

  const [tempiFile, setTempiFile] = useState(null)
  const inputTempiFileRef = useRef(null)
  const [
    {
      data: importTempiResult,
      error: importTempiError,
      pending: importingTempi
    },
    {
      run: importTempi,
      clean: cleanImportTempi
    }
  ] = useRj(ImportTempiState)

  return (
    <div className='container-fluid mt-3'>
      <div className='row'>
        <div className='col-md-7'>
          <select name='tipo_serramento' onChange={(e) => setFilterSerramento(e.target.value)} className='custom-select custom-select-sm mb-2'>
            <option value=''>Filtra per tipo serramento</option>
            {tipiSerramento.map(tipo => (
              <option key={tipo.id} value={tipo.id}>{tipo.name}</option>
            ))}
          </select>
          <table className='table table-bordered table-hover'>
            <thead>
              <tr>
                <th></th>
                <th className='text-center'>Reparto Macchine</th>
                <th className='text-center'>Reparto Banchi</th>
              </tr>
            </thead>
            <tbody>
              {tipiSerramentoFiltered.map(tipo => (
                <Fragment key={tipo.id}>
                  <tr className='table-danger'>
                    <td colSpan={3}>
                      {tipo.name}
                    </td>
                  </tr>
                  {categorieWithCodici.map(categoria => (
                    <Fragment key={categoria.value}>
                      <tr className='table-primary'>
                        <td colSpan={3}>
                          <div className='ml-2'>{categoria.label}</div>
                        </td>
                      </tr>
                      {map(categoria.codici, (codiciForma, forma) => (
                        <Fragment key={forma}>
                          <tr className='table-success'>
                            <td colSpan={3}>
                              <div className='ml-4'>{codiciForma[0].forma}</div>
                            </td>
                          </tr>
                          {codiciForma.map(codice => {
                            const tempistica = get(tempisticheTable, `${tipo.id}.${categoria.value}.${forma}.${codice.codice}`)
                            return (
                              <tr key={codice.codice}>
                                <td>
                                  <div className='ml-4'>
                                    <strong>{codice.codice}</strong>
                                    <i className='fas fa-pencil-alt ml-2 text-warning pointer'
                                      onClick={() => {
                                        setTempistica(tempistica)
                                        setShowModalTempistica({ tipo, categoria, codice, tempistica })
                                      }}
                                    />
                                    {tempistica &&
                                    <i className='fas fa-times ml-2 text-danger pointer'
                                      onClick={() => {
                                        setTempistica(tempistica)
                                        setShowModalDeleteTempistica({ tipo, categoria, codice, tempistica })
                                      }}
                                    />
                                  }
                                  </div>
                                </td>
                                <td className='text-center font-weight-bold'>
                                  {get(tempistica, 'tempo_reparto_macchine', '')}
                                </td>
                                <td className='text-center font-weight-bold'>
                                  {get(tempistica, 'tempo_reparto_banchi', '')}
                                </td>
                              </tr>
                            )
                          })}
                        </Fragment>
                      ))}
                    </Fragment>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </table>
          <ModalTempistica
            isOpen={showModalTempistica !== null}
            toggle={closeShowModalTempistica}
            tipoSerramento={showModalTempistica ? showModalTempistica.tipo: null}
            codiceSerramento={showModalTempistica ? showModalTempistica.codice: null}
            categoriaSerramento={showModalTempistica ? showModalTempistica.categoria : null}
            tempistica={tempistica}
            save={(tempisticaData) => {
              if(showModalTempistica.tempistica){
                return updateTempistica.onSuccess(() => closeShowModalTempistica()).asPromise({
                  ...showModalTempistica.tempistica,
                  tempo_reparto_banchi: tempisticaData.tempo_reparto_banchi,
                  tempo_reparto_macchine: tempisticaData.tempo_reparto_macchine,
                })
              }
              else{
                return createTempistica.onSuccess(() => closeShowModalTempistica()).asPromise({
                  tempo_reparto_banchi: tempisticaData.tempo_reparto_banchi,
                  tempo_reparto_macchine: tempisticaData.tempo_reparto_macchine,
                  codice_serramento_id: showModalTempistica.codice.id,
                  tipo_serramento_id: showModalTempistica.tipo.id
                })
              }
            }}
          />
          <ModalDeleteTempistica
            toggle={closeShowDeleteTempistica}
            isOpen={showModalDeleteTempistica}
            tipoSerramento={showModalDeleteTempistica ? showModalDeleteTempistica.tipo: null}
            codiceSerramento={showModalDeleteTempistica ? showModalDeleteTempistica.codice: null}
            categoriaSerramento={showModalDeleteTempistica ? showModalDeleteTempistica.categoria : null}
            tempistica={tempistica}
            deleting={deleting}
            onSetEliminato={() => {
              deleteTempistica
                .onSuccess(() => {
                  closeShowDeleteTempistica()
                }).run(tempistica)
            }}
          />
        </div>
        <div className='col-md-5'>
          <form onSubmit={e => {
            e.preventDefault()
            if (tempiFile) {
              importTempi
                .onSuccess(() => {
                  setTempiFile(null)
                  fetchTempistiche()
                  refreshCodiciSerramento()
                  inputTempiFileRef.current.value = ''
                })
                .run(tempiFile)
            }
          }}>
            <input
              ref={inputTempiFileRef}
              onChange={e => {
                const file = e.target.files[0]
                if (file) {
                  setTempiFile(file)
                }
              }}
              type='file'
              accept='.xlsx'
            />
            <button
              disabled={importingTempi || tempiFile === null}
              className='btn btn-success'>IMPORTA TEMPI</button>

            {importingTempi && <div className='text-center mt-2'>
              <b>Importo Tempistiche...</b> <br />
              <CircularProgress color='primary'/>
            </div>}

            <Alert className='mt-2' color="success" isOpen={importTempiResult !== null} toggle={cleanImportTempi}>
              {importTempiResult && <>
                Nuovi tempi importanti: {importTempiResult.added}
                <br />
                Tempi esistenti modificati: {importTempiResult.updated}
              </>}
            </Alert>
            <Alert className='mt-2' color="danger" isOpen={importTempiError !== null} toggle={cleanImportTempi}>
              Errore durante l'importanzione dei tempi.
            </Alert>
          </form>
        </div>
      </div>
    </div>
  )
}
