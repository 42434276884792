import React, { useState, useMemo } from 'react'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import 'moment/locale/it'

moment.locale('it')
const FORMAT = 'YYYY-MM-DD'

const FiltersPersonale = ({
  filters,
  tipiSerramento,
  onFilterChangeDebounced,
  onFilterChange,
  onFiltersChange,
  }) => {
  const { search } = filters

  const startDate = useMemo(() => {
    if (filters.startDate === null || filters.startDate === '') {
      return null
    }
    const m = moment(filters.startDate, FORMAT)
    return m.isValid() ? m : null
  }, [filters.startDate])

  const endDate = useMemo(() => {
    if (filters.endDate === null || filters.endDate === '') {
      return null
    }
    const m = moment(filters.endDate, FORMAT)
    return m.isValid() ? m : null
  }, [filters.endDate])

  const [focusedInput, setFocusedInput] = useState(null)

  return(
    <div>
      <div className='pt-2 d-flex flex-1'>
        <div>
          <input
            name='search'
            type='text'
            className='form-control form-control-sm mr-2'
            placeholder={'Ricerca libera'}
            value={search}
            onChange={onFilterChangeDebounced}
          />
        </div>
        <div className='ml-2'>
          <DateRangePicker
            startDate={startDate}
            startDateId="data_inizio"
            endDate={endDate}
            block={true}
            showClearDates={true}
            horizontalMargin={30}
            endDateId="data_fine"
            startDatePlaceholderText={'Data inizio'}
            endDatePlaceholderText={'Data fine'}
            hideKeyboardShortcutsPanel
            isOutsideRange={() => false}
            onDatesChange={({ startDate, endDate }) => {
              onFiltersChange({
                startDate: startDate ? startDate.format(FORMAT) : '',
                endDate: endDate ? endDate.format(FORMAT) : ''
              })
            }}
            focusedInput={focusedInput} //
            onFocusChange={focusedInput => setFocusedInput(focusedInput)}
          />
        </div>
      </div>
    </div>
  )
}

export default FiltersPersonale
