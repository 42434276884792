import React from 'react'
import classNames from 'classnames'

const TabsMobile = ({ setStep, step }) => (
  <div className='TabsMobile row fixed-bottom bg-white'>
    <div
      className={classNames('col-4 border-top p-0 border-right text-center pl-3 pt-2',
      { 'bg-info': step === 1,
        'text-white': step === 1
      })}
      onClick={() => setStep(1)}
      >
      <div><strong>Step 1</strong></div>
      <div>Serramento</div>
    </div>
    <div
      className={classNames('col-4 border-top border-right text-center pt-2',
      { 'bg-info': step === 2,
        'text-white': step === 2
      })}
      onClick={() => setStep(2)}
      >
      <div><strong>Step 2</strong></div>
      <div>Misure</div>
    </div>
    <div
      className={classNames('col-4 border-top border-right text-center pl-1 pt-2',
      { 'bg-info': step === 3,
        'text-white': step === 3
      })}
      onClick={() => setStep(3)}
      >
      <div><strong>Step 3</strong></div>
      <div>Riferimenti</div>
    </div>
  </div>
)

export default TabsMobile
