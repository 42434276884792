import { sumBy } from 'lodash'
import moment from 'moment'
import React from 'react'

export default function TablePerformanceGiorno({
  groupedAttivitaByData,
  operatori,
  lavorazioni,
}) {
  return (
    <table className="table table-bordered table-hover table-striped mt-2">
      <thead>
        <tr>
          <th>Giorno</th>
          <th>Operatori</th>
          <th>Totale teorico</th>
          <th>Totale effettivo</th>
          <th>Performance</th>
          <th>Lavorazioni</th>
          <th>Ordini</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(groupedAttivitaByData).map((data, i) => {
          const attivitaGiorno = groupedAttivitaByData[data].filter(
            (attivita) => attivita.ordine
          )
          const operatoriWithoutDuplicates = [
            ...new Set(attivitaGiorno.map((attivita) => attivita.operatore.id)),
          ]

          const lavorazioniWithoutDuplicates = [
            ...new Set(
              attivitaGiorno
                .map((attivita) => attivita.lavorazione_data.id)
                .concat(
                  attivitaGiorno.map(
                    (attivita) => attivita?.seconda_lavorazione_data?.id
                  )
                )
            ),
          ]


          const ordiniWithoutDuplicates = [
            ...new Set(
              attivitaGiorno.map((attivita) => attivita?.ordine_data?.id)
            ),
          ]

          const totaleTeorico = sumBy(attivitaGiorno, (attivita) => {
            return parseInt(
              moment.duration(attivita.tempo_teorico).as('minutes')
            )
          })
          const totaleEffettivo = sumBy(attivitaGiorno, (attivita) => {
            return parseInt(moment.duration(attivita.tempo).as('minutes'))
          })
          const percentPerformance = (totaleTeorico / totaleEffettivo) * 100
          return (
            <tr key={i}>
              <td className="text-nowrap">
                {moment(data).format('DD-MM-YYYY')}
              </td>
              <td>
                {operatoriWithoutDuplicates
                  .map((operatoreId) => {
                    const operatore = operatori.find(
                      (operatore) => operatore.id === Number(operatoreId)
                    )
                    return (
                      operatore &&
                      `${operatore.user.first_name} ${operatore.user.last_name}`
                    )
                  })
                  .join(', ')}
              </td>
              <td>
                {moment.duration(totaleTeorico, 'minutes').hours()}h{' '}
                {moment.duration(totaleTeorico, 'minutes').minutes()}m
              </td>
              <td>
                {moment.duration(totaleEffettivo, 'minutes').hours()}h{' '}
                {moment.duration(totaleEffettivo, 'minutes').minutes()}m
              </td>
              <td>{percentPerformance.toFixed(0)}%</td>
              <td>
                {lavorazioniWithoutDuplicates
                  .map((lavorazioneId) => {
                    const lavorazione = lavorazioni.find(
                      (lavorazione) => lavorazione.id === Number(lavorazioneId)
                    )
                    return lavorazione && lavorazione.nome
                  })
                  .join(', ')}
              </td>
              <td>
                {ordiniWithoutDuplicates
                  .map((ordineId) => {
                    const ordine = attivitaGiorno.find(
                      (attivita) =>
                        attivita?.ordine_data?.id === Number(ordineId)
                    )
                    return (
                      ordine &&
                      ordine.ordine_data.numero_commessa +
                        ' - ' +
                        ordine.ordine_data.cliente.nome
                    )
                  })
                  .join(', ')}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
