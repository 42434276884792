import { useEffect } from 'react'
import { useMediaLayout } from 'use-media'
import keyBy from 'lodash/keyBy'

export function useScrollOnTop() {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export function useIsDesktop() {
  return useMediaLayout({ minWidth: 576 })
}

export function makeTipiSerramentoById(tipiSerramento) {
  return keyBy(tipiSerramento.map(tipo => ({
    ...tipo,
    accessoriById: keyBy(tipo.accessori, 'id'),
    apertureById: keyBy(tipo.aperture, 'id'),
  })), 'id')
}

export function makeFamiglieById(tipologieFamiglia) {
  return tipologieFamiglia.reduce((famiglieById, tipologia) => {
    const { famiglie, ...tipologiaFamiglia } = tipologia
    return famiglie.reduce((famiglieById, famiglia) => ({
      ...famiglieById,
      [famiglia.id]: {
        ...famiglia,
        tipologiaFamiglia,
      }
    }), famiglieById)
  }, {})
}

export function getFamilyOptionsValuesDefault(famiglia){
  const defaultOptionsValues = famiglia.opzioni.reduce((options, option) => {
    options[`${option.id}`] = option.valori.length > 0 ? option.valori[0].id : null
    return options
  }, {})
  return defaultOptionsValues
}
