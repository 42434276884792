import { rjPlugin, SUCCESS } from 'react-rocketjump'
import get from 'lodash/get'

export function adjustMulitpleMaterialiFilters(filters) {
  if (
    !Array.isArray(filters.materiali) ||
    !Array.isArray(filters.stati_materiali)
  ) {
    return filters
  }
  const deFilters = { ...filters }
  deFilters.materiali = deFilters.materiali.filter(
    (_, i) => (deFilters.stati_materiali ?? [])[i] !== '_'
  )
  deFilters.stati_materiali = deFilters.stati_materiali.filter(
    (v) => v !== '_'
  )
  return deFilters
}

export function getFasiNeededByOrder(order, fasiScheduler) {
  const hasTelai =
    order && order.righe_ordine.some((riga) => riga.tipo_serramento.telai)
  const fasiNeeded = fasiScheduler.reduce((fasiNeeded, fase) => {
    const nomeFase = fase.nome_fase
    const isNeeded = nomeFase === 'telai' ? hasTelai : true
    return {
      ...fasiNeeded,
      [nomeFase]: isNeeded,
    }
  }, {})
  return fasiNeeded
}

export function rjListWithTotals() {
  return rjPlugin({
    composeReducer: (prevState, { type, payload }) => {
      if (type === SUCCESS) {
        return {
          ...prevState,
          data: {
            ...prevState.data,
            totals: payload.data.totals,
          },
        }
      }
      return prevState
    },
    selectors: () => ({
      getTotals: (state) => get(state.root, 'data.totals', null),
    }),
  })
}
