import React, { useState, useEffect, useContext, useMemo } from 'react'
import { ajax } from 'rxjs/ajax'
import { Formik, Form, connect } from 'formik'
import * as Yup from 'yup'
import { setLocale } from 'yup'
import Step1 from '../../components/Form/Steps/Step1'
import Step2 from '../../components/Form/Steps/Step2'
import Step3 from '../../components/Form/Steps/Step3'
import TabsMobile from '../../components/Form/Tabs/TabsMobile'
import TabsDesktop from '../../components/Form/Tabs/TabsDesktop'
import { ConfigPrevetiviContext } from '../../context'
import {
  getFamilyOptionsValuesDefault,
  useIsDesktop,
} from '../../helpers'
import { CSRF } from '../../django'

setLocale({
  mixed: {
    required: 'Campo obbligatorio',
  },
})

// Skuuuuuuuu
const ValidateFormikOnMount = connect(({ formik }) => {
  const { validateForm } = formik

  useEffect(() => {
    validateForm()
  }, [validateForm])

  return null
})

// Map steps 2 -*-> Component
const StepsComponents = [
  Step1,
  Step2,
  Step3,
]

export default function StepperForm({ onPreventivoCreated }) {

  const {
    vincoliMisura,
    famiglieById,
    tipiSerramentoById,
    famigliaDefault: famigliaDefaultId,
  } = useContext(ConfigPrevetiviContext)

  // Current form step
  const [step, setStep] = useState(1)

  const isDesktop = useIsDesktop()

  // Current form step component
  const CurrentStep = StepsComponents[step - 1]

  // Default selected from config
  let idFamigliaDefault = null
  let famigliaDefault = null
  if (famigliaDefaultId) {
    famigliaDefault = famiglieById[famigliaDefaultId]
    idFamigliaDefault = famigliaDefault.id
  }

  // TODO: Move this mega shit 2 another file ...
  const PreventivoSchema = useMemo(() => Yup.object().shape({
    serramenti: Yup.array().when('famiglia', (family, schema) => {
      return Yup.array().of(Yup.object().shape({
        quantita: Yup.number().required().label('Quantità').positive().typeError('Campo obbligatorio'),
        altezza: Yup.number().required('Devi inserire un altezza serramento').positive().label('Altezza')
          // NOTE Hey bro, this is fucking workaround because on field
          // array empty values '' combining \w number raise a TypeError so fuck off
          // show the same message as required ....
          .typeError('Campo obbligatorio')
          .when('tipo_serramento', (idTipoSerramento, schema) => {
             const tipoSerramento = tipiSerramentoById[idTipoSerramento]

             // Use master (tipo serramento) constraints
             if (tipoSerramento.altezza_max !== null && tipoSerramento.altezza_min !== null) {
               const messageAltezza = `Altezza Min: ${tipoSerramento.altezza_min} mm \n Altezza Max: ${tipoSerramento.altezza_max} mm`
               return schema.max(tipoSerramento.altezza_max,messageAltezza).min(tipoSerramento.altezza_min,messageAltezza)
             }

             const vincoli = vincoliMisura.filter(vincolo => (
               Number(vincolo.famiglia) === Number(family) &&
               Number(vincolo.tipo_serramento) === Number(idTipoSerramento)
             ))

             if (vincoli.length > 0) {
               const max = Math.max(...vincoli.map(vincolo => vincolo.altezza_max))
               const min = Math.min(...vincoli.map(vincolo => vincolo.altezza_min))
               const messageAltezza = `Altezza Min: ${min} mm \n Altezza Max: ${max} mm`
               return schema.max(max, messageAltezza).min(min, messageAltezza)
             }
          }),
        larghezza: Yup.number().required('Devi inserire una larghezza serramento').positive().label('Larghezza')
          .typeError('Campo obbligatorio')
          .when(['tipo_serramento', 'altezza'], (idTipoSerramento, altezza, schema) => {
            // Nothing 2 Do
            if (isNaN(altezza) || altezza === null || altezza === undefined || altezza === '') {
              return schema
            }

             const tipoSerramento = tipiSerramentoById[idTipoSerramento]

             //console.log('Jeky Chan', larghezza, idTipoSerramento, family, vincoliMisura, tipiSerramentoById)
             //Use master (tipo serramento) constraints
             if (tipoSerramento.larghezza_max !== null && tipoSerramento.larghezza_min !== null) {
               const messageLarghezza = `Larghezza Min: ${tipoSerramento.larghezza_min} mm \n Larghezza Max: ${tipoSerramento.larghezza_max} mm`
               return schema.max(tipoSerramento.larghezza_max,messageLarghezza).min(tipoSerramento.larghezza_min,messageLarghezza)
             }

             const vincoli = vincoliMisura.filter(vincolo => (
               Number(vincolo.famiglia) === Number(family) &&
               Number(vincolo.tipo_serramento) === Number(idTipoSerramento)
             ))

             if (vincoli.length > 0) {
               const vincoliByAltezza = vincoliMisura.filter(vincolo => (
                 altezza >= vincolo.altezza_min && altezza <= vincolo.altezza_max
               ))
               if (vincoliByAltezza.length > 0) {
                 const vincolo = vincoliByAltezza[0]
                 const min = vincolo.larghezza_min
                 const max = vincolo.larghezza_max
                 const messageLarghezza = `Larghezza Min: ${min} mm \n Larghezza Max: ${max} mm`
                 return schema.max(max, messageLarghezza).min(min, messageLarghezza)
               } else {
                 return schema.test('guakamole', 'Nessun serramento disponibile con questa altezza', () => false)
               }
             }

             return schema
          }),
      tipo_apertura: Yup.string().required().label('Tipo apertura'),
    })).min(1,'Non è stato inserito nessun serramento.')
    }),
    regione_posa: Yup.string()
      .when(['messa_in_posa'],(messa_in_posa,schema) => {
        return schema
        // return messa_in_posa ? schema.required('Non è stata specificata la regione di posa') : schema
      }),
    nome_cognome: Yup.string().required('Devi inserire nome e cognome').label('Nome e cognome'),
    telefono: Yup.string().required('Devi inserire un numero di telefono').label('Telefono'),
    email: Yup.string().required('Devi inserire un indirizzo email valido').label('E-mail'),
    citta: Yup.string().required('Devi inserire una città').label('Città'),
    provincia: Yup.string().required('Devi inserire una provincia').label('Provincia'),
    citta_posa: Yup.string()
      .when(['messa_in_posa'],(messa_in_posa,schema) => {
        return messa_in_posa ? schema.required('Devi inserire una città di posa') : schema
      }),
    provincia_posa: Yup.string()
      .when(['messa_in_posa'],(messa_in_posa,schema) => {
        return messa_in_posa ? schema.required('Devi inserire una provincia di posa') : schema
      }),
    piano_posa: Yup.string()
      .when(['messa_in_posa'],(messa_in_posa,schema) => {
        return messa_in_posa ? schema.required('Devi inserire il piano di posa') : schema
      }),
    privacy: Yup.bool().oneOf([true], "Devi accettare l'informativa ai sensi del D.Lgs. 196/2003").label('Privacy'),
  }), [vincoliMisura, tipiSerramentoById])

  return (
    <Formik
      onSubmit={(values, actions) => {
        const { options, ...preventivo } = values
        const valori_opzioni = Object.values(options)

        // Prepare data 4 server
        const preventivoSendData = {
          ...preventivo,
          famiglia: +preventivo.famiglia,
          valori_opzioni,
        }
        console.log('Preventivo Data 4 Server', preventivoSendData)

        return ajax({
          method: 'POST',
          url: '/api/preventivi-create/',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: preventivoSendData,
        }).toPromise().then(
          (data) => {
            onPreventivoCreated(data.response)
            console.log('Preventivo create!', data)
          },
          (error) => {
            // TODO: Handle proper error
            actions.setSubmitting(false)
            console.log('Fail send preventivo...', error)
          }
        )
      }}
      initialValues={{
        famiglia: idFamigliaDefault === null ? null : `${idFamigliaDefault}`,
        options: idFamigliaDefault === null ? {} : getFamilyOptionsValuesDefault(famigliaDefault),
        serramenti: [],
        nome_cognome: '',
        telefono: '',
        indirizzo: '',
        citta: '',
        provincia: '',
        email: '',
        indirizzo_posa: '',
        citta_posa: '',
        provincia_posa: '',
        note: '',
        piano_posa: '',
        messa_in_posa: true,
        regione_posa:'',
        tipo_cliente: 'non professionista',
        tipo_intervento: 'nuovo',
        privacy: false,
      }}
      validationSchema={PreventivoSchema}
    >
      {({ values, setFieldValue, isSubmitting, handleChange, errors }) => (
        <Form>
          <ValidateFormikOnMount />
          {isDesktop && <TabsDesktop step={step} setStep={setStep} />}
          <CurrentStep
            step={step}
            setStep={setStep}
          />
          {!isDesktop && <TabsMobile step={step} setStep={setStep} />}
        </Form>
      )}
    </Formik>
  )
}
