import React from 'react'
import logger from 'react-rocketjump/logger'
import ReactDOM from 'react-dom'
import './index.css'
import FormApp from './apps/FormApp'
import AdminPreventiviApp from './apps/AdminPreventiviApp'
import PlannerApp from './apps/PlannerApp'
import FornitoreApp from './apps/FornitoreApp'
import DemoApp from './apps/DemoApp'
import AdminUsersApp from './apps/AdminUsersApp'
import QrApp from './apps/QrApp'
import QrBoundary from './QrBoundary'
import MaterialiApp from './apps/MaterialiApp'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import * as serviceWorker from './serviceWorker'
import { USER } from './django'

if (process.env.NODE_ENV !== 'production') {
  logger()
} else if (process.env.REACT_APP_SENTRY_RELEASE) {
  Sentry.init({
    dsn: 'https://e33535e44a0a4c8eab1d7365b6117c24@o1047151.ingest.sentry.io/4503930499825664',
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0,
  })
  // Sentry production error handler
  if (USER) {
    Sentry.setUser({
      id: USER.id,
      username: USER.username,
      email: USER.email,
    })
  }
}

function render() {
  for (let elementId in ConfigApps) {
    const element = document.getElementById(elementId)
    if (element) {
      const { Component, ssr } = ConfigApps[elementId]
      if (ssr && process.env.NODE_ENV === 'production') {
        ReactDOM.hydrate(
          <QrBoundary>
            <Component />
          </QrBoundary>,
          element
        )
      } else {
        ReactDOM.render(
          <QrBoundary>
            <Component />
          </QrBoundary>,
          element
        )
      }
      return
    }
  }
}

// Dom ID -> App
// TODO: Load component on the fly import()
const ConfigApps = {
  root: {
    ssr: true,
    Component: FormApp,
  },
  'root-admin': {
    ssr: false,
    Component: AdminPreventiviApp,
  },
  'root-admin-users': {
    ssr: false,
    Component: AdminUsersApp,
  },
  'root-planner': {
    ssr: false,
    Component: PlannerApp,
  },
  'root-fornitore': {
    ssr: false,
    Component: FornitoreApp,
  },
  'root-home-demo': {
    ssr: false,
    Component: DemoApp,
  },
  'root-qrapp': {
    ssr: false,
    Component: QrApp,
  },
  'root-materiali': {
    ssr: false,
    Component: MaterialiApp,
  },
}

render()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
