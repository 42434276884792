import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Formik, Field } from 'formik'
import { InputField } from '../../Form/Fields'
import * as Yup from 'yup'

const TempisticaSchema = Yup.object().shape({
  tempo_reparto_macchine: Yup.number().required(),
  tempo_reparto_banchi: Yup.number().required(),
})

const ModalTempistica = ({ isOpen, toggle, tempistica, save, tipoSerramento, codiceSerramento, categoriaSerramento }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}>
        <small>Tempistica - {tipoSerramento && tipoSerramento.name} - {categoriaSerramento && categoriaSerramento.value} - {codiceSerramento && codiceSerramento.forma}{' '}
        - {codiceSerramento && codiceSerramento.codice}</small>
      </ModalHeader>
        <Formik
          initialValues={tempistica ? tempistica : {
            tempo_reparto_macchine: '',
            tempo_reparto_banchi: '',
          }}
          validationSchema={TempisticaSchema}
          onSubmit={(values, actions) => {
            return save((values)).then(
              () => {
                actions.setSubmitting(false)
              },
              error => {
                actions.setSubmitting(false)
                actions.setErrors((error))
              }
            )
          }}
          render={({ handleSubmit, values, isSubmitting, errors }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <Field
                  label='Reparto macchine *'
                  type='number'
                  name='tempo_reparto_macchine'
                  component={InputField}
                />
                <Field
                  label='Reparto banchi *'
                  type='number'
                  name='tempo_reparto_banchi'
                  component={InputField}
                />
              </ModalBody>

              <ModalFooter className='d-flex justify-content-between'>
                <Button color="secondary" onClick={toggle}>Chiudi</Button>
                <Button type='submit' disabled={isSubmitting} color="success">Salva</Button>
              </ModalFooter>
            </form>
          )}
        />
    </Modal>
  )
}

export default ModalTempistica
