import React from 'react'

export default function OreLavorateCircle({ percentage }) {
  return (
    <div
      style={{
        borderRadius: '50%',
        padding: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 20,
        color: '#fff',
        height: 130,
        backgroundColor: percentage > 0 && percentage < 20 ? '#CA0A0A' 
        : percentage >= 20 && percentage < 40 ? '#E77D1C'
        : percentage >= 40 && percentage < 60 ? '#F9BC20'
        : percentage >= 60 && percentage < 80 ? '#8DCA0A'
        : percentage >= 80  ? '#04A90A'
        : '#EDF2FF',
        width: 130,
      }}
      className="mr-4"
    >
      {percentage.toFixed(0)}%
    </div>
  )
}
