import React from 'react'

const DetailDatiAnagrafici = ({ preventivo }) => {
  return(
    <div className='card p-2'>
      <h5 className='font-weight-bold text-center text-primary'>Dati anagrafici</h5>
      <div className='border-bottom pt-1 pb-1'>
        Nome e cognome: <strong>{preventivo.nome_cognome}</strong>
      </div>
      <div className='border-bottom pt-1 pb-1'>
        Telefono: <strong>{preventivo.telefono}</strong>
      </div>
      <div className='border-bottom pt-1 pb-1'>
        E-mail: <strong>{preventivo.email}</strong>
      </div>
      <div className='border-bottom pt-1 pb-1'>
        Indirizzo: <strong>{preventivo.indirizzo}</strong>
      </div>
      <div className='border-bottom pt-1 pb-1'>
        Città: <strong>{preventivo.citta}</strong>
      </div>
      <div className='border-bottom pt-1 pb-1'>
        Provincia: <strong>{preventivo.provincia}</strong>
      </div>
      <div className='pt-1 pb-1'>
        Professionista:
        {preventivo.tipo_cliente === 'No, non sono un professionista del settore, le misure sono approssimate' ?
          <i className="ml-2 fas fa-times-circle text-danger"></i>
          :
          <i className="ml-2 fas fa-check-circle text-success"></i>
        }
      </div>
    </div>
  )
}

export default DetailDatiAnagrafici
