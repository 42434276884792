import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { Button } from 'reactstrap'
import { Formik, Field } from 'formik'
import { InputField, CheckboxField } from '../Form/Fields'
import { transformErrorForForm } from '../Form/Fields/utils'

const BaseUserShapeSchema = {
  username: Yup.string().required(),
  first_name: Yup.string(),
  last_name: Yup.string(),
  email: Yup.string().email(),
}
const UserSchema = Yup.object().shape(BaseUserShapeSchema)
const UserCreateSchema = Yup.object().shape({
  ...BaseUserShapeSchema,
  password: Yup.string().required(),
  confirm_password: Yup.string()
    .required()
    .oneOf(
      [Yup.ref('password'), null],
      'Le due password inserite non sono uguali'
    ),
})

const InitialUserValues = {
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  info: {
    note: '',
    can_admin_users: false,
    can_view_attivita_operatori: false,
    remind_stato_ordine_fornitore: false,
    remind_miss_ordini_fornitore: false,
    mail_schedulazione: false,

  },
  is_superuser: false,
  is_staff: false,
  password: '',
  confirm_password: '',
}

export default function UserForm({ user, save }) {
  const userValues = useMemo(() => {
    if (!user) {
      return InitialUserValues
    }
    return {
      ...user,
      info: user.info ?? { note: '' },
    }
  }, [user])

  return (
    <Formik
      onSubmit={(values, actions) => {
        return save(values).then(
          () => {
            actions.setSubmitting(false)
          },
          (error) => {
            actions.setSubmitting(false)
            actions.setErrors(transformErrorForForm(error))
          }
        )
      }}
      initialValues={userValues}
      validationSchema={userValues.id ? UserSchema : UserCreateSchema}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <form onSubmit={handleSubmit} className="p-0 m-0">
          <Field
            autoComplete="off"
            component={InputField}
            label="Username *"
            name="username"
          />
          <Field
            autoComplete="off"
            component={InputField}
            label="Nome"
            name="first_name"
          />
          <Field
            autoComplete="off"
            component={InputField}
            label="Cognome"
            name="last_name"
          />
          <Field
            autoComplete="off"
            component={InputField}
            label="Email"
            type="email"
            name="email"
          />
          <Field
            autoComplete="off"
            component={InputField}
            type="textarea"
            label="Note"
            name="info.note"
          />
          {!userValues.id && (
            <>
              <Field
                autoComplete="off"
                component={InputField}
                label="Password *"
                name="password"
                type={'password'}
              />
              <Field
                autoComplete="off"
                component={InputField}
                label="Conferma Password *"
                name="confirm_password"
                type="password"
              />
            </>
          )}
          <div className="py-2">
            <Field
              autoComplete="off"
              component={CheckboxField}
              label="Admin"
              name="is_superuser"
            />
            <small>
              Abilita l'accesso al sito e a Django admin.
              <br />
              Consente la modifica, crezione e l'eliminazione di ogni entità.
            </small>
          </div>
          <Field
            autoComplete="off"
            component={CheckboxField}
            label="Staff"
            name="is_staff"
          />
          <small>
            Abilita l'accesso a Django admin.
            <br />I relativi permessi all'interno di Django admin andranno
            configurati all'interno di Django admin stesso.
          </small>
          <div className="border border-dark px-1 mt-2">
            <div className="py-2">
              <Field
                autoComplete="off"
                component={CheckboxField}
                label="Gestione Utenti"
                name="info.can_admin_users"
                disabled={!values.is_superuser}
              />
              <small>Abilita l'accesso alla gestione utenti.</small>
            </div>
            <div className="py-2">
              <Field
                autoComplete="off"
                component={CheckboxField}
                label="Attività operatore"
                name="info.can_view_attivita_operatori"
                disabled={!values.is_superuser}
              />
              <small>Abilita l'accesso alle attività operatore.</small>
            </div>
            <div className="py-2">
              <Field
                autoComplete="off"
                component={CheckboxField}
                label="Notifiche Ordini Fornitore"
                name="info.remind_stato_ordine_fornitore"
                disabled={!values.is_superuser}
              />
              <small>Abilita la ricezione delle notifiche ordini fornitori.</small>
            </div>
            <div className="py-2">
              <Field
                autoComplete="off"
                component={CheckboxField}
                label="Notifiche Ordini Fornitore Mancanti"
                name="info.remind_miss_ordini_fornitore"
                disabled={!values.is_superuser}
              />
              <small>Abilita la ricezione delle notifiche degli ordini fornitori mancanti.</small>
            </div>
            <div className="py-2">
              <Field
                autoComplete="off"
                component={CheckboxField}
                label="Notifiche Schedulazione"
                name="info.mail_schedulazione"
                disabled={!values.is_superuser}
              />
              <small>Abilita la ricezione delle notifiche di schedulazione.</small>
            </div>
            <div className="py-2">
              <Field
                autoComplete="off"
                component={CheckboxField}
                label="Accesso VBH"
                name="info.vbh_readonly_access"
                disabled={!values.is_superuser}
              />
              <small>Abilita l'accesso in sola lettura all'admin VBH.</small>
            </div>
            <div className="py-2">
              <Field
                autoComplete="off"
                component={CheckboxField}
                label="Visualizzazione logs linea SAOMAD"
                name="info.can_view_linea_saomad"
                disabled={!values.is_superuser}
              />
              <small>Abilita l'accesso alla visualizzazione dei logs linea SAOMAD.</small>
            </div>
            {!values.is_superuser && (
              <div>
                <small>
                  <b>
                    NB: Queste impostazioni hanno effetto solo sugli utenti Admin.
                  </b>
                </small>
              </div>
            )}
          </div>
          <div className="mt-4 d-flex justify-content-end">
            <Button disabled={isSubmitting} color="success" type="submit">
              Salva
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}
