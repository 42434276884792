import React, { useCallback, useState } from 'react'
import { MaterialiState } from '../../apps/PlannerApp/localstate'
import { useReactDataTable } from 'react-use-datatable/react'
import { Button } from 'reactstrap'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paginator from '../../components/Paginator'
import ModalFormMateriale from '../../components/Planner/ModalFormMateriale'
import ModalDeleteMateriale from '../../components/Planner/ModalDeleteMateriale'
import RigaMateriale from '../../components/Planner/RigaMateriale'

export default function ListMateriali() {
  const [
    { materiali, pagination, filters, loading, deleting },
    { page },
    {
      goToPage,
      onFilterChangeDebounced,
      deleteMateriale,
      addMateriale,
      updateMateriale,
    },
  ] = useReactDataTable(MaterialiState, () => ({
    search: '',
  }))

  const [selectedMateriale, setMateriale] = useState(null)

  const [showModalDeleteMateriale, setShowModalDeleteMateriale] = useState(
    false
  )
  const toggleShowModalDeleteMateriale = useCallback(() => {
    setShowModalDeleteMateriale((show) => !show)
  }, [])

  const [showModalFormMateriale, setShowModalFormMateriale] = useState(false)
  const toggleShowModalMateriale = useCallback(() => {
    setMateriale(null)
    setShowModalFormMateriale((show) => !show)
  }, [])

  return (
    <div className="container-fluid">
      {materiali === null && loading && (
        <div className="text-center mt-4">
          <CircularProgress color="primary" />
        </div>
      )}
      {materiali && (
        <>
          <div className="d-flex justify-content-between">
            <div>
              <div>
                <input
                  name="search"
                  type="text"
                  className="form-control form-control-sm mt-2 mr-2"
                  placeholder={'Ricerca libera'}
                  value={filters && filters.search}
                  onChange={onFilterChangeDebounced}
                />
              </div>
            </div>
            <div className="text-right">
              <Button
                onClick={() => toggleShowModalMateriale()}
                size="sm"
                className="mt-2"
                outline
                color="secondary"
              >
                <i className="fas fa-plus-circle"></i> Aggiungi materiale
              </Button>
            </div>
          </div>
          <div className="table-responsive mt-2">
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Materiale</th>
                  <th>Colonne</th>
                  <th>Reminder Ordine Fornitore</th>
                  <th>Azioni</th>
                </tr>
              </thead>
              <tbody>
                {materiali.map((materiale) => (
                  <RigaMateriale
                    key={materiale.nome}
                    materiale={materiale}
                    setMateriale={setMateriale}
                    setShowModalFormMateriale={setShowModalFormMateriale}
                    setShowModalDeleteMateriale={setShowModalDeleteMateriale}
                  />
                ))}
              </tbody>
            </table>
            <Paginator
              numPages={pagination.numPages}
              currentPage={page}
              goToPage={goToPage}
            />
          </div>
          <ModalFormMateriale
            isOpen={showModalFormMateriale}
            toggle={toggleShowModalMateriale}
            materiale={selectedMateriale}
            save={(materiale) => {
              if (selectedMateriale) {
                return updateMateriale
                  .onSuccess(() => {
                    setMateriale(null)
                    toggleShowModalMateriale()
                    //refresh()
                  })
                  .asPromise(selectedMateriale.nome, materiale)
              } else {
                return addMateriale
                  .onSuccess(toggleShowModalMateriale)
                  .asPromise(materiale)
              }
            }}
          />
          <ModalDeleteMateriale
            toggle={toggleShowModalDeleteMateriale}
            isOpen={showModalDeleteMateriale}
            materiale={selectedMateriale}
            deleting={deleting}
            onSetEliminato={() => {
              deleteMateriale
                .onSuccess(() => {
                  toggleShowModalDeleteMateriale()
                })
                .run(selectedMateriale)
            }}
          />
        </>
      )}
    </div>
  )
}
