import React, { useMemo, Fragment, useState, createContext, useContext } from 'react'
import { useRunRj } from 'react-rocketjump'
import moment from 'moment'
// 0.20
import { default as BaseCalendar } from 'react-big-calendar'
import orderBy from 'lodash/orderBy'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { AssenzePersonaState, PersonaState, FestivitaState } from './localstate'
import { DateTimeRangeModalForm } from '../../components/Planner/DateTimeRangeForm'
import { Button } from 'reactstrap'
import { DATE_FORMAT, formatDateAsDay, makeRangeStartEnd } from './dateUtils'

const Calendar = withDragAndDrop(BaseCalendar)

const localizer = BaseCalendar.momentLocalizer(moment)

const ModalActions = createContext({})

const ListAssenze = ({ date, range, events }) => {
  const eventsToDisplay = orderBy(events,'giorno_da','asc')
  const { openModalAssenza } = useContext(ModalActions)
  return (
    <table className='table table-bordered table-stripe mt-4'>
      <thead>
        <tr>
          <th>Data da: </th>
          <th>Data a: </th>
          <th>Ora da: </th>
          <th>Ora a: </th>
          <th>Titolo</th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody>
        {eventsToDisplay && eventsToDisplay.map((event,key) => (
          <tr key={key}>
            <td>{moment(event.giorno_da).format('DD-MM-YYYY')}</td>
            <td>{moment(event.giorno_a).format('DD-MM-YYYY')}</td>
            <td>{event.ora_da || <i>Tutto il giorno</i>}</td>
            <td>{event.ora_a || <i>Tutto il giorno</i>}</td>
            <td>{event.title}</td>
            <td className='text-primary text-center'>
              <i
                onClick={() => openModalAssenza(event)}
                className="fas fa-search pointer"></i>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

ListAssenze.title = date => {
  return `Lista assenze ${moment(date).format('YYYY')}`
}

ListAssenze.range = date => {
  return {
    start: moment(date).startOf('year').toDate(),
    end: moment(date).endOf('year').toDate()
  }
}

ListAssenze.navigate = (date, action) => {
  switch (action) {
    case BaseCalendar.Navigate.PREVIOUS:
      // At the start of prev year
      return moment(date)
        .startOf('year')
        .subtract(1, 'day')
        .startOf('year')
        .toDate()
    case BaseCalendar.Navigate.NEXT:
      // At the start of next year
      return moment(date)
        .endOf('year')
        .add(1, 'day')
        .startOf('year')
        .toDate()
    default:
      return date
  }
}

// Start with very large range
const INITIAL_DATE_RANGE = {
  from_date: moment().subtract(1, 'month').startOf('month').format(DATE_FORMAT),
  to_date: moment().add(1, 'month').endOf('month').format(DATE_FORMAT),
}

function AssenzePeronaCalendar({ idPerona }) {
  // Controll view of calendar
  const [view, setView] = useState('month')

  const [
    { list: assenze, dateRange, deleting },
    {
      run: fetchAssenze, addAssenza, updateAssenza, deleteAssenza,
      updateItem,
    }
  ] = useRunRj(AssenzePersonaState, [idPerona, INITIAL_DATE_RANGE])

  const [
    { filledDay },
    { run: fetchFestivita }
  ] = useRunRj(FestivitaState, [INITIAL_DATE_RANGE])

  const [selectedAssenza, setSelectedAssenza] = useState(null)

  const modalActions = useMemo(() => ({
    openModalAssenza: setSelectedAssenza,
  }), [])

  function optimistiAssenzaUpdate(toUpdateAssenza, oldAssenza) {
    updateItem(toUpdateAssenza)
    updateAssenza
      .onFailure(() => updateItem(oldAssenza))
      .run(toUpdateAssenza)
  }

  const eventsAssenze = useMemo(() => {
    if (assenze === null) {
      return []
    }
    // Nothing 2 MAP
    if (view === 'agenda') {
      return assenze
    }
    return assenze.map(a => {
      return {
        title: 'Assente',
        ...a,
        ...makeRangeStartEnd(a, view),
      }
    })
  }, [assenze, view])

  return (
    <Fragment>
      <ModalActions.Provider value={modalActions}>
        <Calendar
          selectable
          dayPropGetter={date => {
            if (filledDay[moment(date).format('YYYY-MM-DD')]) {
              return ({ className: 'calendar-festa-date' })
            }
          }}
          view={view}
          onView={setView}
          style={{ height: 'fit-content' }}
          // This line avoid resize but fix event render
          // resizableAccessor={() => false}
          // The following line disable the click on day cell
          // for now noop maybe in future open the week at day
          // withoute the calendary try to open day view and crash ...
          onDrillDown={() => {}}
          views={{
            month: true,
            week: true,
            agenda: ListAssenze,
          }}
          onSelectSlot={selection => {
            if (selection.action === 'click') {
              // avoid create shit on click
              return
            }
            const assenza = {
              persona: idPerona,
              giorno_da: formatDateAsDay(selection.start),
              giorno_a: formatDateAsDay(selection.end),
            }
            if (view === 'week') {
              assenza.ora_da = moment(selection.start).format('HH:mm')
              assenza.ora_a = moment(selection.end).format('HH:mm')
            }
            addAssenza(assenza)
          }}
          events={eventsAssenze}
          onSelectEvent={event => {
            setSelectedAssenza(event)
          }}
          onEventResize={resized => {
            const oldAssenza = resized.event

            const shouldHandleHours = (
              view === 'week' &&
              moment(resized.end).diff(moment(resized.start), 'days') === 0
            )

            let toUpdateAssenza = {
              ...resized.event,
            }

            let end = moment(resized.end)
            // Workaround to end resized bugged =(
            if (!shouldHandleHours && end.format('HH:mm') === '00:00') {
              end = end.subtract(1, 'day')
            }

            toUpdateAssenza = {
              ...resized.event,
              giorno_da: formatDateAsDay(resized.start),
              giorno_a: formatDateAsDay(end.toDate()),
            }
            if (shouldHandleHours) {
              toUpdateAssenza.ora_da = moment(resized.start).format('HH:mm')
              toUpdateAssenza.ora_a = moment(resized.end).format('HH:mm')
            }
            optimistiAssenzaUpdate(toUpdateAssenza, oldAssenza)
          }}
          onEventDrop={dropped => {
            const oldAssenza = dropped.event
            const toUpdateAssenza = {
              ...dropped.event,
              giorno_da: formatDateAsDay(dropped.start),
              giorno_a: formatDateAsDay(dropped.end),
            }
            // Handle hours in drop only in week view and when
            // dropping in then same day (no multiple days ranges)
            if (view === 'week' && moment(dropped.end).diff(moment(dropped.start), 'days') === 0) {
              toUpdateAssenza.ora_da = moment(dropped.start).format('HH:mm')
              toUpdateAssenza.ora_a = moment(dropped.end).format('HH:mm')
            }
            optimistiAssenzaUpdate(toUpdateAssenza, oldAssenza)
          }}
          onRangeChange={(range, view) => {
            let params
            if (Array.isArray(range)) {
              // List of days (week)
              params = {
                from_date: formatDateAsDay(range[0]),
                to_date: formatDateAsDay(range[range.length - 1]),
              }
            } else {
              params = {
                from_date: formatDateAsDay(range.start),
                to_date: formatDateAsDay(range.end)
              }
            }

            fetchAssenze(idPerona, params)
            fetchFestivita(params)
          }}
          localizer={localizer}
        />
        <DateTimeRangeModalForm
          title='Assenza'
          deleting={deleting}
          isOpen={selectedAssenza !== null}
          toggle={() => setSelectedAssenza(null)}
          dateTimeRange={selectedAssenza}
          onDelete={() => {
            deleteAssenza
              .onSuccess(() => setSelectedAssenza(null))
              .run(selectedAssenza)
          }}
          save={(assenza) => {
            return updateAssenza
              .onSuccess(() => setSelectedAssenza(null))
              .asPromise(assenza)
          }}
        />
      </ModalActions.Provider>
    </Fragment>
  )
}

export default function Personale({ match, history }) {
  const { id } = match.params
  const [{ data: persona }] = useRunRj(PersonaState, [id])

  return (
    <div className='container-fluid mt-2'>
      {persona && <div className='card'>
        <div className='card-header position-relative text-center font-weight-bold'>
          <Button className='position-absolute' style={{left:10, top:4}} size='sm' onClick={() => history.push('/personale')} color='secondary' outline>
            <i className="fas fa-arrow-left"></i>{' ' }Lista personale
          </Button>
          <div className='pt-1'>
            {persona.nome}
          </div>
        </div>
        <div className='card-body'>
          <div className='py-2'>Nome: <b>{persona.nome}</b></div>
          <div className='py-2'>Reparto: <b>{persona.reparto}</b></div>
          <div className='py-2'>Data inizio: <b>{moment(persona.data_inizio).format('DD-MM-YYYY')}</b></div>
          {persona.data_fine && <div>Data fine: <b>{moment(persona.data_fine).format('DD-MM-YYYY')}</b></div>}
        </div>

      </div>}
      {persona && <div className='card mt-3'>
        <div className='card-header text-center font-weight-bold'>Assenze</div>
        <div className='card-body pb-5'>
          <AssenzePeronaCalendar
            idPerona={id}
          />
        </div>
      </div>}
    </div>
  )
}
