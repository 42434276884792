import React, { useMemo } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Formik, Field, FieldArray } from 'formik'
import * as Yup from 'yup'
import {
  InputField,
  CreatableSelectField,
  MultiSelectField,
} from '../../../components/Form/Fields'
import { OPERATIONS, OPERATIONS_KEYS } from './consts'
import './StatsConfigModal.css'

const INITIAL_VALUES = {
  name: '',
  fields_conf: {
    fields: [],
  },
}

const StatsSchema = Yup.object().shape({
  name: Yup.string().required(),
})

const OPERATIONS_OPTIONS = OPERATIONS_KEYS.map((k) => ({
  label: OPERATIONS[k],
  value: k,
}))

function FieldsConfigList({ values, availableFields }) {
  const fieldValues = values.fields_conf.fields
  const fieldsOptions = useMemo(() => {
    const flatValuesFields = fieldValues.map((f) => f.name)
    return availableFields.reduce((out, field) => {
      if (flatValuesFields.indexOf(field) === -1) {
        out.push({
          value: field,
          label: field,
        })
      }
      return out
    }, [])
  }, [availableFields, fieldValues])

  return (
    <FieldArray
      name="fields_conf.fields"
      render={(arrayHelpers) => (
        <div>
          <table className="table">
            <thead>
              <tr>
                <th>Colonna</th>
                <th>Aggregazione</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {values.fields_conf.fields &&
                values.fields_conf.fields.length > 0 &&
                values.fields_conf.fields.map((colonna, index) => (
                  <tr key={index}>
                    <td className='stats-create-select-fix'>
                      <Field
                        component={CreatableSelectField}
                        placeholder="Scegli"
                        name={`fields_conf.fields.${index}.name`}
                        options={fieldsOptions}
                      />
                    </td>
                    <td className='stats-multi-select-fix-col'>
                      <Field
                        placeholder="Scegli"
                        component={MultiSelectField}
                        name={`fields_conf.fields.${index}.operations`}
                        options={OPERATIONS_OPTIONS}
                      />
                    </td>
                    <td>
                      <div className="d-flex">
                        <Button
                          type="button"
                          color="danger"
                          outline
                          className="mr-2"
                          onClick={() => {
                            arrayHelpers.remove(index)
                          }}
                        >
                          Rimuovi
                        </Button>
                        <Button
                          color="dark"
                          outline
                          disabled={index === 0}
                          onClick={() => arrayHelpers.move(index, index - 1)}
                        >
                          <i className="fas fa-arrow-up"></i>
                        </Button>
                        <Button
                          className="ml-1"
                          color="dark"
                          outline
                          disabled={
                            index === values.fields_conf.fields.length - 1
                          }
                          onClick={() => arrayHelpers.move(index, index + 1)}
                        >
                          <i className="fas fa-arrow-down"></i>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <Button
            type="button"
            className="mt-2"
            outline
            color="success"
            onClick={() =>
              arrayHelpers.push({
                name: '',
                operations: [],
              })
            }
          >
            Aggiungi colonna
          </Button>
        </div>
      )}
    />
  )
}

export default function StatConfigModal({
  config,
  isOpen,
  toggle,
  save,
  availableFields,
}) {
  const initialValues = config ?? INITIAL_VALUES

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        {config
          ? `Modifica configurazione ${config.name}`
          : 'Nuova configurazione'}
      </ModalHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={StatsSchema}
        onSubmit={(values, actions) => {
          return save({
            ...values,
            fields_conf: {
              ...values.fields_conf,
              fields: values.fields_conf.fields.filter((f) => f.name),
            },
          }).catch((err) => {
            actions.setSubmitting(false)
          })
        }}
        render={({ handleSubmit, values, isSubmitting, errors, setValues }) => (
          <>
            <ModalBody>
              <form onSubmit={handleSubmit} autoComplete="off">
                <Field
                  label="Nome *"
                  type="text"
                  name="name"
                  className="mb-2"
                  component={InputField}
                />
                <FieldsConfigList
                  values={values}
                  availableFields={availableFields}
                />
                <input type="submit" className="d-none" />
              </form>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
              <Button color="secondary" onClick={toggle}>
                Chiudi
              </Button>
              <Button
                onClick={handleSubmit}
                type="button"
                disabled={isSubmitting}
                color="success"
              >
                Salva
              </Button>
            </ModalFooter>
          </>
        )}
      />
    </Modal>
  )
}
