import React, { useMemo, useState } from 'react'
import { useRunRj } from 'react-rocketjump'
import { ManipolazioniState } from './localstate/manipolazioni'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import moment from 'moment'
import { DATE_FORMAT } from './dateUtils'
import DateRangeQsFilter from '../../components/Planner/DateRangeQsFilter'
import qs from 'query-string'
import useRouterQueryParams from 'magik-react-hooks//useRouterQueryParams'

export default function ManipolatoreMacchina({ match }) {
  const [queryParams] = useRouterQueryParams()

  const INITIAL_DATE_RANGE = {
    from_date:
      queryParams.from_date || moment().startOf('month').format(DATE_FORMAT),
    to_date: queryParams.to_date || moment().endOf('month').format(DATE_FORMAT),
  }
  const [dateRange, setDateRange] = useState(INITIAL_DATE_RANGE)
  const [{ data, loading }] = useRunRj(ManipolazioniState, [dateRange])

  const dataOrdered = useMemo(() => {
    if (!data) return []
    const dataFormatted = data.map((item) => {
      return {
        date: moment(item.date).format('DD-MM-YYYY'),
        parziale: item.partial,
        totale: item.total,
      }
    })
    return dataFormatted.sort((a, b) => {
      return moment(a.date, 'DD-MM-YYYY').diff(moment(b.date, 'DD-MM-YYYY'))
    })
  }, [data])

  const maxManipolazioni = useMemo(() => {
    if (!data) return 0
    return Math.max(...data.map((item) => item.total))
  }, [data])

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <h3 className="p-2">Manipolatore</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="mt-2 mb-2 pt-2 pb-2">
            <DateRangeQsFilter
              filters={dateRange}
              minDate={moment('2024-04-01').toDate()}
              onFiltersChange={({ from_date, to_date }) => {
                setDateRange({ from_date, to_date })
                // set also to query params for sharing
                const qsFilters = qs.stringify({ from_date, to_date })
                const path = `${window.location.pathname}#/manipolatore-macchina?${qsFilters}`
                window.history.pushState({}, '', path)
              }}
              startField="from_date"
              endField="to_date"
              withPortal={true}
              customArrowIcon={<div />}
              small={false}
              noBorder
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h5 className="p-2">Manipolazioni totali</h5>
        </div>
        <div className="col-md-6">
          {!loading && dataOrdered && dataOrdered.length === 0 && (
            <div className="alert alert-info">Nessun dato disponibile.</div>
          )}
          {dataOrdered && dataOrdered.length > 0 && (
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={dataOrdered}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis dataKey="date" />
                <YAxis />
                <Line type="monotone" dataKey="totale" stroke="#1770F6" />
                <Tooltip />
                <CartesianGrid stroke="#ccc" vertical={false} />
              </LineChart>
            </ResponsiveContainer>
          )}
        </div>
        <div className="col-md-6">
          <div className="bg-light p-3 h-100 d-flex justify-content-center align-items-center">
            <div
              style={{
                height: 130,
                width: 130,
                background: '#1770F6',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 24,
                fontWeight: 'bold',
                borderRadius: '50%',
              }}
            >
              {maxManipolazioni}
            </div>
            <h4
              className="ml-4"
              style={{
                color: '#1770F6',
              }}
            >
              Manipolazioni totali
            </h4>
          </div>
        </div>
      </div>
      <div className="row mt-4 align-items-center">
        <div className="col-md-12">
            <h5 className="p-2">Manipolazioni parziali</h5>
        </div>
        <div className="col-md-6">
          {loading && <div>Caricamento...</div>}
          {!loading && data && data.length > 0 && (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={dataOrdered}
                margin={{
                  top: 0,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="parziale" fill="#1770F6" />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
        <div className="col-md-6">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Data</th>
                <th>Parziale</th>
                <th>Totale</th>
              </tr>
            </thead>
            <tbody>
              {dataOrdered.map((item, i) => (
                <tr key={i}>
                  <td>{item.date}</td>
                  <td>{item.parziale}</td>
                  <td>{item.totale}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
