import React, { useMemo, useEffect, useRef, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Formik, Form, FastField, FieldArray, Field, getIn } from 'formik'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import get from 'lodash/get'
import { Button } from 'reactstrap'
import { useRunRj } from 'react-rocketjump'
import { InputField, DateField, CheckboxField } from '../../Form/Fields'
import {
  transformErrorForForm,
  setNullOnEmptyValues,
} from '../../Form/Fields/utils'
import FormOrderRow from '../FormOrderRow'
import { PERMISSIONS } from '../../../django'
import { isNumeric } from '../../../helpers'
import { AgentiState } from '../../../apps/AdminPreventiviApp/localstate'
import { ConfigPlannerContext } from '../../../context'
import ClientiSelectAutoComplete from '../../ClientiSelectAutoComplete'
import FormFeedback from 'reactstrap/lib/FormFeedback'
import classNames from 'classnames'
import { TIPI_CLIENTE } from '../../../apps/PlannerApp/Stats/consts'

const EMPTY_ROW_ORDER = {
  altezza: '',
  ante: '',
  codice_serramento_id: '',
  forma: '',
  larghezza: '',
  listino: '',
  netto: '',
  numero: '',
  sconto_articolo: '',
  tipo_serramento_id: '',
}

const RowOrderForm = ({
  form,
  push,
  remove,
  setFieldValue,
  tipiSerramento,
  codiciSerramento,
  fetchOrder,
  order,
}) => {
  const { righe_ordine } = form.values
  return (
    <div>
      {righe_ordine.map((riga, index) => {
        return (
          <FormOrderRow
            key={index}
            fetchOrder={fetchOrder}
            remove={remove}
            order={order}
            riga={riga}
            index={index}
            tipiSerramento={tipiSerramento}
            codiciSerramento={codiciSerramento}
            setFieldValue={setFieldValue}
          />
        )
      })}
    </div>
  )
}

const OrdineSchema = Yup.object().shape({
  cliente: Yup.string().required(),
  data: Yup.string().required(),
  numero_commessa: Yup.string().required(),
  tipo_cliente: Yup.string().required(),
  righe_ordine: Yup.array().of(
    Yup.object().shape({
      numero: Yup.number().required().typeError('Campo obbligatorio'),
      tipo_serramento_id: Yup.number()
        .required()
        .typeError('Campo obbligatorio'),
      codice_serramento_id: Yup.number()
        .required()
        .typeError('Campo obbligatorio'),
    })
  ),
})

function CalcOrderHead({ values, setFieldValue }) {
  const totals = useMemo(() => {
    return values.righe_ordine.reduce(
      (tot, riga) => {
        if (isNumeric(riga.netto)) {
          tot.netto += Number(riga.netto)
        }
        if (isNumeric(riga.listino)) {
          tot.listino += Number(riga.listino)
        }
        return tot
      },
      { netto: 0, listino: 0 }
    )
  }, [values.righe_ordine])

  const { netto, listino } = totals
  const didMountRef = useRef(false)
  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true
      return
    }
    setFieldValue('netto', +netto.toFixed(2))
    setFieldValue('listino', +listino.toFixed(2))
    if (listino > 0 && netto > 0) {
      const sconto = +(+((listino - netto) / listino) * 100).toFixed(2)
      setFieldValue('sconto', sconto)
    }
  }, [netto, listino, setFieldValue])

  return null
}

const EMPTY_LIST = []
export default function FormOrder({
  tipiSerramento,
  toggleRemoveSchedule,
  save,
  codiciSerramento,
  order,
  showLinks = true,
  history,
  fetchOrder,
}) {
  const { valoriStato } = useContext(ConfigPlannerContext)
  const statiOrdine = get(valoriStato, 'ordine', [])
  const [{ data: agentiData }] = useRunRj(AgentiState)
  const agenti = agentiData || EMPTY_LIST

  return (
    <Formik
      onSubmit={(values, actions) => {
        return save(setNullOnEmptyValues(values)).then(
          () => {
            actions.setSubmitting(false)
          },
          (error) => {
            actions.setSubmitting(false)
            actions.setErrors(transformErrorForForm(error))
          }
        )
      }}
      initialValues={
        order || {
          data: '',
          numero_commessa: '',
          tipo_cliente: '',
          listino: '',
          sconto: '',
          agente: '',
          netto: '',
          codice_sistema: '',
          stato: '',
          cliente: '',
          tipo_serramento_id: '',
          righe_ordine: [],
        }
      }
      validationSchema={OrdineSchema}
    >
      {({
        values,
        setFieldValue,
        isSubmitting,
        handleChange,
        errors,
        touched,
      }) => (
        <Form>
          <CalcOrderHead values={values} setFieldValue={setFieldValue} />
          <div className="d-flex justify-content-center">
            <div className="card mt-2 w-100">
              {showLinks && (
                <div className="card-header position-relative text-center font-weight-bold">
                  <Button
                    className="position-absolute"
                    style={{ left: 10, top: 4 }}
                    size="sm"
                    onClick={() => history.push('/')}
                    color="secondary"
                    outline
                  >
                    <i className="fas fa-arrow-left"></i> Lista ordini
                  </Button>
                  {order && PERMISSIONS.scheduleOrder && (
                    <Button
                      className="position-absolute"
                      style={{ right: 10, top: 4 }}
                      size="sm"
                      onClick={() =>
                        history.push(`/schedulatore?id=${order.id}`)
                      }
                      color="success"
                      outline
                    >
                      <i className="fas fa-clock"></i>{' '}
                      {order && order.schedulazione_ordine
                        ? 'Schedulato'
                        : 'Non schedulato'}
                    </Button>
                  )}
                  <div className="pt-1">
                    {order
                      ? 'Modifica ordine ' + order.numero_commessa
                      : 'Aggiungi ordine'}
                  </div>
                </div>
              )}
              <div className="row pl-3 pr-3 pt-2">
                <div className="col-md-2">
                  <FastField
                    label="Data commessa *"
                    type="text"
                    name="data"
                    component={DateField}
                    className="mb-2 form-control"
                  />
                </div>
                <div className="col-md-2">
                  <FastField
                    label="Data approntamento"
                    name="data_consegna"
                    component={DateField}
                    className="mb-2 form-control"
                  />
                </div>
                <div className="col-md-2">
                  <FastField
                    label="Data posa"
                    name="data_posa"
                    component={DateField}
                    className="mb-2 form-control"
                  />
                </div>
                <div className="col-md-2">
                  <FastField
                    label="Numero commessa *"
                    type="text"
                    name="numero_commessa"
                    component={InputField}
                    className="mb-2"
                  />
                </div>
                <div className="col-md-2">
                  <FastField
                    label="Riferimento"
                    type="text"
                    name="riferimento"
                    component={InputField}
                    className="mb-2"
                  />
                </div>
                <div className="col-md-2">
                  <Field
                    label="Agente"
                    name="agente"
                    type="select"
                    component={InputField}
                    className="mb-2"
                  >
                    <option value={''}>--</option>
                    {agenti.map((agente) => (
                      <option key={agente.id} value={agente.id}>
                        {agente.user.first_name} {agente.user.last_name}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
              <div className="row pl-3 pr-3 pb-2">
                <div className="col-md-6">
                  <ClientiSelectAutoComplete
                    value={values.cliente}
                    hasValue
                    label="Cliente"
                    required
                    debounceTimeout={150}
                    loadOptionsOnMenuOpen
                    onChange={(cliente) => {
                      if (cliente) {
                        setFieldValue('cliente', cliente)
                      } else {
                        setFieldValue('cliente', '')
                      }
                    }}
                    classNamePrefix={classNames({
                      InvalidReactSelect:
                        getIn(errors, 'cliente') && getIn(touched, 'cliente'),
                    })}
                    noOptionsMessage={() => 'Nessun cliente trovato'}
                    loadingMessage={() => 'Caricamento clienti...'}
                    placeholder={''}
                    isClearable={true}
                    name={'cliente'}
                    additional={{
                      page: 1,
                    }}
                  />
                  {getIn(errors, 'cliente') && getIn(touched, 'cliente') && (
                    <FormFeedback className="d-block">
                      {errors['cliente']}
                    </FormFeedback>
                  )}
                </div>
                <div className="col-md-2">
                  <Field
                    label="Tipo cliente *"
                    type="select"
                    required
                    name="tipo_cliente"
                    component={InputField}
                    className="mb-2"
                  >
                    <option value={''}>--</option>
                    {TIPI_CLIENTE.map((tipo) => (
                      <option key={tipo} value={tipo}>
                        {tipo}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
              <div className="row pl-3 pr-3 pb-2">
                <div className="col-md-2">
                  <FastField
                    label="Prezzo listino"
                    type="number"
                    name="listino"
                    readOnly
                    component={InputField}
                    className="mb-2"
                  />
                </div>
                <div className="col-md-2">
                  <FastField
                    label="Sconto"
                    type="number"
                    name="sconto"
                    readOnly
                    component={InputField}
                    className="mb-2"
                  />
                </div>
                <div className="col-md-2">
                  <FastField
                    label="Netto"
                    type="number"
                    name="netto"
                    readOnly
                    component={InputField}
                    className="mb-2"
                  />
                </div>
                <div className="col-md-2">
                  <Field
                    label="Stato ordine"
                    type="select"
                    name="stato"
                    component={InputField}
                    className="mb-2"
                  >
                    <option value={''}>--</option>
                    {statiOrdine.map((stato) => (
                      <option key={stato.nome} value={stato.nome}>
                        {stato.nome}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="col-md-2">
                  <FastField
                    label="Codice sistema"
                    type="text"
                    name="codice_sistema"
                    component={InputField}
                    className="mb-2"
                  />
                </div>
                <div className="col-md-2 d-flex flex-column justify-content-end">
                  {PERMISSIONS.autoScheduleOrder && (
                    <Field
                      label="Auto Schedulazione"
                      name="auto_schedulazione"
                      component={CheckboxField}
                      disabled={values.non_schedulabile}
                      className="mb-1"
                    />
                  )}
                  <FastField
                    label="Non Schedulabile"
                    name="non_schedulabile"
                    component={CheckboxField}
                    onChange={(e) => {
                      if (PERMISSIONS.autoScheduleOrder && e.target.checked) {
                        setFieldValue('auto_schedulazione', false)
                      }
                    }}
                    className="mb-2"
                  />
                </div>
              </div>
              <div className="row pl-3 pr-3 pb-2">
                <div className="col-md-4">
                  <FastField
                    label="Note"
                    type="textarea"
                    name="note"
                    component={InputField}
                    className="mb-2"
                  />
                </div>
              </div>
            </div>
          </div>
          <FieldArray
            name="righe_ordine"
            render={(props) => (
              <RowOrderForm
                {...props}
                setFieldValue={setFieldValue}
                tipiSerramento={tipiSerramento}
                order={order}
                fetchOrder={fetchOrder}
                codiciSerramento={codiciSerramento}
              />
            )}
          />
          {order && order.schedulazione_ordine && (
            <div className="card mt-4 w-100">
              <div className="card-header position-relative text-center font-weight-bold">
                Schedulazione
              </div>
              <div className="pl-3 pr-3 mt-2">
                <div>
                  <span className="mr-1">Inizio:</span>{' '}
                  {dayjs(order.schedulazione_ordine.inizio).format(
                    'DD-MM-YYYY HH:mm'
                  )}
                  <span className="ml-4 mr-1">Fine:</span>{' '}
                  {dayjs(order.schedulazione_ordine.fine).format(
                    'DD-MM-YYYY HH:mm'
                  )}
                  <hr style={{ margin: '5px 0px 5px 0px' }} />
                  <div className="d-flex">
                    {order.schedulazione_ordine.schedulazioni_fasi.map(
                      (fase, key) => (
                        <div className="mr-4" key={key}>
                          <span className="mr-1">Inizio {fase.nome_fase}:</span>{' '}
                          <br /> {dayjs(fase.inizio).format('DD-MM-YYYY HH:mm')}
                        </div>
                      )
                    )}
                  </div>
                  <hr style={{ margin: '5px 0px 5px 0px' }} />
                  <div className="d-flex">
                    {order.schedulazione_ordine.schedulazioni_fasi.map(
                      (fase, key) => (
                        <div className="mr-4" key={key}>
                          <span className="mr-1">Fine {fase.nome_fase}:</span>{' '}
                          <br /> {dayjs(fase.fine).format('DD-MM-YYYY HH:mm')}
                        </div>
                      )
                    )}
                  </div>
                </div>
                {order &&
                  PERMISSIONS.scheduleOrder &&
                  order.schedulazione_ordine && (
                    <div className="text-center mt-2">
                      <Button
                        onClick={toggleRemoveSchedule}
                        className="ml-3 mr-3 mb-3 text-center"
                        color="danger"
                        size="md"
                        type="button"
                      >
                        <i className="fas fa-remove"></i> Rimuovi Schedulazione
                      </Button>
                    </div>
                  )}
              </div>
            </div>
          )}
          {PERMISSIONS.editOrder && (
            <div
              className="mt-2 d-flex justify-content-end"
              style={{ position: 'sticky', bottom: 10, zIndex: 20 }}
            >
              <div className="shadow-sm p-2">
                <Link to="/ordini" className="btn btn-info mr-2">
                  <i className="fas fa-list" /> Lista Ordini
                </Link>
                <FieldArray
                  name="righe_ordine"
                  render={({ push }) => (
                    <Button
                      disabled={isSubmitting}
                      onClick={() => {
                        push(EMPTY_ROW_ORDER)
                        // NOTE i am not what i am dooing XD
                        setTimeout(() => {
                          window.scrollTo(0, document.body.scrollHeight)
                        })
                      }}
                      type="button"
                      color="info"
                      className="mr-1"
                    >
                      <i className="fas fa-plus-circle"></i> Aggiungi Riga
                    </Button>
                  )}
                />
                <Button
                  type="submit"
                  disabled={
                    (!values.non_schedulabile &&
                      values.righe_ordine.length === 0) ||
                    isSubmitting
                  }
                  color="success"
                >
                  <i className="fas fa-save"></i>{' '}
                  {isSubmitting ? 'Salvo...' : 'Salva'}
                </Button>
              </div>
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}
