import React, { Fragment, useCallback, useMemo, useState } from "react"
import { Formik, Field } from "formik"
import * as Yup from "yup"
import { ControlliOrdineState } from "./localstate/ordini"
import { deps, useRunRj } from "react-rocketjump"
import { Button } from "reactstrap"
import { CircularProgress } from "@material-ui/core"
import {
  DateField,
  InputField,
} from "../../components/Form/Fields"
import { fixInitialValuesNull } from "../../components/Form/Fields/utils"
import { OperatoriState } from "./localstate/operatori"
import flatMap from "lodash/flatMap"
import groupBy from "lodash/groupBy"
import ModalDeleteControlloEffettuato from "../../components/Planner/ModalDeleteControlloEffettuato"
import { USER } from "../../django"

const BIG_PAGE_PARAMS = { page_size: 1000 }

const ControlloRow = React.memo(
  ({
    baseName,
    lavorazione,
    controllo,
    operatori,
    setControllo,
    toggleShowModalDelete,
  }) => {
    const canUpdateRow =
      controllo.controllo_effettuato.operatore &&
      USER.operatore &&
      controllo.controllo_effettuato.operatore !== USER.operatore.id
    return (
      <tr key={controllo.id}>
        <td className="font-weight-bold">{lavorazione.nome}</td>
        <td>{controllo.descrizione}</td>
        <td>{controllo.frequenza}</td>
        <td>
          <Field
            disabled={canUpdateRow}
            component={DateField}
            name={`${baseName}.data`}
          />
        </td>
        <td>
          <Field
            component={InputField}
            type="select"
            disabled={canUpdateRow}
            name={`${baseName}.operatore`}
          >
            {USER.operatore && !canUpdateRow ? (
              <>
                <option value="">---</option>
                <option value={USER.operatore.id}>
                  {USER.first_name} {USER.last_name}
                </option>
              </>
            ) : (
              <>
                <option value="">---</option>
                {operatori.map((operatore) => (
                  <option value={operatore.id} key={operatore.id}>
                    {operatore.user.first_name} {operatore.user.last_name}
                  </option>
                ))}
              </>
            )}
          </Field>
        </td>
        <td>
          <Field
            component={InputField}
            type={"select"}
            disabled={canUpdateRow}
            className="form-control-sm"
            name={`${baseName}.conforme`}
          >
            <option value={""}>Non indicato</option>
            <option value={true}>Conforme</option>
            <option value={false}>Non Conforme</option>
          </Field>
        </td>
        <td>
          <Field
            type="textarea"
            disabled={canUpdateRow}
            component={InputField}
            name={`${baseName}.note`}
          />
        </td>
        <td>
          {controllo.controllo_effettuato.id && (
            <Button
              onClick={() => {
                setControllo(controllo.controllo_effettuato)
                toggleShowModalDelete()
              }}
              disabled={canUpdateRow}
              color="danger"
            >
              Cancella
            </Button>
          )}
        </td>
      </tr>
    )
  }
)

const ControlliSchema = Yup.object().shape({
  controlli: Yup.array().of(
    Yup.object().shape(
      {
        id: Yup.mixed().nullable(),
        data: Yup.string().when(["operatore", "conforme", "note", "id"], {
          is: (operatore,conforme, note, id) => operatore || id || conforme !== "" || note,
          then: Yup.string().required("Data obbligatoria"),
        }),
        operatore: Yup.string().when(["data", "id", "conforme", "note"], {
          is: (data, id, conforme, note) => data || id || conforme !== "" || note,
          then: Yup.string().required("Operatore obbligatorio"),
        }),
        note: Yup.string().when(["conforme"], {
          is: (conforme) => conforme !== "" && conforme === 'false',
          then: Yup.string().required("Note obbligatorie"),
        }),
        conforme: Yup.string().when(["operatore", "data", "note", "id"], {
          is: (operatore, data, note, id) => !!(operatore || id || data || note),
          then: Yup.string()
            .required(
              <div>
                Seleziona se la lavorazione
                <br />è conforme o non conforme.
              </div>
            ),
          otherwise: Yup.string().notRequired()
        }),
      },
      [
        ["conforme", "operatore"],
        ["conforme", "data"],
        ["operatore", "data"],
        ["conforme", "note"],
        ["operatore", "note"],
        ["data", "note"],
        ["conforme","id"],
        ["operatore","id"],
        ["data","id"]
      ]
    )
  ),
})

export default function ControlliOrdine({ order }) {
  const [
    { data },
    { writeControlli, deleteControllo },
  ] = useRunRj(ControlliOrdineState, [deps.maybeGet(order, "id")])
  const [{ operatori }] = useRunRj(OperatoriState, [BIG_PAGE_PARAMS])
  const [showModalDeleteControllo, setShowModalDeleteControllo] = useState(
    false
  )
  const [controllo, setControllo] = useState(null)

  const toggleShowModalDelete = useCallback(() => {
    setShowModalDeleteControllo((show) => !show)
  }, [])

  const byNomeFase = useMemo(() => {
    if (data === null) {
      return null
    }
    return groupBy(data, "nome_fase_pianificazione")
  }, [data])

  const fasiList = useMemo(() => {
    if (byNomeFase === null) {
      return []
    }
    return Object.keys(byNomeFase).sort(function (a, b) {
      var faseA = a.toUpperCase()
      var faseB = b.toUpperCase()
      if (faseA < faseB) {
        return 1
      }
      if (faseA > faseB) {
        return -1
      }
      return 0
    })
  }, [byNomeFase])

  const controlliData = useMemo(() => {
    const controlli = flatMap(fasiList, (faseName) =>
      flatMap(byNomeFase[faseName], (lavorazione) =>
        lavorazione.controlli.map((controllo) => ({
          ...controllo.controllo_effettuato,
          conforme: controllo.controllo_effettuato.id
            ? controllo.controllo_effettuato.conforme
            : "",
        }))
      )
    )
    return fixInitialValuesNull({ controlli })
  }, [byNomeFase, fasiList])

  if (data === null || operatori === null) {
    return (
      <div className="table-responsive pt-2 text-center">
        <CircularProgress color="primary" />
      </div>
    )
  }

  return (
    <Formik
      onSubmit={(values, actions) => {
        const controlli = values.controlli.filter((controllo) => {
          return controllo.data && controllo.operatore
        })
        return writeControlli.asPromise(order.id, controlli).then(
          () => {
            actions.setSubmitting(false)
          },
          () => {
            actions.setSubmitting(false)
          }
        )
      }}
      validationSchema={ControlliSchema}
      initialValues={controlliData}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting }) => {
        let index = 0
        return (
          <form onSubmit={handleSubmit}>
            <div className="table-responsive pt-2">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <td width="15%">Fase</td>
                    <td width="10%">Controllo tolleranze</td>
                    <td>Frequenza</td>
                    <td>Data</td>
                    <td>Operatore</td>
                    <td>Conforme</td>
                    <td>Note</td>
                    <td>Azioni</td>
                  </tr>
                </thead>
                <tbody>
                  {fasiList.map((faseName) => (
                    <Fragment key={faseName}>
                      <tr>
                        <td
                          colSpan={8}
                          className="text-capitalize bg-dark text-white text-center"
                        >
                          <b>{faseName}</b>
                        </td>
                      </tr>
                      {byNomeFase[faseName].map((lavorazione) => (
                        <Fragment key={lavorazione.id}>
                          {lavorazione.controlli.map((controllo) => {
                            index++
                            return (
                              <ControlloRow
                                key={controllo.id}
                                operatori={operatori}
                                lavorazione={lavorazione}
                                controllo={controllo}
                                toggleShowModalDelete={toggleShowModalDelete}
                                setControllo={setControllo}
                                baseName={`controlli[${index - 1}]`}
                              />
                            )
                          })}
                        </Fragment>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="text-right">
              {data.length > 0 && USER.is_superuser && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                  href={`/api/planner/ordini/${order.id}/pdf-controllo-ce/`}
                  className="btn btn-outline-danger ml-2"
                >
                  <i className="fas fa-file-pdf-o mr-2 text-danger" /> Pdf
                </a>
              )}
              <Button
                className="ml-2"
                color="success"
                type="submit"
                disabled={isSubmitting}
              >
                Salva
              </Button>
            </div>
            <ModalDeleteControlloEffettuato
              toggle={toggleShowModalDelete}
              isOpen={showModalDeleteControllo}
              controllo={controllo}
              onSetEliminato={() => {
                deleteControllo
                  .onSuccess(() => {
                    toggleShowModalDelete()
                  })
                  .run(controllo.id)
              }}
            />
          </form>
        )
      }}
    </Formik>
  )
}
