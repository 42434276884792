import React, { useState } from 'react'
import {
  Button,
  Col,
  Collapse,
  FormGroup,
  Input,
  InputGroup,
  Label,
} from 'reactstrap'
import { ReadOnlyInput } from '../Form/Fields'
import { UpdateExtraFieldsState } from '../../apps/PlannerApp/localstate'
import { useRj } from 'react-rocketjump'

export default function RowExtraFields({ extraFields, canEdit, rigaId, fetchOrder, order }) {
  const [open, setOpen] = useState(false)
  const [fields, setFields] = useState(extraFields)
  const [edit, setEdit] = useState(false)

  const [{ pending, error, data }, { run, clean }] = useRj(
    UpdateExtraFieldsState
  )
  return (
    <div>
      <div className="d-flex align-items-center justify-content-center mb-1">
        <Button outline type="button" size="sm" onClick={() => setOpen(!open)}>
          {!open && <small>{'+ '}Mostra campi aggiuntivi</small>}
          {open && <small>{'- '}Nascondi campi aggiuntivi</small>}
        </Button>
      </div>
      <Collapse isOpen={open}>
        {canEdit && (
          <div className="row px-3 mb-2 mx-1">
            <Button
              
              type="button"
              color="success"
              size="sm"
              onClick={() => {
                if (edit) {
                  run.onSuccess(() => {
                    setEdit(!edit)
                    fetchOrder(order.id)
                  }).run(rigaId, fields)
                  
                }
                setEdit(!edit)
              }}
            >
              {edit ? <i className="fas fa-save mr-2" /> : <i className="fas fa-edit mr-2" />}
              {edit ? 'Salva campi aggiuntivi' : 'Modifica campi aggiuntivi'}
            </Button>
          </div>
        )}

        <div className="row px-3 mb-2">
          {Object.keys(fields).map((key) => (
            <div className="col-md-2 mt-2" key={key}>
              {edit && canEdit ? (
                <FormGroup className="field-qr-form-readonly">
                  <Label className="text-left" for={key}>
                    {key}
                  </Label>

                  <Input
                    value={fields[key]}
                    label={key}
                    onChange={(e) =>
                      setFields({ ...fields, [key]: e.target.value })
                    }
                  />
                </FormGroup>
              ) : (
                <ReadOnlyInput label={<i>{key}</i>} value={fields[key]} />
              )}
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  )
}
