import React, { useMemo, useContext, useCallback, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useRunRj, deps } from 'react-rocketjump'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import uniq from 'lodash/uniq'
import moment from 'moment'
import classNames from 'classnames'
import OrderFornitoreForm from '../../components/Planner/OrderFornitoreForm'
import ModalUpdateStato from '../../components/Planner/ModalUpdateStato'
import { ConfigPlannerContext } from '../../context'
import {
  FasiOrdineState,
  OrdineOrdiniFornitoreMancantiState,
  OrdineOrdiniFornitoreState,
  OrdineStatiMaterialeState,
} from './localstate'
import { getFasiNeededByOrder } from './localstate/common'
import { PERMISSIONS } from '../../django'
import { useSubscribeToOrderDetailEvents } from './OrderDetailEventsProvider'

function statoFaseByOrdineFornitore(ordiniForniotre = []) {
  return uniq(ordiniForniotre.map((o) => o.stato).filter(Boolean)).join(', ')
}

function OrdiniFornitoriMiniList({
  ordiniFornitore,
  tipologia,
  onAddClick,
  onUpdateClick,
  onDeleteClick,
}) {
  return (
    <div>
      {PERMISSIONS.adminOrdiniFornitore && (
        <Button
          onClick={() => onAddClick(tipologia)}
          size="sm"
          outline
          color="success"
          className="mb-2"
        >
          <i className="fa fa-plus" />
        </Button>
      )}
      <table className="table table-borderless table-sm">
        <tbody>
          {ordiniFornitore.length === 0 && (
            <tr>
              <td colSpan={4}>
                <i>
                  Ancora nessuno ordine fornitore per{' '}
                  <span className="text-capitalize">{tipologia}</span>
                </i>
              </td>
            </tr>
          )}
          {ordiniFornitore.map((ordineFornitore) => (
            <tr key={ordineFornitore.id}>
              <td>
                {PERMISSIONS.adminOrdiniFornitore && (
                  <i
                    onClick={() => onDeleteClick(ordineFornitore)}
                    className="fas fa-trash text-danger pointer"
                  ></i>
                )}
                <i
                  onClick={() => onUpdateClick(ordineFornitore)}
                  className="fas fa-edit text-primary pointer ml-2"
                ></i>
              </td>
              <td>{ordineFornitore.fornitore_data.nome}</td>
              <td>{ordineFornitore.note}</td>
              <td>{ordineFornitore.stato}</td>
              <td>
                <ul>
                  {ordineFornitore.allegati_data.map((allegato) => (
                    <li key={allegato.id}>
                      <a href={allegato.allegato}>
                        {allegato.original_name || 'Allegato'}
                      </a>
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const EMPTY_LIST = []

export default function FasiOrder({ order }) {
  const [{ data: fasiOrdine, savingStato }, { setStato, run: refreshFasi }] =
    useRunRj(FasiOrdineState, [deps.maybeGet(order, 'id')])

  const [
    { list: ordiniFornitore, deleting: deletingOrdineFornitore },
    {
      addOrdineFornitore,
      updateOrdineFornitore,
      deleteOrdineFornitore,
      run: refreshOrdiniFornitori,
    },
  ] = useRunRj(OrdineOrdiniFornitoreState, [deps.maybeGet(order, 'id')])

  const [
    { list: statiMateriale, saving: savingStatoMateriale },
    { setStato: setStatoMateriale },
  ] = useRunRj(OrdineStatiMaterialeState, [deps.maybeGet(order, 'id')])

  const [
    { data: ordiniFornitoriMancanti },
    { run: refreshOrdiniFornitoriMancanti },
  ] = useRunRj(OrdineOrdiniFornitoreMancantiState, [deps.maybeGet(order, 'id')])
  const materialiMancantiMap = useMemo(() => {
    if (ordiniFornitoriMancanti) {
      return keyBy(ordiniFornitoriMancanti, 'materiale')
    }
    return {}
  }, [ordiniFornitoriMancanti])

  const ordiniFornitoreByTipo = useMemo(() => {
    if (ordiniFornitore === null) {
      return {}
    }
    return groupBy(ordiniFornitore, 'tipologia')
  }, [ordiniFornitore])

  const { fasiScheduler, materiali, valoriStato } =
    useContext(ConfigPlannerContext)
  const fasiNeeded = useMemo(() => {
    if (!order) {
      return {}
    }
    return getFasiNeededByOrder(order, fasiScheduler)
  }, [order, fasiScheduler])

  const [modalOrderFornitore, setModalOrderFornitore] = useState(null)
  const toggleModalFornitore = useCallback(
    () => setModalOrderFornitore(null),
    []
  )

  const showModalAddOrdineFornitore = useCallback((tipologia) => {
    setModalOrderFornitore({
      operation: 'ADD',
      record: null,
      tipologia,
    })
  }, [])

  const showModalUpdateOrdineFornitore = useCallback((ordineFornitore) => {
    setModalOrderFornitore({
      operation: 'UPDATE',
      record: ordineFornitore,
      tipologia: ordineFornitore.tipologia,
    })
  }, [])

  const showModalDeleteOrdineFornitore = useCallback((ordineFornitore) => {
    setModalOrderFornitore({
      operation: 'DELETE',
      record: ordineFornitore,
      tipologia: ordineFornitore.tipologia,
    })
  }, [])

  const saveOrdineFornitore = useCallback(
    (ordineFornitore) => {
      const ordineFornitoreOnCurrentOrdine = {
        ...ordineFornitore,
        ordine: order.id,
      }
      if (ordineFornitore.id) {
        return updateOrdineFornitore
          .onSuccess(() => {
            toggleModalFornitore()
            refreshFasi(order.id)
          })
          .asPromise(ordineFornitoreOnCurrentOrdine)
      } else {
        return addOrdineFornitore
          .onSuccess(() => {
            toggleModalFornitore()
            refreshOrdiniFornitoriMancanti(order.id)
            refreshFasi(order.id)
          })
          .asPromise(ordineFornitoreOnCurrentOrdine)
      }
    },
    [
      order,
      updateOrdineFornitore,
      toggleModalFornitore,
      refreshFasi,
      addOrdineFornitore,
      refreshOrdiniFornitoriMancanti,
    ]
  )

  const [modalStatoFase, setModalStatoFase] = useState(null)
  const toggleModalStatoFase = useCallback(() => setModalStatoFase(null), [])

  const [modalStatoMateriale, setModalStatoMateriale] = useState(null)
  const toggleModalStatoMateriale = useCallback(
    () => setModalStatoMateriale(null),
    []
  )

  const statiFase = useMemo(() => {
    if (modalStatoFase === null) {
      return []
    }
    return valoriStato[modalStatoFase.nome_fase] || []
  }, [modalStatoFase, valoriStato])

  useSubscribeToOrderDetailEvents((event) => {
    if (event.type === 'ordiniFornitoriImported') {
      refreshOrdiniFornitori(order.id)
      refreshOrdiniFornitoriMancanti(order.id)
    }
  })

  return (
    <div className="table-responsive pt-1">
      {fasiOrdine && (
        <table className="table table-bordered w-100">
          <thead>
            <tr>
              <th>Fase / Materiale</th>
              <th>Inizio</th>
              <th>Fine</th>
              <th>Stato</th>
              {PERMISSIONS.viewOrderFornitore && <th>Ordini fornitore</th>}
            </tr>
          </thead>
          <tbody>
            {fasiOrdine.map((fase) =>
              fasiNeeded[fase.nome_fase] ? (
                <tr
                  key={fase.nome_fase}
                  className={classNames({
                    'miss-order-fornitore-row':
                      materialiMancantiMap[fase.nome_fase],
                  })}
                >
                  <td>
                    <span className="text-capitalize">{fase.nome_fase}</span>
                  </td>
                  <td>
                    {fase.schedulazione && (
                      <span>
                        {fase.schedulazione.inizio
                          ? moment(fase.schedulazione.inizio).format(
                              'DD/MM/YYYY HH:mm'
                            )
                          : null}
                      </span>
                    )}
                  </td>
                  <td>
                    {fase.schedulazione && (
                      <span>
                        {fase.schedulazione.fine
                          ? moment(fase.schedulazione.fine).format(
                              'DD/MM/YYYY HH:mm'
                            )
                          : null}
                      </span>
                    )}
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      {fase.nome_fase === 'macchine' ||
                      fase.nome_fase === 'banchi' ? (
                        <>
                          <div>{get(fase, 'stato.stato', null)}</div>
                          {PERMISSIONS.updateStatoFasiOrdine && (
                            <Button
                              onClick={() => setModalStatoFase(fase)}
                              color="success"
                              outline
                              size="sm"
                              className="ml-1"
                            >
                              <i
                                className={`fas ${
                                  !fase.stato ? 'fa-plus-circle' : 'fa-edit'
                                }`}
                              ></i>
                            </Button>
                          )}
                        </>
                      ) : (
                        <div>
                          {statoFaseByOrdineFornitore(
                            ordiniFornitoreByTipo[fase.nome_fase]
                          )}
                        </div>
                      )}
                    </div>
                  </td>
                  {PERMISSIONS.viewOrderFornitore && (
                    <td>
                      {fase.with_fornitore && (
                        <OrdiniFornitoriMiniList
                          tipologia={fase.nome_fase}
                          ordiniFornitore={
                            ordiniFornitoreByTipo[fase.nome_fase] || EMPTY_LIST
                          }
                          onAddClick={showModalAddOrdineFornitore}
                          onUpdateClick={showModalUpdateOrdineFornitore}
                          onDeleteClick={showModalDeleteOrdineFornitore}
                        />
                      )}
                    </td>
                  )}
                </tr>
              ) : (
                <tr key={fase.nome_fase} className="bg-light">
                  <td>
                    <span className="text-capitalize">{fase.nome_fase}</span>
                  </td>
                  <td />
                  <td />
                  <td />
                  {PERMISSIONS.viewOrderFornitore && <td />}
                </tr>
              )
            )}
            {PERMISSIONS.viewOrderFornitore && (
              <tr>
                <td colSpan={5} className="bg-info text-white text-center">
                  <h5 className="m-0 p-0">Materiali non legati a fasi</h5>
                </td>
              </tr>
            )}
            {PERMISSIONS.viewOrderFornitore &&
              materiali.map((materiale) => (
                <tr
                  key={materiale}
                  className={classNames({
                    'miss-order-fornitore-row': materialiMancantiMap[materiale],
                  })}
                >
                  <td>{materiale}</td>
                  <td className="bg-light" />
                  <td className="bg-light" />
                  <td>
                    {statiMateriale && (
                      <div className="d-flex align-items-center">
                        <div>{get(statiMateriale, materiale, null)}</div>
                        {PERMISSIONS.updateStatoFasiOrdine && (
                          <Button
                            onClick={() => setModalStatoMateriale(materiale)}
                            color="success"
                            outline
                            size="sm"
                            className="ml-1"
                          >
                            <i
                              className={`fas ${
                                !statiMateriale[materiale]
                                  ? 'fa-plus-circle'
                                  : 'fa-edit'
                              }`}
                            ></i>
                          </Button>
                        )}
                      </div>
                    )}
                  </td>
                  <td>
                    <OrdiniFornitoriMiniList
                      tipologia={materiale}
                      ordiniFornitore={
                        ordiniFornitoreByTipo[materiale] || EMPTY_LIST
                      }
                      onAddClick={showModalAddOrdineFornitore}
                      onUpdateClick={showModalUpdateOrdineFornitore}
                      onDeleteClick={showModalDeleteOrdineFornitore}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      <Modal
        toggle={toggleModalFornitore}
        size="lg"
        isOpen={
          modalOrderFornitore !== null &&
          modalOrderFornitore.operation !== 'DELETE'
        }
      >
        <ModalHeader toggle={toggleModalFornitore}>
          {modalOrderFornitore &&
            modalOrderFornitore.operation === 'UPDATE' &&
            'Modifica Ordine Fornitore'}
          {modalOrderFornitore &&
            modalOrderFornitore.operation === 'ADD' &&
            'Aggiungi Ordine Fornitore'}
        </ModalHeader>
        <OrderFornitoreForm
          tipologia={modalOrderFornitore ? modalOrderFornitore.tipologia : null}
          save={saveOrdineFornitore}
          toggle={toggleModalFornitore}
          orderFornitore={
            modalOrderFornitore ? modalOrderFornitore.record : null
          }
          inModal
        />
      </Modal>
      <Modal
        toggle={toggleModalFornitore}
        isOpen={
          modalOrderFornitore !== null &&
          modalOrderFornitore.operation === 'DELETE'
        }
      >
        <ModalHeader toggle={toggleModalFornitore}>
          {modalOrderFornitore &&
            modalOrderFornitore.operation === 'DELETE' &&
            'Elimina Ordine Fornitore'}
        </ModalHeader>
        <ModalBody>
          {modalOrderFornitore &&
            modalOrderFornitore.operation === 'DELETE' && (
              <div>
                Eliminare ordine fornitore per{' '}
                {modalOrderFornitore.record.fornitore_data.nome}?
              </div>
            )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button
            disabled={deletingOrdineFornitore}
            onClick={toggleModalFornitore}
          >
            Annulla
          </Button>
          <Button
            disabled={deletingOrdineFornitore}
            color="danger"
            onClick={() => {
              deleteOrdineFornitore
                .onSuccess(() => {
                  toggleModalFornitore()
                  refreshOrdiniFornitoriMancanti(order.id)
                })
                .run(modalOrderFornitore.record)
            }}
          >
            Elimina
          </Button>
        </ModalFooter>
      </Modal>
      <ModalUpdateStato
        title={
          modalStatoFase && (
            <span>
              Modifica stato fase{' '}
              <span className="text-capitalize">
                {modalStatoFase.nome_fase}
              </span>
            </span>
          )
        }
        initialStato={
          modalStatoFase && modalStatoFase.stato && modalStatoFase.stato.stato
        }
        required
        toggle={toggleModalStatoFase}
        isOpen={modalStatoFase !== null}
        saving={savingStato}
        stati={statiFase}
        onSave={(stato) => {
          setStato
            .onSuccess(() => {
              toggleModalStatoFase()
            })
            .run(order.id, modalStatoFase, stato)
        }}
      />
      <ModalUpdateStato
        title={
          modalStatoMateriale && (
            <span>
              Modifica stato materiale{' '}
              <span className="text-capitalize">{modalStatoMateriale}</span>
            </span>
          )
        }
        initialStato={
          statiMateriale && modalStatoMateriale
            ? statiMateriale[modalStatoMateriale]
            : null
        }
        required
        toggle={toggleModalStatoMateriale}
        isOpen={modalStatoMateriale !== null}
        saving={savingStatoMateriale}
        stati={valoriStato.materiali || EMPTY_LIST}
        onSave={(stato) => {
          setStatoMateriale
            .onSuccess(() => {
              toggleModalStatoMateriale()
            })
            .run(order.id, modalStatoMateriale, stato)
        }}
      />
    </div>
  )
}
