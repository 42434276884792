import React, { useState, useCallback } from "react"
import { ClientiState } from "../../apps/PlannerApp/localstate"
import {
  Input,
  Button,
  ModalHeader,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { useReactDataTable } from "react-use-datatable/react"
import CircularProgress from "@material-ui/core/CircularProgress"
import Paginator from "../Paginator"
import moment from "moment"

export function TableClientiAccessoApp({
  clienti,
  checked,
  numPages,
  page,
  goToPage,
  toggle,
}) {
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Associa?</th>
            <th>Nome</th>
            <th>Codice</th>
          </tr>
        </thead>
        <tbody>
          {clienti.map((cliente) => (
            <tr
              key={cliente.id}
              onClick={() => toggle(cliente)}
              className="pointer"
            >
              <td className="text-center">
                <input
                  onChange={() => {}}
                  type="checkbox"
                  checked={!!checked[cliente.id]}
                />{" "}
              </td>
              <td>{cliente.nome}</td>
              <td>{cliente.codice}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginator numPages={numPages} currentPage={page} goToPage={goToPage} />
    </div>
  )
}

function ClientiPicker({ accessoClienti, onSave, saving, toggle }) {
  const [
    { clienti, pagination },
    { page, search },
    { goToPage, onFilterChangeDebounced },
  ] = useReactDataTable(ClientiState, () => ({
    search: "",
  }))

  const [checked, setChecked] = useState(() => {
    return accessoClienti.reduce(
      (all, account) => ({
        ...all,
        [account.id]: true,
      }),
      {}
    )
  })

  const toggleCheck = useCallback((account) => {
    setChecked((checked) => ({
      ...checked,
      [account.id]: !checked[account.id],
    }))
  }, [])

  const handleSave = useCallback(() => {
    onSave(Object.keys(checked).filter((id) => checked[id]))
  }, [onSave, checked])

  if (clienti === null) {
    return (
      <div className="text-center p-5">
        <CircularProgress color="primary" />
      </div>
    )
  }

  return (
    <>
      <ModalBody>
        <div className="d-flex mb-2">
          <div>
            <Input
              placeholder="Cerca account"
              value={search}
              onChange={onFilterChangeDebounced}
              name="search"
              autoComplete="off"
            />
          </div>
        </div>
        <TableClientiAccessoApp
          toggle={toggleCheck}
          checked={checked}
          clienti={clienti}
          goToPage={goToPage}
          numPages={pagination.numPages}
          page={page}
        />
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button disabled={saving} onClick={toggle}>
          Annulla
        </Button>
        <Button disabled={saving} color="success" onClick={handleSave}>
          Salva
        </Button>
      </ModalFooter>
    </>
  )
}

export default function AppAccessoClienti({ user, save, saving }) {
  const [showModal, setShowModal] = useState(false)
  const toggleModal = useCallback(() => setShowModal((a) => !a), [])

  return (
    <div>
      <div className="alert alert-primary">
        <i className="fa fa-info-circle mr-2" aria-hidden="true"></i>
        Collega all'App Account ad uno o più clienti questo consentirà a questo
        account di vedere gli ordini relativi ai clienti collegati all'interno
        dell App QrLegno.
        {user.app_account.accesso_clienti.length === 0 && (
          <div className="mt-1">
            <b>Nessun cliente collegato.</b>
          </div>
        )}
      </div>
      <div className="mb-2">
        <Button color="primary" outline onClick={toggleModal}>
          Collega Clienti
        </Button>
      </div>
      <ul className="px-0">
        {user.app_account.accesso_clienti.map((cliente) => (
          <li key={cliente.id} className="list-group-item">
            {cliente.nome}
            <br />
            <small>{cliente.codice}</small>
          </li>
        ))}
      </ul>
      {user.app_account.ultimo_accesso && (
        <div className="mt-3">
          Ultimo accesso: {moment(user.app_account.ultimo_accesso).format('DD/MM/YYYY HH:mm')}
        </div>
      )}

      <Modal isOpen={showModal} toggle={toggleModal} size="lg" scrollable>
        <ModalHeader toggle={toggleModal}>
          Condividi con altri account
        </ModalHeader>
        <ClientiPicker
          saving={saving}
          onSave={(accessoClienti) =>
            save
              .onSuccess((data) => {
                toggleModal()
              })
              .run(user.app_account.id, accessoClienti)
          }
          toggle={toggleModal}
          accessoClienti={user.app_account.accesso_clienti}
        />
      </Modal>
    </div>
  )
}
