import React from 'react'
import { Route } from 'react-router-dom'
import './PermissionRoute.css'

function BadPermission() {
  return (
    <div className="Bad-Permission-Cont p-4">
      <div className='Bad-Permission-Man'>
        <i className="fa fa-user-secret" />
      </div>
      <h3>Non hai i permessi per accedere a questa pagina.</h3>
    </div>
  )
}

export default function PermissionRoute({
  hasPermission = true,
  component,
  children,
  ...props
}) {
  return (
    <Route
      {...props}
      render={(props) => {
        if (!hasPermission) {
          return <BadPermission />
        }
        if (component) {
          return React.createElement(component, props)
        }
        if (children) {
          return children
        }
      }}
    />
  )
}
