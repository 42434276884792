import React, { useContext } from 'react'
import { getColorByOrderFornitoreId } from '../../apps/PlannerApp/utils'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button } from 'reactstrap'
import { ContextOrdineOrdiniFornitoriRigheActions } from '../../apps/PlannerApp/contexts'

function RigaOrdineFornitore({ riga, checked, extraFields }) {
  const color = riga.ordine_fornitore
    ? getColorByOrderFornitoreId(riga.ordine_fornitore)
    : '#FFF'

  let styleColLeft, styleColRight
  if (color) {
    styleColLeft = {
      borderLeft: `5px solid ${color}`,
    }
    styleColRight = {
      backgroundColor: color,
    }
  }

  const {
    toggleSelectedRigaId,
    openModalSplitRigaOrdineFornitore,
    openModalUpdateRigaOrdineFornitore,
    openModalDuplicateRigaOrdineFornitore,
  } = useContext(ContextOrdineOrdiniFornitoriRigheActions)

  return (
    <tr>
      <td style={styleColLeft} className="text-capitalize">
        {riga.tipologia}
      </td>
      <td>{riga.riga_ordine}</td>
      <td>{riga.fornitore_data.nome}</td>
      <td>{riga.codice}</td>
      {extraFields.map((field) => {
        return (
          <td key={field} className="text-capitalize">
            {riga.extra_fields[field]}
          </td>
        )
      })}
      <td>{riga.quantita}</td>
      <td>{riga.descrizione}</td>
      <td className="sticky-col-right-buttons bg-white">
        <div className="d-flex">
          <div
            onClick={() => {
              openModalUpdateRigaOrdineFornitore(riga)
            }}
            className="pointer"
          >
            <i
              title="Modifica riga ordine fornitore"
              className="fas fa-pencil-alt text-warning"
            />
          </div>
          <div
            onClick={() => {
              openModalSplitRigaOrdineFornitore(riga)
            }}
            className="pointer ml-2"
          >
            <i
              title="Dividi riga ordine fornitore"
              className="fas fa-list-ul text-primary"
            />
          </div>
          <div
            onClick={() => {
              openModalDuplicateRigaOrdineFornitore(riga)
            }}
            className="pointer ml-2"
          >
            <i title="Duplica riga" className="fas fa-clone text-success" />
          </div>
        </div>
      </td>
      <td className="text-center sticky-col-right" style={styleColRight}>
        <input
          onChange={() => toggleSelectedRigaId(riga.id)}
          checked={checked}
          type="checkbox"
        />
      </td>
    </tr>
  )
}

const underscoreReplaceRegex = /(_)/g

export default function RigheOrdiniFornitoriTable({
  righeOrdiniFornitore,
  selectedIds,
  loading,
  extraFields,
}) {
  const { setSelectedRigheIds } = useContext(
    ContextOrdineOrdiniFornitoriRigheActions
  )
  const toggleAllRigheOrdineFornitoreTipologia = () => {
    const areAllChecked = righeOrdiniFornitore.every(
      (riga) => selectedIds[riga.id]
    )
    if (areAllChecked) {
      setSelectedRigheIds({})
    } else {
      const allChecked = righeOrdiniFornitore.reduce(
        (all, riga) => ({
          ...all,
          [riga.id]: true,
        }),
        {}
      )
      setSelectedRigheIds(allChecked)
    }
  }

  return (
    <div className="position-relative">
      <div className="scroller-div">
        {righeOrdiniFornitore === null && loading && (
          <div className="text-center mt-4">
            <CircularProgress color="primary" />
          </div>
        )}
        {righeOrdiniFornitore && (
          <table
            className="table table-bordered table-righe-ordini-fornitore mt-2"
            style={{ fontSize: 12 }}
          >
            <thead>
              <tr>
                <th>Tipologia</th>
                <th>Riga<br/>Ordine</th>
                <th>Fornitore</th>
                <th>Codice</th>
                {extraFields.map((field) => (
                  <th key={field} className="text-capitalize">
                    {field.replace(underscoreReplaceRegex, ' ')}
                  </th>
                ))}
                <th>Quantità</th>
                <th>Descrizione</th>
                <th className="border-left sticky-col-right-buttons bg-white">
                  &nbsp;
                </th>
                <th className="text-center sticky-col-right bg-white">
                  <Button
                    outline
                    title="Seleziona tutto"
                    onClick={toggleAllRigheOrdineFornitoreTipologia}
                    size="sm"
                    color={'success'}
                  >
                    <i className="fa fa-check" />
                  </Button>
                </th>
              </tr>
              {righeOrdiniFornitore &&
                righeOrdiniFornitore.map((riga) => (
                  <RigaOrdineFornitore
                    checked={!!selectedIds[riga.id]}
                    key={riga.id}
                    riga={riga}
                    extraFields={extraFields}
                  />
                ))}
            </thead>
          </table>
        )}
      </div>
    </div>
  )
}
