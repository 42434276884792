import React, { useContext, useMemo } from 'react'
import { Field, connect } from 'formik'
import find from 'lodash/find'
import { InputField, CheckboxField } from '../../../Fields'
import { ConfigPrevetiviContext } from '../../../../../context'
import AlertMessageRegione from './AlertMessageRegione'
import regioni from '../../../../../data/regioni.json'

function FormInfoCliente({ formik }){

  const { tipiCliente } = useContext(ConfigPrevetiviContext)
  const { values } = formik
  const regionePosa = values.regione_posa

  const selectedRegione = useMemo(() => {
    if(!regionePosa){
      return null
    }
    return find(regioni,{ nome: regionePosa })
  },[regionePosa])

  return (
    <div className='FormInfoCliente'>
      <h4 className='text-primary'>Inserimento misure</h4>
      <div className='alert alert-info p-4'>
        <p>
          Per poter stimare il tuo preventivo è necessario inserire il numero di serramenti che vuoi sostituire e le relative misure.
        </p>
        <div className='row'>
          <div className='col-md-6'>
            <h4 className='text-primary'>Tipo di cliente</h4>
            <p>
              Se sei un professionista del settore e sai esattamente come misurare i serramenti, specificalo di seguito.
              <br />
              Che tipo di misure stai riportando?
            </p>
            <Field
              component={InputField}
              name='tipo_cliente'
              type='select'
            >
              {tipiCliente.map((tipo, i) => (
                <option key={i} value={tipo[0]}>{tipo[1]}</option>
              ))}
            </Field>
          </div>
          <div className='col-md-5 ml-4'>
            <h4 className='text-primary'>Istallazione serramenti</h4>
            <Field
              component={CheckboxField}
              name='messa_in_posa'
              value={values.messa_in_posa}
              type='checkbox'
              label={'Preventivo compreso installazione serramenti'}
            />
            <h4 className='text-primary mt-4'>Regione di posa{/*values.messa_in_posa && <span>*</span>*/}</h4>
            <Field
              component={InputField}
              name='regione_posa'
              type='select'
              disabled={!values.messa_in_posa}
            >
              <option value=''></option>
              {regioni.map((regione, i) => (
                <option key={i} value={regione.nome}>{regione.nome}</option>
              ))}
            </Field>
          </div>
        </div>
        {selectedRegione && selectedRegione.showAlert &&
        <AlertMessageRegione />
        }
      </div>
    </div>
  )
}

export default React.memo(connect(FormInfoCliente))
