import React, { useContext, useMemo } from "react"
import { ConfigPlannerContext } from "../../context"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { keyBy } from "lodash"
import { PERMISSIONS } from "../../django"
import ModalCheckList from "../../components/Planner/ModalCheckList"
import { ListGroup, ListGroupItem, Button } from "reactstrap"

export default function CheckListOrdine({ order, writeCheckList }) {
  const { checkList } = useContext(ConfigPlannerContext)
  console.log(order, "order")
  const byCheck = useMemo(() => keyBy(order.check_list, "checklist"), [
    order.check_list,
  ])

  const [modalCheckList, actionsModalCheckList] = useModalTrigger()

  return (
    <div>
      <div className="d-flex justify-content-center">
        <div className="w-50">
          <Button
            color="primary"
            className="mt-2"
            outline
            onClick={
              PERMISSIONS.writeOrderCheckList
                ? () => actionsModalCheckList.open(order)
                : undefined
            }
            disabled={!PERMISSIONS.writeOrderCheckList}
          >
            Modifica Checklist
          </Button>
          <ListGroup
            onClick={
              PERMISSIONS.writeOrderCheckList
                ? () => actionsModalCheckList.open(order)
                : undefined
            }
            className={`mt-2 mb-2 ${
              PERMISSIONS.writeOrderCheckList ? "pointer" : ""
            }`}
          >
            {checkList.map((action) => (
              <ListGroupItem
                color={byCheck[action.id]?.done ? "success" : "danger"}
                key={action.id}
              >
                {byCheck[action.id]?.done ? (
                  <i className="fa fa-check text-success" />
                ) : (
                  <i className="fa fa-times text-danger" />
                )}{" "}
                - {action.nome}
              </ListGroupItem>
            ))}
          </ListGroup>
        </div>
      </div>
      <ModalCheckList
        save={(checkList) =>
          writeCheckList
            .onSuccess(actionsModalCheckList.close)
            .asPromise(modalCheckList.value.id, checkList)
        }
        isOpen={modalCheckList.isOpen}
        toggle={actionsModalCheckList.toggle}
        compileCheckList={modalCheckList.value?.check_list}
        onClosed={actionsModalCheckList.onClosed}
      />
    </div>
  )
}
