import React, { useMemo, useState } from 'react'
import { useRunRj } from 'react-rocketjump'
import { useQsDataTable as useDataTable } from 'react-use-datatable/react'
import Select from 'react-select'
import { LavorazioniOrdineOperatoreAdminState } from '../localstate/lavorazioniOrdineOperatore'
import { LavorazioniState } from '../localstate/lavorazioni'
import Paginator from '../../../components/Paginator'
import Spinner from 'reactstrap/lib/Spinner'
import { OperatoriState } from '../localstate/operatori'
import DateRangeQsFilter from '../../../components/Planner/DateRangeQsFilter'
import TableLavorazioniOrdine from '../../../components/Planner/TableLavorazioniOrdine'
import OrdersSelectAutoComplete from '../../../components/OrdersSelectAutoComplete'
import moment from 'moment'
import { AttivitaAdminState } from '../localstate/attivita'
import PerformanceChart from './PerformanceChart'
import BarChartAttivita from './BarChartAttivita'
import classNames from 'classnames'
import TablePerformanceGiorno from './TablePerformanceGiorno'
// import { AttivitaAdminState } from '../localstate/attivita'

const BIG_PAGE_PARAMS = { page_size: 1000 }

const COLOR_ACTIVITIES = 'rgba(255, 0, 0, 0.3)'

export default function Performance() {
  const [{ lavorazioni }] = useRunRj(LavorazioniState, [BIG_PAGE_PARAMS])
  const [{ operatori }] = useRunRj(OperatoriState, [BIG_PAGE_PARAMS])

  const [
    // Data State
    { lavorazioniOrdine, pagination, loading, totals },
    // Table state
    { page, ...filters },
    {
      // DT Actions
      onFiltersChange,
      onFilterChange,
      goToPage,
    },
  ] = useDataTable(LavorazioniOrdineOperatoreAdminState, () => ({
    operatore: '',
    lavorazione: '',
    ordine: '',
    from_date: moment().startOf('week').format('YYYY-MM-DD'),
    to_date: moment().endOf('week').format('YYYY-MM-DD'),
    lavorazioni: [],
    operatori: [],
  }))

  const [
    // Data State
    { attivita },
    // Table state
  ] = useDataTable(AttivitaAdminState, () => ({
    operatore: filters.operatore,
    lavorazione: filters.lavorazione,
    ordine: filters.ordine,
    from_date: filters.from_date,
    to_date: filters.to_date,
    lavorazioni: filters.lavorazioni ? filters.lavorazioni : [],
    operatori: filters.operatori ? filters.operatori : [],
    page_size: 1000,
  }))

  const lavorazioniForSelect = useMemo(() => {
    if (!lavorazioni) {
      return []
    }
    return lavorazioni.map((lavorazione) => ({
      value: lavorazione.id,
      label: lavorazione.nome,
    }))
  }, [lavorazioni])

  const operatoriForSelect = useMemo(() => {
    if (!operatori) {
      return []
    }
    return operatori.map((operatore) => ({
      value: operatore.id,
      label: operatore.user.first_name + ' ' + operatore.user.last_name,
    }))
  }, [operatori])

  const selectedOperatori = useMemo(() => {
    if (!filters.operatori || !operatori) {
      return []
    }
    if (typeof filters.operatori === 'string') {
      return filters.operatori.split(',').map((id) => ({
        value: id,
        label:
          operatori.find((operatore) => operatore.id === Number(id)).user
            .first_name +
          ' ' +
          operatori.find((operatore) => operatore.id === Number(id)).user
            .last_name,
      }))
    } else {
      return filters.operatori.map((id) => ({
        value: id,
        label:
          operatori.find((operatore) => operatore.id === Number(id)).user
            .first_name +
          ' ' +
          operatori.find((operatore) => operatore.id === Number(id)).user
            .last_name,
      }))
    }
  }, [filters.operatori, operatori])

  const selectedLavorazioni = useMemo(() => {
    if (!filters.lavorazioni || !lavorazioni) {
      return []
    }
    if (typeof filters.lavorazioni === 'string') {
      return filters.lavorazioni.split(',').map((id) => ({
        value: id,
        label: lavorazioni.find((lavorazione) => lavorazione.id === Number(id))
          .nome,
      }))
    } else {
      return filters.lavorazioni.map((id) => ({
        value: id,
        label: lavorazioni.find((lavorazione) => lavorazione.id === Number(id))
          .nome,
      }))
    }
  }, [filters.lavorazioni, lavorazioni])

  const percentPerformanceFromTotals = useMemo(() => {
    if (
      !totals ||
      totals.tempo_tot === '00:00:00' ||
      !totals.tempo_teorico_tot
    ) {
      return 0
    }
    if (totals.tempo_tot === 0) return 0
    const percent =
      (parseInt(moment.duration(totals.tempo_teorico_tot).as('minutes')) /
        parseInt(moment.duration(totals.tempo_tot).as('minutes'))) *
      100
    return percent.toFixed(0)
  }, [totals])

  const groupedAttivitaByData = useMemo(() => {
    if (!attivita) {
      return {}
    }
    return attivita.filter(a => a.ordine).reduce((acc, singleAttivita) => {
      const data = moment(singleAttivita.data).format('YYYY-MM-DD')
      if (!acc[data]) {
        acc[data] = []
      }
      acc[data].push(singleAttivita)
      return acc
    }, {})
  }, [attivita])

  const [typeView, setTypeView] = useState('dati-originali')

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          {lavorazioniOrdine === null && loading && (
            <div className="text-center mt-4">
              <Spinner color="primary" />
            </div>
          )}
          {lavorazioniOrdine && (
            <>
              <div className="text-center d-flex justify-content-center text-primary mt-2"></div>
              <div className="d-flex align-items-center">
                <div
                  style={{
                    height: 20,
                    width: 30,
                    background: COLOR_ACTIVITIES,
                  }}
                ></div>
                <div className="ml-2">Attività fuori tempo </div>
              </div>
              <div className="filters mt-2">
                <div className="row">
                  <div className="col-md-3">
                    <Select
                      name="operatori"
                      isMulti
                      placeholder={'Filtra per operatori'}
                      options={operatoriForSelect.filter(
                        (operatore) =>
                          !selectedOperatori.find(
                            (item) =>
                              Number(item.value) === Number(operatore.value)
                          )
                      )}
                      classNamePrefix="multi-select"
                      hideSelectedOptions={false}
                      isOptionSelected={(option) =>
                        selectedOperatori.find(
                          (item) => item.value === option.value
                        )
                      }
                      value={selectedOperatori}
                      className="basic-multi-select select-multi-options text-nowrap w-100"
                      onChange={(newValues) => {
                        if (newValues) {
                          const items = newValues.map((item) => item.value)
                          onFilterChange(items, 'operatori')
                        } else {
                          onFilterChange([], 'operatori')
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <Select
                      name="lavorazioni"
                      isMulti
                      placeholder={'Filtra per lavorazioni'}
                      options={lavorazioniForSelect.filter(
                        (lavorazione) =>
                          !selectedLavorazioni.find(
                            (item) =>
                              Number(item.value) === Number(lavorazione.value)
                          )
                      )}
                      classNamePrefix="multi-select"
                      hideSelectedOptions={false}
                      isOptionSelected={(option) =>
                        selectedLavorazioni.find(
                          (item) => item.value === option.value
                        )
                      }
                      value={selectedLavorazioni}
                      className="basic-multi-select select-multi-options text-nowrap w-100"
                      onChange={(newValues) => {
                        if (newValues) {
                          const items = newValues.map((item) => item.value)
                          onFilterChange(items, 'lavorazioni')
                        } else {
                          onFilterChange([], 'lavorazioni')
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <OrdersSelectAutoComplete
                      value={filters.ordine}
                      hasValue
                      debounceTimeout={150}
                      loadOptionsOnMenuOpen
                      onChange={(ordine) => {
                        if (ordine) {
                          onFiltersChange({ ordine: ordine.id })
                        } else {
                          onFiltersChange({ ordine: '' })
                        }
                      }}
                      noOptionsMessage={() => 'Nessun ordine trovato'}
                      loadingMessage={() => 'Caricamento ordini...'}
                      placeholder={'Filtra per ordine'}
                      isClearable={true}
                      name={'ordine'}
                      additional={{
                        page: 1,
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <DateRangeQsFilter
                      filters={filters}
                      onFiltersChange={onFiltersChange}
                      startField="from_date"
                      endField="to_date"
                      withPortal={true}
                      customArrowIcon={<div />}
                      small={false}
                      noBorder
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3 mb-2">
                <div className="col-md-8 mt-3 w-100 h-100">
                  <BarChartAttivita
                    groupedAttivitaByData={groupedAttivitaByData}
                  />
                </div>
                <div className="col-md-4 position-relative bg-light d-flex align-items-center justify-content-center">
                  <PerformanceChart percentage={percentPerformanceFromTotals} />
                  <div className="ml-5  ps-4">
                    <h6 className="ps-5">Performance attività</h6>
                    <div className="ps-5">
                      <small>
                        {moment(filters.from_date).format('DD/MM/YYYY')}
                      </small>
                      {' - '}
                      <small>
                        {moment(filters.to_date).format('DD/MM/YYYY')}
                      </small>
                    </div>
                    <div className="badge badge-primary p-2 mt-2">
                      Tempo teorico:{' '}
                      {parseInt(
                        moment.duration(totals.tempo_teorico_tot).as('minutes')
                      ) + ' min'}
                      {' ('}
                      {/* show numero gg numero hh numero mm */}
                      {moment
                        .duration(totals.tempo_teorico_tot, 'minutes')
                        .days()}
                      g{' '}
                      {moment
                        .duration(totals.tempo_teorico_tot, 'minutes')
                        .hours()}
                      h{' '}
                      {moment
                        .duration(totals.tempo_teorico_tot, 'minutes')
                        .minutes()}
                      m{')'}
                    </div>
                    <div className="badge badge-primary mt-2 p-2">
                      Tempo:{' '}
                      {parseInt(
                        moment.duration(totals.tempo_tot).as('minutes')
                      ) + ' min'}
                      {' ('}
                      {/* show numero gg numero hh numero mm */}
                      {moment
                        .duration(totals.tempo_tot, 'minutes')
                        .days()}g{' '}
                      {moment.duration(totals.tempo_tot, 'minutes').hours()}h{' '}
                      {moment.duration(totals.tempo_tot, 'minutes').minutes()}m
                      {')'}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3 mb-2">
                <div className="col-md-8 mt-3">
                  <div>
                    <div
                      className={classNames('btn pointer', {
                        'btn-primary': typeView === 'giorno-per-giorno',
                        'btn-outline-primary': typeView !== 'giorno-per-giorno',
                      })}
                      onClick={() => {
                        setTypeView('giorno-per-giorno')
                      }}
                    >
                      Giorno per giorno
                    </div>
                    <div
                      className={classNames('btn pointer', {
                        'btn-primary': typeView === 'dati-originali',
                        'btn-outline-primary': typeView !== 'dati-originali',
                      })}
                      onClick={() => {
                        setTypeView('dati-originali')
                      }}
                    >
                      Dati originali
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-end"></div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {typeView === 'dati-originali' && (
                    <TableLavorazioniOrdine
                      lavorazioniOrdine={lavorazioniOrdine.filter(
                        (l) => l.ordine
                      )}
                      loading={loading}
                      totals={totals}
                      showOldInfo={false}
                    />
                  )}
                  {typeView === 'giorno-per-giorno' && (
                    <TablePerformanceGiorno
                      groupedAttivitaByData={groupedAttivitaByData}
                      operatori={operatori}
                      lavorazioni={lavorazioni}
                    />
                  )}
                  <Paginator
                    numPages={pagination.numPages}
                    currentPage={page}
                    goToPage={goToPage}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
