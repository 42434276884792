import React, {
  useContext,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import useQueryParams from 'magik-react-hooks/useRouterQueryParams'
import useConstant from 'magik-react-hooks/useConstant'
import { ConfigPlannerContext } from '../../context'
import { useRj, useRunRj } from 'react-rocketjump'
import get from 'lodash/get'
import OrderSelector from '../../components/Planner/OrderSelector'
import { useDimensions } from '../../hooks'
import FasiSchedulazioneOrdine from '../../components/Planner/FasiSchedulazioneOrdine'
import FasiTimeline, {
  DATE_TITLE_HEIGHT,
  FASE_ROW_HEIGHT,
} from '../../components/Planner/FasiTimeline'
import {
  useOrderScheduler,
  DetailOrderState,
  FornitoriState,
  SchedulerSlotsState,
} from './localstate'
import moment from 'moment'
import { ContextFornitori } from './contexts'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import ModalOrdiniFornitori from '../../components/Planner/ModalOrdiniFornitori'

const EMPTY_LIST = []

const TIMELINE_FROM_DATE = moment().subtract(2, 'months').format('YYYY-MM-DD')
const TIMELINE_TO_DATE = moment().add(9, 'months').format('YYYY-MM-DD')

const SIDE_WIDTH = 280

const BIG_PAGE_SIZE = {
  page_size: 1000,
}

export default function Schedulatore() {
  const [{ data: ordine }, { clean: clearOrder, run: fetchOrder, patchOrder }] =
    useRj(DetailOrderState)

  const [queryParams, setQueryParams] = useQueryParams()
  const initialOrderId = useConstant(() => queryParams.id)
  useEffect(() => {
    if (initialOrderId) {
      fetchOrder(initialOrderId)
    }
  }, [initialOrderId, fetchOrder])

  const closeOrder = useCallback(() => {
    setQueryParams({ id: undefined })
    clearOrder()
  }, [clearOrder, setQueryParams])

  // Set order from modal
  const setOrdine = useCallback(
    (order) => {
      setQueryParams({ id: order.id })
      fetchOrder(order.id)
    },
    [fetchOrder, setQueryParams]
  )

  // Set order from timeline
  const onOrderSlotClick = useCallback(
    (orderData) => {
      setQueryParams({ id: orderData.id })
      fetchOrder(orderData.id)
    },
    [fetchOrder, setQueryParams]
  )

  const { fasiScheduler } = useContext(ConfigPlannerContext)
  const [{ fornitori }] = useRunRj(FornitoriState, [BIG_PAGE_SIZE])

  const [{ slots, range, loadedItems, loading }, { run: fetchSlots }] =
    useRj(SchedulerSlotsState)
  const loadSlots = useCallback(
    (from_date, to_date) => {
      if (loadedItems[from_date] && loadedItems[to_date]) {
        return
      }
      if (!loading) {
        fetchSlots({
          from_date,
          to_date,
        })
      }
    },
    [loading, fetchSlots, loadedItems]
  )

  // Current schedule order
  const refreshSlots = useCallback(
    (orderFromDate, orderToDate) => {
      if (range) {
        const minDate = new Date(Math.max(range.fromDate, orderFromDate))
        const maxDate = new Date(Math.min(range.toDate, orderToDate))
        fetchSlots({
          from_date: moment(minDate).format('YYYY-MM-DD'),
          to_date: moment(maxDate).format('YYYY-MM-DD'),
        })
      }
    },
    [range, fetchSlots]
  )
  const [
    {
      fasiSchedulazioneList,
      fasiSchedulazione,
      fasiNeeded,
      scheduleErrors,
      scheduling,
      scheduleOrder,
      saved,
      saving,
      isScheduleComplete,
      tempiOrdine,
    },
    {
      updateFornitore,
      updateDurata,
      updateDataInizio,
      saveSchedulazione,
      autoUpdateDateInizio,
    },
  ] = useOrderScheduler(ordine, fornitori, refreshSlots)

  const loadedScheduler = fasiSchedulazione !== null
  const onFreeSlotClick = useCallback(
    (nomeFase, dateTimeStr) => {
      if (loadedScheduler) {
        updateDataInizio(
          nomeFase,
          moment(dateTimeStr, 'YYYY-MM-DD HH').toDate()
        )
      }
    },
    [loadedScheduler, updateDataInizio]
  )

  const timelineRef = useRef()
  const scrollToDate = useCallback(
    (date) => timelineRef.current.scrollToDate(date),
    []
  )

  // Measure the container width
  const [ref, { width }] = useDimensions({ liveMeasure: false })

  const missingTempi = get(tempiOrdine, 'missing', [])
  const [isOpenModalTempi, setOpenModalTempi] = useState(false)
  const toggleModalTempi = useCallback(() => setOpenModalTempi((o) => !o), [])

  const [showMoveDateDelivery, setShowMoveDateDelivery] = useState(null)
  const orderId = ordine ? ordine.id : null
  const moveDateDelivery = useCallback(
    (date) => {
      if (!orderId) {
        return
      }
      setShowMoveDateDelivery(date)
    },
    [orderId]
  )

  const [modalOrdineFornitore, modalOrdineFornitoreActions] = useModalTrigger()

  return (
    // TODO: Make schedulatore context
    <ContextFornitori.Provider value={fornitori || EMPTY_LIST}>
      <ModalOrdiniFornitori
        orderId={ordine?.id}
        isOpen={modalOrdineFornitore.isOpen}
        tipologia={modalOrdineFornitore.value}
        toggle={modalOrdineFornitoreActions.toggle}
        onClosed={modalOrdineFornitoreActions.onClosed}
      />
      <Modal isOpen={isOpenModalTempi} toggle={toggleModalTempi}>
        <ModalHeader toggle={toggleModalTempi}>
          Tempistiche Mancanti
        </ModalHeader>
        <ModalBody>
          {ordine && (
            <div>
              <div>
                Attenzione mancano le seguenti tempistiche relative all'ordine
                corrente: {ordine.numero_commessa} - {ordine.cliente_data.nome}.
              </div>
              <table className="table table-bordered table-striped mt-2">
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Famiglia</th>
                    <th>Forma</th>
                    <th>Codice</th>
                  </tr>
                </thead>
                <tbody>
                  {missingTempi.map((miss, index) => (
                    <tr key={index}>
                      <td>{miss.tipo}</td>
                      <td>{miss.famiglia}</td>
                      <td>{miss.forma}</td>
                      <td>{miss.codice}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showMoveDateDelivery !== null}
        toggle={() => setShowMoveDateDelivery(null)}
      >
        <ModalHeader>Data Approntamento</ModalHeader>
        <ModalBody>
          Spostatare data approntamento a{' '}
          {showMoveDateDelivery
            ? moment(showMoveDateDelivery).format('DD-MM-YYYY')
            : null}
          {'?'}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button onClick={() => setShowMoveDateDelivery(null)}>Annulla</Button>
          <Button
            onClick={() => {
              patchOrder
                .onSuccess(() => setShowMoveDateDelivery(null))
                .run({
                  id: orderId,
                  data_consegna: showMoveDateDelivery,
                })
            }}
            color="success"
          >
            Sposta
          </Button>
        </ModalFooter>
      </Modal>
      <div className="d-flex" ref={ref}>
        {ordine && (
          <div style={{ width: SIDE_WIDTH }}>
            {fasiSchedulazioneList && (
              <FasiSchedulazioneOrdine
                titleHeight={DATE_TITLE_HEIGHT}
                faseHeight={FASE_ROW_HEIGHT}
                updateFornitore={updateFornitore}
                updateDurata={updateDurata}
                autoUpdateDateInizio={autoUpdateDateInizio}
                updateDataInizio={updateDataInizio}
                isScheduleComplete={isScheduleComplete}
                saving={saving}
                saved={saved}
                scheduling={scheduling}
                saveSchedulazione={saveSchedulazione}
                onClose={closeOrder}
                ordine={ordine}
                fasiSchedulazione={fasiSchedulazioneList}
                errors={scheduleErrors}
                scrollToDate={scrollToDate}
              />
            )}
          </div>
        )}
        {slots && width && (
          <FasiTimeline
            width={ordine ? width - SIDE_WIDTH : width}
            ref={timelineRef}
            errors={scheduleErrors}
            fromDate={TIMELINE_FROM_DATE}
            toDate={TIMELINE_TO_DATE}
            loadedDates={loadedItems}
            fasiNeeded={fasiNeeded}
            // interactive={!!scheduleOrder}
            onMoveDateDelivery={moveDateDelivery}
            onFreeSlotClick={scheduleOrder ? onFreeSlotClick : undefined}
            onOrderSlotClick={!scheduleOrder ? onOrderSlotClick : undefined}
            // onOrderSlotClick={onOrderSlotClick}
            fasiScheduler={fasiScheduler}
            dateDelivery={ordine ? ordine.data_consegna : null}
            scheduleOrder={scheduleOrder}
            fasiSchedulazioneOrdine={fasiSchedulazione}
            slots={slots}
            loadSlots={loadSlots}
            renderFaseLegend={(fase) => (
              <div className="text-capitalize text-dark d-flex justify-content-center align-items-center h-100 w-100 position-relative">
                <div>
                  {ordine && fase.with_fornitore && <div className="text-center">
                    <small>
                      <i
                        onClick={() => modalOrdineFornitoreActions.open(fase.nome_fase)}
                        className="fa fa-shopping-cart pointer text-primary"
                        aria-hidden="true"
                      ></i>
                    </small>
                  </div>}
                  <small>{fase.nome_fase}</small>
                  <br />
                  {(fase.nome_fase === 'banchi' ||
                    fase.nome_fase === 'macchine') &&
                    missingTempi.length > 0 && (
                      <div
                        style={{ position: 'absolute', left: 0, right: 0 }}
                        className="text-danger text-center pt-1"
                      >
                        <i
                          onClick={toggleModalTempi}
                          className="fa fa-exclamation pointer"
                        />
                      </div>
                    )}
                </div>
              </div>
            )}
            renderAngle={() => (
              <OrderSelector
                renderBtn={({ onClick }) => (
                  <div className="w-100 h-100 d-flex align-items-center justify-content-around border-left border-right">
                    <button
                      disabled={saving || scheduling}
                      className="btn btn-sm btn-outline-success"
                      onClick={onClick}
                    >
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="true"
                      ></i>
                    </button>
                    {ordine && (
                      <button
                        disabled={saving || scheduling}
                        className="btn btn-sm btn-outline-danger"
                        onClick={closeOrder}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </button>
                    )}
                  </div>
                )}
                selectedOrder={ordine}
                setSelectedOrder={setOrdine}
              />
            )}
          />
        )}
      </div>
    </ContextFornitori.Provider>
  )
}
