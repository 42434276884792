import React, { useState, useRef } from 'react'
import { useRj } from 'react-rocketjump'
import { ImportRigheOrdiniFornitoreState } from './localstate'
import { Link } from 'react-router-dom'

export function ImportRigheOrdiniFornitoreForm({
  onImportSuccess,
  showResults = true,
  importOnlyOrder
}) {
  const [generateOrdiniFonitore, setGenerateOrdiniFonitore] = useState(false)
  const [collapseRighe, setCollapseRighe] = useState(false)
  const [importNumeroRiga, setImportNumeroRiga] = useState(false)
  const [file, setFile] = useState(null)
  const inputRef = useRef()
  const [{ pending, error, data }, { run, clean }] = useRj(
    ImportRigheOrdiniFornitoreState
  )

  function handleSubmit(e) {
    e.preventDefault()
    if (file) {
      clean()
      run
        .onSuccess((data) => {
          setFile(null)
          inputRef.current.value = ''
          if (typeof onImportSuccess === 'function') {
            onImportSuccess(data)
          }
        })
        .run(file, {
          import_only_order: importOnlyOrder,
          generate_ordini_fornitori: generateOrdiniFonitore ? 1 : 0,
          collapse_righe: collapseRighe ? 1 : 0,
          import_riga_ordine_column: importNumeroRiga ? 1 : 0,
        })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="importFile">{file ? file.name : 'Seleziona xls'}</label>
        {file && (
          <span
            className="ml-3 pointer text-danger"
            onClick={() => {
              if (!pending) {
                setFile(null)
                clean()
                inputRef.current.value = ''
              }
            }}
          >
            <i className="fa fa-trash" aria-hidden="true"></i>
          </span>
        )}
        <input
          ref={inputRef}
          id="importFile"
          accept={'.xls'}
          type="file"
          className="form-control"
          onChange={(e) => {
            if (e.target.files) {
              setFile(e.target.files[0])
            }
          }}
        />
        <div className="form-check my-3">
          <input
            id="gen_1"
            className="form-check-input"
            type="checkbox"
            checked={generateOrdiniFonitore}
            onChange={() => setGenerateOrdiniFonitore((a) => !a)}
          />
          <label className="form-check-label" htmlFor="gen_1">
            Genera ordini a fornitore da tracciato
          </label>
        </div>
        <div className="form-check my-3">
          <input
            id="gen_2"
            className="form-check-input"
            type="checkbox"
            checked={collapseRighe}
            onChange={() => setCollapseRighe((a) => !a)}
          />
          <label className="form-check-label" htmlFor="gen_2">
            Unisci le righe uguali
          </label>
        </div>
        <div className="form-check my-3">
          <input
            id="gen_3"
            className="form-check-input"
            type="checkbox"
            checked={importNumeroRiga}
            onChange={() => setImportNumeroRiga((a) => !a)}
          />
          <label className="form-check-label" htmlFor="gen_2">
            Importa numero riga ordine
          </label>
        </div>
        <div className="pt-2">
          <button
            disabled={!file || pending}
            type="submit"
            className="btn btn-success"
          >
            Importa
          </button>
        </div>
      </div>
      {data && showResults && (
        <div className="alert alert-success mt-3">
          <p>Importazione avvenuta con successo.</p>
          <div>
            <div>
              <b>Righe Importate:</b> {data.rows_count}
            </div>
            <b>Ordini:</b>
            <ul>
              {data.orders.map((ordine) => (
                <li key={ordine.id}>
                  <Link to={`/ordini/${ordine.id}/fornitori`}>
                    {ordine.numero_commessa}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {error && (
        <div className="alert alert-danger mt-3">
          <p>{error.response?.[0] ?? "Errore durante l'importazione"}</p>
        </div>
      )}
    </form>
  )
}

export default function ImportRigheOrdiniFornitore() {
  return (
    <div className="container">
      <h2>Importa Righe Ordini a Fornitore</h2>
      <ImportRigheOrdiniFornitoreForm />
    </div>
  )
}
